import { useMemo, useState, useEffect } from "react";
import { Link, useLocation } from 'react-router-dom';
import { PHONE_CODES } from "../../constants";
import { IResourceComponentsProps, useTranslate } from "@refinedev/core";
import { Create, useForm } from "@refinedev/antd";
import {
    Form,
    Input,
    Avatar,
    Row,
    Col,
    Typography,
    Space,
    Radio,
    Select,
    InputProps,
    Alert
} from "antd";

import MaskedInput from "antd-mask-input";

const { Text } = Typography;

import { ICustomer } from "interfaces";
import { stringify } from "querystring";

export const CustomerCreate: React.FC<IResourceComponentsProps> = () => {
    const t = useTranslate();
    const { formProps, saveButtonProps, queryResult, redirect } = useForm<ICustomer>({
        redirect: false,
        onMutationSuccess: (data, variables, context) => {
            console.log({ data, variables, context });
            console.log("data",data);
            // redirect("show", data?.data?.id);
            redirect("edit", data?.data?.id,{ 'openModal': 'true'});
        }
    });
    const [customerType, setCustomerType] = useState(null);
    const [customerPhone, setCustomerPhone] = useState('');
    const [phoneMask, setPhoneMask] = useState(null as any);
    // const [phoneCodes, setPhoneCodes] = useState([] as any[]);

    const handleTypeChange = (e: any) => {
        setCustomerType(e.target.value);
        onNameChange(e.target.value);
    };

    const phoneCodes = PHONE_CODES?.map(item => { return { label: `${item.name} (${item.code})`, value: item.iso, mask: item.mask}}) ?? [];

    const changePhoneCode = (value: any) => {
        console.log(`selected ${value}`);
        let findMask = phoneCodes?.find(item => item.value===value)?.mask;
        if(Array.isArray(findMask)){
            let mask =  findMask[findMask.length - 1]; // findMask.map((item: any) => { return { mask: item.replaceAll("9","0"), lazy: true } });
            console.log(mask);
            setPhoneMask(mask?.replaceAll("9","0"));
        } else {            
            let mask = findMask; //Array.isArray(findMask) && findMask?.length ? findMask[findMask.length - 1] : findMask; // pega último mask por padrão
            console.log(mask);
            setPhoneMask(mask?.replaceAll("9","0") || "(00) 00000-0000");
        }        
    };

    const onNameChange = (value: any) => {

        const type = formProps.form?.getFieldValue("type");
        const name = formProps.form?.getFieldValue("name");
        if(type==="individual" && name){
            formProps.form?.setFields([
                {
                    name: "razaoSocial", // required
                    value: name
                },
            ]);
        }        
    }

    const location = useLocation();
    const currentPath = location.pathname;
    const customerView = currentPath?.includes("payers") ? "Pagador" : "Recebedor";

    return (
        <Create
            isLoading={queryResult?.isFetching}
            saveButtonProps={saveButtonProps}
            title={`Criar Novo ${customerView}`}
        >            
            <Form
                {...formProps}
                layout="vertical"
                initialValues={{
                    countryCode: "BR",
                    active: true,
                    phone: null,
                    payerOrReceiver: "all"
                }}
            >
                <Row gutter={[64, 0]} wrap>
                    <Col xs={24} lg={12}>
                        <Form.Item
                            label={t("customers.fields.type.title")}
                            name="type"
                            rules={[
                                {
                                    required: true,
                                },
                            ]}
                        >
                            <Radio.Group onChange={handleTypeChange}>
                                <Radio value="individual">{t("customers.fields.type.individual")}</Radio><br/>
                                <Radio value="corporation">{t("customers.fields.type.corporation")}</Radio>
                            </Radio.Group>
                        </Form.Item>
                        <Form.Item
                            label={t("customers.fields.cpfCnpj")}
                            name="cpfCnpj"
                            rules={[
                                {
                                    required: true,
                                },
                            ]}
                        >
                            <MaskedInput
                                disabled={false}
                                mask={customerType==="individual" ? '000.000.000-00' : '00.000.000/0000-00'}
                            >
                                    {/* 
                                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                        // @ts-ignore */}
                                    {/* {(props: InputProps) => <Input disabled={false} {...props} />} */}
                            </MaskedInput>
                        </Form.Item>  
                        <Form.Item
                            label={t("customers.fields.name")}
                            name="name"
                            rules={[
                                {
                                    required: true,
                                },
                            ]}                            
                        >
                            <Input onChange={onNameChange}/>
                        </Form.Item>
                        <Form.Item
                            label={t("customers.fields.razaoSocial")}
                            name="razaoSocial"
                            rules={[
                                {
                                    required: true
                                },
                            ]}                            
                        >
                            <Input disabled={formProps.form?.getFieldValue("type")==="individual" ? true : false}/>
                        </Form.Item>   
                        <Form.Item
                            label={t("customers.fields.payerOrReceiver.title")}
                            name="payerOrReceiver"
                            rules={[
                                {
                                    required: true,
                                },
                            ]}
                        >
                            <Radio.Group>
                                <Radio value="payer">{t("customers.fields.payerOrReceiver.payer")}</Radio><br/>
                                <Radio value="receiver">{t("customers.fields.payerOrReceiver.receiver")}</Radio><br/>
                                <Radio value="all">{t("customers.fields.payerOrReceiver.all")}</Radio>
                            </Radio.Group>
                        </Form.Item>                                           
                    </Col>
                    <Col xs={24} lg={12}>
                        {/* <Form.Item
                            label={t("customers.fields.address")}
                            name={["address", "text"]}
                            rules={[
                                {
                                    required: true,
                                },
                            ]}
                        >
                            <Input.TextArea rows={8} />
                        </Form.Item> */}
                        <Form.Item
                            label={t("customers.fields.email")}
                            name="email"
                            rules={[
                                {
                                    required: false,
                                    type: "email",
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>

                        <Space.Compact style={{ width: '100%' }}>
                        <Form.Item
                            label={"País"}
                            name="countryCode"
                            style={{ width: '40%' }}
                        >
                            <Select
                                showSearch
                                // defaultValue="BR"
                                options={phoneCodes}
                                onChange={changePhoneCode}
                                // onSearch={onSearch}
                                filterOption={(input, option) =>
                                    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                }
                            />
                        </Form.Item>
                        <Form.Item
                            label={t("customers.fields.phone")}
                            name="phone"
                            style={{ width: '60%' }}
                            rules={[
                                {
                                    required: false,
                                },
                            ]}
                        >
                            <MaskedInput
                                    disabled={false}
                                    mask={phoneMask || "(00) 00000-0000"}
                                    >
                                    {/* 
                                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                    // @ts-ignore */}
                                    {/* {(props: InputProps) => <Input {...props} />} */}
                            </MaskedInput>                            
                        </Form.Item>
                        </Space.Compact>                        
                    </Col>
                </Row>
                <Alert
                    message="Obs: Clientes devem ter apenas um cadastro único, fazemos a verifição pelo CPF/CNPJ"
                    // description="Additional description and information about copywriting."
                    type="info"
                    style={{margin: '15px auto'}}
                    showIcon
                />
            </Form>
        </Create>
    );
};
