import { useState, useEffect } from "react";
import { TOKEN_KEY, API_URL } from "./../../constants";
import { useApiUrl, useCustom, useCustomMutation, useTranslate } from "@refinedev/core";
import { Edit, useForm } from "@refinedev/antd";
import { Form, Row, Col, Input, Card, Space, Button, Typography, Alert, Tooltip } from "antd";
import { SaveOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import { update } from "lodash";

const { Text } = Typography;

export const PreferencePage: React.FC = () => {
    const { t } = useTranslation();
    const { formProps, saveButtonProps, onFinish } = useForm();    
    const [errorMsg,setErrorMsg] = useState("");
    const { mutate } = useCustomMutation();


    // Pega dados pessoais/preferências direto da custom endpoint
    const url = `${API_URL}/auth/me`;
    const { data } = useCustom<{data: any; user: any;}>({ url, method: "get" });
    const user : any = data?.data?.user;

    const handleSubmit = async() => {

        let values : any = formProps.form?.getFieldsValue();
        console.log(formProps.form?.getFieldsValue());

        let password = values.password;
        let newPassword = values.newPassword;
        let newPasswordConfirm = values.newPasswordConfirm;
        
        setErrorMsg("");
        const strongPassword = new RegExp("(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})");

        if(!password){
            setErrorMsg("Insira sua senha atual")
            formProps.form?.setFields([ { name: "password", errors: ["Insira sua senha atual"] }, ]);
            return false;
        }
        if(!newPassword || !newPasswordConfirm){
            setErrorMsg("Insira sua nova senha e a confirmação")
            formProps.form?.setFields([ { name: !newPassword ? "newPassword" : "newPasswordConfirm", errors: ["Insira sua nova senha e a confirmação"] }, ]);
            return false;
        }
        if(newPassword!==newPasswordConfirm){
            setErrorMsg("As novas senhas não são iguais")
            formProps.form?.setFields([ { name: "newPassword", errors: ["As novas senhas não são iguais"] }, ]);
            formProps.form?.setFields([ { name: "newPasswordConfirm", errors: ["As novas senhas não são iguais"] }, ]);
            return false;
        }
        if(newPassword==password){
            setErrorMsg("Sua nova senha é a mesma que a atual, crie uma nova senha diferente")
            formProps.form?.setFields([ { name: "password", errors: ["Sua nova senha é a mesma que a atual, crie uma nova senha diferente"] }, ]);
            formProps.form?.setFields([ { name: "newPasswordConfirm", errors: ["Sua nova senha é a mesma que a atual, crie uma nova senha diferente"] }, ]);
            return false;
        }
        if(!strongPassword.test(newPassword)){
            setErrorMsg("Sua nova senha deve conter no mínimo 8 caracteres e conter ao menos uma letra minúscula, uma letra maiucúscula e um número")
            formProps.form?.setFields([ { name: "newPassword", errors: ["Sua nova senha deve conter no mínimo 8 caracteres e conter ao menos uma letra minúscula, uma letra maiucúscula e um número"] }, ]);
            return false;
        }

        mutate({
            url: `${API_URL}/auth/password/edit`,
            method: "post",
            values: {
                password,
                newPassword,
                newPasswordConfirm
            },        
            successNotification: (data, values) => {
                formProps.form?.setFields([ { name: "password", value: null }, { name: "newPassword", value: null }, { name: "newPasswordConfirm", value: null } ]);                
                return {
                    message: `Senha alterada`,
                    description: "Sua nova senha foi cadastrada com sucesso",
                    type: "success",
                };
            },
            errorNotification: (data, values) => {
                return {
                    message: `Oops! Houve um erro`,
                    description: "Não conseguimos atualizar sua senha, tente novamente ou entre em contato com o nosso suporte.",
                    type: "error",
                };
            }
        });

    }

    // console.log(formProps);

    return (
        <Edit
            headerProps={{
                extra: <></>,
            }}
            breadcrumb={<></>}
            footerButtons={<></>}
        >            
                <Row gutter={[64, 0]} wrap>
                    <Col xl={12} lg={12} md={12} sm={24} xs={24}>
                        <Row>
                            <Text style={{ fontSize: 20, fontWeight: 'bold' }}>{t("users.fields.name")}:</Text>
                        </Row>
                        <Row>
                            <Text style={{ fontSize: 14 }}>{user?.name}</Text>
                        </Row>

                        <Row>
                            <Text style={{ fontSize: 20, fontWeight: 'bold' }}>{t("users.fields.email")}:</Text>
                        </Row>
                        <Row>
                            <Text style={{ fontSize: 14, marginBottom: 20 }}>{user?.email}</Text>
                        </Row>
                    </Col>
                                 
                    <Col xl={12} lg={12} md={12} sm={24} xs={24}>
                        <Form
                            {...formProps}
                            // form={form}
                            layout="vertical"
                            initialValues={{
                                active: true,
                                // ...formProps
                            }}
                            onFinish={handleSubmit}
                        >       
                            <Row>
                                <Text style={{ fontSize: 20, fontWeight: 'bold' }}>Alterar senha:</Text>
                            </Row>
                            <Form.Item
                                label={t("pages.updatePassword.fields.password")}
                                name="password"
                                rules={[
                                    {
                                        required: true,
                                    },
                                ]}
                                tooltip={"Sua senha atual"}
                            >
                                <Input.Password />
                            </Form.Item> 
                            <Form.Item
                                label={t("pages.updatePassword.fields.newPassword")}
                                name="newPassword"
                                rules={[
                                    {
                                        required: true,
                                    },
                                ]}
                                tooltip={"Sua nova senha deve conter no mínimo 8 caracteres e conter ao menos uma letra minúscula, uma letra maiucúscula e um número"}
                            >
                                    <Input.Password />
                            </Form.Item>       
                            <Form.Item
                                label={t("pages.updatePassword.fields.confirmNewPassword")}
                                name="newPasswordConfirm"
                                rules={[
                                    {
                                        required: true,
                                    },
                                ]}
                                tooltip={"Sua nova senha deve conter no mínimo 8 caracteres e conter ao menos uma letra minúscula, uma letra maiucúscula e um número"}
                            >
                                <Input.Password />
                            </Form.Item> 
                            {errorMsg && (
                                <Space direction="vertical" style={{ width: '100%', marginBottom: 15 }}>
                                    <Alert
                                        message="ATENÇÃO:"
                                        description={errorMsg}
                                        type="error"
                                        showIcon
                                    />
                                </Space>
                            )}       
                            <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
                                <Button
                                type="primary"
                                htmlType="submit"
                                icon={<SaveOutlined />} 
                                // onClick={() => updatePassword()}
                                size="large">
                                    Salvar
                                </Button>
                            </Form.Item>
                        </Form>
                    </Col>
                    
                </Row>
        </Edit>
    );
};
