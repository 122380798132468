import { TOKEN_KEY, API_URL } from "./../../constants";
import { useMemo, useState, useEffect } from "react";
import { useTranslate, useCustom, IResourceComponentsProps } from "@refinedev/core";
import {
    List,
    useTable,
    BooleanField,
    TextField,
    useEditableTable,
    SaveButton,
    NumberField,
    DateField,
    useDrawerForm,
    EditButton,
    ShowButton,
    CreateButton
} from "@refinedev/antd";

import {
    InfoText,
    InfoBox,
    InfoBoxText,
    BoxContainer,
} from "./styled";

import { FormOutlined, MoreOutlined } from "@ant-design/icons";
import {
    Table,
    Space,
    Form,
    Card,
    Row,
    Col,
    Button,
    Input,
    Checkbox,
    Dropdown,
    Menu,
    Avatar,
    Typography,
    Grid,
    Alert,
    Descriptions,
    Switch,
    Spin
} from "antd";

import "./../../css/main.css";

const { Text } = Typography;

import { ICompanyAccount, ICompany } from "interfaces";

import { InvoiceStatus, AccountStatus } from "components";

export const CompanyAccountList: React.FC<IResourceComponentsProps> = () => {
    
    const [loading, setLoading] = useState(false);

    const {
        tableProps,
        formProps,
        isEditing,
        saveButtonProps,
        cancelButtonProps,
        setId: setEditId,
    } = useEditableTable<ICompanyAccount>({
        initialSorter: [
            {
                field: "active",
                order: "desc",
            },
        ],
    });

    const t = useTranslate();

    // Pega Empresa da endpoint
    const companyId = localStorage.getItem("companyId");
    const url = `${API_URL}/companies/${companyId}`;
    const { data } = useCustom<{data: any;}>({ url, method: "get" });
    const company : any = data?.data;
    console.log(company);

    const moreMenu = (record: ICompanyAccount) => (
        <Menu
            mode="vertical"
            onClick={({ domEvent }) => domEvent.stopPropagation()}
        >
            <Menu.Item
                key="accept"
                style={{
                    fontSize: 15,
                    display: "flex",
                    alignItems: "center",
                    fontWeight: 500,
                }}
                icon={
                    <FormOutlined
                        style={{
                            color: "#52c41a",
                            fontSize: 17,
                            fontWeight: 500,
                        }}
                    />
                }
                onClick={() => {
                    setEditId?.(record.id);
                }}
            >
                {t("buttons.edit")}
            </Menu.Item>
        </Menu>
    );

    const renderCompany = () => (
        <>
        <Card style={{ marginBottom: '30px' }}>
            <Row>
                <Col xl={24} lg={24} md={24}>
                    <Descriptions title={"Dados Cadastrais:"}>
                        <Descriptions.Item label={t("companies.fields.name")} span={3}>{company?.name}</Descriptions.Item>
                        <Descriptions.Item label={t("companies.fields.razaoSocial")} span={3}>{company?.razaoSocial}</Descriptions.Item>
                        <Descriptions.Item label={t("companies.fields.cpfCnpj")} span={3}>{company?.cpfCnpj}</Descriptions.Item>
                        <Descriptions.Item label={t("companies.fields.defaultFee")} span={3}>{company?.defaultFee}%</Descriptions.Item>
                    </Descriptions>                                    
                    {/* <InfoBox> */}
                        {/* <InfoText>
                            <Text style={{ fontSize: 20, fontWeight: 'bold' }}>{t("companies.companies")}:</Text>
                            <Text>{t("companies.fields.name")}: {company?.name}</Text>
                            <Text>{t("companies.fields.razaoSocial")}:{company?.razaoSocial}</Text>
                            <Text>{t("companies.fields.cpfCnpj")}: {company?.cpfCnpj}</Text>
                            <Text>{t("companies.fields.defaultFee")}: {company?.defaultFee}%</Text>
                        </InfoText> */}
                    {/* </InfoBox> */}
                </Col>
            </Row>
        </Card>
        {(tableProps?.dataSource?.length && tableProps?.dataSource?.filter((item : any) => item.status===0)?.length > 0) && (
            <Alert
                message="Conta Em Análise: após inserir uma nova conta, ela passará por uma análise de aprovação de cadastro. (Média de 5 minutos. Máxima em até 1 dia útil)"
                // description="Additional description and information about copywriting."
                type="info"
                showIcon/>
        )}
        </>
    );

    const breakpoint = Grid.useBreakpoint();

    const TrueIcon = () => <span>✅</span>;

    const FalseIcon = () => <span>❌</span>;

    const loader = () => {
        setLoading(true);
        setTimeout(() => {
            setLoading(false);
        }, 3000);            
    };

    const rowClassName = (record : any) => {
        return record.active ? '' : 'row-disabled';
    };

    return (  
        <>
        {renderCompany()}
        <List
            headerProps={{
                style: { marginTop: 20 },
                extra: (<CreateButton>Incluir Conta Bancária</CreateButton>),
            }}
            title={
                <Text style={{ fontSize: 22, fontWeight: 800 }}>
                    Minhas Contas Bancárias
                </Text>
            }>
            <Alert
                message={`Ao criar uma conta bancária ela irá passar por um processo de análise de aprovação em alguns minutos.`}
                type="info"
                showIcon
                style={{marginTop: 15, marginBottom: 15}}
            />
            <Form {...formProps}>
                {!tableProps?.dataSource?.length &&
                    (<Space direction="vertical" style={{ width: '100%', marginBottom: 20 }}>
                        <Alert
                            message="ATENÇÃO"
                            description={t("accounts.titles.empty")}
                            type="error"
                            showIcon
                            />
                        </Space>
                    )
                }
                <Table
                    {...tableProps}
                    rowKey="id"
                    onRow={(record) => ({
                        // eslint-disable-next-line
                        onClick: (event: any) => {
                            if (event.target.nodeName === "TD") {
                                setEditId && setEditId(record.id);
                            }
                        },
                    })}
                    rowClassName={rowClassName}
                >
                    <Table.Column
                            key="name"
                            dataIndex="name"
                            title={t("accounts.fields.name")}
                            render={(value,record : any) => <TextField value={`${value} - ${record.cpfCnpj ?? ""}`} />}
                        />
                    {/* <Table.Column
                            key="cpfCnpj"
                            dataIndex="cpfCnpj"
                            title={t("accounts.fields.cpfCnpj")}
                            render={(value) => <TextField value={value} />}
                        /> */}
                    <Table.Column
                            key="bank"
                            dataIndex="bank"
                            title={t("accounts.fields.bank")}                            
                            render={(value) => 
                                <Space
                                size="large"
                                style={{
                                    display: "flex",
                                    minWidth: 75,
                                    justifyContent: "space-between",
                                }}
                                >
                                    <TextField value={value} />
                                </Space>
                            }
                        />
                    <Table.Column
                            key="agency"
                            dataIndex="agency"
                            title={t("accounts.fields.agency")}
                            render={(value) => <TextField value={value} />}
                        />             
                    <Table.Column
                            key="account"
                            dataIndex="account"
                            title={t("accounts.fields.account")}
                            render={(value) => <TextField value={value} />}
                        />        
                    <Table.Column
                            key="typeText"
                            dataIndex="typeText"
                            title={t("accounts.fields.type")}
                            render={(value) => <TextField value={value} />}
                        />         
                    <Table.Column
                            key="statusText"
                            dataIndex="statusText"
                            title={"Status de Análise"}
                            render={(value) => {
                                return <AccountStatus status={value} />;
                            }}
                    />
                    <Table.Column
                        key="active"
                        dataIndex="active"
                        title={t("accounts.fields.active.title")}
                        /*  render={(value) => <BooleanField value={value} />} */
                        render={(value, data: ICompanyAccount) => {
                            if (isEditing(data.id)) {
                                return (
                                    <Button {...saveButtonProps} style={{ padding: 0, border: 'none', background: 'transparent' }}>
                                        <Form.Item
                                                name="active"                                                
                                                valuePropName="checked"
                                        >
                                            {(loading) && (<Spin spinning={loading}/>)}
                                            <Switch checkedChildren="SIM" unCheckedChildren="NÃO" onChange={loader}/>
                                        </Form.Item>
                                    </Button>
                                );
                            }
                            return <Switch onClick={() => { loader(); setEditId(data.id); }} disabled={true} checked={value} style={{ 'pointerEvents': 'none'}} checkedChildren="SIM" unCheckedChildren="NÃO"/>
                            // <BooleanField
                            // trueIcon={<TrueIcon />}
                            // falseIcon={<FalseIcon />}
                            // value={value} />;
                        }}
                    />
                    {/* <Table.Column<ICompanyAccount>
                        title={t("table.actions")}
                        dataIndex="actions"
                        key="actions"
                        align="center"
                        render={(_text, record) => {
                            if (isEditing(record.id)) {
                                return (
                                    <Space>
                                        <SaveButton
                                            {...saveButtonProps}
                                            size="small"
                                        />
                                        <Button
                                            {...cancelButtonProps}
                                            size="small"
                                        >
                                            {t("buttons.cancel")}
                                        </Button>
                                    </Space>
                                );
                            }
                            return (
                                <Dropdown
                                    overlay={moreMenu(record)}
                                    trigger={["click"]}
                                >
                                    <MoreOutlined
                                        onClick={(e) => e.stopPropagation()}
                                        style={{
                                            fontSize: 24,
                                        }}
                                    />
                                </Dropdown>
                            );
                        }}
                    /> */}
                    {/* <Table.Column<ICompanyAccount>
                    title="Opções"
                    dataIndex="actions"
                    render={(_, record) => {
                        if (isEditing(record.id)) {
                            return (
                                <Space>
                                    <SaveButton
                                        {...saveButtonProps}
                                        size="small"
                                    />
                                    <Button
                                        {...cancelButtonProps}
                                        size="small"
                                    >
                                        {t("buttons.cancel")}
                                    </Button>
                                </Space>
                            );
                        }
                        return (                        
                        <Space>
                            <EditButton
                                hideText
                                size="large"
                                recordItemId={record.id}
                                onClick={() => {
                                    setEditId?.(record.id);
                                }}
                            />
                        </Space>
                    )}}
                    /> */}
                </Table>                
            </Form>            
        </List>
        </>
    );
};
