import { useState, useEffect } from "react";
import { TOKEN_KEY, API_URL, PAGARME_TRANFER_FEE, PAGARME_BOLETO_FEE, PAGARME_PIX_FEE } from "./../../constants";
import { replaceAccents } from "./../../functions";
import { IResourceComponentsProps, useCustom, useTranslate, useLink } from "@refinedev/core";
import { MinusCircleOutlined, PlusOutlined, DeleteOutlined, NotificationFilled } from "@ant-design/icons";
import { Create, useForm, useTable, NumberField, SaveButton } from "@refinedev/antd";
import locale from 'antd/es/date-picker/locale/pt_BR';
import dayjs from "dayjs";
import {
    Form,
    Input,
    Card,
    Select,
    InputNumber,
    Avatar,
    Row,
    Col,
    Typography,
    Space,
    Alert,
    Radio,
    InputProps,
    Button,
    Switch,
    Checkbox,
    Drawer,
    Table,
    DatePicker,
    DatePickerProps
} from "antd";

const { Text } = Typography;
const { TextArea } = Input;

import { IInvoice, ITag } from "interfaces";
import type { CheckboxChangeEvent } from 'antd/es/checkbox';
import { type } from "os";
import axios from "axios";

export const InvoiceCreate: React.FC<IResourceComponentsProps> = () => {

    const t = useTranslate();
    const Link = useLink();
    const { Option } = Select;

    const { formProps, saveButtonProps, queryResult, redirect } = useForm<IInvoice>({
        redirect: false,
        onMutationSuccess: (data, variables, context) => {
            console.log({ data, variables, context });
            console.log("data",data);
            redirect("show", data?.data?.id);
        }
    });    
    // const [customers, setCustomers] = useState([]);    
    // const [payers, setPayers] = useState([{label: "", value: ""}]);
    // const [receivers, setReceivers] = useState([{label: "", value: ""}]);        
    const [receiversErrorMsg,setReceiversErrorMsg] = useState("");
    const [errorMsg,setErrorMsg] = useState("");
    const [gross, setGross] = useState(0);
    const [fees, setFees] = useState(0);
    const [fixedFees, setFixedFees] = useState(0);
    const [transfers, setTransfers] = useState(0);
    const [total, setTotal] = useState(0);
    const [paymentType, setPaymentType] = useState(null);
    const [open, setOpen] = useState(false);
    const [groups, setGroups] = useState([]);
    const companyFee : number = Number(localStorage.getItem("companyFee") ?? 2) ?? 2;
    const split = [
        {
            customerId: null,
            accountId: null,
            amount: 0,
            fees: 0,
            total: 0,
            name: null,
            type: "customer"
    }];    
    console.log(companyFee);
    console.log(formProps);
    console.log(formProps.form?.getFieldsValue());

    // Update property of sum state
    const updateSplit = () => {

        // console.log("updateSplit",value);
        let receiversErrorMsgAlert : any = [];
        let type = formProps.form?.getFieldValue("type");
        let calcGross = formProps.form?.getFieldValue("gross") ?? 0;
        let calcTransfers : any = formProps.form?.getFieldValue("split")?.reduce((prev: number,curr: any) => prev+=(((curr?.amount ?? 0) + (curr?.fees ?? 0))) ,0) ?? 0;
        let calcFees = calcGross * companyFee/100;
        let calcFixedFees = (formProps.form?.getFieldValue("splitCompany")?.fees ?? 0);
        let calcTotal = calcGross - calcTransfers - calcFees - calcFixedFees;
        let expireAtRaw = formProps.form?.getFieldValue("expireAt");
        setGross(calcGross);
        setTransfers(calcTransfers);
        setFees(calcFees);
        setTotal(calcTotal);
        setFixedFees(calcFixedFees);
        setPaymentType(type);

        let split = formProps.form?.getFieldValue("split")?.map( (item : any) => {
            return {
                ...item,        
                total: (item?.amount ?? 0) + (item?.fees ?? 0)
            }
        });

        formProps.form?.setFields([
            // {
            //     name: "gross",
            //     // value: calcGross,
            //     errors: [(calcGross && calcGross<1000) ? `Oops! Insira um valor válido de cobrança, valor mínimo de R$ 1000` : ``]
            // },
            {
                name: "expireAtRaw", // required
                value: `${expireAtRaw?.["$y"]}-${("0" + (Number(expireAtRaw?.["$M"]) + 1))?.slice(-2)}-${("0" + expireAtRaw?.["$D"])?.slice(-2)}`
            },
            {
                name: "fees", // required
                value: calcFees
            },
            {
                name: "transfers", // required
                value: calcTransfers
            },
            {
                name: "total", // required
                value: calcTotal
            },
            {
                name: "fixedFees", // required
                value: calcFixedFees
            },
            {
                name: "split", // required
                value: split
            },
            {
                name: "splitCompany",
                value: {
                    ...formProps.form?.getFieldValue("splitCompany"),
                    amount: calcTotal,
                    fees: calcFixedFees,
                    total: calcTotal + calcFixedFees,
                    accountId: formProps.form?.getFieldValue("accountId") ?? null
                }
            },
            {
                name: "splitPlatform",
                value: {
                    ...formProps.form?.getFieldValue("splitPlatform"),
                    amount: calcFees - Number((type==="boleto") ? PAGARME_BOLETO_FEE : PAGARME_PIX_FEE),
                    fees: (type==="boleto") ? PAGARME_BOLETO_FEE : PAGARME_PIX_FEE,
                    total: calcFees,
                }
            }
        ]);

        // valida se valores distribuidos são maiores que o recebimento
        if(calcGross && (calcGross<(calcTransfers + calcFees + calcFixedFees))){
            receiversErrorMsgAlert.push(`Oops! Valores distribuídos (R$ ${calcTransfers}) estão maiores que o total a ser recebido - taxas (R$ ${calcGross - calcFees})`);
        }
        if(receiversErrorMsgAlert) setReceiversErrorMsg(receiversErrorMsgAlert?.join(" \n"));

    };

    // Pega Customers da endpoint
    const { data } = useCustom<{data: any;}>({ url: `${API_URL}/customers?payerOrReceiver=all`, method: "get"});
    const customers : any = data?.data;
    console.log(customers);
    // setCustomers(result);    

    // Filtra pagadores
    const payers = customers?.filter((item : any) => item.active && ["payer","all"].includes(item.payerOrReceiver))?.map(
                    (item : any) => {
                        return {
                            value: item.id,
                            label: `${item.name} (${item.razaoSocial} | ${item.cpfCnpj} )`,
                            disabled: !item.active ? true : false
                        }
                    }
    );
                
    // Filtra recebedores
    const receivers = Object.values(customers?.filter((item : any) => 
    // item.active && item?.account?.some((item : any) => item.active && item.statusText==="Aprovado") &&
    ["receiver","all"].includes(item.payerOrReceiver))?.map(
                    (item : any) => {
                        return {
                            value: item.id,
                            label: `${item.name} (${item.razaoSocial} | ${item.cpfCnpj} )`,                            
                            account: item.account ?? [],
                            disabled: item.active && item?.account?.some((ac : any) => ac.active && ac.statusText==="Aprovado") ? false : true
                        }
                    }
    )?.reduce((acc: any, item: any) => {
        const { disabled } = item;
        const label = disabled ? 'Recebedores inativos ou cadastro incompleto' : 'Recebedores ativos';
      
        if (!acc[label]) {
          acc[label] = { label, options: [] };
        }
      
        acc[label].options.push(item);
      
        return acc;
    }, {}) ?? []) as any;      

    const onCustomerChange = (value: any, option: any) => {
        let split = formProps.form?.getFieldValue("split") ?? [];
         // filtra dados do cliente pelo id selecionado
        let customer = customers.find((item : any) => item.id===value) ?? {};
        let account = customer?.account?.filter((item : any) => item.active && item.statusText==="Aprovado");
        console.log("zzz1",value,option,customer);

        formProps.form?.setFields([
            {
                name: "split", // required
                value: split.map((item: any) => {
                    let receiverFee = (customer?.account?.[0]?.bank?.includes("(237)") ? 0 : PAGARME_TRANFER_FEE);
                    return {
                        ...item,
                        accountId: ((item?.customerId===value ? customer?.account?.filter((ac : any) => ac.active && ac.statusText==="Aprovado")?.[0]?.accountId : item.accountId) ?? null),
                        fees: ((item?.customerId===value ? receiverFee : item.fees) ?? 0),
                        total: item.amount ? item.amount + ((item?.customerId===value ? receiverFee : item.fees) ?? 0) : 0,
                        customer: ((item?.customerId===value ? customer : item.customer) ?? {}),
                        account: ((item?.customerId===value ? account : item.account) ?? []),
                        name: ((item?.customerId===value ? customer?.name : item.name) ?? null),
                    }
                })
            },
        ]);
        console.log("zzz2",formProps.form?.getFieldsValue());
    }

    const onAccountChange = (value: any) => {
        console.log("www1",value?.target?.value); // accountId do cliente
        let accountId = value?.target?.value ?? null;
        let split = formProps.form?.getFieldValue("split") ?? [];
        // filtra dados do cliente pelo id selecionado
        let customer = split.find((item : any) => item.accountId===accountId) ?? {};
        console.log(customer);

        formProps.form?.setFields([
            {
                name: "split", // required
                value: split.map((item: any) => {
                    let receiverFee = (customer?.account?.find((item : any) => item.accountId===accountId)?.bank?.includes("(237)") ? 0 : PAGARME_TRANFER_FEE);
                    return {
                        ...item,
                         // TO-DO verificar se eh bradesco ou nao
                        fees: item.customerId===customer.customerId ? receiverFee : item.fees,
                        total: item.amount + (item.customerId===customer.customerId ? receiverFee : item.fees)
                    }
                })
            },
        ]);
        updateSplit();
        console.log("www2",formProps.form?.getFieldsValue());
    }

    const validateInvoice = async () => {

        console.log("validateInvoice",formProps.form?.getFieldsValue());
        console.log(gross,fees,transfers,total);

        let errorMsgAlert : any = [];
        let errorInputs : any = [];
        let values : any = formProps.form?.getFieldsValue();

        if(!formProps.form?.getFieldValue("customerId")) errorMsgAlert.push(`Selecione o cliente pagador`);
        if(!formProps.form?.getFieldValue("type")) errorMsgAlert.push(`Selecione uma forma de pagamento`);

        let calcGross = formProps.form?.getFieldValue("gross") ?? 0;
        if(calcGross<1000){
            errorMsgAlert.push(`Oops! Insira um valor válido de cobrança, valor mínimo de R$ 1000`);
            formProps.form?.setFields([ { name: "gross", errors: ["Oops! Insira um valor válido, valor mínimo de R$ 1000"] }, ]);
        }
        
        let split = formProps.form?.getFieldValue("split") ?? [];

        split.map((item : any, index : number) => {
            if(!item?.customerId) errorMsgAlert.push(`Selecione um cliente no Recebedor # ${index + 1} ${item?.customer?.name ?? ""}`);
            if(!item?.accountId) errorMsgAlert.push(`Selecione uma conta bancária do Recebedor # ${index + 1} ${item?.customer?.name ?? ""}`);
            if(item?.customerId && item?.accountId && item.account?.find((item : any) => item.id===item.accountId)?.customerId!==item.customerId) errorMsgAlert.push(`Verifique e selecione novamente uma conta bancária do Recebedor # ${index + 1} ${item?.customer?.name ?? ""}`);
            if(!item?.amount) errorMsgAlert.push(`Insira o valor a pagar do Recebedor # ${index + 1} ${item?.customer?.name ?? ""}`);
            if(split?.filter((s : any) => s?.customerId && s?.customerId===item?.customerId && s?.type==="customer")?.length>1) errorMsgAlert.push(`Recebedor # ${index + 1} ${item?.customer?.name ?? ""} com cadastro duplicado`);
        });

        if(!formProps.form?.getFieldValue("accountId")) errorMsgAlert.push(`Selecione uma conta bancária de recebimento`);
        if(!formProps.form?.getFieldValue("terms")) errorMsgAlert.push(`Confirme seu acordo com os Termos de Serviço`);

        console.log("aaaa",Number(formProps.form?.getFieldValue("transfers")),Number(formProps.form?.getFieldValue("fees")),Number(formProps.form?.getFieldValue("fixedFees")));
        if(formProps.form?.getFieldValue("gross") && (formProps.form?.getFieldValue("gross")<(Number(formProps.form?.getFieldValue("transfers")) + Number(formProps.form?.getFieldValue("fees")) + Number(formProps.form?.getFieldValue("fixedFees"))))){
            errorMsgAlert.push(`Oops! Valores distribuídos (R$ ${formProps.form?.getFieldValue("transfers")}) estão maiores que o total a ser recebido + taxas (R$ ${formProps.form?.getFieldValue("gross") - formProps.form?.getFieldValue("fees")})`);
        }

        if(errorMsgAlert) setErrorMsg(errorMsgAlert?.join(" \n"));

        updateSplit();
    }

    const currencyParser = (value : any) => {
        // Remove non-digit characters
        const numericValue = value.replace(/[^\d]/g, '');
      
        // If the input is empty or only contains non-digit characters, return 0
        if (!numericValue) {
            return 0;
        }

        // Get the last two digits as decimals
        const decimals = numericValue.slice(-2);
      
        // Get the integer part excluding the last two digits
        const integerValue = numericValue.slice(0, -2) || '0';
      
        // Concatenate the integer and decimal parts
        const formattedValue = integerValue + '.' + decimals;
      
        // Parse the formatted value to a float
        const parsedValue = parseFloat(formattedValue);
      
        // Return the parsed numeric value
        return isNaN(parsedValue) ? 0 : parsedValue;
    };   
                              
    const currencyFormatter = (value : any) => {
        return new Intl.NumberFormat("pt-br", {
          style: "currency",
          currency: "BRL"
        }).format(value);
    };

    const changeCheckbox = (e: CheckboxChangeEvent) => {
        console.log(`checked = ${e.target.checked}`);
    };

    const CompanyAccount = () => {
        const t = useTranslate();
        
        // Pega Contas Empresa
        const url = `${API_URL}/companyAccounts`;
        const { data } = useCustom<{data: any;}>({ url, method: "get"});
        const result : any = data?.data;                
        console.log(result);
        const accounts = result?.filter((item : any) => item.active && item.statusText==="Aprovado");             

        const onCompanyAccountChange = (value: any) => {
            console.log("www1",value?.target?.value); // accountId do cliente
            let accountId = value?.target?.value ?? value ?? null;
            let receiverFee = (accounts?.find((ac : any) => ac.id===accountId)?.bank?.includes("(237)") ? 0 : PAGARME_TRANFER_FEE);
            console.log(receiverFee);
            formProps.form?.setFields([
                {
                    name: "accountId",
                    value: accountId ?? formProps.form?.getFieldValue("splitCompany")?.accountId
                },
                {
                    name: "splitCompany",
                    value: {
                        ...formProps.form?.getFieldValue("splitCompany"),
                        account: accounts?.filter((item : any) => item.id===accountId),
                        fees: receiverFee,
                        accountId: accountId ?? formProps.form?.getFieldValue("splitCompany")?.accountId,
                    }
                }
            ]);
            updateSplit();
            console.log("www2",formProps.form?.getFieldsValue());
        }

        if(!formProps.form?.getFieldValue("accountId")){
            onCompanyAccountChange(accounts?.[0]?.id);
        }

        return (
            <Form.Item
                            label={t("invoices.fields.account")}
                            name="accountId"                        
                            rules={[
                                {
                                    required: true,
                                },
                                {
                                    // ({ getFieldValue }) => ({
                                        validator(_, value) {
                                          console.log(value);
                                          if (!value || value===undefined) {
                                            return Promise.reject('Oops! Selecione uma conta bancária');
                                          }
                                          return Promise.resolve();
                                        },
                                    // }),
                                }
                            ]}
                            style={{ marginTop: 20}}
                            tooltip={"Conta da sua empresa que receberá o dinheiro final"}
                        >
                            {/* <Select
                                showSearch
                                placeholder=""
                                optionFilterProp="children"
                                defaultActiveFirstOption={true}
                                options={accounts}
                            /> */}
                            <Radio.Group
                                // defaultValue={accounts?.[0]?.id || null}
                                size="large"
                                onChange={onCompanyAccountChange}
                                style={{marginTop: 15}}
                            >
                                <Space direction="vertical">
                                    {(accounts || []).map((item : any, index: number) => (
                                        <Radio key={`acc-company-${index}-${item?.id ?? null}`} value={item?.id ?? null} style={{border: '1px solid #fafafa'}}>
                                            <Text style={{ fontSize: 14, fontWeight: 600 }}>
                                                {`${item.name}`}
                                                <br />{`(${item.bank} | Ag: ${item.agency} | Cc:${item.account})`}
                                            </Text>
                                                <Text style={{ fontSize: 14, color: "#1677ff" }}>
                                                    <br />Taxa de Transferência: {item?.bank?.includes("(237)") ? "ISENTO" : `R$ ${PAGARME_TRANFER_FEE}` }
                                                </Text>
                                        </Radio>
                                    ))}
                                </Space>
                            </Radio.Group>
                        </Form.Item> 
        );
    }

    const showDrawer = () => {
        setOpen(true);
    };
    
    const onClose = () => {
        setOpen(false);
    };    

    const importGroup = (tagId : any) => {
        console.log(tagId);
        if(confirm("Gostaria de importar os recebedores deste grupo? Os recebedores atuais serão substituídos.")){
            axios.get(`${API_URL}/customerTags?tagId=${tagId}`,{
                headers:{
                    'Authorization': `Bearer ${localStorage.getItem(TOKEN_KEY)}`,
                    'companyId': localStorage.getItem("companyId") ?? '0'
                }
            })
            .then((response : any) => {
                console.log("tagg",response.data);
                const group = response?.data?.filter((t : any) => t.tagActive)
                ?.map((i : any) => {
                    return {
                        customerId: i.customerId,
                        accountId: null,
                        amount: 0,
                        fees: 0,
                        total: 0,
                        name: i.name,
                        type: "customer"
                    }
                });
                formProps.form?.setFields([
                    {
                        name: "split", // required
                        value: group
                    }
                ]);
                group?.map((i : any) => onCustomerChange(i.customerId,i.name));
                updateSplit();
            });
        }
    }

    const customFormat: DatePickerProps['format'] = (value) => `${value.format('DD/MM/YYYY')}`;
    const disabledDate = (current : any) => { return current && current < new Date(new Date().getTime() + 1*24*60*60*1000).getTime() };

    useEffect(() => {
        axios.get(`${API_URL}/tags`,{
            headers:{
                'Authorization': `Bearer ${localStorage.getItem(TOKEN_KEY)}`,
                'companyId': localStorage.getItem("companyId") ?? '0'
            }
        })
        .then((response : any) => {
            console.log("tagg",response.data);
            const result = response?.data?.filter((i : any) => i.active)?.map((i : any) => { return { label: `${i.name} (${i.customers} clientes)`, value: i.id }})?.sort((a : any,b : any) => a.label?.localeCompare(b.label));
            setGroups(result);
        });
    },[]);

    return (
        <>
        <Create
            isLoading={queryResult?.isFetching}
            title={
                <Text style={{ fontSize: 22, fontWeight: 800 }}>
                    {t("invoices.titles.create")}
                </Text>
            }
            saveButtonProps={{ ...saveButtonProps }}
            footerButtons={({ saveButtonProps }) => (
                <>
                    <Button
                        onClick={showDrawer}>
                        Ver Resumo
                    </Button>
                    <SaveButton
                        {...saveButtonProps}
                        size="large"
                        type="primary"
                        style={{ minWidth: 350 }}
                        block
                    >
                        GERAR COBRANÇA
                    </SaveButton>                    
                </>
            )}
            >
            <Form
                {...formProps}
                layout="vertical"
                // onFinish={onFinish}
                onValuesChange={validateInvoice}
                initialValues={{
                    active: true,
                    terms: false,
                    expireAt: dayjs(new Date(new Date().getTime() + 1*24*60*60*1000).toISOString().replace("T", " ").substr(0, 10), 'YYYY-MM-DD'),
                    expireAtRaw: new Date(new Date().getTime() + 1*24*60*60*1000).toISOString().replace("T", " ").substr(0, 10),
                    splitCompany: {
                        name: "Seu Recebimento",
                        type: "company",
                        customerId: 0,
                        account: []
                    },
                    splitPlatform: {
                        accountId: null,
                        name: "Taxas de Serviço",
                        type: "platform",
                        customerId: -1,
                        account: []
                    },
                    ...split,
                }}
            >                

                <Row gutter={[64, 0]} wrap>
                    <Col xs={24} lg={24}>
                        <Card>
                            <Space
                                style={{ display: "flex", marginBottom: 10 }}
                                align="baseline"
                                >
                                <Text style={{ fontSize: 16, fontWeight: 800 }}>
                                    {t("invoices.fields.payer")}:
                                </Text>      
                            </Space> 

                            {/* Hidden fields */}
                            <Form.Item
                                label={t("invoices.fields.company")}
                                name="splitCompany"
                                rules={[
                                    {
                                        required: true,
                                    },
                                ]}
                                tooltip={"Cliente que irá efetuar o pagamento da cobrança"}
                                hidden={true}
                            >
                                <Input />
                            </Form.Item>
                            <Form.Item
                                label={t("invoices.fields.platform")}
                                name="splitPlatform"
                                rules={[
                                        {
                                            required: true,
                                        },
                                        ]}
                                        tooltip={"Cliente que irá efetuar o pagamento da cobrança"}
                                        hidden={true}
                                        >
                                <Input />
                            </Form.Item>
                            {/* Cliente */}
                            <Form.Item
                                label={t("invoices.fields.customer")}
                                name="customerId"
                                rules={[
                                    {
                                        required: true,
                                    },
                                ]}
                                tooltip={"Cliente que irá efetuar o pagamento da cobrança"}
                            >
                                <Select
                                    showSearch
                                    placeholder=""
                                    optionFilterProp="children"
                                    // onChange={onChange}
                                    // onSearch={onSearch}
                                    filterOption={(input, option) =>
                                    String(option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                    }
                                    options={payers}
                                />
                            </Form.Item>                                                
                            <Form.Item
                                label={t("invoices.fields.type")}
                                name="type"
                                rules={[
                                    {
                                        required: true,
                                    },
                                ]}                                
                            >
                                <Select
                                    showSearch
                                    placeholder=""
                                    optionFilterProp="children"
                                    // defaultActiveFirstOption={true}
                                    // defaultValue="boleto"
                                    onChange={(value) => updateSplit()}
                                    // onSearch={onSearch}
                                    filterOption={(input, option) =>
                                    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                    }
                                    options={[
                                        {
                                            value: 'boleto',
                                            label: 'Boleto Bancário',
                                        },
                                        {
                                            value: 'pix',
                                            label: 'Pix',
                                        },
                                        // {
                                        //     value: 'credit_card',
                                        //     label: 'Cartão de Crédito',
                                        // }
                                    ]}
                                />
                            </Form.Item>
                            {paymentType && (
                                <Space.Compact style={{marginBottom:20}}>
                                    <Text style={{color: "#888"}}>{paymentType==="boleto" ? "Transferências demoram em média até 3 dias úteis após a aprovação do pagamento do boleto. Durante os finais de semana NÃO ocorrem transferências." : "Transferências demoram em média até no máximo 2 dias úteis após a aprovação do pagamento do Pix. Durante os finais de semana NÃO ocorrem transferências."}</Text>
                                </Space.Compact>
                            )}
                            <Form.Item
                                label={t("invoices.fields.expireAt")}
                                name="expireAt"
                                rules={[
                                    {
                                        required: true,
                                    },
                                ]}
                            >
                                <DatePicker
                                    locale={locale}
                                    format={customFormat}
                                    onChange={updateSplit}
                                    onOk={updateSplit}
                                    disabledDate={disabledDate}
                                    showToday={false}
                                    // defaultValue={dayjs(new Date(new Date().getTime() + 2*24*60*60*1000).toISOString().replace("T", " ").substr(0, 10), 'YYYY-MM-DD')}
                                    style={{ width: "100%" }}
                                />
                            </Form.Item>
                            <Form.Item
                                name="expireAtRaw"                                
                                hidden={true}
                            ></Form.Item>
                            <Form.Item
                                label={t("invoices.fields.description")}
                                name="description"
                                rules={[
                                    {
                                        required: false,
                                    },
                                ]}
                            >
                                <TextArea
                                showCount
                                maxLength={130}
                                style={{ height: 100, resize: 'none' }}
                                // onChange={onChange}
                                placeholder="Descrição da cobrança"
                                />
                            </Form.Item>
                            <Form.Item
                                label={`${t("invoices.fields.gross")} (mínimo R$ 1.000)`}
                                name="gross"
                                rules={[
                                    {
                                        required: true,
                                    },
                                    {
                                    // ({ getFieldValue }) => ({
                                        validator(_, value) {
                                          console.log(value);
                                          if (!value || Number(value)===0) {
                                            return Promise.reject('Oops! Insira um valor mínimo de R$ 1000,00');
                                          } else if (value && Number(value)<1000) {
                                            return Promise.reject(`Oops! Insira um valor mínimo de R$ 1000,00`);
                                          }
                                          return Promise.resolve();
                                        },
                                    // }),
                                    }
                                ]}      
                                tooltip={"Valor bruto total da cobrança, mínimo R$ 1.000"}
                            >
                                {/* <InputNumber
                                    prefix="R$"
                                    // min="1"
                                    // max={sum.total}
                                    // decimalSeparator=","
                                    onChange={(value) => updateSplit(Number(value))}
                                    formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                    parser={(value) => value!.replace(/\$\s?|(,*)/g, '')}
                                    style={{ width: '100%' }}  />                             */}
                                <InputNumber
                                    size="large"
                                    formatter={currencyFormatter}
                                    parser={currencyParser}
                                    // onBlur={currencyParser}
                                    onChange={(value) => updateSplit()}
                                    value={0}
                                    style={{ width: '100%' }}  /> 
                            </Form.Item>        
                            <Alert
                                message="Taxas da transação:"
                                description={<>{companyFee}% sobre valor bruto da cobrança + R$ {PAGARME_TRANFER_FEE} por cada transferência (isenção para Bradesco)</>}
                                type="info" showIcon
                            />
                        </Card>  
                        <Card>
                            <Space
                                style={{ display: "flex", marginBottom: 10 }}
                                align="baseline"
                                >
                                <Text style={{ fontSize: 16, fontWeight: 800 }}>
                                    {t("invoices.fields.split.title")}:
                                </Text>                            
                            </Space>   
                            <Space
                                style={{ display: "flex", marginBottom: 20 }}
                                align="baseline"
                                >
                                <Text style={{ fontSize: 12, fontWeight: 800 }}>
                                    {t("invoices.fields.split.subtitle")}:
                                </Text>
                            </Space>
                            {(groups?.length>0) && (
                            <Card
                                style={{ display: "block", width: '100%', padding: "0px", marginBottom: 30, backgroundColor: "#ffd66621", border: "1px solid #ccc", borderRadius: "5px" }}
                            >
                            <Form.Item
                                label={`Importe recebedores de um grupo salvo:`}
                                name="tagId"
                                rules={[
                                    {
                                        required: false,
                                    },
                                ]}                                
                            >
                                <Select
                                    showSearch
                                    placeholder=""
                                    optionFilterProp="children"
                                    onChange={(value) => importGroup(value)}
                                    // filterOption={(input, option) =>
                                    // (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                    // }
                                    options={groups}
                                    style={{width: '100%'}}
                                />
                            </Form.Item>
                            </Card>
                            )}
                            <Form.List
                                name="split"
                                initialValue={split}
                            >
                                {(fields, { add, remove }) => (
                                    <>
                                    {fields.map((field,index) => {
                                    
                                    console.log("customer.type",formProps?.form?.getFieldValue(['split', field.name, 'type']));
                                    let hideSplit = formProps?.form?.getFieldValue(['split', field.name, 'type'])!==undefined && formProps?.form?.getFieldValue(['split', field.name, 'type'])!=="customer" ? true : false;

                                    return (
                                        <Card
                                            key={`card-${index}`} 
                                            style={{ display: "block", width: '100%', padding: "12.5px", marginBottom: 8, backgroundColor: "#fafafa", border: "1px solid #ccc", borderRadius: "5px" }}
                                            hidden={hideSplit}
                                        >

                                            <Space.Compact block style={{ width: '100%'}}>
                                                {/* Alerta de recebedor duplicado */}
                                                {((formProps.form?.getFieldValue("split")?.filter((item : any) => (item?.customerId && item?.customerId===formProps?.form?.getFieldValue(['split', field.name, 'customerId']))) || [])?.length>1) && (
                                                    <Space style={{ color: 'red', marginBottom: 20}}>
                                                        Atenção: Este recebedor já foi inserido anteriormente, verifique os recebedores novamente.
                                                    </Space>
                                                )}
                                                {/* Tipo de recebedor */}
                                                <Form.Item
                                                        label={t("invoices.fields.type")}
                                                        name={[field.name, "type"]}
                                                        fieldKey={[field.key, "type"]}
                                                        rules={[
                                                            {
                                                                required: true,
                                                            },
                                                        ]}
                                                        initialValue={'customer'}
                                                        hidden={true}
                                                    >
                                                        <Input />
                                                </Form.Item>
                                            </Space.Compact>

                                            <Space.Compact block style={{ width: '100%'}}>

                                                {/* Selecionar Recebedor */}
                                                <Form.Item
                                                    {...field}
                                                    name={[field.name, "customerId"]}
                                                    fieldKey={[field.key, "customerId"]}
                                                    label={`${t("invoices.fields.split.customer")} #${(index + 1)}`}
                                                    style={{ width: '100%' }}
                                                    rules={[
                                                        {
                                                            required: true,
                                                        },
                                                    ]}
                                                    tooltip={"Cliente que receberá uma parte da divisão"}
                                                >
                                                    <Select
                                                        showSearch
                                                        placeholder=""
                                                        optionFilterProp="children"
                                                        onChange={onCustomerChange}
                                                        // onSearch={onSearch}
                                                        // status="error"                                                        
                                                        filterOption={(input, option) =>
                                                            // String(option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                                            (replaceAccents(String(option?.label ?? '')).toLowerCase().indexOf(replaceAccents(input).toLowerCase()) >= 0)
                                                        }
                                                        // options={receivers?.filter((item : any) => !formProps.form?.getFieldValue("split")?.filter((s : any) => !(s?.customerId && s?.customerId===item?.customerId))?.length )}
                                                        options={receivers}
                                                        style={{ width: '100%' }}
                                                    />
                                                </Form.Item>

                                                {/* Botao Deletar */}
                                                {fields?.length>1 && (
                                                <Form.Item
                                                    // label={t("invoices.fields.split.delete")}
                                                    key={`del-${index}`} 
                                                    label="*"
                                                    >
                                                    {/* <DeleteOutlined onClick={() => remove(field.name)} /> */}
                                                    <Button
                                                    onClick={() => remove(field.name)}                                            
                                                    block
                                                    type="primary"
                                                    danger
                                                    icon={<DeleteOutlined />}                                            
                                                    >
                                                        {t("buttons.delete")}
                                                    </Button>
                                                </Form.Item>
                                                )}
                                            </Space.Compact>

                                            <Space.Compact block style={{ width: '100%'}}>
                                                {/* Selecionar Conta */}
                                                <Form.Item
                                                    {...field}
                                                    name={[field.name, "accountId"]}
                                                    fieldKey={[field.key, "accountId"]}
                                                    label={t("invoices.fields.split.account")}
                                                    // style={{ width: '50%' }}
                                                    rules={[
                                                        {
                                                            required: true,
                                                        },
                                                        {
                                                        // ({ getFieldValue }) => ({
                                                            validator(_, value) {
                                                              console.log(value);
                                                              if (!value) {
                                                                return Promise.reject('Oops! Selecione uma conta bancária');
                                                              }
                                                              return Promise.resolve();
                                                            },
                                                        // }),
                                                        }
                                                    ]}
                                                    tooltip={"Conta bancária que o seu cliente receberá parte da divisão"}
                                                >
                                                    {/* <Select
                                                        options={(formProps.form?.getFieldValue("split")[index]?.account || [])?.map((item : any) => ({ label: item?.bank, value: item?.accountId ?? null }))}
                                                    >          
                                                    </Select> */}
                                                    <Radio.Group
                                                        // defaultValue={(Number(formProps.form?.getFieldValue("split")[index]?.account?.[0]?.accountId) ?? null)}
                                                        size="large"
                                                        onChange={onAccountChange}
                                                    >
                                                        <Space direction="vertical">
                                                            {(formProps.form?.getFieldValue("split")[index]?.account ?? []).map((item : any) => (
                                                            <Radio
                                                            key={`acc-${index}-${item?.accountId ?? null}`}
                                                            value={item?.accountId ?? null}
                                                            // checked={formProps.form?.getFieldValue("split")[index]?.accountId===item?.accountId}
                                                            style={{border: '1px solid #fafafa'}}>
                                                                <Text style={{ fontSize: 14, fontWeight: 600 }}>
                                                                {item?.bank} | {item?.account} ({item?.agency})
                                                                </Text>
                                                                {/* {!item?.bank?.includes("(237)") && ( */}
                                                                <Text style={{ fontSize: 14, color: "#1677ff" }}>
                                                                    <br />Taxa de Transferência: {item?.bank?.includes("(237)") ? "ISENTO" : `R$ ${PAGARME_TRANFER_FEE}` }
                                                                </Text>
                                                                {/* )} */}
                                                            </Radio>
                                                            ))}
                                                            {!formProps.form?.getFieldValue("split")[index]?.account && (
                                                                <Text style={{ fontSize: 12 }}>
                                                                    Selecione um Recebedor primeiro
                                                                </Text>
                                                            )}
                                                        </Space>
                                                    </Radio.Group>
                                                </Form.Item>
                                            </Space.Compact>

                                            <Space.Compact block style={{ width: '100%'}}>
                                                {/* Valor Bruto */}
                                                <Form.Item
                                                    {...field}
                                                    name={[field.name, "amount"]}
                                                    fieldKey={[field.key, "amount"]}
                                                    label={t("invoices.fields.split.amount")}
                                                    style={{ width: '100%' }}
                                                    rules={[
                                                        {
                                                            required: true,
                                                        },
                                                        {
                                                        // ({ getFieldValue }) => ({
                                                            validator(_, value) {
                                                              console.log(value);
                                                              if (!value || Number(value)===0) {
                                                                return Promise.reject('Oops! Insira um valor válido');
                                                              }
                                                            //   else if (value && Number(value)<PAGARME_TRANFER_FEE) {
                                                            //     return Promise.reject(`Oops! Insira um valor mínimo de R$ ${PAGARME_TRANFER_FEE}`);
                                                            //   }
                                                              return Promise.resolve();
                                                            },
                                                        // }),
                                                        }
                                                    ]}
                                                    tooltip={"Valor bruto que o seu cliente receberá parte da divisão"}
                                                >
                                                        <InputNumber
                                                        // max={total}
                                                        size="large"
                                                        formatter={currencyFormatter}
                                                        parser={currencyParser}
                                                        onChange={(value) => { updateSplit(); validateInvoice(); }}
                                                        style={{ width: '100%' }}  /> 
                                                </Form.Item>
                                            </Space.Compact>

                                            <Space.Compact block style={{ width: '100%'}}>
                                                {/* Taxas de Transf. */}                                            
                                                <Form.Item
                                                    {...field}
                                                    name={[field.name, "fees"]}
                                                    fieldKey={[field.key, "fees"]}
                                                    label={t("invoices.fields.split.fees")}
                                                    // style={{ width: '33%' }}
                                                    rules={[
                                                        {
                                                            required: true,
                                                        },
                                                    ]}
                                                    tooltip={"Valor de taxas que será incluído para realizar a trasferência"}
                                                    hidden={true}
                                                >
                                                        <InputNumber
                                                        // max={total}
                                                        formatter={currencyFormatter}
                                                        parser={currencyParser}
                                                        onChange={(value) => updateSplit()}
                                                        // style={{ width: '100%' }}
                                                        disabled={true}/> 
                                                </Form.Item>
                                            </Space.Compact>
                                            
                                            <Space.Compact block style={{ width: '100%'}}>                                                
                                                {/* Total */}
                                                <Form.Item
                                                    {...field}
                                                    name={[field.name, "total"]}
                                                    fieldKey={[field.key, "total"]}
                                                    label={t("invoices.fields.split.total")}
                                                    style={{ width: '100%' }}
                                                    rules={[
                                                        {
                                                            required: true,
                                                        },
                                                    ]}
                                                    tooltip={"Valor total final recebido"}
                                                    hidden={false}
                                                >
                                                        <InputNumber
                                                        // max={total}
                                                        formatter={currencyFormatter}
                                                        parser={currencyParser}
                                                        onChange={(value) => updateSplit()}
                                                        style={{ width: '100%' }}
                                                        disabled={true}/> 
                                                </Form.Item>
                                            </Space.Compact>

                                            <Space.Compact block style={{ width: '100%'}}>
                                            {/* Alerta de recebedor duplicado */}
                                            {((formProps.form?.getFieldValue("split")?.filter((item : any) => (item?.customerId && item?.customerId===formProps?.form?.getFieldValue(['split', field.name, 'customerId']))) || [])?.length>1) && (
                                                <Space style={{ color: 'red', marginBottom: 20}}>
                                                    Atenção: Este recebedor já foi inserido anteriormente, verifique os recebedores novamente.
                                                </Space>
                                            )}
                                            </Space.Compact>

                                        </Card>
                                    ) })}
                                    <Form.Item>
                                        <Button
                                        type="dashed"
                                        style={{ backgroundColor: "#dee7f5" }}
                                        onClick={() => add()}
                                        block
                                        icon={<PlusOutlined />}
                                        >
                                        {t("buttons.add")}
                                        </Button>
                                    </Form.Item>
                                    {receiversErrorMsg && (
                                    <Space direction="vertical" style={{ width: '100%' }}>
                                        <Alert
                                            message="ATENÇÃO"
                                            description={<pre>{receiversErrorMsg}</pre>}
                                            type="error"
                                            showIcon
                                            />
                                    </Space>
                                    )}
                                    </>
                                )}
                            </Form.List>
                        </Card>
                        <Card>
                            <Space
                                style={{ display: "flex", marginBottom: 10 }}
                                align="baseline"
                                >
                                <Text style={{ fontSize: 16, fontWeight: 800 }}>
                                    Seu Recebimento:
                                </Text>      
                            </Space> 
                            <CompanyAccount></CompanyAccount>
                            <Form.Item
                                label={t("invoices.fields.total")}
                                name="total"
                                rules={[
                                    {
                                        required: true,
                                    },
                                    {
                                    // ({ getFieldValue }) => ({
                                        validator(_, value) {
                                          console.log(value);
                                          if (!value || Number(value)<0) {
                                            return Promise.reject('Oops! Reveja os valores distribuídos, a soma é insuficiente para o valor da cobrança.');
                                          }
                                          return Promise.resolve();
                                        },
                                    // }),
                                    }
                                ]}
                                style={{ marginBottom: 10}}     
                                tooltip={"Valor líquido que você receberá em sua conta"}
                            >
                                {/* <Input value={total} disabled={true}/> */}
                                <InputNumber
                                    // prefix="R$"
                                    // min="1"
                                    // decimalSeparator=","
                                    size="large"
                                    formatter={currencyFormatter}
                                    parser={currencyParser}
                                    value={0}
                                    disabled={true}
                                    style={{ width: '100%' }}  />                            
                            </Form.Item>
                            <Form.Item
                                label={t("invoices.fields.fees")}
                                name="fees"
                                rules={[
                                    {
                                        required: true,
                                    },
                                ]}
                                hidden={true}
                            >
                                <InputNumber
                                    formatter={currencyFormatter}
                                    parser={currencyParser}
                                    value={0}
                                    disabled={true}
                                    style={{ width: '100%' }}  /> 
                            </Form.Item> 
                            <Form.Item
                                label={t("invoices.fields.transfers")}
                                name="transfers"
                                rules={[
                                    {
                                        required: true,
                                    },
                                ]}
                                hidden={true}
                            >
                                <InputNumber
                                    formatter={currencyFormatter}
                                    parser={currencyParser}
                                    value={0}
                                    disabled={true}
                                    style={{ width: '100%' }}  /> 
                            </Form.Item> 
                            <Form.Item
                                label={t("invoices.fields.fixedFees")}
                                name="fixedFees"
                                rules={[
                                        {
                                            required: false,
                                        },
                                        ]}
                                tooltip={"Taxas Fixas"}
                                hidden={true}
                                        >
                                <InputNumber
                                    formatter={currencyFormatter}
                                    parser={currencyParser}
                                    value={0}
                                    disabled={true}
                                    style={{ width: '100%' }}  /> 
                            </Form.Item>
                            <Alert
                                message={`Valor líquido após as taxas da transação:`}
                                description={<>{companyFee}% sobre valor bruto da cobrança + R$ {PAGARME_TRANFER_FEE} por cada transferência (isenção para Bradesco)</>}
                                type="info" showIcon
                            />
                            <Form.Item
                                style={{ marginTop: 20 }}
                                label={t("invoices.fields.terms")}
                                name="terms"
                                valuePropName="checked"
                                rules={[
                                    {
                                        validator: async (_, checked) => {
                                            if (!checked) {
                                                return Promise.reject(
                                                    new Error("Você deve aceitar os termos para continuar, clique no botão acima."),
                                                );
                                            }
                                        },
                                    },
                                
                                ]}
                                // hidden={true}
                                tooltip={"Termo de consentimento sobre os serviços, você deve aceitar para conseguir emitir a cobrança"}
                            >
                                {/* <Switch
                                defaultChecked={false}
                                unCheckedChildren="Clique aqui para aceitar"
                                checkedChildren="Aceito" />
                                <Text style={{ marginLeft: 10 }}>Estou ciente dos termos e condições de uso da plataforma</Text> */}
                                <Checkbox onChange={changeCheckbox}>Estou ciente dos termos e condições de uso da plataforma</Checkbox>                                
                            </Form.Item>                             
                            <Link to="https://docs.google.com/document/d/e/2PACX-1vSTKHxxpm3t9FnNDrTz7kA9sXXNCrkVJ_0uJCtYHz9yVM8dYEfiEoYjy5t4kQDNcvccEH9_F26Mawsx/pub" target="_blank">Clique aqui para ler os termos de uso</Link>
                        </Card>
                        {errorMsg && (
                            <Space direction="vertical" style={{ width: '100%' }}>
                                <Alert
                                    message="ATENÇÃO: RESOLVA ESSES ERROS ANTES DE CRIAR A COBRANÇA!"
                                    description={<pre>{errorMsg}</pre>}
                                    type="error"
                                    showIcon
                                />
                            </Space>
                        )}
                    </Col>
                </Row>
            </Form>
        </Create>
        <Drawer 
            width={640}
            title={"Resumo"}
            placement="right"
            closable={true}
            onClose={onClose}
            open={open}
            extra={
            <Space>
                <Button onClick={onClose} type="primary">Fechar</Button>
            </Space>
            }
        >
            <Row style={{marginBottom: 20}}>
                {/* <Col span={12}>
                    <Text style={{ fontSize:16, fontWeight: 600}}>Pagador:</Text>
                    <br/>
                    <Text>Lorem</Text>
                </Col> */}
                <Col span={12}>
                    <Text style={{ fontSize:16, fontWeight: 600}}>Forma de Pgto:</Text>
                    <br/>
                    <Text>{formProps.form?.getFieldValue("type") ?? "Pendente"}</Text>
                </Col>
                <Col span={12}>
                    <Text style={{ fontSize:16, fontWeight: 600}}>Valor Bruto da Cobrança (R$):</Text>
                    <br/>
                    <Text>
                    {formProps.form?.getFieldValue("gross")>0 ? (
                        <NumberField
                            style={{ fontSize: 14, fontWeight: 600, color: "#52c41a" }}
                            options={{
                                    currency: "BRL",
                                    style: "currency",
                                    }}
                            locale="pt-BR" 
                            value={formProps.form?.getFieldValue("gross")}
                        /> 
                     ) : ("Pendente")}                        
                    </Text>
                </Col>
            </Row>
            {/* <Row style={{marginBottom: 20}}>
                <Col span={24}>
                    <Text style={{ fontSize:16, fontWeight: 600}}>Valor Cobrança (R$)</Text>
                    <br/>
                    <Text>R$ {formProps.form?.getFieldValue("gross") ?? "Pendente"}</Text>
                </Col>                
            </Row> */}
            <Row style={{marginBottom: 20}}>
                <Col span={24} style={{ marginBottom: 20 }}>
                    <Text style={{ fontSize:16, fontWeight: 600 }}>Distribuição de recebedores:</Text>
                    <br/>
                </Col>
                <Col span={24}>
                <Table
                    pagination={false}
                    dataSource={formProps.form?.getFieldValue("split")}
                    style={{overflow: "scroll"}}     
                    bordered={true}    
                    size="small"                
                >
                    <Table.Column
                        defaultSortOrder="descend"
                        dataIndex="name"
                        title={t("invoices.split.fields.name")}
                        render={(value) => (
                            <Text style={{ fontWeight: 600 }}>{value ?? "Pendente"}</Text>
                        )}
                    />                
                    <Table.Column
                        title={t("invoices.split.fields.account")}
                        dataIndex="account"
                        ellipsis={true}
                        render={(value,record : any) => {
                            let account = record?.account?.find((item : any) => item?.id===record?.accountId);
                            return (
                            <>
                            <div
                            style={{
                                // display: "flex",
                                minWidth: 125,
                                // justifyContent: "space-between",
                            }}
                            >
                                <Text style={{ fontWeight: 600 }}>
                                    {account?.bank}
                                </Text>
                                <br />
                                <Text style={{ fontWeight: 400 }}>
                                    {account?.account} {account?.agency ? `(ag: ${account?.agency})` : ``}
                                </Text>
                                <br />
                                {record?.link && (
                                <Text style={{ fontWeight: 600 }}>
                                    <a href={record?.link} target="_blank">{record?.link ? "Ver Comprovante" : ""}</a>
                                </Text>
                                )} 
                            </div>                       
                            </>
                            )
                        }}                        
                    />
                    <Table.Column
                        defaultSortOrder="descend"
                        // sorter={(a: ISplit, b: ISplit) => a.price - b.price}
                        dataIndex="amount"
                        title={t("invoices.split.fields.amount")}                    
                        render={(value) => {
                            return (
                                <>
                                <div
                                style={{
                                    // display: "flex",
                                    minWidth: 75,
                                    // justifyContent: "space-between",
                                }}
                                >
                                    <NumberField
                                        style={{ fontSize: 14, fontWeight: 600, color: "#52c41a" }}
                                        options={{
                                            currency: "BRL",
                                            style: "currency",
                                        }}
                                        locale="pt-BR" 
                                        value={value}
                                    />
                                </div>
                                </>
                            );
                        }}
                    />
                </Table>
                </Col>
            </Row>
            <Row style={{marginBottom: 20}}>
                <Col span={12}>
                    <Text style={{ fontSize:16, fontWeight: 600}}>Sua Conta Bancária:</Text>
                    <br/>
                    <Text>
                        {formProps.form?.getFieldValue("splitCompany")?.account?.[0]?.bank ?? "Pendente"}
                        <br/>cc: {`${formProps.form?.getFieldValue("splitCompany")?.account?.[0]?.account ?? ""}`}
                        <br/>ag: {`${formProps.form?.getFieldValue("splitCompany")?.account?.[0]?.agency ?? ""}`}
                    </Text>
                </Col>
                <Col span={12}>
                    <Text style={{ fontSize:16, fontWeight: 600}}>Seu Recebimento Líquido:</Text>
                    <br/>
                    <Text>
                    {formProps.form?.getFieldValue("total")>0 ? (
                        <NumberField
                            style={{ fontSize: 14, fontWeight: 600, color: "#52c41a" }}
                            options={{
                                    currency: "BRL",
                                    style: "currency",
                                    }}
                            locale="pt-BR" 
                            value={formProps.form?.getFieldValue("total")}
                        /> 
                     ) : ("Pendente")}
                    </Text>
                </Col>                
            </Row>
            <Row style={{marginBottom: 20}}>
                <Col span={24}>
                    <Button onClick={onClose} type="primary" block>Fechar</Button>
                </Col>
            </Row>
        </Drawer>
        </>
    );
};
