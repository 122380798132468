import { useMemo, useState, useEffect } from "react";
import { TOKEN_KEY, API_URL, BANK_CODES } from "./../../constants";
import { replaceAccents } from "./../../functions";
import { IResourceComponentsProps, useCustom, useTranslate } from "@refinedev/core";
import { Create, useForm } from "@refinedev/antd";
import {
    Form,
    Input,
    Select,
    Avatar,
    Row,
    Col,
    Typography,
    Space,
    Radio,
    InputProps,
    Card,
    Alert,
    Switch
} from "antd";
import {
    InfoText,
    InfoBox,
    InfoBoxText,
    BoxContainer,
} from "./styled";

import MaskedInput from "antd-mask-input";
import BankAccountValidator from "br-bank-account-validator";

const { Text } = Typography;

import { ICompanyAccount } from "interfaces";

export const CompanyAccountCreate: React.FC<IResourceComponentsProps> = () => {
    const t = useTranslate();
    const [bank, setBank] = useState([] as any[]);
    const [errorMsg, setErrorMsg] = useState(null);    
    const { formProps, saveButtonProps, queryResult } = useForm<ICompanyAccount>({
        // errorNotification: (data, values, resource) => {
        //     console.log({data, values, resource});
        //     let errorMsg = data?.response?.data?.errors.map((item : any) => item.message)?.join(" / ");
        //     setErrorMsg(errorMsg);
        //     return {
        //         message: errorMsg,
        //         description: "Erro",
        //         type: "error",
        //     };
        // }
    });    
    console.log(formProps);
    const [customerType, setCustomerType] = useState(null);

    const handleTypeChange = (e: any) => {
        setCustomerType(e?.target?.value ?? e);
    };

    // useEffect(() => {
        let bankCodes = BANK_CODES.filter(item => item.strCode).map((item : any) => { return { label: `${item.name} (${item.strCode})`, name: item.displayName, code: ("00" + item.strCode).slice(-3), value: ("00" + item.strCode).slice(-3), mask: item.mask}})
        // setBankCodes(bankCodesFormatted);
    // });

    // Pega Empresa da endpoint
    const companyId = localStorage.getItem("companyId");
    const url = `${API_URL}/companies/${companyId}`;
    const { data } = useCustom<{data: any;}>({ url, method: "get" });
    const company : any = data?.data;

    const renderCompany = () => (
        <Card style={{ marginBottom: '30px' }}>
            <Row>
                <Col xl={24} lg={24} md={24}>
                    {/* <InfoBox> */}
                        <InfoText>
                            <Text style={{ fontSize: 20, fontWeight: 'bold' }}>{t("companies.companies")}:</Text>
                            <Text>{t("companies.fields.name")}:  {company?.name}</Text>
                            <Text>{t("companies.fields.razaoSocial")}:  {company?.razaoSocial}</Text>
                            <Text>{t("companies.fields.cpfCnpj")}:  {company?.cpfCnpj}</Text>
                            <Text style={{ color: 'green' }}>* Sua conta deve ser da mesma titularidade desta Empresa</Text>
                        </InfoText>
                    {/* </InfoBox> */}
                </Col>
            </Row>
        </Card>
    );

    const handleBankChange = (value: any, option: any) => {
        console.log("zzz",value,option);
        formProps.form?.setFields([
            {
              name: "bank", // required
              value: option?.label
            },
        ]);
    };

    const validateBankAccount = async () => {
        // const form = Form.useFormInstance();
        console.log(formProps.form?.getFieldsValue());
        let values : any = formProps.form?.getFieldsValue();
        if(values.bankCode && values.agency && values.account){
            try {
                let bank = BankAccountValidator.validate({
                    bankNumber: "00" + values.bankCode.slice(-3),
                    agencyNumber: values.agency,
                    agencyCheckNumber: values.agencyDigit,
                    accountNumber: values.account,
                    accountCheckNumber: values.accountDigit,
                });
                // Se chegou até aqui, a conta bancária é válida
                console.log("aaaaaa",bank);
                setErrorMsg(null);
                let fields = ["agency","agencyDigit","account","accountDigit"];
                fields.map(item => {
                    formProps.form?.setFields([
                        {
                          name: item, // required
                          errors: [],
                        },
                    ]);
                });
            } catch (e : any) {
                // se não for válida, lança uma exceção
                console.log("eeeeee",e?.message);
                let fields = [];
                if(e?.message.includes("Agência inválida")) fields.push({ name: "agency", error: "Agência Inválida" })
                if(e?.message.includes("Dígito da agência inválido")) fields.push({ name: "agencyDigit", error: "Dígito da agência inválido" })
                if(e?.message.includes("Conta corrente inválida")) fields.push({ name: "account", error: "Conta corrente inválida" })
                if(e?.message.includes("Dígito da conta corrente inválido")) fields.push({ name: "accountDigit", error: "Dígito da conta corrente inválido" })

                fields.map(item => {
                    formProps.form?.setFields([
                        {
                          name: item.name, // required
                          errors: [item.error],
                        },
                    ]);
                });

                let msg = fields.map(item => item.error)?.join("<br>") || null;
                console.log(msg);
                setErrorMsg(e?.message);
            }
        }
    }    

    const sameAccountChange = (checked: boolean) => {
        console.log(`switch to ${checked}`);
        formProps.form?.setFields([
            {
              name: "razaoSocial", // required
              value: checked ? company?.razaoSocial : null
            },
            {
                name: "cpfCnpj", // required
                value: checked ? company?.cpfCnpj : null
            },
            {
                name: "entityType", // required
                value: company?.type
            },
        ]);
        handleTypeChange(company?.type)
    };      
    
    const onFinishFailed = (errorInfo : any) =>{
        console.log('Failed:', errorInfo);
    };

    return (
        <>        
        <Create
            isLoading={queryResult?.isFetching}
            saveButtonProps={saveButtonProps}
            title={
                <Text style={{ fontSize: 22, fontWeight: 800 }}>
                    {t("accounts.titles.create")}
                </Text>
            }>
            {/* {renderCompany()} */}
            <Form
                {...formProps}
                layout="vertical"
                onValuesChange={validateBankAccount}
                // onFinish={onFinish}
                // onFinishFailed={onFinishFailed}
                initialValues={{
                    active: true,
                    sameAccount: false,
                    razaoSocial: null,
                    cpfCnpj: null,
                    entityType: "corporation"
                }}
            >
                <Alert
                    message={`Atenção: Esta conta bancária deve ser do titular informado. Caso haja divergência seu saque estará impossibilitado.`}
                    type="info"
                    showIcon
                    style={{marginBottom: 30}}
                />
                <Row gutter={[64, 0]} wrap>
                    <Col xs={24} lg={12}>
                        <Text style={{ fontSize: 18, fontWeight: 800 }}>Dados do Titular da Conta</Text><br/><br/>
                        <Form.Item
                            label={`Usar os dados de ${company?.razaoSocial} (${company?.cpfCnpj})?`}
                            name="sameAccount"
                            valuePropName="sameAccount"
                            style={{backgroundColor: "#fafafa"}}
                            rules={[
                                {
                                    required: false,
                                },
                            ]}
                        >
                            <Switch onChange={sameAccountChange}/>
                        </Form.Item>
                        <Form.Item
                            label={t("customers.fields.type.title")}
                            name="entityType"
                            rules={[
                                {
                                    required: true,
                                },
                            ]}
                        >
                            <Radio.Group onChange={handleTypeChange}>
                                <Radio value="individual">{t("customers.fields.type.individual")}</Radio>
                                <Radio value="corporation">{t("customers.fields.type.corporation")}</Radio>
                            </Radio.Group>
                        </Form.Item>
                        <Form.Item
                            label={t("customers.fields.razaoSocial")}
                            name="razaoSocial"
                            rules={[
                                {
                                    required: true
                                },
                            ]}                            
                        >
                            <Input/>
                        </Form.Item>
                        <Form.Item
                            label={t("customers.fields.cpfCnpj")}
                            name="cpfCnpj"
                            rules={[
                                {
                                    required: true,
                                },
                            ]}
                        >
                            <MaskedInput
                                disabled={false}
                                mask={customerType==="individual" ? '000.000.000-00' : '00.000.000/0000-00'}
                            >
                                    {/* 
                                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                        // @ts-ignore */}
                                    {/* {(props: InputProps) => <Input disabled={false} {...props} />} */}
                            </MaskedInput>
                        </Form.Item>                        
                    </Col>
                    <Col xs={24} lg={12}>
                        <Text style={{ fontSize: 18, fontWeight: 800 }}>Dados Bancários</Text><br/>
                        {/* <Text style={{ color: 'green' }}>* Esta conta deve ser de titularidade de {formProps.form?.getFieldValue("razaoSocial")} ({formProps.form?.getFieldValue("cpfCnpj")})</Text><br/><br/> */}                        
                        <br/>
                        {/* <Form.Item
                            label={t("accounts.fields.name")}
                            name="name"
                            rules={[
                                {
                                    required: true,
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item> */}
                        <Form.Item
                            label={t("accounts.fields.razaoSocial")}
                            name="razaoSocial"
                            rules={[
                                {
                                    required: false,
                                },
                            ]}
                            hidden={true}
                        >
                            <Input  />
                        </Form.Item>
                        <Form.Item
                            label={t("accounts.fields.cpfCnpj")}
                            name="cpfCnpj"
                            rules={[
                                {
                                    required: false,
                                },
                            ]}
                            hidden={true}           
                        >
                            <MaskedInput
                                disabled={false}
                                mask={'00.000.000/0000-00'}
                            >
                                    {/* 
                                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                        // @ts-ignore */}
                                    {/* {(props: InputProps) => <Input disabled={false} {...props} />} */}
                            </MaskedInput>
                        </Form.Item>
                    {/* </Col>
                    <Col xs={24} lg={12}> */}
                        <Form.Item
                            label={t("accounts.fields.bank")}
                            name="bankCode"
                            rules={[
                                {
                                    required: true,
                                },
                            ]}
                        >
                            <Select
                                showSearch
                                // defaultValue=""
                                onChange={handleBankChange}
                                // onSelect={(value) => {
                                //     console.log("onSelect",value);
                                //     setBank(value?.label);
                                // }}
                                options={bankCodes}
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                    // (option?.label ?? '').includes(input) || (option?.name ?? '').includes(input)
                                    (replaceAccents(option?.label ?? '').toLowerCase().indexOf(replaceAccents(input).toLowerCase()) >= 0) || (replaceAccents(option?.name ?? '').toLowerCase().indexOf(replaceAccents(input).toLowerCase()) >= 0)
                                }
                                filterSort={(optionA, optionB) =>
                                  (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                                }
                            />
                        </Form.Item>
                        <Form.Item
                                label={t("accounts.fields.bank")}
                                name="bank"
                                rules={[
                                    {
                                        required: false,
                                    },
                                ]}
                                hidden={true}
                            >
                                <Input />                        
                        </Form.Item>
                        <Space.Compact style={{ width: '100%' }}>
                            <Form.Item
                                label={t("accounts.fields.agency")}
                                name="agency"                                
                                style={{ width: '70%' }}
                                rules={[
                                    {
                                        required: true,
                                    },
                                    {
                                    // ({ getFieldValue }) => ({
                                        validator(_, value) {
                                          console.log(value);
                                          if (!value) {
                                            return Promise.reject('Oops! Insira o número da conta corrente');
                                          } else if (value && value?.includes("-")) {
                                            return Promise.reject(`Oops! O dígito da agência deve ser informado no campo ao lado`);
                                          }
                                          return Promise.resolve();
                                        },
                                    // }),
                                    }
                                ]}
                            >
                                <Input />                        
                            </Form.Item>
                            <Form.Item
                                label={t("accounts.fields.agencyDigit")}
                                name="agencyDigit"
                                style={{ width: '30%' }}
                                rules={[
                                    {
                                        required: false,
                                    },
                                ]}
                            >
                                <Input />                        
                            </Form.Item>
                        </Space.Compact>
                        <Space.Compact style={{ width: '100%' }}>
                            <Form.Item
                                label={t("accounts.fields.account")}
                                name="account"
                                style={{ width: '70%' }}
                                rules={[
                                    {
                                        required: true,
                                    },
                                ]}
                            >
                                <Input showCount />
                            </Form.Item>
                            <Form.Item
                                label={t("accounts.fields.accountDigit")}
                                name="accountDigit"
                                style={{ width: '30%' }}
                                rules={[
                                    {
                                        required: false,
                                    },
                                ]}
                            >
                                <Input />
                            </Form.Item>
                        </Space.Compact>
                        <Form.Item
                                label={t("accounts.fields.type")}
                                name="type"
                                rules={[
                                    {
                                        required: true,
                                    },
                                ]}
                            >
                            <Select
                                showSearch
                                defaultActiveFirstOption={true}
                                placeholder=""
                                optionFilterProp="children"
                                // onChange={onChange}
                                // onSearch={onSearch}
                                filterOption={(input, option) =>
                                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                }
                                options={[
                                    {
                                        value: 'conta_corrente',
                                        label: 'Conta Corrente',
                                    },
                                    {
                                        value: 'conta_poupanca',
                                        label: 'Conta Poupança',
                                    },
                                    // {
                                    //     value: 'conta_corrente_conjunta',
                                    //     label: 'Conta Corrente Conjunta',
                                    // },
                                    // {
                                    //     value: 'conta_poupanca_conjunta',
                                    //     label: 'Conta Poupança Conjunta',
                                    // },
                                ]}
                            />
                        </Form.Item>
                        {errorMsg && (
                          <Space direction="vertical" style={{ width: '100%' }}>
                            <Alert
                            message="ATENÇÃO"
                            description={errorMsg}
                            type="error"
                            showIcon
                            />
                        </Space>
                        )}
                    </Col>
                </Row>
            </Form>
        </Create>
        </>
    );
};
