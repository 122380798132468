import { useTranslate } from "@refinedev/core";

import { Tag } from "antd";

export type AccountStatusProps = {
    status: "Em Análise" | "Aprovado" | "Negado";
};

export const AccountStatus: React.FC<AccountStatusProps> = ({ status }) => {
    const t = useTranslate();
    let color;

    switch (status) {
        case "Em Análise":
            color = "yellow";
            break;
        case "Aprovado":
            color = "green";
            break;
        case "Negado":
            color = "red";
            break;
    }

    // return <Tag color={color}>{t(`enum.invoiceStatuses.${status}`)}</Tag>;
    return <Tag color={color}>{t(`${status}`)}</Tag>;
};