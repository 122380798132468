export const TOKEN_KEY = "authKey";
export const ROLE_KEY = "role";
export const COMPANY_KEY = "companyId";
export const PAGARME_TRANFER_FEE = 3.67;
export const PAGARME_BOLETO_FEE = 3.79;
export const PAGARME_PIX_FEE = 4.99;
// LOCALHOST
// export const API_URL = "http://localhost:4000";
// export const PORTAL_URL = "https://app.splitfacil.com.br";

// PROD
export const API_URL = "https://api.splitfacil.com.br";
export const PORTAL_URL = "https://app.splitfacil.com.br";

// HOMOLOG
// export const API_URL = "https://api-homolog.splitfacil.com.br";
// export const PORTAL_URL = "https://homolog.splitfacil.com.br";

// TEST
// export const API_URL = "https://api-test.splitfacil.com.br";
// export const PORTAL_URL = "https://test.splitfacil.com.br";

// source: Starkbank Institutions
export const BANK_CODES = [
  {
     "displayName":"Stark Bank",
     "name":"Stark Bank S.A.",
     "spiCode":"20018183",
     "strCode":""
  },
  {
     "displayName":"ITAÚ UNIBANCO",
     "name":"BANCO ITAÚ UNIBANCO S.A.",
     "spiCode":"60701190",
     "strCode":"341"
  },
  {
     "displayName":"BRADESCO",
     "name":"Banco Bradesco S.A.",
     "spiCode":"60746948",
     "strCode":"237"
  },
  {
   "displayName":"BCO BBI S.A.",
   "name":"Banco Bradesco BBI S.A.",
   "spiCode":"",
   "strCode":"36"
   },
   //   {
   //      "displayName":"BCO BRADESCO FINANC. S.A.",
   //      "name":"Banco Bradesco Financiamentos S.A.",
   //      "spiCode":"",
   //      "strCode":"394"
   //   },
  {
     "displayName":"NUBANK",
     "name":"Nubank - Nu Pagamentos S.A.",
     "spiCode":"18236120",
     "strCode":"260"
  },
  {
     "displayName":"BANCO DO BRASIL",
     "name":"Banco do Brasil S.A.",
     "spiCode":"00000000",
     "strCode":"1"
  },
  {
     "displayName":"CAIXA ECONOMICA FEDERAL",
     "name":"CAIXA ECONOMICA FEDERAL",
     "spiCode":"00360305",
     "strCode":"104"
  },
  {
     "displayName":"SANTANDER",
     "name":"BANCO SANTANDER (BRASIL) S.A.",
     "spiCode":"90400888",
     "strCode":"33"
  },
  {
     "displayName":"BANCO INTER",
     "name":"Banco Inter S.A.",
     "spiCode":"00416968",
     "strCode":"77"
  },
  {
     "displayName":"NEON PAGAMENTOS",
     "name":"NEON PAGAMENTOS S.A.",
     "spiCode":"20855875",
     "strCode":"536"
  },
  {
     "displayName":"BANCO ORIGINAL",
     "name":"Banco Original S.A.",
     "spiCode":"92894922",
     "strCode":"212"
  },
//   {
//      "displayName":"NEXT",
//      "name":"NEXT",
//      "spiCode":"60746948",
//      "strCode":"237"
//   },
  {
     "displayName":"C6",
     "name":"Banco C6 S.A.",
     "spiCode":"31872495",
     "strCode":"336"
  },
  {
     "displayName":"SAFRA",
     "name":"Banco Safra S.A.",
     "spiCode":"58160789",
     "strCode":"422"
  },
  {
     "displayName":"AGK CC S.A.",
     "name":"AGK CORRETORA DE CAMBIO S.A.",
     "spiCode":"",
     "strCode":"272"
  },
  {
     "displayName":"CONF NAC COOP CENTRAIS UNICRED",
     "name":"CONFEDERAÇÃO NACIONAL DAS COOPERATIVAS CENTRAIS UNICRED LTDA. - UNICRED DO BRASI",
     "spiCode":"00315557",
     "strCode":"136"
  },
  {
     "displayName":"STN",
     "name":"Secretaria do Tesouro Nacional - STN",
     "spiCode":"00394460",
     "strCode":""
  },
  {
     "displayName":"BCO RIBEIRAO PRETO S.A.",
     "name":"BANCO RIBEIRAO PRETO S.A.",
     "spiCode":"00517645",
     "strCode":"741"
  },
  {
     "displayName":"BANCO BARI S.A.",
     "name":"BANCO BARI DE INVESTIMENTOS E FINANCIAMENTOS S.A.",
     "spiCode":"00556603",
     "strCode":"330"
  },
  {
     "displayName":"BCO CETELEM S.A.",
     "name":"Banco Cetelem S.A.",
     "spiCode":"",
     "strCode":"739"
  },
  {
     "displayName":"BANCO SEMEAR",
     "name":"Banco Semear S.A.",
     "spiCode":"00795423",
     "strCode":"743"
  },
  {
     "displayName":"PLANNER CV S.A.",
     "name":"Planner Corretora de Valores S.A.",
     "spiCode":"",
     "strCode":"100"
  },
  {
     "displayName":"BCO B3 S.A.",
     "name":"Banco B3 S.A.",
     "spiCode":"",
     "strCode":"96"
  },
  {
     "displayName":"BCO RABOBANK INTL BRASIL S.A.",
     "name":"Banco Rabobank International Brasil S.A.",
     "spiCode":"",
     "strCode":"747"
  },
  {
     "displayName":"CIELO S.A.",
     "name":"CIELO S.A.",
     "spiCode":"01027058",
     "strCode":"362"
  },
  {
     "displayName":"CCR DE ABELARDO LUZ",
     "name":"Cooperativa de Crédito Rural de Abelardo Luz - Sulcredi/Crediluz",
     "spiCode":"01073966",
     "strCode":"322"
  },
  {
     "displayName":"BCO COOPERATIVO SICREDI S.A.",
     "name":"BANCO COOPERATIVO SICREDI S.A.",
     "spiCode":"01181521",
     "strCode":"748"
  },
  {
     "displayName":"CREHNOR LARANJEIRAS",
     "name":"COOPERATIVA DE CRÉDITO RURAL DE PEQUENOS AGRICULTORES E DA REFORMA AGRÁRIA DO CE",
     "spiCode":"01330387",
     "strCode":"350"
  },
  {
     "displayName":"BCO BNP PARIBAS BRASIL S A",
     "name":"Banco BNP Paribas Brasil S.A.",
     "spiCode":"",
     "strCode":"752"
  },
  {
     "displayName":"CCCM UNICRED CENTRAL RS",
     "name":"CENTRAL DE COOPERATIVAS DE ECONOMIA E CRÉDITO MÚTUO DO ESTADO DO RIO GRANDE DO S",
     "spiCode":"",
     "strCode":"91"
  },
  {
     "displayName":"CECM COOPERFORTE",
     "name":"COOPERFORTE - COOPERATIVA DE ECONOMIA E CRÉDITO MÚTUO DE FUNCIONÁRIOS DE INSTITU",
     "spiCode":"",
     "strCode":"379"
  },
  {
     "displayName":"KIRTON BANK",
     "name":"Kirton Bank S.A. - Banco Múltiplo",
     "spiCode":"",
     "strCode":"399"
  },
  {
     "displayName":"PORTOCRED S.A. - CFI",
     "name":"PORTOCRED S.A. - CREDITO",
     "spiCode":"",
     "strCode":"108"
  },
  {
     "displayName":"BBC LEASING",
     "name":"BBC LEASING S.A. - ARRENDAMENTO MERCANTIL",
     "spiCode":"",
     "strCode":"378"
  },
  {
     "displayName":"BANCOOB",
     "name":"BANCO COOPERATIVO DO BRASIL S.A. - BANCOOB",
     "spiCode":"02038232",
     "strCode":"756"
  },
  {
     "displayName":"TRINUS CAPITAL DTVM",
     "name":"TRINUS CAPITAL DISTRIBUIDORA DE TÍTULOS E VALORES MOBILIÁRIOS S.A.",
     "spiCode":"",
     "strCode":"360"
  },
  {
     "displayName":"BCO KEB HANA DO BRASIL S.A.",
     "name":"BANCO KEB HANA DO BRASIL S.A.",
     "spiCode":"02318507",
     "strCode":"757"
  },
  {
     "displayName":"XP INVESTIMENTOS CCTVM S/A",
     "name":"XP INVESTIMENTOS CORRETORA DE CÂMBIO",
     "spiCode":"",
     "strCode":"102"
  },
  {
     "displayName":"UNIPRIME NORTE DO PARANÁ - CC",
     "name":"UNIPRIME NORTE DO PARANÁ - COOPERATIVA DE CRÉDITO LTDA",
     "spiCode":"02398976",
     "strCode":"84"
  },
  {
     "displayName":"CM CAPITAL MARKETS CCTVM LTDA",
     "name":"CM CAPITAL MARKETS CORRETORA DE CÂMBIO",
     "spiCode":"",
     "strCode":"180"
  },
  {
     "displayName":"BCO MORGAN STANLEY S.A.",
     "name":"BANCO MORGAN STANLEY S.A.",
     "spiCode":"",
     "strCode":"66"
  },
  {
     "displayName":"UBS BRASIL CCTVM S.A.",
     "name":"UBS Brasil Corretora de Câmbio",
     "spiCode":"",
     "strCode":"15"
  },
  {
     "displayName":"TREVISO CC S.A.",
     "name":"Treviso Corretora de Câmbio S.A.",
     "spiCode":"",
     "strCode":"143"
  },
  {
     "displayName":"CIP Siloc",
     "name":"Câmara Interbancária de Pagamentos - CIP - LDL",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"HIPERCARD BM S.A.",
     "name":"Hipercard Banco Múltiplo S.A.",
     "spiCode":"",
     "strCode":"62"
  },
  {
     "displayName":"BCO. J.SAFRA S.A.",
     "name":"Banco J. Safra S.A.",
     "spiCode":"",
     "strCode":"74"
  },
  {
     "displayName":"UNIPRIME CENTRAL CCC LTDA.",
     "name":"UNIPRIME CENTRAL - CENTRAL INTERESTADUAL DE COOPERATIVAS DE CREDITO LTDA.",
     "spiCode":"03046391",
     "strCode":"99"
  },
  {
     "displayName":"BCO TOYOTA DO BRASIL S.A.",
     "name":"Banco Toyota do Brasil S.A.",
     "spiCode":"",
     "strCode":"387"
  },
  {
     "displayName":"PARATI - CFI S.A.",
     "name":"PARATI - CREDITO",
     "spiCode":"03311443",
     "strCode":"326"
  },
  {
     "displayName":"BCO ALFA S.A.",
     "name":"Banco Alfa S.A.",
     "spiCode":"03323840",
     "strCode":"25"
  },
  {
     "displayName":"PI DTVM S.A.",
     "name":"PI Distribuidora de Títulos e Valores Mobiliários S.A.",
     "spiCode":"",
     "strCode":"315"
  },
  {
     "displayName":"BCO ABN AMRO S.A.",
     "name":"Banco ABN Amro S.A.",
     "spiCode":"",
     "strCode":"75"
  },
  {
     "displayName":"BCO CARGILL S.A.",
     "name":"Banco Cargill S.A.",
     "spiCode":"",
     "strCode":"40"
  },
  {
     "displayName":"TERRA INVESTIMENTOS DTVM",
     "name":"Terra Investimentos Distribuidora de Títulos e Valores Mobiliários Ltda.",
     "spiCode":"",
     "strCode":"307"
  },
  {
     "displayName":"SERVICOOP",
     "name":"SERVICOOP - COOPERATIVA DE CRÉDITO DOS SERVIDORES PÚBLICOS ESTADUAIS DO RIO GRAN",
     "spiCode":"",
     "strCode":"190"
  },
  {
     "displayName":"VISION S.A. CC",
     "name":"VISION S.A. CORRETORA DE CAMBIO",
     "spiCode":"",
     "strCode":"296"
  },
//   {
//      "displayName":"BANCO BRADESCARD",
//      "name":"Banco Bradescard S.A.",
//      "spiCode":"",
//      "strCode":"63"
//   },
  {
     "displayName":"NOVA FUTURA CTVM LTDA.",
     "name":"Nova Futura Corretora de Títulos e Valores Mobiliários Ltda.",
     "spiCode":"",
     "strCode":"191"
  },
  {
     "displayName":"FIDUCIA SCMEPP LTDA",
     "name":"FIDÚCIA SOCIEDADE DE CRÉDITO AO MICROEMPREENDEDOR E À EMPRESA DE PEQUENO PORTE L",
     "spiCode":"04307598",
     "strCode":"382"
  },
  {
     "displayName":"GOLDMAN SACHS DO BRASIL BM S.A",
     "name":"GOLDMAN SACHS DO BRASIL BANCO MULTIPLO S.A.",
     "spiCode":"",
     "strCode":"64"
  },
  {
     "displayName":"CIP Sitraf",
     "name":"Câmara Interbancária de Pagamentos",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"CREDISIS CENTRAL DE COOPERATIVAS DE CRÉDITO LTDA.",
     "name":"Credisis - Central de Cooperativas de Crédito Ltda.",
     "spiCode":"04632856",
     "strCode":"97"
  },
  {
     "displayName":"CCM DESP TRÂNS SC E RS",
     "name":"COOPERATIVA DE CRÉDITO MÚTUO DOS DESPACHANTES DE TRÂNSITO DE SANTA CATARINA E RI",
     "spiCode":"04715685",
     "strCode":"16"
  },
  {
     "displayName":"SOROCRED CFI S.A.",
     "name":"SOROCRED   CRÉDITO",
     "spiCode":"04814563",
     "strCode":"299"
  },
  {
     "displayName":"BANCO INBURSA",
     "name":"Banco Inbursa S.A.",
     "spiCode":"04866275",
     "strCode":"12"
  },
  {
     "displayName":"BCO DA AMAZONIA S.A.",
     "name":"BANCO DA AMAZONIA S.A.",
     "spiCode":"04902979",
     "strCode":"3"
  },
  {
     "displayName":"CONFIDENCE CC S.A.",
     "name":"Confidence Corretora de Câmbio S.A.",
     "spiCode":"",
     "strCode":"60"
  },
  {
     "displayName":"BCO DO EST. DO PA S.A.",
     "name":"Banco do Estado do Pará S.A.",
     "spiCode":"04913711",
     "strCode":"37"
  },
  {
     "displayName":"ZEMA CFI S/A",
     "name":"ZEMA CRÉDITO",
     "spiCode":"05351887",
     "strCode":"359"
  },
  {
     "displayName":"CASA CREDITO S.A. SCM",
     "name":"Casa do Crédito S.A. Sociedade de Crédito ao Microempreendedor",
     "spiCode":"05442029",
     "strCode":"159"
  },
  {
     "displayName":"COOP CENTRAL AILOS",
     "name":"Cooperativa Central de Crédito - Ailos",
     "spiCode":"05463212",
     "strCode":"85"
  },
  {
     "displayName":"CENTRAL COOPERATIVA DE CRÉDITO NO ESTADO DO ESPÍRITO SANTO",
     "name":"Central Cooperativa de Crédito no Estado do Espírito Santo - CECOOP",
     "spiCode":"",
     "strCode":"114"
  },
  {
     "displayName":"BCO DO NORDESTE DO BRASIL S.A.",
     "name":"Banco do Nordeste do Brasil S.A.",
     "spiCode":"07237373",
     "strCode":"4"
  },
  {
     "displayName":"BCO CCB BRASIL S.A.",
     "name":"China Construction Bank (Brasil) Banco Múltiplo S/A",
     "spiCode":"",
     "strCode":"320"
  },
  {
     "displayName":"HS FINANCEIRA",
     "name":"HS FINANCEIRA S/A CREDITO",
     "spiCode":"",
     "strCode":"189"
  },
  {
     "displayName":"LECCA CFI S.A.",
     "name":"Lecca Crédito",
     "spiCode":"",
     "strCode":"105"
  },
  {
     "displayName":"BCO KDB BRASIL S.A.",
     "name":"Banco KDB do Brasil S.A.",
     "spiCode":"",
     "strCode":"76"
  },
  {
     "displayName":"BANCO TOPÁZIO S.A.",
     "name":"BANCO TOPÁZIO S.A.",
     "spiCode":"07679404",
     "strCode":"82"
  },
  {
     "displayName":"CCR DE OURO",
     "name":"COOPERATIVA DE CRÉDITO RURAL DE OURO   SULCREDI/OURO",
     "spiCode":"",
     "strCode":"286"
  },
  {
     "displayName":"POLOCRED SCMEPP LTDA.",
     "name":"PÓLOCRED   SOCIEDADE DE CRÉDITO AO MICROEMPREENDEDOR E À EMPRESA DE PEQUENO PORT",
     "spiCode":"",
     "strCode":"93"
  },
  {
     "displayName":"CCR DE IBIAM",
     "name":"COOPERATIVA DE CREDITO RURAL DE IBIAM - SULCREDI/IBIAM",
     "spiCode":"",
     "strCode":"391"
  },
  {
     "displayName":"CCR DE SÃO MIGUEL DO OESTE",
     "name":"Cooperativa de Crédito Rural de São Miguel do Oeste - Sulcredi/São Miguel",
     "spiCode":"08253539",
     "strCode":"273"
  },
  {
     "displayName":"BCO CSF S.A.",
     "name":"Banco CSF S.A.",
     "spiCode":"08357240",
     "strCode":"368"
  },
  {
     "displayName":"PAGSEGURO",
     "name":"PagBank - Pagseguro Internet S.A.",
     "spiCode":"08561701",
     "strCode":"290"
  },
  {
     "displayName":"MONEYCORP BCO DE CÂMBIO S.A.",
     "name":"MONEYCORP BANCO DE CÂMBIO S.A.",
     "spiCode":"",
     "strCode":"259"
  },
  {
     "displayName":"F D GOLD DTVM LTDA",
     "name":"F.D'GOLD - DISTRIBUIDORA DE TÍTULOS E VALORES MOBILIÁRIOS LTDA.",
     "spiCode":"",
     "strCode":"395"
  },
  {
     "displayName":"GERENCIANET",
     "name":"GERENCIANET S.A.",
     "spiCode":"09089356",
     "strCode":"364"
  },
  {
     "displayName":"ICAP DO BRASIL CTVM LTDA.",
     "name":"ICAP do Brasil Corretora de Títulos e Valores Mobiliários Ltda.",
     "spiCode":"",
     "strCode":"157"
  },
  {
     "displayName":"SOCRED SA - SCMEPP",
     "name":"SOCRED S.A. - SOCIEDADE DE CRÉDITO AO MICROEMPREENDEDOR E À EMPRESA DE PEQUENO P",
     "spiCode":"09210106",
     "strCode":"183"
  },
  {
     "displayName":"STATE STREET BR S.A. BCO COMERCIAL",
     "name":"STATE STREET BRASIL S.A. ? BANCO COMERCIAL",
     "spiCode":"",
     "strCode":"14"
  },
  {
     "displayName":"CARUANA SCFI",
     "name":"CARUANA S.A. - SOCIEDADE DE CRÉDITO",
     "spiCode":"09313766",
     "strCode":"130"
  },
  {
     "displayName":"CODEPE CVC S.A.",
     "name":"Codepe Corretora de Valores e Câmbio S.A.",
     "spiCode":"",
     "strCode":"127"
  },
//   {
//      "displayName":"BCO ORIGINAL DO AGRO S/A",
//      "name":"Banco Original do Agronegócio S.A.",
//      "spiCode":"09516419",
//      "strCode":"79"
//   },
  {
     "displayName":"SUPER PAGAMENTOS E ADMINISTRACAO DE MEIOS ELETRONICOS S.A.",
     "name":"Super Pagamentos e Administração de Meios Eletrônicos S.A.",
     "spiCode":"09554480",
     "strCode":"340"
  },
  {
     "displayName":"BANCOSEGURO S.A.",
     "name":"BancoSeguro S.A.",
     "spiCode":"",
     "strCode":"81"
  },
  {
     "displayName":"CRESOL CONFEDERAÇÃO",
     "name":"CONFEDERAÇÃO NACIONAL DAS COOPERATIVAS CENTRAIS DE CRÉDITO E ECONOMIA FAMILIAR E",
     "spiCode":"10398952",
     "strCode":"133"
  },
  {
     "displayName":"MERCADO PAGO",
     "name":"MERCADOPAGO.COM REPRESENTACOES LTDA.",
     "spiCode":"10573521",
     "strCode":"323"
  },
  {
     "displayName":"BCO AGIBANK S.A.",
     "name":"Banco Agibank S.A.",
     "spiCode":"10664513",
     "strCode":"121"
  },
  {
     "displayName":"BCO DA CHINA BRASIL S.A.",
     "name":"Banco da China Brasil S.A.",
     "spiCode":"",
     "strCode":"83"
  },
  {
     "displayName":"GET MONEY CC LTDA",
     "name":"Get Money Corretora de Câmbio S.A.",
     "spiCode":"",
     "strCode":"138"
  },
  {
     "displayName":"BCO BANDEPE S.A.",
     "name":"Banco Bandepe S.A.",
     "spiCode":"",
     "strCode":"24"
  },
  {
     "displayName":"GLOBAL SCM LTDA",
     "name":"GLOBAL FINANÇAS SOCIEDADE DE CRÉDITO AO MICROEMPREENDEDOR E À EMPRESA DE PEQUENO",
     "spiCode":"11165756",
     "strCode":"384"
  },
  {
     "displayName":"BANCO RANDON S.A.",
     "name":"BANCO RANDON S.A.",
     "spiCode":"11476673",
     "strCode":"88"
  },
  {
     "displayName":"OM DTVM LTDA",
     "name":"OM DISTRIBUIDORA DE TÍTULOS E VALORES MOBILIÁRIOS LTDA",
     "spiCode":"",
     "strCode":"319"
  },
  {
     "displayName":"MONEY PLUS SCMEPP LTDA",
     "name":"MONEY PLUS SOCIEDADE DE CRÉDITO AO MICROEMPREENDEDOR E A EMPRESA DE PEQUENO PORT",
     "spiCode":"11581339",
     "strCode":"274"
  },
  {
     "displayName":"TRAVELEX BANCO DE CÂMBIO S.A.",
     "name":"Travelex Banco de Câmbio S.A.",
     "spiCode":"11703662",
     "strCode":"95"
  },
  {
     "displayName":"BANCO FINAXIS",
     "name":"Banco Finaxis S.A.",
     "spiCode":"",
     "strCode":"94"
  },
  {
     "displayName":"BCO SENFF S.A.",
     "name":"BANCO SENFF S.A.",
     "spiCode":"11970623",
     "strCode":"276"
  },
  {
     "displayName":"BRK S.A. CFI",
     "name":"BRK S.A. Crédito",
     "spiCode":"",
     "strCode":"92"
  },
  {
     "displayName":"BCO DO EST. DE SE S.A.",
     "name":"Banco do Estado de Sergipe S.A.",
     "spiCode":"13009717",
     "strCode":"47"
  },
  {
     "displayName":"BEXS BCO DE CAMBIO S.A.",
     "name":"BEXS BANCO DE CÂMBIO S/A",
     "spiCode":"13059145",
     "strCode":"144"
  },
  {
     "displayName":"ACESSO SOLUCOES PAGAMENTO SA",
     "name":"Acesso Soluções de Pagamento S.A.",
     "spiCode":"13140088",
     "strCode":"332"
  },
  {
     "displayName":"BR PARTNERS BI",
     "name":"BR Partners Banco de Investimento S.A.",
     "spiCode":"",
     "strCode":"126"
  },
  {
     "displayName":"ÓRAMA DTVM S.A.",
     "name":"Órama Distribuidora de Títulos e Valores Mobiliários S.A.",
     "spiCode":"",
     "strCode":"325"
  },
  {
     "displayName":"BPP IP S.A.",
     "name":"BPP Instituição de Pagamento S.A.",
     "spiCode":"13370835",
     "strCode":"301"
  },
  {
     "displayName":"BRL TRUST DTVM SA",
     "name":"BRL Trust Distribuidora de Títulos e Valores Mobiliários S.A.",
     "spiCode":"",
     "strCode":"173"
  },
  {
     "displayName":"FRAM CAPITAL DTVM S.A.",
     "name":"Fram Capital Distribuidora de Títulos e Valores Mobiliários S.A.",
     "spiCode":"",
     "strCode":"331"
  },
  {
     "displayName":"BCO WESTERN UNION",
     "name":"Banco Western Union do Brasil S.A.",
     "spiCode":"13720915",
     "strCode":"119"
  },
  {
     "displayName":"HUB PAGAMENTOS",
     "name":"HUB PAGAMENTOS S.A",
     "spiCode":"13884775",
     "strCode":"396"
  },
  {
     "displayName":"CAMBIONET CC LTDA",
     "name":"CAMBIONET CORRETORA DE CÂMBIO LTDA.",
     "spiCode":"",
     "strCode":"309"
  },
  {
     "displayName":"PARANA BCO S.A.",
     "name":"PARANÁ BANCO S.A.",
     "spiCode":"14388334",
     "strCode":"254"
  },
  {
     "displayName":"BARI CIA HIPOTECÁRIA",
     "name":"BARI COMPANHIA HIPOTECÁRIA",
     "spiCode":"",
     "strCode":"268"
  },
  {
     "displayName":"BCO BOCOM BBM S.A.",
     "name":"Banco Bocom BBM S.A.",
     "spiCode":"",
     "strCode":"107"
  },
  {
     "displayName":"BCO CAPITAL S.A.",
     "name":"BANCO CAPITAL S.A.",
     "spiCode":"15173776",
     "strCode":"412"
  },
  {
     "displayName":"BCO WOORI BANK DO BRASIL S.A.",
     "name":"Banco Woori Bank do Brasil S.A.",
     "spiCode":"15357060",
     "strCode":"124"
  },
  {
     "displayName":"FACTA S.A. CFI",
     "name":"Facta Financeira S.A. - Crédito Financiamento e Investimento",
     "spiCode":"",
     "strCode":"149"
  },
  {
     "displayName":"STONE PAGAMENTOS S.A.",
     "name":"Stone Pagamentos S.A.",
     "spiCode":"16501555",
     "strCode":"197"
  },
  {
     "displayName":"AMAZÔNIA CC LTDA.",
     "name":"AMAZÔNIA CORRETORA DE CÂMBIO LTDA.",
     "spiCode":"",
     "strCode":"313"
  },
  {
     "displayName":"BROKER BRASIL CC LTDA.",
     "name":"Broker Brasil Corretora de Câmbio Ltda.",
     "spiCode":"",
     "strCode":"142"
  },
  {
     "displayName":"BCO MERCANTIL DO BRASIL S.A.",
     "name":"Banco Mercantil do Brasil S.A.",
     "spiCode":"17184037",
     "strCode":"389"
  },
//   {
//      "displayName":"BCO ITAÚ BBA S.A.",
//      "name":"Banco Itaú BBA S.A.",
//      "spiCode":"",
//      "strCode":"184"
//   },
  {
     "displayName":"BCO TRIANGULO S.A.",
     "name":"BANCO TRIANGULO S.A.",
     "spiCode":"17351180",
     "strCode":"634"
  },
  {
     "displayName":"SENSO CCVM S.A.",
     "name":"SENSO CORRETORA DE CAMBIO E VALORES MOBILIARIOS S.A",
     "spiCode":"",
     "strCode":"545"
  },
  {
     "displayName":"ICBC DO BRASIL BM S.A.",
     "name":"ICBC do Brasil Banco Múltiplo S.A.",
     "spiCode":"",
     "strCode":"132"
  },
  {
     "displayName":"VIPS CC LTDA.",
     "name":"Vip's Corretora de Câmbio Ltda.",
     "spiCode":"",
     "strCode":"298"
  },
  {
     "displayName":"BMS SCD S.A.",
     "name":"BMS SOCIEDADE DE CRÉDITO DIRETO S.A.",
     "spiCode":"",
     "strCode":"377"
  },
  {
     "displayName":"CREFAZ SCMEPP LTDA",
     "name":"CREFAZ SOCIEDADE DE CRÉDITO AO MICROEMPREENDEDOR E A EMPRESA DE PEQUENO PORTE LT",
     "spiCode":"",
     "strCode":"321"
  },
  {
     "displayName":"UBS BRASIL BI S.A.",
     "name":"UBS Brasil Banco de Investimento S.A.",
     "spiCode":"",
     "strCode":"129"
  },
  {
     "displayName":"MS BANK S.A. BCO DE CÂMBIO",
     "name":"MS Bank S.A. Banco de Câmbio",
     "spiCode":"",
     "strCode":"128"
  },
  {
     "displayName":"PARMETAL DTVM LTDA",
     "name":"PARMETAL DISTRIBUIDORA DE TÍTULOS E VALORES MOBILIÁRIOS LTDA",
     "spiCode":"",
     "strCode":"194"
  },
  {
     "displayName":"JUNO",
     "name":"BOLETOBANCÁRIO.COM TECNOLOGIA DE PAGAMENTOS LTDA.",
     "spiCode":"21018182",
     "strCode":"383"
  },
  {
     "displayName":"CARTOS SCD S.A.",
     "name":"CARTOS SOCIEDADE DE CRÉDITO DIRETO S.A.",
     "spiCode":"21332862",
     "strCode":"324"
  },
  {
     "displayName":"VORTX DTVM LTDA.",
     "name":"VORTX DISTRIBUIDORA DE TITULOS E VALORES MOBILIARIOS LTDA.",
     "spiCode":"22610500",
     "strCode":"310"
  },
  {
     "displayName":"PICPAY",
     "name":"PICPAY SERVICOS S.A.",
     "spiCode":"22896431",
     "strCode":"380"
  },
  {
     "displayName":"COMMERZBANK BRASIL S.A. - BCO MÚLTIPLO",
     "name":"Commerzbank Brasil S.A. - Banco Múltiplo",
     "spiCode":"",
     "strCode":"163"
  },
  {
     "displayName":"WILL FINANCEIRA S.A.CFI",
     "name":"WILL FINANCEIRA S.A. CRÉDITO",
     "spiCode":"",
     "strCode":"280"
  },
  {
     "displayName":"GUITTA CC LTDA",
     "name":"GUITTA CORRETORA DE CAMBIO LTDA.",
     "spiCode":"",
     "strCode":"146"
  },
  {
     "displayName":"FFA SCMEPP LTDA.",
     "name":"FFA SOCIEDADE DE CRÉDITO AO MICROEMPREENDEDOR E À EMPRESA DE PEQUENO PORTE LTDA.",
     "spiCode":"",
     "strCode":"343"
  },
  {
     "displayName":"CCR DE PRIMAVERA DO LESTE",
     "name":"COOPERATIVA DE CREDITO RURAL DE PRIMAVERA DO LESTE",
     "spiCode":"26563270",
     "strCode":"279"
  },
  {
     "displayName":"BANCO DIGIO",
     "name":"Banco Digio S.A.",
     "spiCode":"27098060",
     "strCode":"335"
  },
  {
     "displayName":"AL5 S.A. CFI",
     "name":"AL5 S.A. CRÉDITO",
     "spiCode":"",
     "strCode":"349"
  },
  {
     "displayName":"REALIZE CFI S.A.",
     "name":"REALIZE CRÉDITO",
     "spiCode":"27351731",
     "strCode":"374"
  },
  {
     "displayName":"GENIAL INVESTIMENTOS CVM S.A.",
     "name":"Genial Investimentos Corretora de Valores Mobiliários S.A.",
     "spiCode":"",
     "strCode":"278"
  },
  {
     "displayName":"IB CCTVM S.A.",
     "name":"IB Corretora de Câmbio",
     "spiCode":"",
     "strCode":"271"
  },
  {
     "displayName":"BCO BANESTES S.A.",
     "name":"BANESTES S.A. BANCO DO ESTADO DO ESPIRITO SANTO",
     "spiCode":"28127603",
     "strCode":"21"
  },
  {
     "displayName":"BCO ABC BRASIL S.A.",
     "name":"Banco ABC Brasil S.A.",
     "spiCode":"28195667",
     "strCode":"246"
  },
  {
     "displayName":"BS2 DTVM S.A.",
     "name":"BS2 Distribuidora de Títulos e Valores Mobiliários S.A.",
     "spiCode":"",
     "strCode":"292"
  },
  {
     "displayName":"Balcão B3",
     "name":"Sistema do Balcão B3",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"CIP C3",
     "name":"Câmara Interbancária de Pagamentos - CIP C3",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"SCOTIABANK BRASIL",
     "name":"Scotiabank Brasil S.A. Banco Múltiplo",
     "spiCode":"",
     "strCode":"751"
  },
  {
     "displayName":"TORO CTVM LTDA",
     "name":"TORO CORRETORA DE TÍTULOS E VALORES MOBILIÁRIOS LTDA",
     "spiCode":"",
     "strCode":"352"
  },
  {
     "displayName":"BANCO BTG PACTUAL S.A.",
     "name":"Banco BTG Pactual S.A.",
     "spiCode":"30306294",
     "strCode":"208"
  },
  {
     "displayName":"NU FINANCEIRA S.A. CFI",
     "name":"NU FINANCEIRA S.A. - Sociedade de Crédito",
     "spiCode":"",
     "strCode":"386"
  },
  {
     "displayName":"BCO MODAL S.A.",
     "name":"Banco Modal S.A.",
     "spiCode":"30723886",
     "strCode":"746"
  },
  {
     "displayName":"BCO CLASSICO S.A.",
     "name":"BANCO CLASSICO S.A.",
     "spiCode":"",
     "strCode":"241"
  },
  {
     "displayName":"IDEAL CTVM S.A.",
     "name":"IDEAL CORRETORA DE TÍTULOS E VALORES MOBILIÁRIOS S.A.",
     "spiCode":"",
     "strCode":"398"
  },
  {
     "displayName":"BCO GUANABARA S.A.",
     "name":"Banco Guanabara S.A.",
     "spiCode":"31880826",
     "strCode":"612"
  },
  {
     "displayName":"BCO INDUSTRIAL DO BRASIL S.A.",
     "name":"Banco Industrial do Brasil S.A.",
     "spiCode":"31895683",
     "strCode":"604"
  },
  {
     "displayName":"BCO CREDIT SUISSE S.A.",
     "name":"Banco Credit Suisse (Brasil) S.A.",
     "spiCode":"",
     "strCode":"505"
  },
  {
     "displayName":"QI SCD S.A.",
     "name":"QI Sociedade de Crédito Direto S.A.",
     "spiCode":"32402502",
     "strCode":"329"
  },
  {
     "displayName":"FAIR CC S.A.",
     "name":"FAIR CORRETORA DE CAMBIO S.A.",
     "spiCode":"",
     "strCode":"196"
  },
  {
     "displayName":"CREDITAS SCD",
     "name":"Creditas Sociedade de Crédito Direto S.A.",
     "spiCode":"",
     "strCode":"342"
  },
  {
     "displayName":"BCO LA NACION ARGENTINA",
     "name":"Banco de la Nacion Argentina",
     "spiCode":"",
     "strCode":"300"
  },
  {
     "displayName":"CITIBANK N.A.",
     "name":"Citibank N.A.",
     "spiCode":"",
     "strCode":"477"
  },
  {
     "displayName":"BCO CEDULA S.A.",
     "name":"BANCO CEDULA S.A.",
     "spiCode":"",
     "strCode":"266"
  },
//   {
//      "displayName":"BCO BRADESCO BERJ S.A.",
//      "name":"Banco Bradesco BERJ S.A.",
//      "spiCode":"",
//      "strCode":"122"
//   },
  {
     "displayName":"BCO J.P. MORGAN S.A.",
     "name":"BANCO J.P. MORGAN S.A.",
     "spiCode":"33172537",
     "strCode":"376"
  },
  {
     "displayName":"BCO XP S.A.",
     "name":"Banco XP S.A.",
     "spiCode":"33264668",
     "strCode":"348"
  },
//   {
//      "displayName":"BCO CAIXA GERAL BRASIL S.A.",
//      "name":"Banco Caixa Geral - Brasil S.A.",
//      "spiCode":"",
//      "strCode":"473"
//   },
  {
     "displayName":"BCO CITIBANK S.A.",
     "name":"Banco Citibank S.A.",
     "spiCode":"33479023",
     "strCode":"745"
  },
  {
     "displayName":"BCO RODOBENS S.A.",
     "name":"BANCO RODOBENS S.A.",
     "spiCode":"",
     "strCode":"120"
  },
  {
     "displayName":"BCO FATOR S.A.",
     "name":"Banco Fator S.A.",
     "spiCode":"",
     "strCode":"265"
  },
  {
     "displayName":"BNDES",
     "name":"BANCO NACIONAL DE DESENVOLVIMENTO ECONOMICO E SOCIAL",
     "spiCode":"",
     "strCode":"7"
  },
  {
     "displayName":"ATIVA S.A. INVESTIMENTOS CCTVM",
     "name":"ATIVA INVESTIMENTOS S.A. CORRETORA DE TÍTULOS",
     "spiCode":"",
     "strCode":"188"
  },
  {
     "displayName":"BGC LIQUIDEZ DTVM LTDA",
     "name":"BGC LIQUIDEZ DISTRIBUIDORA DE TÍTULOS E VALORES MOBILIÁRIOS LTDA",
     "spiCode":"",
     "strCode":"134"
  },
//   {
//      "displayName":"BCO ITAÚ CONSIGNADO S.A.",
//      "name":"Banco Itaú Consignado S.A.",
//      "spiCode":"",
//      "strCode":"29"
//   },
  {
     "displayName":"BCO MÁXIMA S.A.",
     "name":"Banco Máxima S.A.",
     "spiCode":"33923798",
     "strCode":"243"
  },
  {
     "displayName":"LISTO SCD S.A.",
     "name":"LISTO SOCIEDADE DE CREDITO DIRETO S.A.",
     "spiCode":"34088029",
     "strCode":"397"
  },
  {
     "displayName":"HAITONG BI DO BRASIL S.A.",
     "name":"Haitong Banco de Investimento do Brasil S.A.",
     "spiCode":"",
     "strCode":"78"
  },
  {
     "displayName":"ÓTIMO SCD S.A.",
     "name":"ÓTIMO SOCIEDADE DE CRÉDITO DIRETO S.A.",
     "spiCode":"34335592",
     "strCode":"355"
  },
  {
     "displayName":"VITREO DTVM S.A.",
     "name":"VITREO DISTRIBUIDORA DE TÍTULOS E VALORES MOBILIÁRIOS S.A.",
     "spiCode":"",
     "strCode":"367"
  },
  {
     "displayName":"UP.P SEP S.A.",
     "name":"UP.P SOCIEDADE DE EMPRÉSTIMO ENTRE PESSOAS S.A.",
     "spiCode":"",
     "strCode":"373"
  },
  {
     "displayName":"OLIVEIRA TRUST DTVM S.A.",
     "name":"OLIVEIRA TRUST DISTRIBUIDORA DE TÍTULOS E VALORES MOBILIARIOS S.A.",
     "spiCode":"",
     "strCode":"111"
  },
  {
     "displayName":"BÔNUSCRED SCD S.A.",
     "name":"BÔNUSCRED SOCIEDADE DE CRÉDITO DIRETO S.A.",
     "spiCode":"36586946",
     "strCode":"408"
  },
  {
     "displayName":"COBUCCIO SCD S.A.",
     "name":"COBUCCIO SOCIEDADE DE CRÉDITO DIRETO S.A.",
     "spiCode":"36947229",
     "strCode":"402"
  },
  {
     "displayName":"SUMUP SCD S.A.",
     "name":"SUMUP SOCIEDADE DE CRÉDITO DIRETO S.A.",
     "spiCode":"37241230",
     "strCode":"404"
  },
  {
     "displayName":"ACCREDITO SCD S.A.",
     "name":"ACCREDITO - SOCIEDADE DE CRÉDITO DIRETO S.A.",
     "spiCode":"37715993",
     "strCode":"406"
  },
  {
     "displayName":"CORA SCD S.A.",
     "name":"CORA SOCIEDADE DE CRÉDITO DIRETO S.A.",
     "spiCode":"37880206",
     "strCode":"403"
  },
  {
     "displayName":"PORTOPAR DTVM LTDA",
     "name":"PORTOPAR DISTRIBUIDORA DE TITULOS E VALORES MOBILIARIOS LTDA.",
     "spiCode":"",
     "strCode":"306"
  },
  {
     "displayName":"BNY MELLON BCO S.A.",
     "name":"BNY Mellon Banco S.A.",
     "spiCode":"",
     "strCode":"17"
  },
  {
     "displayName":"PEFISA S.A. - CFI",
     "name":"PEFISA S.A. - CRÉDITO",
     "spiCode":"43180355",
     "strCode":"174"
  },
  {
     "displayName":"BCO LA PROVINCIA B AIRES BCE",
     "name":"Banco de La Provincia de Buenos Aires",
     "spiCode":"",
     "strCode":"495"
  },
  {
     "displayName":"PLURAL BCO BM",
     "name":"Plural S.A. Banco Múltiplo",
     "spiCode":"45246410",
     "strCode":"125"
  },
  {
     "displayName":"JPMORGAN CHASE BANK",
     "name":"JPMorgan Chase Bank",
     "spiCode":"",
     "strCode":"488"
  },
  {
     "displayName":"BCO ANDBANK S.A.",
     "name":"Banco AndBank (Brasil) S.A.",
     "spiCode":"",
     "strCode":"65"
  },
  {
     "displayName":"ING BANK N.V.",
     "name":"ING Bank N.V.",
     "spiCode":"",
     "strCode":"492"
  },
  {
     "displayName":"LEVYCAM CCV LTDA",
     "name":"LEVYCAM - CORRETORA DE CAMBIO E VALORES LTDA.",
     "spiCode":"",
     "strCode":"145"
  },
  {
     "displayName":"BCV",
     "name":"BCV - BANCO DE CRÉDITO E VAREJO S.A.",
     "spiCode":"",
     "strCode":"250"
  },
  {
     "displayName":"NECTON INVESTIMENTOS S.A CVM",
     "name":"NECTON INVESTIMENTOS  S.A. CORRETORA DE VALORES MOBILIÁRIOS E COMMODITIES",
     "spiCode":"",
     "strCode":"354"
  },
  {
     "displayName":"BEXS CC S.A.",
     "name":"Bexs Corretora de Câmbio S/A",
     "spiCode":"",
     "strCode":"253"
  },
  {
     "displayName":"BCO HSBC S.A.",
     "name":"BANCO HSBC S.A.",
     "spiCode":"53518684",
     "strCode":"269"
  },
  {
     "displayName":"BCO ARBI S.A.",
     "name":"Banco Arbi S.A.",
     "spiCode":"54403563",
     "strCode":"213"
  },
  {
     "displayName":"Câmara B3",
     "name":"Câmara B3",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"INTESA SANPAOLO BRASIL S.A. BM",
     "name":"Intesa Sanpaolo Brasil S.A. - Banco Múltiplo",
     "spiCode":"",
     "strCode":"139"
  },
  {
     "displayName":"BCO TRICURY S.A.",
     "name":"Banco Tricury S.A.",
     "spiCode":"",
     "strCode":"18"
  },
  {
     "displayName":"SMARTBANK",
     "name":"Banco Smartbank S.A.",
     "spiCode":"58497702",
     "strCode":"630"
  },
  {
     "displayName":"BCO FIBRA S.A.",
     "name":"Banco Fibra S.A.",
     "spiCode":"58616418",
     "strCode":"224"
  },
  {
     "displayName":"BCO VOLKSWAGEN S.A",
     "name":"Banco Volkswagen S.A.",
     "spiCode":"",
     "strCode":"393"
  },
  {
     "displayName":"BCO LUSO BRASILEIRO S.A.",
     "name":"Banco Luso Brasileiro S.A.",
     "spiCode":"59118133",
     "strCode":"600"
  },
  {
     "displayName":"BCO GM S.A.",
     "name":"BANCO GM S.A.",
     "spiCode":"",
     "strCode":"390"
  },
  {
     "displayName":"BANCO PAN",
     "name":"Banco Pan S.A.",
     "spiCode":"59285411",
     "strCode":"623"
  },
  {
     "displayName":"BCO VOTORANTIM S.A.",
     "name":"Banco Votorantim S.A.",
     "spiCode":"59588111",
     "strCode":"655"
  },
//   {
//      "displayName":"BCO ITAUBANK S.A.",
//      "name":"Banco ItauBank S.A.",
//      "spiCode":"",
//      "strCode":"479"
//   },
  {
     "displayName":"BCO MUFG BRASIL S.A.",
     "name":"Banco MUFG Brasil S.A.",
     "spiCode":"",
     "strCode":"456"
  },
  {
     "displayName":"BCO SUMITOMO MITSUI BRASIL S.A.",
     "name":"Banco Sumitomo Mitsui Brasileiro S.A.",
     "spiCode":"",
     "strCode":"464"
  },
  {
     "displayName":"BCO MERCEDES-BENZ S.A.",
     "name":"BANCO MERCEDES-BENZ DO BRASIL S.A.",
     "spiCode":"",
     "strCode":"381"
  },
  {
     "displayName":"OMNI BANCO S.A.",
     "name":"Omni Banco S.A.",
     "spiCode":"60850229",
     "strCode":"613"
  },
//   {
//      "displayName":"HOLDING ITAÚ UNIBANCO",
//      "name":"Itaú Unibanco Holding S.A.",
//      "spiCode":"",
//      "strCode":"652"
//   },
  {
     "displayName":"BCO SOFISA S.A.",
     "name":"BANCO SOFISA S.A.",
     "spiCode":"60889128",
     "strCode":"637"
  },
  {
     "displayName":"Câmbio B3",
     "name":"Câmara de Câmbio B3",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"BCO INDUSVAL S.A.",
     "name":"BANCO INDUSVAL S.A.",
     "spiCode":"61024352",
     "strCode":"653"
  },
  {
     "displayName":"BCO CREFISA S.A.",
     "name":"Banco Crefisa S.A.",
     "spiCode":"61033106",
     "strCode":"69"
  },
  {
     "displayName":"BCO MIZUHO S.A.",
     "name":"Banco Mizuho do Brasil S.A.",
     "spiCode":"",
     "strCode":"370"
  },
  {
     "displayName":"BANCO INVESTCRED UNIBANCO S.A.",
     "name":"Banco Investcred Unibanco S.A.",
     "spiCode":"",
     "strCode":"249"
  },
  {
     "displayName":"BCO BMG S.A.",
     "name":"Banco BMG S.A.",
     "spiCode":"61186680",
     "strCode":"318"
  },
//   {
//      "displayName":"C6 CONSIGNADO",
//      "name":"BANCO C6 CONSIGNADO S.A.",
//      "spiCode":"",
//      "strCode":"626"
//   },
  {
     "displayName":"SAGITUR CC LTDA",
     "name":"Sagitur Corretora de Câmbio Ltda.",
     "spiCode":"",
     "strCode":"270"
  },
  {
     "displayName":"BCO SOCIETE GENERALE BRASIL",
     "name":"BANCO SOCIETE GENERALE BRASIL S.A.",
     "spiCode":"",
     "strCode":"366"
  },
  {
     "displayName":"MAGLIANO S.A. CCVM",
     "name":"Magliano S.A. Corretora de Cambio e Valores Mobiliarios",
     "spiCode":"",
     "strCode":"113"
  },
  {
     "displayName":"TULLETT PREBON BRASIL CVC LTDA",
     "name":"TULLETT PREBON BRASIL CORRETORA DE VALORES E CÂMBIO LTDA",
     "spiCode":"",
     "strCode":"131"
  },
  {
     "displayName":"C.SUISSE HEDGING-GRIFFO CV S/A",
     "name":"CREDIT SUISSE HEDGING-GRIFFO CORRETORA DE VALORES S.A",
     "spiCode":"",
     "strCode":"11"
  },
  {
     "displayName":"BCO PAULISTA S.A.",
     "name":"Banco Paulista S.A.",
     "spiCode":"",
     "strCode":"611"
  },
  {
     "displayName":"BOFA MERRILL LYNCH BM S.A.",
     "name":"Bank of America Merrill Lynch Banco Múltiplo S.A.",
     "spiCode":"62073200",
     "strCode":"755"
  },
  {
     "displayName":"CREDISAN CC",
     "name":"CREDISAN COOPERATIVA DE CRÉDITO",
     "spiCode":"62109566",
     "strCode":"89"
  },
  {
     "displayName":"BCO PINE S.A.",
     "name":"Banco Pine S.A.",
     "spiCode":"",
     "strCode":"643"
  },
  {
     "displayName":"EASYNVEST - TÍTULO CV SA",
     "name":"Easynvest - Título Corretora de Valores SA",
     "spiCode":"",
     "strCode":"140"
  },
  {
     "displayName":"BCO DAYCOVAL S.A",
     "name":"Banco Daycoval S.A.",
     "spiCode":"62232889",
     "strCode":"707"
  },
  {
     "displayName":"CAROL DTVM LTDA.",
     "name":"CAROL DISTRIBUIDORA DE TITULOS E VALORES MOBILIARIOS LTDA.",
     "spiCode":"",
     "strCode":"288"
  },
  {
     "displayName":"SINGULARE CTVM S.A.",
     "name":"SINGULARE CORRETORA DE TÍTULOS E VALORES MOBILIÁRIOS S.A.",
     "spiCode":"",
     "strCode":"363"
  },
  {
     "displayName":"RENASCENCA DTVM LTDA",
     "name":"RENASCENCA DISTRIBUIDORA DE TÍTULOS E VALORES MOBILIÁRIOS LTDA",
     "spiCode":"",
     "strCode":"101"
  },
  {
     "displayName":"DEUTSCHE BANK S.A.BCO ALEMAO",
     "name":"DEUTSCHE BANK S.A. - BANCO ALEMAO",
     "spiCode":"",
     "strCode":"487"
  },
  {
     "displayName":"BANCO CIFRA",
     "name":"Banco Cifra S.A.",
     "spiCode":"",
     "strCode":"233"
  },
  {
     "displayName":"GUIDE",
     "name":"Guide Investimentos S.A. Corretora de Valores",
     "spiCode":"",
     "strCode":"177"
  },
  {
     "displayName":"SOLIDUS S.A. CCVM",
     "name":"SOLIDUS S.A. CORRETORA DE CAMBIO E VALORES MOBILIARIOS",
     "spiCode":"",
     "strCode":"365"
  },
  {
     "displayName":"BCO RENDIMENTO S.A.",
     "name":"Banco Rendimento S.A.",
     "spiCode":"68900810",
     "strCode":"633"
  },
  {
     "displayName":"BCO BS2 S.A.",
     "name":"Banco BS2 S.A.",
     "spiCode":"71027866",
     "strCode":"218"
  },
  {
     "displayName":"BCO OLÉ CONSIGNADO S.A.",
     "name":"BANCO OLÉ CONSIGNADO S.A.",
     "spiCode":"",
     "strCode":"169"
  },
  {
     "displayName":"LASTRO RDV DTVM LTDA",
     "name":"Lastro RDV Distribuidora de Títulos e Valores Mobiliários Ltda.",
     "spiCode":"",
     "strCode":"293"
  },
  {
     "displayName":"FRENTE CC LTDA.",
     "name":"Frente Corretora de Câmbio Ltda.",
     "spiCode":"",
     "strCode":"285"
  },
  {
     "displayName":"B&T CC LTDA.",
     "name":"B&T CORRETORA DE CAMBIO LTDA.",
     "spiCode":"",
     "strCode":"80"
  },
  {
     "displayName":"NOVO BCO CONTINENTAL S.A. - BM",
     "name":"Novo Banco Continental S.A. - Banco Múltiplo",
     "spiCode":"",
     "strCode":"753"
  },
  {
     "displayName":"BCO CRÉDIT AGRICOLE BR S.A.",
     "name":"BANCO CRÉDIT AGRICOLE BRASIL S.A.",
     "spiCode":"",
     "strCode":"222"
  },
  {
     "displayName":"CCR COOPAVEL",
     "name":"Cooperativa de Crédito Rural Coopavel",
     "spiCode":"76461557",
     "strCode":"281"
  },
  {
     "displayName":"BANCO SISTEMA",
     "name":"Banco Sistema S.A.",
     "spiCode":"",
     "strCode":"754"
  },
  {
     "displayName":"CREDIALIANÇA CCR",
     "name":"Credialiança Cooperativa de Crédito Rural",
     "spiCode":"78157146",
     "strCode":"98"
  },
  {
     "displayName":"BCO VR S.A.",
     "name":"Banco VR S.A.",
     "spiCode":"78626983",
     "strCode":"610"
  },
  {
     "displayName":"BCO OURINVEST S.A.",
     "name":"Banco Ourinvest S.A.",
     "spiCode":"78632767",
     "strCode":"712"
  },
  {
     "displayName":"CREDICOAMO",
     "name":"CREDICOAMO CREDITO RURAL COOPERATIVA",
     "spiCode":"81723108",
     "strCode":"10"
  },
  {
     "displayName":"RB CAPITAL INVESTIMENTOS DTVM LTDA.",
     "name":"RB CAPITAL INVESTIMENTOS DISTRIBUIDORA DE TÍTULOS E VALORES MOBILIÁRIOS LIMITADA",
     "spiCode":"",
     "strCode":"283"
  },
  {
     "displayName":"BANCO JOHN DEERE S.A.",
     "name":"Banco John Deere S.A.",
     "spiCode":"",
     "strCode":"217"
  },
  {
     "displayName":"BCO DO ESTADO DO RS S.A.",
     "name":"Banco do Estado do Rio Grande do Sul S.A.",
     "spiCode":"92702067",
     "strCode":"41"
  },
  {
     "displayName":"ADVANCED CC LTDA",
     "name":"ADVANCED CORRETORA DE CÂMBIO LTDA",
     "spiCode":"",
     "strCode":"117"
  },
  {
     "displayName":"BCO DIGIMAIS S.A.",
     "name":"BANCO DIGIMAIS S.A.",
     "spiCode":"92874270",
     "strCode":"654"
  },
  {
     "displayName":"WARREN CVMC LTDA",
     "name":"WARREN CORRETORA DE VALORES MOBILIÁRIOS E CÂMBIO LTDA.",
     "spiCode":"",
     "strCode":"371"
  },
  {
     "displayName":"DECYSEO CC LTDA.",
     "name":"DECYSEO CORRETORA DE CAMBIO LTDA.",
     "spiCode":"",
     "strCode":"289"
  },
  {
     "displayName":"CCLA TRIÂNGULO MINEIRO E SUDESTE DE GOIÁS",
     "name":"COOPERATIVA DE CRÉDITO DE LIVRE ADMISSÃO DO TRIÂNGULO MINEIRO E SUDESTE DE GOIÁS LTDA. - SICOOB ARACREDI",
     "spiCode":"00068987",
     "strCode":""
  },
  {
     "displayName":"CC UNICRED CENTRO-SUL LTDA – UNICRED CENTRO-SUL",
     "name":"COOPERATIVA DE CRÉDITO UNICRED CENTRO-SUL LTDA – UNICRED CENTRO-SUL",
     "spiCode":"00075847",
     "strCode":""
  },
  {
     "displayName":"CCC DOS EST DE MT, MS E MUN DE CACOAL/RO",
     "name":"CENTRAL DAS COOPERATIVAS DE CRÉDITO DOS ESTADOS DE MATO GROSSO, MATO GROSSO DO SUL E DO MUNICÍPIO DE CACOAL/RO - SICOOB CENTRAL RONDON",
     "spiCode":"00106180",
     "strCode":""
  },
  {
     "displayName":"CCLA SICOOB UNIMAIS METROPOLITANA",
     "name":"SICOOB UNIMAIS METROPOLITANA – COOPERATIVA DE CRÉDITO DE LIVRE ADMISSÃO",
     "spiCode":"00259231",
     "strCode":""
  },
  {
     "displayName":"CCC ESTADO MG - CECREMGE",
     "name":"CENTRAL DAS COOPERATIVAS DE ECONOMIA E CREDITO DO ESTADO DE MINAS GERAIS LTDA. - SICOOB CENTRAL CECREMGE",
     "spiCode":"00309024",
     "strCode":""
  },
  {
     "displayName":"CC CREDIAGRO",
     "name":"COOPERATIVA DE CREDITO CREDIAGRO LTDA. - SICOOB CREDIAGRO",
     "spiCode":"00429890",
     "strCode":""
  },
  {
     "displayName":"CECM SERV MUNIC SANTO ANDRE",
     "name":"COOPERATIVA DE ECONOMIA E CRÉDITO MÚTUO DOS SERVIDORES DO MUNICIPIO DE SANTO ANDRÉ - SP – SICOOB CECRESA",
     "spiCode":"00512839",
     "strCode":""
  },
  {
     "displayName":"TRIVALE",
     "name":"TRIVALE ADMINISTRACAO LTDA",
     "spiCode":"00604122",
     "strCode":""
  },
  {
     "displayName":"APE POUPEX",
     "name":"ASSOCIACAO DE POUPANCA E EMPRESTIMO - POUPEX",
     "spiCode":"00655522",
     "strCode":""
  },
  {
     "displayName":"CCEC PLANALTO CENTRAL LTDA",
     "name":"CENTRAL DAS COOPERATIVAS DE ECONOMIA E CRÉDITO DO PLANALTO CENTRAL LTDA - SICOOB PLANALTO CENTRAL",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"CCLA SICOOB CREDICARU SC/RS",
     "name":"COOPERATIVA DE CRÉDITO DE LIVRE ADMISSÃO DE ASSOCIADOS - SICOOB CREDICARU SC/RS",
     "spiCode":"00694389",
     "strCode":""
  },
  {
     "displayName":"CECM SERV EXEC FED SECSAÚDE DF",
     "name":"COOPERATIVA DE ECONOMIA E CRÉDITO MÚTUO DOS SERVIDORES DO PODER EXECUTIVO FEDERAL, DOS SERVIDORES DA SECRETARIA DE SAÚDE E DOS TRABALHADORES EM ENSINO DO DISTRITO FEDERAL LTDA. SICOOB EXECUTIVO",
     "spiCode":"00694877",
     "strCode":""
  },
  {
     "displayName":"CC NORTE E NOROESTE DE MINAS GERAIS",
     "name":"COOPERATIVA DE CRÉDITO DO NORTE E NOROESTE DE MINAS GERAIS LTDA. - SICOOB CREDIGERAIS",
     "spiCode":"00698609",
     "strCode":""
  },
  {
     "displayName":"CC VALE DO CANOAS",
     "name":"COOPERATIVA DE CRÉDITO VALE DO CANOAS - SICOOB/SC CREDICANOAS",
     "spiCode":"00707903",
     "strCode":""
  },
  {
     "displayName":"EWALLY",
     "name":"EWALLY TECNOLOGIA E SERVICOS S.A. ",
     "spiCode":"00714671",
     "strCode":"534"
  },
  {
     "displayName":"CC VAZANTE LTDA",
     "name":"COOPERATIVA DE CRÉDITO DE VAZANTE LTDA. – SICOOB CREDIVAZ",
     "spiCode":"00803287",
     "strCode":""
  },
  {
     "displayName":"CCLA CAPITÓLIO",
     "name":"COOPERATIVA DE CRÉDITO DE LIVRE ADMISSÃO DE CAPITÓLIO LTDA SICOOB CREDICAPI",
     "spiCode":"00804046",
     "strCode":""
  },
  {
     "displayName":"SICOOB SUL-SERRANO",
     "name":"COOPERATIVA DE CRÉDITO SUL-SERRANA DO ESPÍRITO SANTO",
     "spiCode":"00815319",
     "strCode":""
  },
  {
     "displayName":"CCLA BOA ESPERANÇA",
     "name":"COOPERATIVA DE CRÉDITO DE LIVRE ADMISSÃO DE BOA ESPERANÇA LTDA. - SICOOB BELCREDI",
     "spiCode":"00869687",
     "strCode":""
  },
  {
     "displayName":"CECM SICOOB COOPERCREDI - SP",
     "name":"COOPERATIVA DE ECONOMIA E CREDITO MUTUO SICOOB COOPERCREDI - SP",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"SICOOB CREDFAZ",
     "name":"COOPERATIVA DE CRÉDITO DE LIVRE ADMISSÃO CREDFAZ LTDA. - SICOOB CREDFAZ",
     "spiCode":"00952415",
     "strCode":""
  },
  {
     "displayName":"CC CREDICERIPA",
     "name":"COOPERATIVA DE CREDITO CREDICERIPA - SICOOB CREDICERIPA",
     "spiCode":"00966246",
     "strCode":""
  },
  {
     "displayName":"CCLA VALE SÃO PATRICIO LTDA",
     "name":"COOPERATIVA DE CREDITO DE LIVRE ADMISSÃO DO VALE DO SÃO PATRICIO LTDA.",
     "spiCode":"00968602",
     "strCode":""
  },
  {
     "displayName":"CCR INT SOL PIONEIRA",
     "name":"COOPERATIVA DE CREDITO RURAL COM INTERACAO SOLIDARIA PIONEIRA - CRESOL PIONEIRA",
     "spiCode":"00971300",
     "strCode":""
  },
  {
     "displayName":"CCLA PARA DE MINAS",
     "name":"COOPERATIVA DE CREDITO DE LIVRE ADMISSAO DE PARA DE MINAS LTDA - SICOOB ASCICRED",
     "spiCode":"01009908",
     "strCode":""
  },
  {
     "displayName":"CC UNICRED DESBRAVADORA LTDA - UNICRED DESBRAVADORA",
     "name":"COOPERATIVA DE CRÉDITO UNICRED DESBRAVADORA LTDA - UNICRED DESBRAVADORA",
     "spiCode":"01039011",
     "strCode":""
  },
  {
     "displayName":"CC SICOOB UNIDAS",
     "name":"COOPERATIVA DE CRÉDITO SICOOB UNIDAS",
     "spiCode":"01042487",
     "strCode":""
  },
  {
     "displayName":"CCLA VALES DO PIRANGA E MATIPÓ",
     "name":"COOPERATIVA DE CRÉDITO DE LIVRE ADMISSÃO DA UNIÃO DOS VALES DO PIRANGA E MATIPÓ LTDA. SICOOB UNIÃO",
     "spiCode":"01060307",
     "strCode":""
  },
  {
     "displayName":"CECM DOS FUNCIONARIOS DA COMIGO",
     "name":"COOPERATIVA DE ECONOMIA E CREDITO MUTUO DOS FUNCIONARIOS DA COMIGO",
     "spiCode":"01062439",
     "strCode":""
  },
  {
     "displayName":"CECMME CATEG AFINS-UNIC.NITERO",
     "name":"COOPERATIVA DE ECONOMIA E CREDITO MUTUO DOS MEDICOS E CATEGORIAS AFINS-UNICRED NITEROI LTDA",
     "spiCode":"01090027",
     "strCode":""
  },
  {
     "displayName":"CCPIMÉD E LA MINAS - ES LTDA. - UNICRED MINAS - ES",
     "name":"COOPERATIVA DE CRÉDITO, POUPANÇA E INVESTIMENTO DOS MÉDICOS E DEMAIS PROFISSIONAIS DA SAÚDE E LIVRE ADMISSÃO MINAS - ESPÍRITO SANTO LTDA. - UNICRED MINAS - ES",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"CC CREDIMATA",
     "name":"COOPERATIVA DE CRÉDITO CREDIMATA LTDA. – SICOOB CREDIMATA",
     "spiCode":"01152097",
     "strCode":""
  },
  {
     "displayName":"CCR INT SOL CURITIBANOS",
     "name":"COOPERATIVA DE CRÉDITO RURAL COM INTERAÇÃO SOLIDÁRIA DE CURITIBANOS - CRESOL CURITIBANOS",
     "spiCode":"01155801",
     "strCode":""
  },
  {
     "displayName":"CC DISTRITO FEDERAL E ENTORNO",
     "name":"COOPERATIVA DE CRÉDITO DO DISTRITO FEDERAL E ENTORNO LTDA",
     "spiCode":"01187961",
     "strCode":""
  },
  {
     "displayName":"CC SICOOB COSTA DO DESCOBRIMENTO",
     "name":"COOPERATIVA DE CRÉDITO DO MÉDIO SUL BAIANO LTDA. – SICOOB COSTA DO DESCOBRIMENTO",
     "spiCode":"01205736",
     "strCode":""
  },
  {
     "displayName":"SICOOB UNIMAIS CENTRO LESTE PAULISTA",
     "name":"COOPERATIVA DE CRÉDITO DE LIVRE ADMISSÃO SICOOB UNIMAIS CENTRO LESTE PAULISTA - SICOOB UNIMAIS CENTRO LESTE PAULISTA",
     "spiCode":"01259518",
     "strCode":""
  },
  {
     "displayName":"UNIPRIME PIONEIRA CC",
     "name":"UNIPRIME PIONEIRA COOPERATIVA DE CRÉDITO",
     "spiCode":"01286361",
     "strCode":""
  },
  {
     "displayName":"CECME GRUPO ALGAR E EMPR TRIANGULO MINEIRO",
     "name":"COOPERATIVA DE ECONOMIA E CRÉDITO MÚTUO DOS EMPREGADOS DO GRUPO ALGAR E EMPREENDEDORES DO TRIÂNGULO MINEIRO LTDA - SICOOB TRIÂNGULO",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"SICOOB SERVIDORES",
     "name":"COOPERATIVA DE ECONOMIA E CRÉDITO MÚTUO DOS SERVIDORES PÚBLICOS NO ESTADO DO ESPÍRITO SANTO",
     "spiCode":"01330295",
     "strCode":""
  },
  {
     "displayName":"CC SÃO FRANCISCO DE SALES LTDA.",
     "name":"COOPERATIVA DE CRÉDITO DE SÃO FRANCISCO DE SALES LTDA. – SICOOB CREDISALES",
     "spiCode":"01374196",
     "strCode":""
  },
  {
     "displayName":"CCLA DO PLANALTO CATARINENSE",
     "name":"COOPERATIVA DE CRÉDITO DE LIVRE ADMISSÃO DE ASSOCIADOS DO PLANALTO CATARINENSE SICOOB CREDIPLANALTO SC/RS",
     "spiCode":"01389651",
     "strCode":""
  },
  {
     "displayName":"CECM UNICRED IJUÍ",
     "name":"COOPERATIVA DE ECONOMIA E CRÉDITO MÚTUO UNICRED IJUÍ LTDA - UNICRED IJUÍ",
     "spiCode":"01526924",
     "strCode":""
  },
  {
     "displayName":"CCLA LESTE E NORDESTE MINEIRO",
     "name":"SICOOB AC CREDI - COOPERATIVA DE CRÉDITO DE LIVRE ADMISSÃO DO LESTE E NORDESTE MINEIRO LTDA.",
     "spiCode":"01559914",
     "strCode":""
  },
  {
     "displayName":"CCLA MANAUS",
     "name":"COOPERATIVA DE CRÉDITO DE LIVRE ADMISSÃO DE ASSOCIADOS DE MANAUS",
     "spiCode":"01566038",
     "strCode":""
  },
  {
     "displayName":"UNICRED ERECHIM",
     "name":"COOPERATIVA DE ECONOMIA E CRÉDITO MÚTUO DOS MÉDICOS E DEMAIS PROFISSIONAIS DA SAÚDE, ENGENHARIA, ARQUITETURA, CONTABILIDADE, ADMINISTRAÇÃO, ECONOMIA E EMPRESÁRIOS DE ERECHIM LTDA",
     "spiCode":"01572667",
     "strCode":""
  },
  {
     "displayName":"CCLA SUL MINAS-SICOOB CREDIVAS",
     "name":"COOPERATIVA DE CREDITO DE LIVRE ADMISSÃO DO SUL DE MINAS LTDA - SICOOB CREDIVASS",
     "spiCode":"01604998",
     "strCode":""
  },
  {
     "displayName":"CCLA DE RIO BRANCO",
     "name":"COOPERATIVA DE CRÉDITO DE LIVRE ADMISSÃO DE RIO BRANCO LTDA",
     "spiCode":"01608685",
     "strCode":""
  },
  {
     "displayName":"CCLA CAMPINA VERDE",
     "name":"COOPERATIVA DE CRÉDITO CREDICAMPINA LTDA. - SICOOB CREDICAMPINA",
     "spiCode":"01609345",
     "strCode":""
  },
  {
     "displayName":"CECM MED VALES TAQUARI RP E PR",
     "name":"COOPERATIVA DE ECONOMIA E CRÉDITO MÚTUO DOS MÉDICOS DOS VALES DO TAQUARI E RIO PARDO E REGIÃO DA PRODUÇÃO LTDA",
     "spiCode":"01635462",
     "strCode":""
  },
  {
     "displayName":"CCLA VALE DO PARANAPANEMA",
     "name":"COOPERATIVA DE CRÉDITO DE LIVRE ADMISSÃO DO VALE DO PARANAPANEMA",
     "spiCode":"01637949",
     "strCode":""
  },
  {
     "displayName":"CCLA MEDIO PIRACICABA E CIRCUITO DO OURO LTDA",
     "name":"COOPERATIVA DE CRÉDITO DE LIVRE ADMISSÃO DO MÉDIO PIRACICABA E DO CIRCUITO DO OURO LTDA. - SICOOB CREDIMEPI",
     "spiCode":"01644264",
     "strCode":""
  },
  {
     "displayName":"CCLA SEBASTIAO PARAISO",
     "name":"COOPERATIVA DE CRÉDITO DE LIVRE ADMISSÃO DA REGIÃO DE SÃO SEBASTIÃO DO PARAÍSO LTDA. - SICOOB PARAÍSOCRED",
     "spiCode":"01657678",
     "strCode":""
  },
  {
     "displayName":"CCLA DE ASSOCIADOS UNIRONDÔNIA LTDA",
     "name":"COOPERATIVA DE CRÉDITO DE LIVRE ADMISSÃO DE ASSOCIADOS UNIRONDÔNIA LTDA",
     "spiCode":"01664968",
     "strCode":""
  },
  {
     "displayName":"CCLA SERTÃO MINAS",
     "name":"COOPERATIVA DE CRÉDITO DE LIVRE ADMISSÃO DO SERTÃO DE MINAS GERAIS LTDA. - SICOOB SERTÃO MINAS",
     "spiCode":"01667352",
     "strCode":""
  },
  {
     "displayName":"CCLA NOVA SERRANA E REGIÃO CO",
     "name":"SICOOB CREDINOVA - COOPERATIVA DE CRÉDITO DE LIVRE ADMISSÃO DE NOVA SERRANA E REGIÃO CENTRO-OESTE LTDA.",
     "spiCode":"01667766",
     "strCode":""
  },
  {
     "displayName":"CCLA DO PLANALTO SERRANO",
     "name":"COOPERATIVA DE CRÉDITO DE LIVRE ADMISSÃO DE ASSOCIADOS DO PLANALTO SERRANO - SICOOB CREDISERRA SC",
     "spiCode":"01692448",
     "strCode":""
  },
  {
     "displayName":"CCLA REGIÃO GUAXUPÉ",
     "name":"COOPERATIVA DE CRÉDITO DE LIVRE ADMISSÃO DA REGIÃO DE GUAXUPÉ LTDA. - SICOOB ACICREDI",
     "spiCode":"01699652",
     "strCode":""
  },
  {
     "displayName":"CCLA UNIÃO CENTRO OESTE MINAS",
     "name":"COOPERATIVA DE CRÉDITO DE LIVRE ADMISSÃO UNIÃO DO CENTRO OESTE DE MINAS LTDA - SICOOB CREDESP",
     "spiCode":"01703620",
     "strCode":""
  },
  {
     "displayName":"CECM UNICRED REGIÃO DA CAMPANHA LTDA",
     "name":"COOPERATIVA DE ECONOMIA E CRÉDITO MÚTUO UNICRED REGIÃO DA CAMPANHA LTDA - UNICRED REGIÃO DA CAMPANHA",
     "spiCode":"01705236",
     "strCode":""
  },
  {
     "displayName":"CECM MÉD TEÓFILO OTONI",
     "name":"COOPERATIVA DE ECONOMIA E CRÉDITO MÚTUO DOS MÉDICOS E DEMAIS PROFISSIONAIS DA SAÚDE DE TEÓFILO OTONI LTDA.",
     "spiCode":"01709266",
     "strCode":""
  },
  {
     "displayName":"CCLA DE GOIÂNIA E MICRORREGIÕE",
     "name":"COOPERATIVA DE CRÉDITO DE LIVRE ADMISSÃO DE GOIÂNIA E MICRORREGIÕES LTDA.",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"VERDE- ADMINISTRADORA DE CARTO",
     "name":"VERDE - ADMINISTRADORA DE CARTOES DE CREDITO S.A.",
     "spiCode":"01722480",
     "strCode":""
  },
  {
     "displayName":"CCLA ALTO PARANAIBA, NOROESTE OESTE MINAS",
     "name":"COOPERATIVA DE CRÉDITO DE LIVRE ADMISSÃO DO ALTO PARANAÍBA, NOROESTE E OESTE DE MINAS LTDA - UNICRED INTEGRAÇÃO DE MINAS",
     "spiCode":"01727929",
     "strCode":""
  },
  {
     "displayName":"UNIPRIME ALLIANCE - CECM",
     "name":"UNIPRIME ALLIANCE - COOPERATIVA DE ECONOMIA E CRÉDITO MÚTUO",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"CCLA REG. CTR. E OESTE MINEIRO",
     "name":"COOPERATIVA DE CRÉDITO DE LIVRE ADMISSÃO DA REGIÃO CENTRAL E OESTE MINEIRO LTDA. - SICOOB DIVICRED",
     "spiCode":"01736516",
     "strCode":""
  },
  {
     "displayName":"CCLA LAGOA DA PRATA",
     "name":"COOPERATIVA DE CRÉDITO DE LIVRE ADMISSÃO DE LAGOA DA PRATA E REGIÃO LTDA. SICOOB LAGOACRED GERAIS",
     "spiCode":"01739375",
     "strCode":""
  },
  {
     "displayName":"CCLA DE BH E CIDADES POLO DE MG",
     "name":"COOPERATIVA DE CRÉDITO DE LIVRE ADMISSÃO DE BELO HORIZONTE E CIDADES POLO DO ESTADO DE MINAS GERAIS LTDA. – SICOOB NOSSACOOP",
     "spiCode":"01760242",
     "strCode":""
  },
  {
     "displayName":"UNICRED REGIÃO DOS VALES",
     "name":"COOPERATIVA DE CRÉDITO UNICRED REGIÃO DOS VALES LTDA. - UNICRED REGIÃO DOS VALES",
     "spiCode":"01796302",
     "strCode":""
  },
  {
     "displayName":"UNIPRIME DO IGUAÇU CECM PROF SAÚDE CIÊNCIAS ARTES EMP",
     "name":"UNIPRIME DO IGUAÇU - COOPERATIVA DE ECONOMIA E CRÉDITO MÚTUO DOS PROFISSIONAIS DA SAÚDE, DAS CIÊNCIAS, DAS ARTES E EMPRESÁRIOS",
     "spiCode":"01848322",
     "strCode":""
  },
  {
     "displayName":"CECM SERV E EMPREG MUN BH BETIM BRUMAD CONTAG IBI NL RN",
     "name":"COOPERATIVA ECONOMIA CRÉDITO MÚTUO SERVIDORES E EMPREGADOS MUNIC PREFS DE BELO HORIZONTE BETIM BRUMADINHO CONTAGEM IBIRITÉ NOVA LIMA RIBEIRÃO DAS NEVES SABARÁ SANTA LUZIA VESPASIANO SICOOB CREDISERV",
     "spiCode":"01864151",
     "strCode":""
  },
  {
     "displayName":"CECMSERV POD JUD TCE SERV A C TEC RJ",
     "name":"COOPERATIVA ECONOMIA CRÉDITO MÚTUO SERVIDORES PODER JUDICIÁRIO, TRIBUNAL CONTAS ESTADUAL E SERVIDORES ÓRGÃOS ENTIDADES ÁREA CIÊNCIA TECNOLOGIA NO ESTADO DO RIO DE JANEIRO LTDA.",
     "spiCode":"02000895",
     "strCode":""
  },
  {
     "displayName":"CCLA DO CENTRO SUL RONDONIENSE",
     "name":"COOPERATIVA DE CRÉDITO DE LIVRE ADMISSÃO DO CENTRO SUL RONDONIENSE - SICOOB CREDIP.",
     "spiCode":"02015588",
     "strCode":""
  },
  {
     "displayName":"CCR ASCOOB COOPERAR",
     "name":"COOPERATIVA DE CRÉDITO RURAL ASCOOB COOPERAR",
     "spiCode":"02025743",
     "strCode":""
  },
  {
     "displayName":"CCLA MONTES CLAROS",
     "name":"SICOOB CREDIMONTES - COOPERATIVA DE CRÉDITO DE LIVRE ADMISSÃO REGIONAL DE MONTES CLAROS LTDA",
     "spiCode":"02031139",
     "strCode":""
  },
  {
     "displayName":"CC SICOOB SERTÃO",
     "name":"COOPERATIVA DE CRÉDITO DO SERTÃO BAIANO LTDA - SICOOB SERTÃO",
     "spiCode":"02057584",
     "strCode":""
  },
  {
     "displayName":"CECM SICOOB CREDSAÚDE",
     "name":"COOPERATIVA DE ECONOMIA CRÉDITO MÚTUO – SICOOB CREDSAÚDE",
     "spiCode":"02072790",
     "strCode":""
  },
  {
     "displayName":"CCLA SICOOB VALCREDI SUL",
     "name":"COOPERATIVA DE CRÉDITO DE LIVRE ADMISSÃO DE ASSOCIADOS VALE DO CHAPECOZINHO - SICOOB VALCREDI SUL",
     "spiCode":"02090126",
     "strCode":""
  },
  {
     "displayName":"CCM EMPR MAGAZINE LUIZA",
     "name":"COOPERATIVA DE CRÉDITO MÚTUO DOS EMPREGADOS DO MAGAZINE LUIZA, EMPRESAS CONTROLADAS E COLIGADAS – SICOOB COOPLUIZA",
     "spiCode":"02093154",
     "strCode":""
  },
  {
     "displayName":"CCLA DE RORAIMA LTDA.",
     "name":"COOPERATIVA DE CRÉDITO DE LIVRE ADMISSÃO DE RORAIMA LTDA.",
     "spiCode":"02137018",
     "strCode":""
  },
  {
     "displayName":"CCLA DA REGIÃO CENTRAL DE RONDÔNIA",
     "name":"COOPERATIVA DE CRÉDITO DE LIVRE ADMISSÃO DA REGIÃO CENTRAL DE RONDÔNIA SICOOB OUROCREDI",
     "spiCode":"02144899",
     "strCode":""
  },
  {
     "displayName":"CCLA CENTRO NORDESTE MINEIRO",
     "name":"COOPERATIVA DE CREDITO DE LIVRE ADMISSAO DO CENTRO NORDESTE MINEIRO LTDA - SICOOB CREDICENM",
     "spiCode":"02173447",
     "strCode":""
  },
  {
     "displayName":"CECM MÉD OURINHOS",
     "name":"COOPERATIVA DE ECONOMIA E CRÉDITO MÚTUO DOS MÉDICOS E DEMAIS PROFISSIONAIS DE NÍVEL SUPERIOR DA ÁREA DE SAÚDE DE OURINHOS - OURICRED",
     "spiCode":"02179673",
     "strCode":""
  },
  {
     "displayName":"CCLA CREDSAOPAULO",
     "name":"COOPERATIVA DE CRÉDITO CREDSAOPAULO - SICOOB CREDSAOPAULO",
     "spiCode":"02197569",
     "strCode":""
  },
  {
     "displayName":"CECM CONTAB CORRET BH",
     "name":"COOPERATIVA DE ECONOMIA E CRÉDITO MÚTUO DOS CONTABILISTAS E CORRETORES DE SEGUROS DA GRANDE BELO HORIZONTE LTDA. - SICOOB CREDITABIL",
     "spiCode":"02217068",
     "strCode":""
  },
  {
     "displayName":"CCLA CIRCUITO CAMPOS VERTENTES",
     "name":"COOPERATIVA DE CRÉDITO DE LIVRE ADMISSÃO DA REGIÃO DO CIRCUITO CAMPOS DAS VERTENTES LTDA - SICOOB COPERMEC",
     "spiCode":"02232383",
     "strCode":""
  },
  {
     "displayName":"CCLA DE DORES DO INDAIA",
     "name":"COOPERATIVA DE CRÉDITO DE LIVRE ADMISSÃO DE ASSOCIADOS DE DORES DO INDAIÁ LTDA - COOPCREDI",
     "spiCode":"02254376",
     "strCode":""
  },
  {
     "displayName":"CCM SERV FEDERAIS NA PARAÍBA - SICOOB COOPERCRET",
     "name":"COOPERATIVA DE CRÉDITO MÚTUO DOS SERVIDORES FEDERAIS NA PARAÍBA - SICOOB COOPERCRET",
     "spiCode":"02275781",
     "strCode":""
  },
  {
     "displayName":"CC SICOOB CREDICONQUISTA",
     "name":"COOPERATIVA DE CRÉDITO DO SUDOESTE BAIANO LTDA – SICOOB CREDICONQUISTA",
     "spiCode":"02282165",
     "strCode":""
  },
  {
     "displayName":"CCLA CENTRO NORTE GOIANO",
     "name":"COOPERATIVA DE CRÉDITO DE LIVRE ADMISSÃO DO CENTRO NORTE GOIANO",
     "spiCode":"02282709",
     "strCode":""
  },
  {
     "displayName":"CCLA DO VALE DO MACHADO - CREDISIS JICRED",
     "name":"COOPERATIVA DE CRÉDITO DE LIVRE ADMISSÃO DO VALE DO MACHADO - CREDISIS JICRED",
     "spiCode":"02309070",
     "strCode":""
  },
  {
     "displayName":"CCLA ZONA DA MATA LTDA",
     "name":"COOPERATIVA DE CRÉDITO DE LIVRE ADMISSÃO DA ZONA DA MATA LTDA SICOOB COOPEMATA",
     "spiCode":"02335109",
     "strCode":""
  },
  {
     "displayName":"CCLA CREDIEMBRAPA",
     "name":"COOPERATIVA DE CRÉDITO DE LIVRE ADMISSÃO CREDIEMBRAPA LTDA - SICOOB.",
     "spiCode":"02338666",
     "strCode":""
  },
  {
     "displayName":"CECMS DA SSP DO RN E TRAB SIND ÁREA SAÚDE RM NATAL",
     "name":"COOPERATIVA DE ECONOMIA E CREDITO MUTUO DOS SERV DA SEC DE E DA SEG PUB E DA DEFESA SOCIAL DO E DO RN E DOS TRAB SINDICALIZADOS DA AREA DE SAUDE E AFINS NA REG MET DE NATAL - SICOOB POTIGUAR",
     "spiCode":"02382755",
     "strCode":""
  },
  {
     "displayName":"ÚNILOS",
     "name":"COOPERATIVA DE CRÉDITO DA GRANDE FLORIANÓPOLIS - ÚNILOS",
     "spiCode":"02405189",
     "strCode":""
  },
  {
     "displayName":"CECM EMPR INST ENS SUP SUL MG",
     "name":"COOPERATIVA DE ECONOMIA E CRÉDITO MÚTUO DOS EMPREGADOS DAS INSTITUIÇÕES DE ENSINO SUPERIOR DO SUL DE MINAS LTDA. - CREDFENAS",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"CC INT SOL UNIÃO DOS PINHAIS",
     "name":"COOPERATIVA DE CRÉDITO COM INTERAÇÃO SOLIDÁRIA UNIÃO DOS PINHAIS - CRESOL UNIÃO DOS PINHAIS",
     "spiCode":"02446089",
     "strCode":""
  },
  {
     "displayName":"CC SICOOB EXTREMO SUL",
     "name":"COOPERATIVA DE CRÉDITO TEIXEIRA DE FREITAS LTDA. – SICOOB EXTREMO SUL",
     "spiCode":"02447120",
     "strCode":""
  },
  {
     "displayName":"CC INT SOL PROGRESSO",
     "name":"COOPERATIVA DE CRÉDITO COM INTERAÇÃO SOLIDÁRIA PROGRESSO - CRESOL PROGRESSO",
     "spiCode":"02448310",
     "strCode":""
  },
  {
     "displayName":"SICOOB CREDIUNIÃO",
     "name":"COOPERATIVA DE CRÉDITO DE RIO RUFINO - SICOOB CREDIUNIÃO",
     "spiCode":"02448839",
     "strCode":""
  },
  {
     "displayName":"CC SICOOB VALE SUL",
     "name":"COOPERATIVA DE CRÉDITO SICOOB VALE SUL",
     "spiCode":"02466552",
     "strCode":""
  },
  {
     "displayName":"CCLA E DOS ADV",
     "name":"COOPERATIVA DE CRÉDITO DE LIVRE ADMISSÃO E DOS ADVOGADOS LTDA. - SICOOB CREDIJUR",
     "spiCode":"02480577",
     "strCode":""
  },
  {
     "displayName":"SICOOB LESTE",
     "name":"COOPERATIVA DE ECONOMIA E CRÉDITO MÚTUO NOS ESTADOS DE ALAGOAS, SERGIPE E BAHIA - SICOOB LESTE",
     "spiCode":"02493000",
     "strCode":""
  },
  {
     "displayName":"CC SICOOB CREDCOOP",
     "name":"COOPERATIVA DE CRÉDITO SICOOB CREDCOOP LTDA.",
     "spiCode":"02528151",
     "strCode":""
  },
  {
     "displayName":"CCLA URUBICI",
     "name":"COOPERATIVA DE CRÉDITO DE LIVRE ADMISSÃO DE ASSOCIADOS URUBICI - SICOOB CREDIARAUCÁRIA/SC",
     "spiCode":"02587492",
     "strCode":""
  },
  {
     "displayName":"CC MÉD, SERV PUBL E EMP DO NE LTDA.",
     "name":"COOPERATIVA DE CRÉDITO DOS MÉDICOS, SERVIDORES PÚBLICOS E EMPRESÁRIOS DO NORDESTE LTDA - UNICRED DO NORDESTE",
     "spiCode":"02602922",
     "strCode":""
  },
  {
     "displayName":"UNICRED PONTO CAPITAL",
     "name":"COOPERATIVA DE ECONOMIA E CRÉDITO MÚTUO UNICRED PONTO CAPITAL - UNICRED PONTO CAPITAL.",
     "spiCode":"02641032",
     "strCode":""
  },
  {
     "displayName":"CCLAA NOVA TRENTO",
     "name":"COOPERATIVA DE CRÉDITO DE LIVRE ADMISSÃO DE ASSOCIADOS NOVA TRENTO SICOOB TRENTOCREDI SC",
     "spiCode":"02641969",
     "strCode":""
  },
  {
     "displayName":"CCE INT SOL DO NOROESTE RS/PE/CE",
     "name":"COOPERATIVA DE CRÉDITO E ECONOMIA COM INTERAÇÃO SOLIDÁRIA DO NOROESTE – CRESOL NOROESTE RS/PE/CE",
     "spiCode":"02663426",
     "strCode":""
  },
  {
     "displayName":"CC SERV PUBL COOPERPLAN",
     "name":"COOPERATIVA DE CRÉDITO DE SERVIDORES PÚBLICOS COOPERPLAN LTDA. - SICOOB COOPERPLAN",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"CCR INT SOL DE SÃO JOAQUIM",
     "name":"COOPERATIVA DE CREDITO RURAL COM INTERACAO SOLIDARIA DE SAO JOAQUIM-CRESOL SÃO JOAQUIM",
     "spiCode":"02766672",
     "strCode":""
  },
  {
     "displayName":"CECM SERV UNIV FED VIÇOSA",
     "name":"COOPERATIVA DE ECONOMIA E CRÉDITO MÚTUO DOS SERVIDORES DA UNIVERSIDADE FEDERAL DE VIÇOSA LTDA. - SICOOB UFVCREDI",
     "spiCode":"02794761",
     "strCode":""
  },
  {
     "displayName":"CECM MÉD VITÓRIA DA CONQUISTA",
     "name":"COOPERATIVA DE CRÉDITO DOS MÉDICOS E DEMAIS PROFISSIONAIS DA SAÚDE DE VITÓRIA DA CONQUISTA",
     "spiCode":"02833202",
     "strCode":""
  },
  {
     "displayName":"CCPI DO NORTE E NORDESTE DE SANTA CATARINA -SICREDI NORTE SC",
     "name":"COOPERATIVA DE CRÉDITO, POUPANÇA E INVESTIMENTO DO NORTE E NORDESTE DE SANTA CATARINA - SICREDI NORTE SC",
     "spiCode":"02843443",
     "strCode":""
  },
  {
     "displayName":"CCE INT SOL DIONÍSIO CERQUEIRA",
     "name":"COOPERATIVA DE CRÉDITO E ECONOMIA COM INTERAÇÃO SOLIDÁRIA DE DIONÍSIO CERQUEIRA - CRESOL DIONÍSIO CERQUEIRA",
     "spiCode":"02844024",
     "strCode":""
  },
  {
     "displayName":"CC SICOOB NORTE SUL",
     "name":"COOPERATIVA DE CRÉDITO NORTE SUL DA BAHIA LTDA - SICOOB NORTE SUL",
     "spiCode":"02876918",
     "strCode":""
  },
  {
     "displayName":"CC VALE ITAJAÍ E ITAPOCÚ",
     "name":"COOPERATIVA DE CRÉDITO DO VALE DO ITAJAÍ E VALE DO ITAPOCÚ - SICOOB MULTICREDI",
     "spiCode":"02883398",
     "strCode":""
  },
  {
     "displayName":"CRESOL JACUTINGA",
     "name":"COOPERATIVA DE CRÉDITO RURAL E ECONOMIA COM INTERAÇÃO SOLIDÁRIA DE JACUTINGA - CRESOL JACUTINGA",
     "spiCode":"02904125",
     "strCode":""
  },
  {
     "displayName":"CCR INT SOL ÁUREA",
     "name":"COOPERATIVA DE CRÉDITO RURAL COM INTERAÇÃO SOLIDÁRIA DE ÁUREA - CRESOL ÁUREA",
     "spiCode":"02904138",
     "strCode":""
  },
  {
     "displayName":"CCR INT SOL CENTRO-SUL RS/MS",
     "name":"COOPERATIVA DE CRÉDITO RURAL COM INTERAÇÃO SOLIDÁRIA CENTRO-SUL RS/MS - CRESOL CENTRO-SUL RS/MS",
     "spiCode":"02910987",
     "strCode":""
  },
  {
     "displayName":"CC SICREDI ARACAJU",
     "name":"COOPERATIVA DE CRÉDITO SICREDI ARACAJU",
     "spiCode":"02923389",
     "strCode":""
  },
  {
     "displayName":"CC POUP INV PROF MED SAUDE CUR REGIAO METROP - SICREDI MED",
     "name":"COOPERATIVA DE CRÉDITO, POUPANÇA E INVESTIMENTO DOS PROFISSIONAIS MÉDICOS E DA SAÚDE DE CURITIBA E REGIÃO METROPOLITANA - SICREDI MEDICRED PR",
     "spiCode":"02924977",
     "strCode":""
  },
  {
     "displayName":"SICOOB FLUMINENSE",
     "name":"COOPERATIVA DE CRÉDITO DE LIVRE ADMISSÃO DO ESTADO DO RIO DE JANEIRO - SICOOB FLUMINENSE",
     "spiCode":"02931668",
     "strCode":""
  },
  {
     "displayName":"CCR INT SOL LIDERANÇA",
     "name":"COOPERATIVA DE CRÉDITO RURAL COM INTERAÇÃO SOLIDÁRIA LIDERANÇA - CRESOL LIDERANÇA",
     "spiCode":"02934201",
     "strCode":""
  },
  {
     "displayName":"CCLA GOIANIA E ANÁPOLIS",
     "name":"COOPERATIVA DE CRÉDITO DE LIVRE ADMISSÃO DAS MICRO-REGIÕES DE GOIÂNIA E ANÁPOLIS LTDA",
     "spiCode":"02935307",
     "strCode":""
  },
  {
     "displayName":"CCPI POLICIAIS FEDERAIS RS SC",
     "name":"COOPERATIVA DE CRÉDITO, POUPANÇA E INVESTIMENTO DOS POLICIAIS FEDERAIS DO RIO GRANDE DO SUL E SANTA CATARINA – SICREDI POL RS/SC",
     "spiCode":"03000142",
     "strCode":""
  },
  {
     "displayName":"CRESOL SÃO VALENTIM",
     "name":"COOPERATIVA DE CRÉDITO RURAL E ECONOMIA COM INTERAÇÃO SOLIDÁRIA DE SÃO VALENTIM - CRESOL SÃO VALENTIM",
     "spiCode":"03015152",
     "strCode":""
  },
  {
     "displayName":"CC DO PLANALTO SUL",
     "name":"COOPERATIVA DE CREDITO DO PLANALTO SUL - SICOOB CREDISSERRANA",
     "spiCode":"03033469",
     "strCode":""
  },
  {
     "displayName":"CC POUP E INV DE CAMPO GRANDE E REGIÃO",
     "name":"COOPERATIVA DE CRÉDITO, POUPANÇA E INVESTIMENTO DE CAMPO GRANDE E REGIÃO - SICREDI CAMPO GRANDE MS",
     "spiCode":"03042597",
     "strCode":""
  },
  {
     "displayName":"CC CAP UNICIDADES",
     "name":"COOPERATIVA DE CRÉDITO E CAPTAÇÃO SICOOB UNICIDADES",
     "spiCode":"03047549",
     "strCode":""
  },
  {
     "displayName":"CC POUP INV NOROESTE SP",
     "name":"COOPERATIVA DE CRÉDITO, POUPANÇA E INVESTIMENTO DO NOROESTE DO ESTADO DE SÃO PAULO - SICREDI NOROESTE SP",
     "spiCode":"03065046",
     "strCode":""
  },
  {
     "displayName":"CC DOS SERVIDORES, EMPRESARIOS, AUTONOMOS E LIBERAIS",
     "name":"COOPERATIVA DE CRÉDITO DOS SERVIDORES DA UNIÃO, EMPRESÁRIOS, PROFISSIONAIS AUTÔNOMOS E LIBERAIS - SICOOB CENTRO NORDESTE",
     "spiCode":"03102185",
     "strCode":""
  },
  {
     "displayName":"CC POUP INV DO ESTADO DO PIAUÍ - SICREDI PIAUÍ",
     "name":"COOPERATIVA DE CREDITO , POUPANÇA E INVESTIMENTO DO ESTADO DO PIAUÍ - SICREDI PIAUÍ",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"CCPI DOS MILITARES ESTADUAIS DO RS - SICREDI MIL RS",
     "name":"COOPERATIVA DE CRÉDITO, POUPANÇA E INVESTIMENTO DOS MILITARES ESTADUAIS DO RIO GRANDE DO SUL – SICREDI MIL RS",
     "spiCode":"03212823",
     "strCode":""
  },
  {
     "displayName":"CC DO NORTE DE RONDÔNIA LTDA.",
     "name":"COOPERATIVA DE CRÉDITO DO NORTE DE RONDÔNIA LTDA. – CREDISIS CREDIARI",
     "spiCode":"03222753",
     "strCode":""
  },
  {
     "displayName":"UNIPRIME CAMPOS GERAIS-CC LTDA",
     "name":"UNIPRIME CAMPOS GERAIS - COOPERATIVA DE CRÉDITO LTDA.",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"CC SERV MIL POL CIVIL SEC ED",
     "name":"COOPERATIVA DE CRÉDITO DOS SERVIDORES MILITARES, POLÍCIA CIVIL E DA SECRETARIA DE EDUCAÇÃO DO ESTADO DE MINAS GERAIS LTDA – SICOOB COOPEMG",
     "spiCode":"03269540",
     "strCode":""
  },
  {
     "displayName":"CC TRIÂNGULO MINEIRO E SÃO FRANCISCO",
     "name":"COOPERATIVA DE CRÉDITO DO TRIÂNGULO MINEIRO E SÃO FRANCISCO LTDA. - SICOOB ARACOOP",
     "spiCode":"03320525",
     "strCode":""
  },
  {
     "displayName":"CCLA CENTRO NORTE MT/MS",
     "name":"COOPERATIVA DE CRÉDITO DE LIVRE ADMISSÃO DO CENTRO NORTE DOS ESTADOS DE MATO GROSSO E MATO GROSSO DO SUL – SICOOB UNIÃO MT/MS",
     "spiCode":"03326437",
     "strCode":""
  },
  {
     "displayName":"CECM SERV LEG FED E DO DF",
     "name":"COOPERATIVA DE ECONOMIA E CRÉDITO MÚTUO DOS SERVIDORES DO PODER LEGISLATIVO FEDERAL E DO DISTRITO FEDERAL - SICOOB LEGISLATIVO",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"SICOOB CREDIROCHAS",
     "name":"COOPERATIVA DE CRÉDITO DOS PROPRIETÁRIOS DA INDÚSTRIA DE ROCHAS ORNAMENTAIS, CAL E CALCÁRIOS DO ESTADO DO ESPÍRITO SANTO - SICOOB CREDIROCHAS",
     "spiCode":"03358914",
     "strCode":""
  },
  {
     "displayName":"CC CREDIRAMA",
     "name":"COOPERATIVA DE CRÉDITO CREDIRAMA LTDA - SICOOB CREDIRAMA",
     "spiCode":"03412878",
     "strCode":""
  },
  {
     "displayName":"CC NOSSA SENHORA DO DESTERRO",
     "name":"COOPERATIVA DE CRÉDITO NOSSA SENHORA DO DESTERRO - SICOOB CREDISC",
     "spiCode":"03419786",
     "strCode":""
  },
  {
     "displayName":"CCLA DO SUL CATARINENSE",
     "name":"COOPERATIVA DE CRÉDITO DE LIVRE ADMISSÃO DO SUL CATARINENSE - ACENTRA",
     "spiCode":"03427097",
     "strCode":""
  },
  {
     "displayName":"CECM SERV INST PUBL ENS SUP EST PB E DAS INST ORG PUBL DA PB",
     "name":"SICREDI CREDUNI - COOPERATIVA DE ECONOMIA E CRÉDITO MÚTUO DOS SERV DAS INST PÚBLICAS DE ENSINO SUPERIOR DO ESTADO DA PARAÍBA E DAS DEMAIS INSTITUIÇÕES E ÓRGÃOS PÚBLICOS NO ESTADO DA PARAIBA LTDA.",
     "spiCode":"03428338",
     "strCode":""
  },
  {
     "displayName":"CC SICOOB METROPOLITANO",
     "name":"COOPERATIVA DE CRÉDITO SICOOB METROPOLITANO",
     "spiCode":"03459850",
     "strCode":""
  },
  {
     "displayName":"CC DO NORTE CATARINENSE",
     "name":"COOPERATIVA DE CRÉDITO DO NORTE CATARINENSE - ACREDICOOP",
     "spiCode":"03461243",
     "strCode":""
  },
  {
     "displayName":"CCRE INT SOL ENCOSTAS DA SERRA GERAL",
     "name":"COOPERATIVA DE CREDITO RURAL E ECONOMIA COM INTERAÇÃO SOLIDARIA DAS ENCOSTAS DA SERRA GERAL – CRESOL ENCOSTAS DA SERRA GERAL",
     "spiCode":"03485130",
     "strCode":""
  },
  {
     "displayName":"CCC DO NORTE DO BRASIL",
     "name":"COOPERATIVA CENTRAL DE CRÉDITO DO NORTE DO BRASIL - SICOOB NORTE",
     "spiCode":"03502131",
     "strCode":""
  },
  {
     "displayName":"CC PODER JUD E MP MG",
     "name":"COOPERATIVA DE CRÉDITO DOS INTEGRANTES DO PODER JUDICIÁRIO E DO MINISTÉRIO PÚBLICO DO ESTADO DE MINAS GERAIS LTDA. - SICOOB JUS-MP",
     "spiCode":"03519240",
     "strCode":""
  },
  {
     "displayName":"CC INV DO ACRE - SICOOB ACRE",
     "name":"COOPERATIVA DE CRÉDITO E INVESTIMENTOS DO ACRE - SICOOB ACRE",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"CC SICOOB CREDMETAL",
     "name":"COOPERATIVA DE CRÉDITO SICOOB CREDMETAL",
     "spiCode":"03535065",
     "strCode":""
  },
  {
     "displayName":"CCPI CELEIRO CENTRO OESTE – SICREDI CELEIRO CENTRO OESTE",
     "name":"COOPERATIVA DE CRÉDITO, POUPANÇA E INVESTIMENTO CELEIRO CENTRO OESTE – SICREDI CELEIRO CENTRO OESTE",
     "spiCode":"03566655",
     "strCode":""
  },
  {
     "displayName":"MEDCRED CC",
     "name":"MEDCRED COOPERATIVA DE CRÉDITO",
     "spiCode":"03575699",
     "strCode":""
  },
  {
     "displayName":"CECM SERV SEC EST FAZENDA DF",
     "name":"COOPERATIVA DE ECONOMIA E CRÉDITO MÚTUO DOS SERVIDORES DA SECRETARIA DE ESTADO DE FAZENDA DO DISTRITO FEDERAL - SICOOB CREDSEF",
     "spiCode":"03603683",
     "strCode":""
  },
  {
     "displayName":"CCM SERV PUBL MUN SÃO BERNARDO",
     "name":"COOPERATIVA DE CRÉDITO MÚTUO DOS SERVIDORES PÚBLICOS MUNICIPAIS DE SÃO BERNARDO DO CAMPO - CREDIABC",
     "spiCode":"03612679",
     "strCode":""
  },
  {
     "displayName":"CC DA REGIÃO DE FRONTEIRAS DE RO/MT",
     "name":"COOPERATIVA DE CRÉDITO DA REGIÃO DE FRONTEIRAS DE RO/MT LTDA. - SICOOB FRONTEIRAS",
     "spiCode":"03612764",
     "strCode":""
  },
  {
     "displayName":"CCM POLFED S UNIÃO CE LA RM FORTALEZA SUL CE - SICOOB CEARÁ",
     "name":"COOPERATIVA DE CRÉDITO MÚTUO DOS POLICIAIS FEDERAIS E SERVIDORES DA UNIÃO NO CEARÁ E LIVRE ADMISSÃO NAS MESORREGIÕES METROPOLITANA DE FORTALEZA E SUL CEARENSE - SICOOB CEARÁ",
     "spiCode":"03620772",
     "strCode":""
  },
  {
     "displayName":"SICOOB CREDISUL",
     "name":"COOPERATIVA DE CRÉDITO DE LIVRE ADMISSÃO DO SUDOESTE DA AMAZÔNIA LTDA. - SICOOB CREDISUL.",
     "spiCode":"03632872",
     "strCode":""
  },
  {
     "displayName":"CC CREDISG",
     "name":"COOPERATIVA DE CRÉDITO CREDISG LTDA. - SICOOB CREDISG",
     "spiCode":"03645752",
     "strCode":""
  },
  {
     "displayName":"CECM INTEGRANTES MP RS",
     "name":"COOPERATIVA DE ECONOMIA E CREDITO MUTUO DOS INTEGRANTES DO MINISTERIO PUBLICO DO RIO GRANDE DO SUL - SICREDI MP",
     "spiCode":"03662047",
     "strCode":""
  },
  {
     "displayName":"CCLA DE PERNAMBUCO - SICOOB PERNAMBUCO",
     "name":"COOPERATIVA DE CRÉDITO DE LIVRE ADMISSÃO DE PERNAMBUCO - SICOOB PERNAMBUCO",
     "spiCode":"03732359",
     "strCode":""
  },
  {
     "displayName":"CCPI DOS JUÍZES E CARREIRAS PUB. DO DIREITO DO ESTADO DO RS",
     "name":"COOPERATIVA DE CRÉDITO, POUPANÇA E INVESTIMENTO DOS JUÍZES E CARREIRAS PÚBLICAS DO DIREITO DO ESTADO DO RIO GRANDE DO SUL – SICREDI AJURIS RS",
     "spiCode":"03750034",
     "strCode":""
  },
  {
     "displayName":"CCPI SUL DO EST DE SC - SICREDI SUL SC",
     "name":"COOPERATIVA DE CRÉDITO, POUPANÇA E INVESTIMENTO DO SUL DO ESTADO DE SANTA CATARINA – SICREDI SUL SC",
     "spiCode":"03793242",
     "strCode":""
  },
  {
     "displayName":"Pagueveloz",
     "name":"PAGUEVELOZ SERVICOS DE PAGAMENTO LTDA",
     "spiCode":"03816413",
     "strCode":""
  },
  {
     "displayName":"FREEDOM2BUY",
     "name":"FREEDOM2BUY.COM BRASIL LTDA.",
     "spiCode":"03856113",
     "strCode":""
  },
  {
     "displayName":"CEC EMPR CORREIOS",
     "name":"COOPERATIVA DE ECONOMIA E CRÉDITO DOS EMPREGADOS DOS CORREIOS LTDA. - SICOOB COOPERCORREIOS",
     "spiCode":"03862898",
     "strCode":""
  },
  {
     "displayName":"CCR ASCOOB ITAPICURU",
     "name":"COOPERATIVA DE CRÉDITO RURAL ASCOOB ITAPICURU - ASCOOB ITAPICURU.",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"CECM CORRET.SEG.RJ",
     "name":"COOPERATIVA DE ECONOMIA E CRÉDITO MÚTUO DOS SECURITARIOS, DOS CORRETORES DE SEGUROS E DE CAPITALIZACAO DO RIO DE JANEIRO LTDA.",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"CCLA CIRCUITO DAS ÁGUAS",
     "name":"COOPERATIVA DE CRÉDITO DE LIVRE ADMISSÃO DO CIRCUITO DAS ÁGUAS - SICOOB CIRCUITO DAS ÁGUAS",
     "spiCode":"03941182",
     "strCode":""
  },
  {
     "displayName":"CC INT SOL OESTE CATARINENSE",
     "name":"COOPERATIVA DE CRÉDITO COM INTERAÇÃO SOLIDÁRIA OESTE CATARINENSE - CRESOL OESTE CATARINENSE",
     "spiCode":"03965737",
     "strCode":""
  },
  {
     "displayName":"CC INV SUDOESTE RONDÔNIA LTDA - CREDISIS SUDOESTE/RO",
     "name":"COOPERATIVA DE CRÉDITO E INVESTIMENTO DO SUDOESTE DE RONDÔNIA LTDA. – CREDISIS SUDOESTE/RO",
     "spiCode":"03985375",
     "strCode":""
  },
  {
     "displayName":"CC SICOOB CRED-ACIF",
     "name":"COOPERATIVA DE CRÉDITO SICOOB CRED-ACIF",
     "spiCode":"04013172",
     "strCode":""
  },
  {
     "displayName":"CCLA DE ITAJUBÁ - SICOOB SUL",
     "name":"COOPERATIVA DE CRÉDITO DE LIVRE ADMISSÃO DE ITAJUBÁ LTDA - SICOOB SUL DE MINAS",
     "spiCode":"04079285",
     "strCode":""
  },
  {
     "displayName":"SICOOB EMPRESAS RJ",
     "name":"COOPERATIVA DE CREDITO CLASSICA DO ESTADO DO RIO DE JANEIRO - SICOOB EMPRESAS RJ",
     "spiCode":"04120633",
     "strCode":""
  },
  {
     "displayName":"CC DO RIO GRANDE DO NORTE - SICOOB RIO GRANDE DO NORTE",
     "name":"COOPERATIVA DE CRÉDITO DO RIO GRANDE DO NORTE - SICOOB RIO GRANDE DO NORTE",
     "spiCode":"04138455",
     "strCode":""
  },
  {
     "displayName":"CC SICREDI PERNAMBUCRED",
     "name":"COOPERATIVA DE CRÉDITO SICREDI PERNAMBUCRED",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"CECM SERV POL MIL SP REGIÃO CE",
     "name":"COOPERATIVA DE ECONOMIA E CRÉDITO MÚTUO DOS SERVIDORES DA POLÍCIA MILITAR DO ESTADO DE SÃO PAULO DA REGIÃO CENTRO-OESTE PAULISTA - CREDMIL",
     "spiCode":"04152107",
     "strCode":""
  },
  {
     "displayName":"CC SICOOB COOPEMAR",
     "name":"COOPERATIVA DE CRÉDITO SICOOB COOPEMAR LTDA",
     "spiCode":"04174720",
     "strCode":""
  },
  {
     "displayName":"CCLA DE PITANGUI E REGIAO LTDA",
     "name":"COOPERATIVA DE CREDITO DE LIVRE ADMISSAO DE PITANGUI E REGIAO LTDA. - SICOOB CREDPIT",
     "spiCode":"04181542",
     "strCode":""
  },
  {
     "displayName":"CC VALE DO SÃO FRANCISCO - SICREDI VALE DO SÃO FRANCISCO",
     "name":"COOPERATIVA DE CRÉDITO DO VALE DO SÃO FRANCISCO - SICREDI VALE DO SÃO FRANCISCO",
     "spiCode":"04237413",
     "strCode":""
  },
  {
     "displayName":"CENTRAL SICOOB UNI DE CC",
     "name":"CENTRAL SICOOB UNI DE COOPERATIVAS DE CRÉDITO",
     "spiCode":"04243780",
     "strCode":""
  },
  {
     "displayName":"CC DE EMPRESÁRIOS",
     "name":"COOPERATIVA DE CRÉDITO DE EMPRESÁRIOS - SICOOB/TRANSCREDI",
     "spiCode":"04247370",
     "strCode":""
  },
  {
     "displayName":"CCR INT SOL DE DONA EMMA",
     "name":"COOPERATIVA DE CRÉDITO RURAL COM INTERAÇÃO SOLIDÁRIA DE DONA EMMA - CRESOL DONA EMMA",
     "spiCode":"04261151",
     "strCode":""
  },
  {
     "displayName":"CCE INT SOL DE CHAPECO",
     "name":"COOPERATIVA DE CREDITO E ECONOMIA COM INTERACAO SOLIDARIA DE CHAPECO - CRESOL CHAPECO",
     "spiCode":"04281182",
     "strCode":""
  },
  {
     "displayName":"CC SICOOB CRED EXECUTIVO",
     "name":"COOPERATIVA DE CRÉDITO DOS SERVIDORES PÚBLICOS NO ESTADO DA BAHIA LTDA. - SICOOB CRED EXECUTIVO",
     "spiCode":"04321309",
     "strCode":""
  },
  {
     "displayName":"CC INT SOL TRADIÇÃO",
     "name":"COOPERATIVA DE CRÉDITO COM INTERAÇÃO SOLIDÁRIA TRADIÇÃO – CRESOL TRADIÇÃO",
     "spiCode":"04350225",
     "strCode":""
  },
  {
     "displayName":"CC UNICRED COOMARCA",
     "name":"COOPERATIVA DE CRÉDITO UNICRED COOMARCA LTDA. - COOMARCA",
     "spiCode":"04355489",
     "strCode":""
  },
  {
     "displayName":"CC SICOOB ENGECRED",
     "name":"COOPERATIVA DE CRÉDITO SICOOB ENGECRED LTDA.",
     "spiCode":"04388688",
     "strCode":""
  },
  {
     "displayName":"CC EMP ESTADO DE GOIÁS",
     "name":"COOPERATIVA DE CRÉDITO DOS EMPRESÁRIOS DO ESTADO DE GOIÁS LTDA",
     "spiCode":"04406371",
     "strCode":""
  },
  {
     "displayName":"CCM GRUPO ABC - COOPER 7",
     "name":"COOPERATIVA DE CRÉDITO MÚTUO DO GRUPO ABC - COOPER 7",
     "spiCode":"04414354",
     "strCode":""
  },
  {
     "displayName":"CECM REGIONAL NORTE LAGOS LTDA",
     "name":"UNICRED REGIONAL NORTE LAGOS - COOPERATIVA DE ECONOMIA E CRÉDITO MÚTUO REGIONAL NORTE LAGOS LTDA",
     "spiCode":"04445917",
     "strCode":""
  },
  {
     "displayName":"CCPI DA REGIÃO CENTRO OESTE PAULISTA",
     "name":"COOPERATIVA DE CRÉDITO, POUPANÇA E INVESTIMENTO DA REGIÃO CENTRO OESTE PAULISTA - SICREDI CENTRO OESTE PAULISTA",
     "spiCode":"04463602",
     "strCode":""
  },
  {
     "displayName":"CC POUP E INV DA ALTA NOROESTE DE SÃO PAULO",
     "name":"COOPERATIVA DE CRÉDITO POUPANÇA E INVESTIMENTO DA ALTA NOROESTE DE SÃO PAULO - SICREDI ALTA NOROESTE SP",
     "spiCode":"04484490",
     "strCode":""
  },
  {
     "displayName":"CRESOL GOIÁS",
     "name":"COOPERATIVA DE CRÉDITO COM INTERAÇÃO SOLIDÁRIA DE GOIÁS - CRESOL GOIÁS",
     "spiCode":"04490531",
     "strCode":""
  },
  {
     "displayName":"CC POUP INV PROFES UNIV CAXIAS DO SUL",
     "name":"COOPERATIVA DE CRÉDITO, POUPANÇA E INVESTIMENTO DOS PROFESSORES, FUNCIONÁRIOS E ALUNOS DA UNIVERSIDADE DE CAXIAS DO SUL – SICREDI COOPERUCS",
     "spiCode":"04525997",
     "strCode":""
  },
  {
     "displayName":"CC CASCAVEL REG SICOOB CREDICAPITAL",
     "name":"COOPERATIVA DE CRÉDITO DE CASCAVEL E REGIÃO - SICOOB CREDICAPITAL",
     "spiCode":"04529074",
     "strCode":""
  },
  {
     "displayName":"CRESOL ARATIBA",
     "name":"COOPERATIVA DE CRÉDITO RURAL E ECONOMIA COM INTERAÇÃO SOLIDÁRIA DE ARATIBA — CRESOL ARATIBA",
     "spiCode":"04565791",
     "strCode":""
  },
  {
     "displayName":"CECM MILITARES ESTADUAIS DE SC",
     "name":"COOPERATIVA DE ECONOMIA E CRÉDITO MÚTUO DOS MILITARES ESTADUAIS DE SANTA CATARINA - CREDPOM",
     "spiCode":"04572960",
     "strCode":""
  },
  {
     "displayName":"CCR INT SOL DE TENENTE PORTELA",
     "name":"COOPERATIVA DE CREDITO RURAL COM INTERAÇÃO SOLIDÁRIA DE TENENTE PORTELA - CRESOL TENENTE PORTELA",
     "spiCode":"04622657",
     "strCode":""
  },
  {
     "displayName":"CC MILITARES SEGURANÇA PUBLICA DF",
     "name":"COOPERATIVA DE CRÉDITO DOS MILITARES E DA SEGURANÇA PÚBLICA NO DISTRITO FEDERAL LTDA. - SICOOB DFMIL",
     "spiCode":"04649337",
     "strCode":""
  },
  {
     "displayName":"CC INT SOL VALE DAS ÁGUAS",
     "name":"COOPERATIVA DE CRÉDITO COM INTERAÇÃO SOLIDÁRIA VALE DAS ÁGUAS - CRESOL VALE DAS ÁGUAS",
     "spiCode":"04663561",
     "strCode":""
  },
  {
     "displayName":"CECM INTEG. MF DP ESTADO RJ",
     "name":"COOPERATIVA DE ECONOMIA E CRÉDITO MÚTUO DOS INTEGRANTES DO MINISTÉRIO PÚBLICO E DA DEFENSORIA PÚBLICA NO ESTADO DO RIO DE JANEIRO LIMITADA. - SICOOB COMPERJ",
     "spiCode":"04694278",
     "strCode":""
  },
  {
     "displayName":"CC INV DE RONDÔNIA - SICOOB CREDJURD",
     "name":"COOPERATIVA DE CRÉDITO E INVESTIMENTOS DE RONDÔNIA – SICOOB CREDJURD",
     "spiCode":"04751713",
     "strCode":""
  },
  {
     "displayName":"CECM SICOOB METALCRED",
     "name":"COOPERATIVA DE ECONOMIA E CRÉDITO MÚTUO SICOOB METALCRED",
     "spiCode":"04833655",
     "strCode":""
  },
  {
     "displayName":"CC POUP INV BANDEIRANTES - SICREDI BANDEIRANTES SP",
     "name":"COOPERATIVA DE CRÉDITO, POUPANÇA E INVESTIMENTO BANDEIRANTES – SICREDI BANDEIRANTES SP",
     "spiCode":"04853988",
     "strCode":""
  },
  {
     "displayName":"CCLA TRÊS FRONTEIRAS",
     "name":"COOPERATIVA DE CRÉDITO DE LIVRE ADMISSÃO TRÊS FRONTEIRAS - SICOOB TRÊS FRONTEIRAS",
     "spiCode":"04876393",
     "strCode":""
  },
  {
     "displayName":"CC POUP INV INTEG MAGISTRAT MINISTÉRIO PÚBL PR",
     "name":"COOPERATIVA DE CRÉDITO, POUPANÇA E INVESTIMENTO DOS INTEGRANTES DA MAGISTRATURA E DO MINISTÉRIO PÚBLICO NO ESTADO DO PARANÁ - SICREDI CREDJURIS",
     "spiCode":"04886317",
     "strCode":""
  },
  {
     "displayName":"CCI DO OESTE - CREDISIS OESTE",
     "name":"COOPERATIVA DE CRÉDITO E INVESTIMENTO DO OESTE - CREDISIS OESTE",
     "spiCode":"04985665",
     "strCode":""
  },
  {
     "displayName":"CCC UNICOOB-SICOOB CENTR UNIC",
     "name":"CENTRAL DAS COOPERATIVAS DE CREDITO UNICOOB - SICOOB CENTRAL UNICOOB",
     "spiCode":"05036532",
     "strCode":""
  },
  {
     "displayName":"CCE INT SOL PEDRA BRANCA",
     "name":"COOPERATIVA DE CRÉDITO E ECONOMIA COM INTERAÇÃO SOLIDÁRIA DE CORONEL MARTINS - CRESOL PEDRA BRANCA",
     "spiCode":"05070112",
     "strCode":""
  },
  {
     "displayName":"CCLA REG LESTE BACIA RIO DOCE",
     "name":"COOPERATIVA DE CRÉDITO DE LIVRE ADMISSÃO DA REGIÃO LESTE DA BACIA DO RIO DOCE LTDA SICOOB CREDILESTE",
     "spiCode":"05158093",
     "strCode":""
  },
  {
     "displayName":"SICOOB AMAZÔNIA",
     "name":"COOPERATIVA DE CRÉDITO DA AMAZÔNIA – SICOOB AMAZÔNIA",
     "spiCode":"05203605",
     "strCode":""
  },
  {
     "displayName":"CCR INT SOL DE MARCELINO RAMOS",
     "name":"COOPERATIVA DE CREDITO RURAL COM INTERACAO SOLIDARIA DE MARCELINO RAMOS-CRESOL MARCELINO RAMOS",
     "spiCode":"05211129",
     "strCode":""
  },
  {
     "displayName":"CCR INT SOL DE SANTA MARIA",
     "name":"COOPERATIVA DE CREDITO RURAL COM INTERACAO SOLIDARIA DE SANTA MARIA-CRESOL SANTA MARIA",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"CCR INT SOL DE SARANDI",
     "name":"COOPERATIVA DE CREDITO RURAL COM INTERACAO SOLIDARIA DE SARANDI-CRESOL SARANDI",
     "spiCode":"05220243",
     "strCode":""
  },
  {
     "displayName":"CCLA PIRACANJUBA",
     "name":"COOPERATIVA DE CRÉDITO DE LIVRE ADMISSÃO DE PIRACANJUBA LTDA",
     "spiCode":"05222094",
     "strCode":""
  },
  {
     "displayName":"CC INT SOL UNIÃO DOS VALES - CRESOL UNIÃO DOS VALES",
     "name":"COOPERATIVA DE CRÉDITO COM INTERAÇÃO SOLIDÁRIA UNIÃO DOS VALES – CRESOL UNIÃO DOS VALES",
     "spiCode":"05231945",
     "strCode":""
  },
  {
     "displayName":"CRESOL GETÚLIO VARGAS",
     "name":"COOPERATIVA DE CRÉDITO RURAL E ECONOMIA COM INTERAÇÃO SOLIDÁRIA DE GETÚLIO VARGAS - CRESOL GETÚLIO VARGAS",
     "spiCode":"05241145",
     "strCode":""
  },
  {
     "displayName":"CCLA M LESTE MT SICOOB PRIMAVE",
     "name":"COOPERATIVA DE CRÉDITO DE LIVRE ADMISSÃO DO MÉDIO LESTE DE MATO GROSSO - SICOOB PRIMAVERA MT",
     "spiCode":"05241619",
     "strCode":""
  },
  {
     "displayName":"CCLA DO CERRADO MATO-GROSSENSE",
     "name":"COOPERATIVA DE CRÉDITO DE LIVRE ADMISSÃO DO CERRADO MATO-GROSSENSE - SICOOB CERRADO MT",
     "spiCode":"05247312",
     "strCode":""
  },
  {
     "displayName":"CCE INT SOL FORMOSA",
     "name":"COOPERATIVA DE CRÉDITO E ECONOMIA COM INTERAÇÃO SOLIDÁRIA DE FORMOSA DO SUL - CRESOL FORMOSA",
     "spiCode":"05269976",
     "strCode":""
  },
  {
     "displayName":"CC INT SOL FRONTEIRAS PR/SC/SP/ES",
     "name":"COOPERATIVA DE CRÉDITO COM INTERAÇÃO SOLIDÁRIA FRONTEIRAS PR/SC/SP/ES - CRESOL FRONTEIRAS PR/SC/SP/ES",
     "spiCode":"05276770",
     "strCode":""
  },
  {
     "displayName":"CCR INT SOL HORIZONTE",
     "name":"COOPERATIVA DE CRÉDITO RURAL COM INTERAÇÃO SOLIDÁRIA HORIZONTE - CRESOL HORIZONTE",
     "spiCode":"05277312",
     "strCode":""
  },
  {
     "displayName":"CC REGIÃO MERIDIONAL BRASIL",
     "name":"COOPERATIVA DE CRÉDITO DA REGIÃO MERIDIONAL DO BRASIL - SICOOB UNICOOB MERIDIONAL",
     "spiCode":"05392810",
     "strCode":""
  },
  {
     "displayName":"CCE INT SOL GUARACIABA",
     "name":"COOPERATIVA DE CRÉDITO E ECONOMIA COM INTERAÇÃO SOLIDÁRIA DE GUARACIABA - CRESOL GUARACIABA",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"CCRE INT SOL JAGUARUNA",
     "name":"COOPERATIVA DE CRÉDITO RURAL E ECONOMIA COM INTERAÇÃO SOLIDÁRIA DE JAGUARUNA – CRESOL JAGUARUNA",
     "spiCode":"05410056",
     "strCode":""
  },
  {
     "displayName":"CCRE CIS CONCÓRDIA",
     "name":"COOPERATIVA DE CRÉDITO RURAL E ECONOMIA COM INTERAÇÃO SOLIDÁRIA CONCÓRDIA - CRESOL CONCÓRDIA",
     "spiCode":"05425526",
     "strCode":""
  },
  {
     "displayName":"CCR CRESOL OLIVEIRA DOS BREJINHOS",
     "name":"COOPERATIVA DE CREDITO RURAL COM INTERACAO SOLIDARIA DE OLIVEIRA DOS BREJINHOS - CRESOL OLIVEIRA DOS BREJINHOS",
     "spiCode":"05428587",
     "strCode":""
  },
  {
     "displayName":"CCLA DO ACRE LTDA.",
     "name":"COOPERATIVA DE CRÉDITO DE LIVRE ADMISSÃO DO ACRE LTDA.",
     "spiCode":"05439425",
     "strCode":""
  },
  {
     "displayName":"CCE INT SOL DE PORTO XAVIER",
     "name":"COOPERATIVA DE CRÉDITO E ECONOMIA COM INTERAÇÃO SOLIDARIA DE PORTO XAVIER – CRESOL PORTO XAVIER",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"CC CREDLIDER",
     "name":"COOPERATIVA DE CREDITO CREDLIDER - SICOOB CREDLIDER",
     "spiCode":"05463934",
     "strCode":""
  },
  {
     "displayName":"SICOOB PIAUÍ",
     "name":"COOPERATIVA DE CRÉDITO DE LIVRE ADMISSÃO DA GRANDE TERESINA E MICRORREGIÕES DE CAMPO MAIOR, MÉDIO PARNAÍBA, ALTO MÉDIO GURGUEIA, FLORIANO, PICOS E LITORAL PIAUIENSE – SICOOB PIAUÍ",
     "spiCode":"05477038",
     "strCode":""
  },
  {
     "displayName":"CCR INT SOL DE ALFREDO WAGNER",
     "name":"COOPERATIVA DE CRÉDITO RURAL COM INTERAÇÃO SOLIDÁRIA DE ALFREDO WAGNER - CRESOL ALFREDO WAGNER",
     "spiCode":"05494591",
     "strCode":""
  },
  {
     "displayName":"CC MEMBROS CARREIRA JURÍDICA E SERV PUB SAO LUIS",
     "name":"SICREDI COOMAMP - COOPERATIVA DE CRÉDITO DOS MEMBROS DE INSTITUIÇÕES PÚBLICAS DAS CARREIRAS JURÍDICAS E DOS SERVIDORES PÚBLICOS FEDERAIS, ESTADUAIS E MUNICIPAIS EM SÃO LUÍS/MA E MUNICÍPIOS CIRCUNVIZIN",
     "spiCode":"05545390",
     "strCode":""
  },
  {
     "displayName":"CC SICOOB OURO VERDE",
     "name":"COOPERATIVA DE CRÉDITO SICOOB OURO VERDE",
     "spiCode":"05582619",
     "strCode":""
  },
  {
     "displayName":"CC INV D'OESTE DE RONDÔNIA",
     "name":"COOPERATIVA DE CRÉDITO E INVESTIMENTO D'OESTE DE RONDÔNIA",
     "spiCode":"05597773",
     "strCode":""
  },
  {
     "displayName":"CECM COOPERSERV",
     "name":"COOPERATIVA DE ECONOMIA E CRÉDITO MÚTUO - COOPERSERV",
     "spiCode":"05667301",
     "strCode":""
  },
  {
     "displayName":"CRESOL ITATIBA DO SUL",
     "name":"COOPERATIVA DE CRÉDITO RURAL E ECONOMIA COM INTERAÇÃO SOLIDÁRIA DE ITATIBA DO SUL - CRESOL ITATIBA DO SUL",
     "spiCode":"05745533",
     "strCode":""
  },
  {
     "displayName":"SICOOB EMPRESARIAL",
     "name":"COOPERATIVA DE ECONOMIA E CRÉDITO DE LIVRE ADMISSÃO LTDA. - SICOOB EMPRESARIAL",
     "spiCode":"05856736",
     "strCode":""
  },
  {
     "displayName":"CCM DOS ADVOGADOS DE SC",
     "name":"COOPERATIVA DE CRÉDITO MÚTUO DOS ADVOGADOS DE SANTA CATARINA",
     "spiCode":"05861932",
     "strCode":""
  },
  {
     "displayName":"CRESOL PLANALTO SERRA",
     "name":"COOPERATIVA DE CRÉDITO RURAL E ECONOMIA COM INTERAÇÃO SOLIDÁRIA DO PLANALTO SERRA DO RIO GRANDE DO SUL - CRESOL PLANALTO SERRA",
     "spiCode":"05863726",
     "strCode":""
  },
  {
     "displayName":"CC SUL",
     "name":"COOPERATIVA DE CRÉDITO SUL - SICOOB SUL",
     "spiCode":"05888589",
     "strCode":""
  },
  {
     "displayName":"CCMS MUNICIPAIS DE BEBEDOURO",
     "name":"COOPERATIVA DE CRÉDITO MÚTUO DOS SERVIDORES MUNICIPAIS DE BEBEDOURO - COOCRESB.",
     "spiCode":"05969937",
     "strCode":""
  },
  {
     "displayName":"CREDCREA",
     "name":"COOPERATIVA DE CRÉDITO DOS PROFISSIONAIS DOS CREAS E DEMAIS ÁREAS TECNOLÓGICAS – CREDCREA",
     "spiCode":"05979692",
     "strCode":""
  },
  {
     "displayName":"CCR INT SOL DE HUMAITÁ",
     "name":"COOPERATIVA DE CRÉDITO RURAL COM INTERAÇÃO SOLIDÁRIA DE HUMAITÁ - CRESOL HUMAITÁ",
     "spiCode":"05983995",
     "strCode":""
  },
  {
     "displayName":"CCR INT SOL DE SANTO CRISTO",
     "name":"COOPERATIVA DE CRÉDITO RURAL COM INTERAÇÃO SOLIDÁRIA DE SANTO CRISTO - CRESOL SANTO CRISTO",
     "spiCode":"06031727",
     "strCode":""
  },
  {
     "displayName":"CC POUP INV ESCR NOT E REG PR",
     "name":"COOPERATIVA DE CRÉDITO, POUPANÇA E INVESTIMENTO DOS ESCRIVÃES, NOTÁRIOS E REGISTRADORES NO ESTADO DO PARANÁ - SICREDI CREDENOREG PR",
     "spiCode":"06078926",
     "strCode":""
  },
  {
     "displayName":"CC INT SOL VALE DAS ARAUCÁRIAS",
     "name":"COOPERATIVA DE CRÉDITO COM INTERAÇÃO SOLIDÁRIA DO VALE DAS ARAUCÁRIAS- CRESOL VALE DAS ARAUCÁRIAS",
     "spiCode":"06126780",
     "strCode":""
  },
  {
     "displayName":"CCR CRESOL NORDESTE",
     "name":"COOPERATIVA DE CRÉDITO RURAL COM INTERAÇÃO SOLIDÁRIA DE IBIRAIARAS - CRESOL NORDESTE",
     "spiCode":"06139650",
     "strCode":""
  },
  {
     "displayName":"CC SICOOB ALIANÇA",
     "name":"COOPERATIVA DE CRÉDITO SICOOB ALIANÇA",
     "spiCode":"06174009",
     "strCode":""
  },
  {
     "displayName":"SICOOB CREDINOSSO",
     "name":"COOPERATIVA DE CRÉDITO CREDINOSSO LTDA - SICOOB CREDINOSSO",
     "spiCode":"06324872",
     "strCode":""
  },
  {
     "displayName":"CC POUP INV CERRADO GOIÁS",
     "name":"COOPERATIVA DE CRÉDITO, POUPANÇA E INVESTIMENTO DO CERRADO DE GOIÁS - SICREDI CERRADO GO",
     "spiCode":"06332931",
     "strCode":""
  },
  {
     "displayName":"CCLA SANTA CRUZ PALMEIRAS",
     "name":"COOPERATIVA DE CRÉDITO DE LIVRE ADMISSÃO DE SANTA CRUZ DAS PALMEIRAS E REGIÃO",
     "spiCode":"06910457",
     "strCode":""
  },
  {
     "displayName":"CCLA SICOOB ARENITO",
     "name":"COOPERATIVA DE CRÉDITO DE LIVRE ADMISSÃO SICOOB ARENITO",
     "spiCode":"07026923",
     "strCode":""
  },
  {
     "displayName":"CCPI EMPREENDEDORES - SICREDI EMPREENDEDORES PR",
     "name":"COOPERATIVA DE CRÉDITO, POUPANÇA E INVESTIMENTO EMPREENDEDORES - SICREDI EMPREENDEDORES PR",
     "spiCode":"07070495",
     "strCode":""
  },
  {
     "displayName":"CCLA MARECHAL CÂNDIDO RONDON",
     "name":"COOPERATIVA DE CRÉDITO DE LIVRE ADMISSÃO DE MARECHAL CÂNDIDO RONDON E REGIÃO - SICOOB MARECHAL",
     "spiCode":"07097064",
     "strCode":""
  },
  {
     "displayName":"CCLA DO VALE RIO CRIXÁS",
     "name":"COOPERATIVA DE CRÉDITO DE LIVRE ADMISSÃO DO VALE RIO CRIXÁS LTDA.",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"CC INTEGRADO - SICOOB INTEGRADO",
     "name":"COOPERATIVA DE CRÉDITO INTEGRADO - SICOOB INTEGRADO",
     "spiCode":"07122321",
     "strCode":""
  },
  {
     "displayName":"CC SICOOB HORIZONTE",
     "name":"COOPERATIVA DE CRÉDITO HORIZONTE - SICOOB HORIZONTE",
     "spiCode":"07194313",
     "strCode":""
  },
  {
     "displayName":"CC POUP INV NOVOS HORIZONTES PR/SP",
     "name":"COOPERATIVA DE CRÉDITO, POUPANÇA E INVESTIMENTO NOVOS HORIZONTES - SICREDI NOVOS HORIZONTES PR/SP",
     "spiCode":"07206072",
     "strCode":""
  },
  {
     "displayName":"CCR INT SOL DE APIÚNA",
     "name":"COOPERATIVA DE CRÉDITO RURAL COM INTERAÇÃO SOLIDÁRIA DE APIÚNA - CRESOL APIÚNA",
     "spiCode":"07215632",
     "strCode":""
  },
  {
     "displayName":"CCR INT SOL DE PAIM FILHO",
     "name":"COOPERATIVA DE CRÉDITO RURAL COM INTERAÇÃO SOLIDÁRIA DE PAIM FILHO - CRESOL PAIM FILHO",
     "spiCode":"07252614",
     "strCode":""
  },
  {
     "displayName":"CC INT SOL INTEGRAÇÃO",
     "name":"COOPERATIVA DE CRÉDITO COM INTERAÇÃO SOLIDÁRIA INTEGRAÇÃO - CRESOL INTEGRAÇÃO",
     "spiCode":"07268499",
     "strCode":""
  },
  {
     "displayName":"CCLA MÉDIO OESTE",
     "name":"COOPERATIVA DE CRÉDITO DE LIVRE ADMISSÃO MÉDIO OESTE - SICOOB MÉDIO OESTE",
     "spiCode":"07318874",
     "strCode":""
  },
  {
     "displayName":"CCE INT SOL VITOR MEIRELES",
     "name":"COOPERATIVA DE CRÉDITO E ECONOMIA COM INTERAÇÃO SOLIDÁRIA DE VITOR MEIRELES - CRESOL VITOR MEIRELES",
     "spiCode":"07320890",
     "strCode":""
  },
  {
     "displayName":"CCE INT SOL PINHALZINHO",
     "name":"COOPERATIVA DE CRÉDITO E ECONOMIA COM INTERAÇÃO SOLIDÁRIA DE PINHALZINHO - CRESOL PINHALZINHO",
     "spiCode":"07412987",
     "strCode":""
  },
  {
     "displayName":"CC SICOOB CREDMED",
     "name":"COOPERATIVA DE CRÉDITO DOS MEDICOS E DEMAIS PROFISSIONAIS DE NIVEL SUPERIOR DA AREA DE SAUDE DE SALVADOR E REGIAO METROPOLITANA LTDA - SICOOB CREDMED",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"CCR INT SOL DE WITMARSUM",
     "name":"COOPERATIVA DE CRÉDITO RURAL COM INTERAÇÃO SOLIDÁRIA DE WITMARSUM - CRESOL WITMARSUM",
     "spiCode":"07465539",
     "strCode":""
  },
  {
     "displayName":"CC CREDIFIEMG",
     "name":"COOPERATIVA DE CRÉDITO CREDIFIEMG LTDA. – SICOOB CREDIFIEMG",
     "spiCode":"07469260",
     "strCode":""
  },
  {
     "displayName":"CECM SAÚDE EMP ACCI ERECHIM",
     "name":"COOPERATIVA DE ECONOMIA E CRÉDITO MÚTUO DOS TRABALHADORES NA ÁREA DE SAÚDE, AFINS E EMPRESÁRIOS VINCULADOS À ACCI DE ERECHIM-RS-CREDISUL",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"CCLA CENTRO OESTE LTDA.",
     "name":"COOPERATIVA DE CRÉDITO DE LIVRE ADMISSÃO CENTRO OESTE LTDA.",
     "spiCode":"07502031",
     "strCode":""
  },
  {
     "displayName":"CCE INT SOL XANXERÊ",
     "name":"COOPERATIVA DE CRÉDITO E ECONOMIA COM INTERAÇÃO SOLIDÁRIA DE XANXERÊ - CRESOL XANXERÊ",
     "spiCode":"07509426",
     "strCode":""
  },
  {
     "displayName":"CC INT SOL DO VALE EUROPEU",
     "name":"COOPERATIVA DE CRÉDITO COM INTERAÇÃO SOLIDÁRIA DO VALE EUROPEU - CRESOL VALE EUROPEU",
     "spiCode":"07512780",
     "strCode":""
  },
  {
     "displayName":"CCR ASCOOB COCREAL",
     "name":"COOPERATIVA DE CRÉDITO RURAL DO SERTÃO ALAGOANO ASCOOB COCREAL",
     "spiCode":"07564184",
     "strCode":""
  },
  {
     "displayName":"CCLA DA REGIÃO METROPOLITANA DE GOIÂNIA LTDA.",
     "name":"COOPERATIVA DE CRÉDITO DE LIVRE ADMISSÃO DA REGIÃO METROPOLITANA DE GOIÂNIA LTDA.",
     "spiCode":"07599206",
     "strCode":""
  },
  {
     "displayName":"CCM PEMM SÃO CARLOS",
     "name":"COOPERATIVA DE CRÉDITO MÚTUO DOS MICROS E PEQUENOS EMPRESÁRIOS E MICROEMPREENDEDORES DE SÃO CARLOS - SICOOB CREDIACISC",
     "spiCode":"07669921",
     "strCode":""
  },
  {
     "displayName":"CECM PROF ÁREA NOTARIAL REGISTRAL",
     "name":"COOPERATIVA DE ECONOMIA E CRÉDITO MÚTUO DOS PROFISSIONAIS DA ÁREA NOTARIAL E REGISTRAL - COOPNORE",
     "spiCode":"07714057",
     "strCode":""
  },
  {
     "displayName":"CCLA TRANSPORT RODOV VEICULOS",
     "name":"COOPERATIVA DE CRÉDITO DE LIVRE ADMISSÃO E DOS TRANSPORTADORES RODOVIÁRIOS DE VEÍCULOS – SICOOB CREDCEG",
     "spiCode":"07789195",
     "strCode":""
  },
  {
     "displayName":"CC INT SOL NORTE PARANAENSE",
     "name":"COOPERATIVA DE CRÉDITO COM INTERAÇÃO SOLIDÁRIA NORTE PARANAENSE - CRESOL NORTE PARANAENSE",
     "spiCode":"07925729",
     "strCode":""
  },
  {
     "displayName":"CCM MPEM DO GRANDE ABC - SICOOB GRANDE ABC",
     "name":"COOPERATIVA DE CRÉDITO MÚTUO DOS MICRO E PEQUENOS EMPRESÁRIOS E MICROEMPREENDEDORES DO GRANDE ABC - SICOOB GRANDE ABC.",
     "spiCode":"07946216",
     "strCode":""
  },
  {
     "displayName":"CCR INT SOL DE TANGARÁ",
     "name":"COOPERATIVA DE CRÉDITO RURAL COM INTERAÇÃO SOLIDÁRIA DE TANGARÁ - CRESOL TANGARÁ",
     "spiCode":"07946451",
     "strCode":""
  },
  {
     "displayName":"CCRE INT SOL RIO FORTUNA",
     "name":"COOPERATIVA DE CREDITO RURAL E ECONOMIA COM INTERAÇÃO SOLIDARIA DE RIO FORTUNA - CRESOL RIO FORTUNA",
     "spiCode":"07958405",
     "strCode":""
  },
  {
     "displayName":"CECM CORRET SEG EST SAO PAULO",
     "name":"COOPERATIVA DE CRÉDITO MÚTUO DOS CORRETORES DE SEGUROS DO ESTADO DE SÃO PAULO - SICOOB CREDICOR/SP",
     "spiCode":"08030602",
     "strCode":""
  },
  {
     "displayName":"CECM MAG SERV PJ MP DF PGE PGM CEARÁ SICREDI COOPERJURIS",
     "name":"COOPERATIVA DE ECONOMIA CRÉDITO MÚTUO MAGISTRADOS MEMBROS SERVIDORES PODER JUDICIÁRIO MINISTÉRIO PÚBLICO DEFENSORIA PÚBLICA PGE PGM POLÍCIA JUDICIÁRIA OFICIAIS REGISTRADORES CEARÁ SICREDI COOPERJURIS",
     "spiCode":"08041950",
     "strCode":""
  },
  {
     "displayName":"C.C. DO CENTRO DO ESTADO DE RONDÔNIA",
     "name":"COOPERATIVA DE CREDITO DO CENTRO DO ESTADO DE RONDONIA - SICOOB CENTRO",
     "spiCode":"08044854",
     "strCode":""
  },
  {
     "displayName":"CCE INT SOL XAVANTINA",
     "name":"COOPERATIVA DE CRÉDITO E ECONOMIA COM INTERAÇÃO SOLIDÁRIA DE XAVANTINA - CRESOL XAVANTINA",
     "spiCode":"08055016",
     "strCode":""
  },
  {
     "displayName":"CECM SICOOB COOPERAC",
     "name":"COOPERATIVA DE ECONOMIA E CRÉDITO MÚTUO – SICOOB COOPERAC",
     "spiCode":"08071414",
     "strCode":""
  },
  {
     "displayName":"TRANSPOCRED",
     "name":"COOPERATIVA DE CRÉDITO DOS EMPRESÁRIOS DE TRANSPORTES DO SUL DO BRASIL - TRANSPOCRED",
     "spiCode":"08075352",
     "strCode":""
  },
  {
     "displayName":"CC MED PROF SAÚDE SÃO LUÍS",
     "name":"COOPERATIVA DE CRÉDITO DOS MÉDICOS E PROFISSIONAIS DE SAÚDE DE SÃO LUÍS - SICREDI SÃO LUÍS",
     "spiCode":"08143326",
     "strCode":""
  },
  {
     "displayName":"CCR INT SOL GUARANI MISSÕES",
     "name":"COOPERATIVA DE CRÉDITO RURAL COM INTERAÇÃO SOLIDÁRIA DE GUARANI DAS MISSÕES - CRESOL GUARANI DAS MISSÕES",
     "spiCode":"08488377",
     "strCode":""
  },
  {
     "displayName":"CCR INT SOL TRIUNFO",
     "name":"COOPERATIVA DE CRÉDITO RURAL COM INTERAÇÃO SOLIDÁRIA TRIUNFO - CRESOL TRIUNFO",
     "spiCode":"08560508",
     "strCode":""
  },
  {
     "displayName":"CC INT SOL MINAS GERAIS",
     "name":"COOPERATIVA DE CRÉDITO COM INTERAÇÃO SOLIDÁRIA DE MINAS GERAIS - CRESOL MINAS GERAIS",
     "spiCode":"08624548",
     "strCode":""
  },
  {
     "displayName":"CCE INT SOL CORONEL FREITAS",
     "name":"COOPERATIVA DE CRÉDITO E ECONOMIA COM INTERAÇÃO SOLIDÁRIA DE CORONEL FREITAS - CRESOL CORONEL FREITAS",
     "spiCode":"08647473",
     "strCode":""
  },
  {
     "displayName":"CCE INT SOL SANTA TEREZINHA DO PROGRESSO",
     "name":"COOPERATIVA DE CRÉDITO E ECONOMIA COM INTERAÇÃO SOLIDÁRIA DE SANTA TEREZINHA DO PROGRESSO - CRESOL SANTA TEREZINHA DO PROGRESSO",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"CCLA UNIÃO E NEGÓCIOS - SICOOB INTEGRAÇÃO",
     "name":"COOPERATIVA DE CRÉDITO DE LIVRE ADMISSÃO UNIÃO E NEGÓCIOS - SICOOB INTEGRAÇÃO",
     "spiCode":"08742188",
     "strCode":""
  },
  {
     "displayName":"DOCK SOLUCOES",
     "name":"DOCK SOLUCOES EM MEIOS DE PAGAMENTO S A",
     "spiCode":"08744817",
     "strCode":""
  },
  {
     "displayName":"CECM LEME",
     "name":"COOPERATIVA DE ECONOMIA E CRÉDITO MÚTUO DE LEME - SICOOB CREDIACIL",
     "spiCode":"08795285",
     "strCode":""
  },
  {
     "displayName":"CCR INT SOL TIRADENTES DO SUL",
     "name":"COOPERATIVA DE CRÉDITO RURAL COM INTERAÇÃO SOLIDÁRIA DE TIRADENTES DO SUL - CRESOL TIRADENTES DO SUL",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"CECM EMP ITAPETININGA",
     "name":"COOPERATIVA DE ECONOMIA E CRÉDITO MÚTUO DOS EMPRESÁRIOS DE ITAPETININGA - SICOOB CRED-ACI",
     "spiCode":"08844074",
     "strCode":""
  },
  {
     "displayName":"CECM EMPR CENT ELÉTRICAS/SC",
     "name":"COOPERATIVA DE ECONOMIA E CRÉDITO MÚTUO DOS EMPREGADOS DA CENTRAIS ELÉTRICAS DE SANTA CATARINA - CREDELESC",
     "spiCode":"08850613",
     "strCode":""
  },
  {
     "displayName":"CECM SICOOB CRED-ACILPA",
     "name":"COOPERATIVA DE ECONOMIA E CRÉDITO MÚTUO SICOOB CRED-ACILPA",
     "spiCode":"09004796",
     "strCode":""
  },
  {
     "displayName":"CCLA DE RIO VERDE E REGIÃO",
     "name":"COOPERATIVA DE CRÉDITO DE LIVRE ADMISSÃO DE RIO VERDE E REGIÃO LTDA.",
     "spiCode":"09033698",
     "strCode":""
  },
  {
     "displayName":"PROJETA UNICRED",
     "name":"COOPERATIVA DE ECONOMIA E CRÉDITO MÚTUO DOS ENGENHEIROS, ARQUITETOS, AGRÔNOMOS E AFINS DO ESTADO DO RIO GRANDE DO SUL - PROJETA UNICRED",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"CCR ASCOOB COOPEC",
     "name":"COOPERATIVA DE CRÉDITO RURAL ASCOOB COOPEC - ASCOOB COOPEC",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"CCR INT SOL DE TREZE DE MAIO",
     "name":"COOPERATIVA DE CRÉDITO RURAL COM INTERAÇÃO SOLIDÁRIA DE TREZE DE MAIO - CRESOL TREZE DE MAIO",
     "spiCode":"09330158",
     "strCode":""
  },
  {
     "displayName":"CC SICREDI ALTO SERTÃO PARAIBANO",
     "name":"COOPERATIVA DE CRÉDITO SICREDI ALTO SERTÃO PARAIBANO - SICREDI ALTO SERTÃO PARAIBANO",
     "spiCode":"09343038",
     "strCode":""
  },
  {
     "displayName":"CCLA SICOOB C. L. N. MA",
     "name":"COOPERATIVA DE CRÉDITO DO CENTRO LESTE E NORTE MARANHENSE - SICOOB CENTRO LESTE E NORTE MARANHENSE",
     "spiCode":"09403026",
     "strCode":""
  },
  {
     "displayName":"CCLA DO OESTE MARANHENSE - SICOOB OESTE MARANHENSE",
     "name":"COOPERATIVA DE CRÉDITO DE LIVRE ADMISSÃO DO OESTE MARANHENSE - SICOOB OESTE MARANHENSE",
     "spiCode":"09424988",
     "strCode":""
  },
  {
     "displayName":"CCR INT SOL DE IRINEÓPOLIS",
     "name":"COOPERATIVA DE CRÉDITO RURAL COM INTERAÇÃO SOLIDÁRIA DE IRINEÓPOLIS - CRESOL IRINEÓPOLIS",
     "spiCode":"09433005",
     "strCode":""
  },
  {
     "displayName":"CCR INT SOL DE JACINTO MACHADO",
     "name":"COOPERATIVA DE CRÉDITO RURAL COM INTERAÇÃO SOLIDÁRIA DE JACINTO MACHADO - CRESOL JACINTO MACHADO",
     "spiCode":"09463721",
     "strCode":""
  },
  {
     "displayName":"CCR INT SOL DE SÃO JOÃO DO SUL",
     "name":"COOPERATIVA DE CRÉDITO RURAL COM INTERAÇÃO SOLIDÁRIA DE SÃO JOÃO DO SUL - CRESOL SÃO JOÃO DO SUL",
     "spiCode":"09488496",
     "strCode":""
  },
  {
     "displayName":"CC DA FOZ DO RIO ITAJAÍ AÇU",
     "name":"COOPERATIVA DE CRÉDITO DA FOZ DO RIO ITAJAÍ AÇU - CREDIFOZ",
     "spiCode":"09512539",
     "strCode":""
  },
  {
     "displayName":"ABASTECE AÍ",
     "name":"ABASTECE AI CLUBE AUTOMOBILISTA PAYMENT LTDA.",
     "spiCode":"09515813",
     "strCode":""
  },
  {
     "displayName":"CC JUST GOIAS E CELG",
     "name":"COOPERATIVA DE CRÉDITO DOS MAGISTRADOS, SERVIDORES DA JUSTIÇA DO ESTADO DE GOIÁS E EMPREGADOS DA CELG LTDA.",
     "spiCode":"09552111",
     "strCode":""
  },
  {
     "displayName":"CCLA CERES E RIALMA LTDA",
     "name":"COOPERATIVA DE CRÉDITO DE LIVRE ADMISSÃO DE CERES E RIALMA LTDA",
     "spiCode":"09576849",
     "strCode":""
  },
  {
     "displayName":"CECM EMP AMERICANA, LIMEIRA E",
     "name":"COOPERATIVA DE ECONOMIA E CRÉDITO MÚTUO DOS EMPRESÁRIOS DE AMERICANA, LIMEIRA E REGIÃO - SICOOB ACICRED",
     "spiCode":"09579249",
     "strCode":""
  },
  {
     "displayName":"CC DA SERRA CATARINENSE",
     "name":"COOPERATIVA DE CRÉDITO DA SERRA CATARINENSE - CREDICOMIN",
     "spiCode":"09590601",
     "strCode":""
  },
  {
     "displayName":"CECM EMP PRAIA GRANDE",
     "name":"COOPERATIVA DE ECONOMIA E CRÉDITO MÚTUO DOS EMPRESÁRIOS DE PRAIA GRANDE E REGIÃO - SICOOB COOPERACE",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"CCLA JATAÍ E REGIÃO",
     "name":"COOPERATIVA DE CRÉDITO DE LIVRE ADMISSÃO DE JATAÍ E REGIÃO LTDA.",
     "spiCode":"10143499",
     "strCode":""
  },
  {
     "displayName":"CCLA DE GUARAMIRIM",
     "name":"COOPERATIVA DE CRÉDITO DE LIVRE ADMISSÃO DE ASSOCIADOS DE GUARAMIRIM - CREVISC",
     "spiCode":"10143743",
     "strCode":""
  },
  {
     "displayName":"CECM SOROCABA E REGIÃO",
     "name":"COOPERATIVA DE ECONOMIA E CRÉDITO MÚTUO DE SOROCABA E REGIÃO – SICOOB COOPERASO",
     "spiCode":"10175348",
     "strCode":""
  },
  {
     "displayName":"CCLA DA GRANDE GOIÂNIA LTDA.",
     "name":"COOPERATIVA DE CRÉDITO DE LIVRE ADMISSÃO DA GRANDE GOIÂNIA LTDA.",
     "spiCode":"10209619",
     "strCode":""
  },
  {
     "displayName":"CIVIA COOP CRED",
     "name":"COOPERATIVA DE CRÉDITO DA REGIÃO DO CONTESTADO - CIVIA",
     "spiCode":"10218474",
     "strCode":""
  },
  {
     "displayName":"CCLA DO ESTADO DE SÃO PAULO - SICOOB PAULISTA",
     "name":"COOPERATIVA DE CRÉDITO DE LIVRE ADMISSÃO DO ESTADO DE SÃO PAULO - SICOOB PAULISTA",
     "spiCode":"10262276",
     "strCode":""
  },
  {
     "displayName":"EVOLUA",
     "name":"COOPERATIVA DE CRÉDITO DA REGIÃO DO SUDOESTE DO PARANÁ - EVOLUA",
     "spiCode":"10311218",
     "strCode":""
  },
  {
     "displayName":"CCLA DO CENTRO SUL DE MS",
     "name":"COOPERATIVA DE CRÉDITO DE LIVRE ADMISSÃO DO CENTRO SUL DE MATO GROSSO DO SUL – SICOOB CENTRO SUL MS",
     "spiCode":"10319386",
     "strCode":""
  },
  {
     "displayName":"CC VALE DO ITAJAI E LITORAL SC - SICREDI VALE LITORAL SC",
     "name":"COOPERATIVA DE CRÉDITO DO VALE DO ITAJAÍ E LITORAL CATARINENSE – SICREDI VALE LITORAL SC",
     "spiCode":"10348181",
     "strCode":""
  },
  {
     "displayName":"CCR INT SOL DE POUSO REDONDO",
     "name":"COOPERATIVA DE CRÉDITO RURAL COM INTERAÇÃO SOLIDÁRIA DE POUSO REDONDO - CRESOL POUSO REDONDO",
     "spiCode":"10453077",
     "strCode":""
  },
  {
     "displayName":"CC INT SOL RONDONIA",
     "name":"COOPERATIVA DE CRÉDITO COM INTERAÇÃO SOLIDÁRIA DE RONDÔNIA - CRESOL RONDÔNIA",
     "spiCode":"10520232",
     "strCode":""
  },
  {
     "displayName":"CC POUP E INV DO PLANALTO CENTRAL - SICREDI PLANALTO CENTRAL",
     "name":"COOPERATIVA DE CRÉDITO, POUPANÇA E INVESTIMENTO DO PLANALTO CENTRAL - SICREDI PLANALTO CENTRAL",
     "spiCode":"10736214",
     "strCode":""
  },
  {
     "displayName":"CC EMPRESÁRIOS DE MANAUS",
     "name":"COOPERATIVA DE CRÉDITO DOS EMPRESÁRIOS DE MANAUS - SICOOB CREDEMPRESAS-AM",
     "spiCode":"10808037",
     "strCode":""
  },
  {
     "displayName":"RECARGAPAY DO BRASIL SERVICOS",
     "name":"Recargapay Pagamentos Ltda.",
     "spiCode":"11275560",
     "strCode":""
  },
  {
     "displayName":"CCLA DA PARAÍBA - SICOOB PARAÍBA",
     "name":"COOPERATIVA DE CRÉDITO DE LIVRE ADMISSÃO DA PARAÍBA – SICOOB PARAÍBA",
     "spiCode":"11907520",
     "strCode":""
  },
  {
     "displayName":"CCR INT SOL COSTA OESTE",
     "name":"COOPERATIVA DE CRÉDITO RURAL COM INTERAÇÃO SOLIDÁRIA DA COSTA OESTE - CRESOL COSTA OESTE",
     "spiCode":"11969853",
     "strCode":""
  },
  {
     "displayName":"CCLAA JARAGUÁ DO SUL E REGIÃO",
     "name":"COOPERATIVA DE CRÉDITO DE LIVRE ADMISSÃO DE ASSOCIADOS DE JARAGUÁ DO SUL E REGIÃO - SICOOB CEJASCRED",
     "spiCode":"12384953",
     "strCode":""
  },
  {
     "displayName":"CELCOIN PAGAMENTOS S.A.",
     "name":"CELCOIN PAGAMENTOS S.A.",
     "spiCode":"13935893",
     "strCode":"509"
  },
  {
     "displayName":"CCLA MARGEM ESQUERDA DO URUCUI",
     "name":"COOPERATIVA DE CRÉDITO DE LIVRE ADMISSÃO DA MARGEM ESQUERDA DO URUCUIA E SÃO FRANCISCO LTDA - SICOOB CREDICHAPADA",
     "spiCode":"14046512",
     "strCode":""
  },
  {
     "displayName":"CCC DO RIO DE JANEIRO LTDA.",
     "name":"COOPERATIVA CENTRAL DE CRÉDITO DO RIO DE JANEIRO LTDA.",
     "spiCode":"14568725",
     "strCode":""
  },
  {
     "displayName":"CEC EMP PROF IMOB CMI/SECOVI",
     "name":"COOPERATIVA DE ECONOMIA E CRÉDITO DOS EMPRESÁRIOS, PROFISSIONAIS DO MERCADO IMOBILIÁRIO E DA CMI / SECOVI - SICOOB SECOVICRED MG",
     "spiCode":"14913919",
     "strCode":""
  },
  {
     "displayName":"IUGU SERVICOS NA INTERNET S/A",
     "name":"IUGU SERVICOS NA INTERNET S/A",
     "spiCode":"15111975",
     "strCode":"401"
  },
  {
     "displayName":"CECM APOS PENS E IDOSOS LIG FORCA SINDICAL SICOOB COOPERNAPI",
     "name":"COOPERATIVA DE ECONOMIA E CREDITO MUTUO DOS APOSENTADOS, PENSIONISTAS E IDOSOS LIGADOS AO SINDICATO NACIONAL DOS APOSENTADOS, PENSIONISTAS E IDOSOS DA FORÇA SINDICAL - SICOOB COOPERNAPI",
     "spiCode":"16564240",
     "strCode":""
  },
  {
     "displayName":"CECM SERV MUN ITABIRA",
     "name":"COOPERATIVA DE ECONOMIA E CRÉDITO MÚTUO DOS SERVIDORES MUNICIPAIS DE ITABIRA LTDA. - SICOOB COSEMI",
     "spiCode":"16651002",
     "strCode":""
  },
  {
     "displayName":"CECMF SECRETARIA DA FAZENDA E ÓRGÃOS OFICIAIS MG",
     "name":"COOPERATIVA DE ECONOMIA E CRÉDITO MÚTUO DOS FUNCIONÁRIOS DA SECRETARIA DE ESTADO DA FAZENDA E ÓRGÃOS OFICIAIS DO ESTADO DE MINAS GERAIS LTDA – SICOOB COOPSEF",
     "spiCode":"16721078",
     "strCode":""
  },
  {
     "displayName":"CCLA VIACREDI ALTO VALE",
     "name":"COOPERATIVA DE CRÉDITO DE LIVRE ADMISSÃO DO ALTO VALE DO ITAJAÍ - VIACREDI ALTO VALE",
     "spiCode":"16779741",
     "strCode":""
  },
  {
     "displayName":"BCO ITAUCARD S.A.",
     "name":"BANCO ITAUCARD S.A.",
     "spiCode":"17192451",
     "strCode":""
  },
  {
     "displayName":"CCR IS FREDERICO WESTPHALEN",
     "name":"COOPERATIVA DE CRÉDITO RURAL COM INTERAÇÃO SOLIDÁRIA DE FREDERICO WESTPHALEN - CRESOL FREDERICO WESTPHALEN",
     "spiCode":"17343510",
     "strCode":""
  },
  {
     "displayName":"CC REG. COLAR METROPOLITANO VALE DO AÇO LTDA",
     "name":"COOPERATIVA DE CRÉDITO DA REGIÃO E COLAR METROPOLITANO DO VALE DO AÇO LTDA. - SICOOB COSMIPA",
     "spiCode":"17361536",
     "strCode":""
  },
  {
     "displayName":"CECM EMPR CEMIG IND METAL E SERV PUB MUN",
     "name":"COOPERATIVA DE ECONOMIA E CRÉDITO MÚTUO DOS EMPREGADOS DA CEMIG E DAS INDÚSTRIAS METALÚRGICAS E DE MATERIAL ELÉTRICO E DOS SERVIDORES PÚBLICOS MUNICIPAIS DE JUIZ DE FORA LTDA. - SICOOB CECREMEC",
     "spiCode":"17502881",
     "strCode":""
  },
  {
     "displayName":"PAGGCERTO ",
     "name":"PAGGCERTO SOLUCOES EM PAGAMENTO S.A. ",
     "spiCode":"17819084",
     "strCode":""
  },
  {
     "displayName":"Cloud Walk Meios de Pagamentos ",
     "name":"CLOUD WALK MEIOS DE PAGAMENTOS E SERVICOS LTDA",
     "spiCode":"18189547",
     "strCode":""
  },
  {
     "displayName":"PAGAR.ME PAGAMENTOS",
     "name":"PAGAR.ME PAGAMENTOS S.A. ",
     "spiCode":"18727053",
     "strCode":""
  },
  {
     "displayName":"CCLA REGIÃO DE TRÊS PONTAS",
     "name":"COOPERATIVA DE CRÉDITO DE LIVRE ADMISSÃO DA REGIÃO DE TRÊS PONTAS LTDA. - SICOOB COPERSUL",
     "spiCode":"18966739",
     "strCode":""
  },
  {
     "displayName":"CECM EMPR EST HOSP BH RM ZM",
     "name":"COOPERATIVA DE ECONOMIA E CRÉDITO MÚTUO DOS SERVIDORES DA FUNDAÇÃO HOSPITALAR E EMPREGADOS DOS ESTABELECIMENTOS HOSPITALARES DE BELO HORIZONTE, REGIÃO METROPOLITANA E ZONA DA MATA LTDA.- SICOOB CECREF",
     "spiCode":"19402130",
     "strCode":""
  },
  {
     "displayName":"CC CREDCOOPER LTDA",
     "name":"COOPERATIVA DE CRÉDITO CREDCOOPER LTDA – SICOOB CREDCOOPER",
     "spiCode":"19449602",
     "strCode":""
  },
  {
     "displayName":"ZOOP BRASIL ",
     "name":"ZOOP TECNOLOGIA E MEIOS DE PAGAMENTO S.A. ",
     "spiCode":"19468242",
     "strCode":""
  },
  {
     "displayName":"CC EMPR CIA IND CATAGUASES",
     "name":"COOPERATIVA DE CRÉDITO DOS EMPREGADOS DA COMPANHIA INDUSTRIAL CATAGUASES E DA BAUMINAS LTDA. - SICOOB COOPECIC",
     "spiCode":"19495266",
     "strCode":""
  },
  {
     "displayName":"ATAR",
     "name":"ATAR S/A",
     "spiCode":"19581142",
     "strCode":""
  },
  {
     "displayName":"CCLA VALE DO AÇO",
     "name":"COOPERATIVA DE CREDITO DE LIVRE ADMISSAO DO VALE DO AÇO LTDA - SICOOB VALE DO AÇO",
     "spiCode":"19869338",
     "strCode":""
  },
  {
     "displayName":"CECM EMPR EMP PREST SERV METAL SIDER E IND SICOOB COPESITA",
     "name":"COOPERATIVA DE ECONOMIA E CRÉDITO MÚTUO DAS EMPRESAS, EMPREGADOS E PRESTADORES DE SERVIÇO DAS METALÚRGICAS, SIDERÚRGICAS E INDUSTRIAIS – SICOOB COPESITA",
     "spiCode":"19875244",
     "strCode":""
  },
  {
     "displayName":"CCPI DOS ADVOGADOS DO ESTADO DO RIO GRANDE DO SUL",
     "name":"COOPERATIVA DE CRÉDITO POUPANÇA E INVESTIMENTO DOS ADVOGADOS DO ESTADO DO RIO GRANDE DO SUL - SICREDI COOABCRED RS",
     "spiCode":"19962468",
     "strCode":""
  },
  {
     "displayName":"ADIQ",
     "name":"ADIQ SOLUCOES DE PAGAMENTO S.A.",
     "spiCode":"20520298",
     "strCode":""
  },
  {
     "displayName":"CECM COLABORADORES DA VALE SICOOB CREDIVALE",
     "name":"COOPERATIVA DE ECONOMIA E CRÉDITO MÚTUO DOS COLABORADORES DA VALE S.A LTDA. – SICOOB CREDIVALE",
     "spiCode":"20961629",
     "strCode":""
  },
  {
     "displayName":"CC CLASSICA FUNC PREST SERV LIG GRUPO EUCATUR",
     "name":"COOPERATIVA DE CRÉDITO CLÁSSICA DOS FUNCIONÁRIOS E PRESTADORES DE SERVIÇOS DAS EMPRESAS LIGADAS AO GRUPO EUCATUR LTDA. - SICOOB EUCRED",
     "spiCode":"21110927",
     "strCode":""
  },
  {
     "displayName":"CC CREDICAMPO LTDA",
     "name":"COOPERATIVA DE CRÉDITO CREDICAMPO LTDA.- SICOOB CREDICAMPO",
     "spiCode":"21661202",
     "strCode":""
  },
  {
     "displayName":"CC CREDIBOM",
     "name":"COOPERATIVA DE CRÉDITO CREDIBOM LTDA. – SICOOB CREDIBOM",
     "spiCode":"21670187",
     "strCode":""
  },
  {
     "displayName":"CECM SERV POD LEGIS MG",
     "name":"COOPERATIVA DE CRÉDITO DOS SERVIDORES DOS PODERES LEGISLATIVOS DO ESTADO DE MINAS GERAIS E DO SEU ÓRGÃO AUXILIAR LTDA. - SICOOB COFAL",
     "spiCode":"21797311",
     "strCode":""
  },
  {
     "displayName":"CC CREDINOR",
     "name":"COOPERATIVA DE CRÉDITO CREDINOR LTDA - SICOOB CREDINOR",
     "spiCode":"21866694",
     "strCode":""
  },
  {
     "displayName":"CELER",
     "name":"CELER PROCESSAMENTO COMERCIO E SERVICO LTDA.",
     "spiCode":"22347623",
     "strCode":""
  },
  {
     "displayName":"CC CREDISUDESTE LTDA",
     "name":"COOPERATIVA DE CREDITO CREDISUDESTE LTDA. - SICOOB CREDISUDESTE",
     "spiCode":"22656789",
     "strCode":""
  },
  {
     "displayName":"CC CREDIVERTENTES",
     "name":"COOPERATIVA DE CRÉDITO CREDIVERTENTES LTDA. – SICOOB CREDIVERTENTES",
     "spiCode":"22724710",
     "strCode":""
  },
  {
     "displayName":"CC REGIÃO CENTRAL MG",
     "name":"COOPERATIVA DE CRÉDITO DA REGIÃO CENTRAL DE MINAS LTDA. – SICOOB UNIÃO CENTRAL",
     "spiCode":"22749014",
     "strCode":""
  },
  {
     "displayName":"CC CREDISETE LTDA",
     "name":"COOPERATIVA DE CRÉDITO CREDISETE LTDA. - SICOOB CREDISETE",
     "spiCode":"22753982",
     "strCode":""
  },
  {
     "displayName":"CC NOSSOCREDITO",
     "name":"COOPERATIVA DE CRÉDITO NOSSOCRÉDITO LTDA - SICOOB NOSSOCRÉDITO",
     "spiCode":"22760839",
     "strCode":""
  },
  {
     "displayName":"FLAGSHIP",
     "name":"FLAGSHIP INSTITUICAO DE PAGAMENTOS LTDA",
     "spiCode":"23114447",
     "strCode":""
  },
  {
     "displayName":"CCR INT SOL DO MATO GROSSO DO SUL",
     "name":"COOPERATIVA DE CRÉDITO RURAL COM INTERAÇÃO SOLIDÁRIA DO MATO GROSSO DO SUL - CRESOL MS",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"CCLA NORTE DE MATO GROSSO - SICOOB NORTE MT",
     "name":"COOPERATIVA DE CRÉDITO DE LIVRE ADMISSÃO DO NORTE DE MATO GROSSO - SICOOB NORTE MT",
     "spiCode":"23623636",
     "strCode":""
  },
  {
     "displayName":"CC CREDICARPA",
     "name":"COOPERATIVA DE CRÉDITO CREDICARPA LTDA. - SICOOB CREDICARPA",
     "spiCode":"23949522",
     "strCode":""
  },
  {
     "displayName":"CC CREDINTER",
     "name":"COOPERATIVA DE CRÉDITO CREDINTER LTDA. – SICOOB CREDINTER",
     "spiCode":"24048910",
     "strCode":""
  },
  {
     "displayName":"BEPAY",
     "name":"BEPAY INSTITUICAO DE PAGAMENTO S.A.",
     "spiCode":"24313102",
     "strCode":""
  },
  {
     "displayName":"CC SICOOB TRANSAMAZÔNICA",
     "name":"COOPERATIVA DE CRÉDITO SICOOB TRANSAMAZÔNICA",
     "spiCode":"24431221",
     "strCode":""
  },
  {
     "displayName":"CC SICOOB IPÊ",
     "name":"COOPERATIVA DE CRÉDITO SICOOB IPÊ - SICOOB IPÊ",
     "spiCode":"24610065",
     "strCode":""
  },
  {
     "displayName":"CC POUP INV UNIÃO DOS EST MS, TO E OESTE DA BAHIA",
     "name":"COOPERATIVA DE CRÉDITO, POUPANÇA E INVESTIMENTO UNIÃO DOS ESTADOS DE MATO GROSSO DO SUL, TOCANTINS E OESTE DA BAHIA - SICREDI UNIÃO MS/TO",
     "spiCode":"24654881",
     "strCode":""
  },
  {
     "displayName":"CCLA SUDOESTE GOIANO",
     "name":"COOPERATIVA DE CRÉDITO DE LIVRE ADMISSÃO DO SUDOESTE GOIANO",
     "spiCode":"24795049",
     "strCode":""
  },
  {
     "displayName":"CCLA VALE DO PARANAÍBA",
     "name":"COOPERATIVA DE CRÉDITO DE LIVRE ADMISSÃO DO VALE DO PARANAÍBA LTDA.",
     "spiCode":"24799033",
     "strCode":""
  },
  {
     "displayName":"CCLA VALE DO ARAGUAIA",
     "name":"COOPERATIVA DE CRÉDITO DE LIVRE ADMISSÃO DO VALE DO ARAGUAIA LTDA.",
     "spiCode":"24830879",
     "strCode":""
  },
  {
     "displayName":"DLOCAL ",
     "name":"DLOCAL BRASIL PAGAMENTOS LTDA. ",
     "spiCode":"25021356",
     "strCode":""
  },
  {
     "displayName":"RoadPass",
     "name":"ROADPASS PAYMENTS & URBAN MOBILITY LTDA",
     "spiCode":"25104230",
     "strCode":""
  },
  {
     "displayName":"CC CREDIALP",
     "name":"COOPERATIVA DE CRÉDITO CREDIALP LTDA - SICOOB CREDIALP",
     "spiCode":"25353939",
     "strCode":""
  },
  {
     "displayName":"CC INTEG PODER JUD E INST JURID UNIAO EM MG",
     "name":"COOPERATIVA DE CRÉDITO DOS INTEGRANTES DO PODER JUDICIÁRIO E DAS INSTITUIÇÕES JURÍDICAS DA UNIÃO EM MINAS GERAIS LTDA. SICOOB COOPJUS",
     "spiCode":"25363615",
     "strCode":""
  },
  {
     "displayName":"C.C.L.A. DO VALE DO MUCURI LTD",
     "name":"COOPERATIVA DE CRÉDITO DE LIVRE ADMISSÃO VALE DO MUCURI LTDA. - SICOOB CREDIVALE",
     "spiCode":"25387655",
     "strCode":""
  },
  {
     "displayName":"CC ALTO PARANAIBA E REGIÃO",
     "name":"COOPERATIVA DE CREDITO DO ALTO PARANAIBA E REGIÃO LTDA. - SICOOB CREDIPATOS",
     "spiCode":"25387671",
     "strCode":""
  },
  {
     "displayName":"CCLA DA REGIÃO DE PARÁ DE MINAS",
     "name":"COOPERATIVA DE CRÉDITO DE LIVRE ADMISSÃO DA REGIÃO DE PARÁ DE MINAS LTDA - SICOOB CREDIRURAL",
     "spiCode":"25387713",
     "strCode":""
  },
  {
     "displayName":"CCLA DO LESTE DE MINAS",
     "name":"COOPERATIVA DE CRÉDITO DE LIVRE ADMISSÃO DO LESTE DE MINAS LTDA. - SICOOB CREDICAF",
     "spiCode":"25395435",
     "strCode":""
  },
  {
     "displayName":"CCLA VALE DO GORUTUBA",
     "name":"COOPERATIVA DE CREDITO DE LIVRE ADMISSÃO DO VALE DO GORUTUBA LTDA - SICOOB CREDIVAG",
     "spiCode":"25404542",
     "strCode":""
  },
  {
     "displayName":"CC CREDIOESTE LTDA. - SICOOB CREDIOESTE",
     "name":"COOPERATIVA DE CRÉDITO CREDIOESTE LTDA. - SICOOB CREDIOESTE",
     "spiCode":"25420696",
     "strCode":""
  },
  {
     "displayName":"CCLA DIVINÓPOLIS",
     "name":"COOPERATIVA DE CRÉDITO DE LIVRE ADMISSÃO DA REGIÃO DE DIVINÓPOLIS LTDA - SICOOB CREDIVERDE",
     "spiCode":"25528753",
     "strCode":""
  },
  {
     "displayName":"CC CREDILUZ",
     "name":"COOPERATIVA DE CRÉDITO CREDILUZ LTDA. - SICOOB CREDILUZ",
     "spiCode":"25536764",
     "strCode":""
  },
  {
     "displayName":"CPC VALE DO RIO DOCE",
     "name":"COOPERATIVA DE POUPANÇA E CRÉDITO DO VALE DO RIO DOCE LTDA – SICOOB CREDIRIODOCE",
     "spiCode":"25606237",
     "strCode":""
  },
  {
     "displayName":"SICREDI CREDIGRANDE MG",
     "name":"COOPERATIVA DE CRÉDITO, POUPANÇA E INVESTIMENTO ALTO RIO GRANDE MG - SICREDI CREDIGRANDE MG",
     "spiCode":"25626490",
     "strCode":""
  },
  {
     "displayName":"CCC MINAS GERAIS - CREDIMINAS",
     "name":"COOPERATIVA CENTRAL DE CRÉDITO DE MINAS GERAIS LTDA. - SICOOB CENTRAL CREDIMINAS",
     "spiCode":"25683434",
     "strCode":""
  },
  {
     "displayName":"CCLA VALE DO RIO GRANDE",
     "name":"COOPERATIVA DE CRÉDITO DE LIVRE ADMISSÃO DO VALE DO RIO GRANDE LTDA. - SICOOB UBERABA",
     "spiCode":"25683475",
     "strCode":""
  },
  {
     "displayName":"CCLA CARMO DO RIO CLARO",
     "name":"COOPERATIVA DE CRÉDITO DE LIVRE ADMISSÃO DE CARMO DO RIO CLARO LTDA - SICOOB CREDICARMO",
     "spiCode":"25743311",
     "strCode":""
  },
  {
     "displayName":"CC CREDIVAR",
     "name":"COOPERATIVA DE CRÉDITO CREDIVAR LTDA. - SICOOB CREDIVAR",
     "spiCode":"25798596",
     "strCode":""
  },
  {
     "displayName":"CC GUARANICREDI",
     "name":"COOPERATIVA DE CRÉDITO GUARANICREDI LTDA. - SICOOB GUARANICREDI",
     "spiCode":"26014175",
     "strCode":""
  },
  {
     "displayName":"CC UNIÃO CENTRO OESTE LTDA",
     "name":"COOPERATIVA DE CRÉDITO UNIÃO CENTRO OESTE LTDA. – SICOOB UNIÃO CENTRO OESTE",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"CCLA REGIÃO DO ALTO SÃO FRANCISCO LTDA",
     "name":"COOPERATIVA DE CRÉDITO DE LIVRE ADMISSÃO DA REGIÃO DO ALTO SÃO FRANCISCO LTDA. - SICOOB CREDIPRATA",
     "spiCode":"26178111",
     "strCode":""
  },
  {
     "displayName":"CCLA PONTAL DO TRIANGULO",
     "name":"COOPERATIVA DE CRÉDITO DE LIVRE ADMISSÃO DO PONTAL DO TRIANGULO LTDA - SICOOB CREDIPONTAL",
     "spiCode":"26178533",
     "strCode":""
  },
  {
     "displayName":"CCLA DA REGIÃO SUL DO MARANHÃO",
     "name":"COOPERATIVA DE CRÉDITO DE LIVRE ADMISSÃO DA REGIÃO SUL DO MARANHÃO – SICREDI SUL DO MARANHÃO",
     "spiCode":"26191078",
     "strCode":""
  },
  {
     "displayName":"CC SICOOBMAIS",
     "name":"COOPERATIVA DE CRÉDITO SICOOBMAIS LTDA. – SICOOBMAIS",
     "spiCode":"26359745",
     "strCode":""
  },
  {
     "displayName":"CC POUP INV ASS CENTRO SUL MS",
     "name":"COOPERATIVA DE CRÉDITO, POUPANÇA E INVESTIMENTO DO CENTRO SUL DO MATO GROSSO DO SUL – SICREDI CENTRO-SUL MS",
     "spiCode":"26408161",
     "strCode":""
  },
  {
     "displayName":"CC POUP E INV PANTANAL DO MATO GROSSO DO SUL",
     "name":"COOPERATIVA DE CRÉDITO POUPANÇA E INVESTIMENTO PANTANAL DO MATO GROSSO DO SUL - SICREDI PANTANAL MS",
     "spiCode":"26408187",
     "strCode":""
  },
  {
     "displayName":"CCLA SUL MARANHENSE - SICOOB SUL MARANHENSE",
     "name":"COOPERATIVA DE CRÉDITO DE LIVRE ADMISSÃO SUL MARANHENSE - SICOOB SUL MARANHENSE",
     "spiCode":"26526166",
     "strCode":""
  },
  {
     "displayName":"CCPI OURO VERDE DO MATO GROSSO",
     "name":"COOPERATIVA DE CRÉDITO, POUPANÇA E INVESTIMENTO OURO VERDE DO MATO GROSSO - SICREDI OURO VERDE MT",
     "spiCode":"26529420",
     "strCode":""
  },
  {
     "displayName":"CC POUP E INV DO SUL DE MT",
     "name":"COOPERATIVA DE CRÉDITO, POUPANÇA E INVESTIMENTO DO SUL DE MATO GROSSO – SICREDI SUL MT",
     "spiCode":"26549311",
     "strCode":""
  },
  {
     "displayName":"CCPI SORRISO",
     "name":"COOPERATIVA DE CRÉDITO, POUPANÇA E INVESTIMENTO SORRISO - SICREDI CELEIRO DO MT",
     "spiCode":"26555235",
     "strCode":""
  },
  {
     "displayName":"CCLA DO TOCANTINS",
     "name":"COOPERATIVA DE CRÉDITO DE LIVRE ADMISSÃO DO TOCANTINS LTDA",
     "spiCode":"26960328",
     "strCode":""
  },
  {
     "displayName":"PAYMEE BRASIL",
     "name":"PAYMEE BRASIL SERVICOS DE PAGAMENTOS S/A",
     "spiCode":"28683892",
     "strCode":""
  },
  {
     "displayName":"ALYMENTE",
     "name":"ALYMENTE BENEFICIOS E SIMILARES LTDA",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"BIT CAPITAL",
     "name":"BIT CAPITAL LTDA",
     "spiCode":"29079725",
     "strCode":""
  },
  {
     "displayName":"BANQI",
     "name":"BANQI INSTITUICAO DE PAGAMENTO LTDA. ",
     "spiCode":"30723871",
     "strCode":""
  },
  {
     "displayName":"U4Crypto Soluções",
     "name":"U4CRYPTO SOLUCOES TECNOLOGICAS E FINANCEIRAS SA",
     "spiCode":"30980539",
     "strCode":"546"
  },
  {
     "displayName":"SICOOB CENTRO-SERRANO",
     "name":"COOPERATIVA DE CREDITO CENTRO-SERRANA DO ESPIRITO SANTO - SICOOB CENTRO-SERRANO",
     "spiCode":"31804966",
     "strCode":""
  },
  {
     "displayName":"SICOOB NORTE",
     "name":"COOPERATIVA DE CRÉDITO NORTE DO ESPÍRITO SANTO - SICOOB NORTE",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"COMPANHIA GLOBAL DE SOLUCOES",
     "name":"COMPANHIA GLOBAL DE SOLUCOES E SERVICOS DE PAGAMENTOS S.A.",
     "spiCode":"32024691",
     "strCode":""
  },
  {
     "displayName":"SPIN PAY ",
     "name":"SPIN PAY SERVICOS DE PAGAMENTOS LTDA. ",
     "spiCode":"32219232",
     "strCode":""
  },
  {
     "displayName":"CCC DO ESP.SANTO - SICOOB ES",
     "name":"COOPERATIVA CENTRAL DE CREDITO DO ESPIRITO SANTO - SICOOB CENTRAL ES",
     "spiCode":"32428294",
     "strCode":""
  },
  {
     "displayName":"SICOOB LESTE CAPIXABA",
     "name":"COOPERATIVA DE CRÉDITO LESTE CAPIXABA - SICOOB LESTE CAPIXABA",
     "spiCode":"32430233",
     "strCode":""
  },
  {
     "displayName":"SICOOB SUL",
     "name":"COOPERATIVA DE CRÉDITO SUL DO ESPÍRITO SANTO - SICOOB SUL",
     "spiCode":"32467086",
     "strCode":""
  },
  {
     "displayName":"SICOOB SUL-LITORÂNEO",
     "name":"COOPERATIVA DE CRÉDITO SUL-LITORÂNEA DO ESPÍRITO SANTO",
     "spiCode":"32474884",
     "strCode":""
  },
  {
     "displayName":"CECM SICOOB COOPEC",
     "name":"COOPERATIVA DE ECONOMIA E CRÉDITO MÚTUO DOS FUNCIONÁRIOS DA CEPLAC LTDA. - SICOOB COOPEC",
     "spiCode":"32615247",
     "strCode":""
  },
  {
     "displayName":"AME",
     "name":"AME DIGITAL BRASIL LTDA.",
     "spiCode":"32778350",
     "strCode":""
  },
  {
     "displayName":"CC POUP E INV VALE DO CERRADO - SICREDI VALE DO CERRADO",
     "name":"COOPERATIVA DE CRÉDITO, POUPANÇA E INVESTIMENTO VALE DO CERRADO - SICREDI VALE DO CERRADO",
     "spiCode":"32983165",
     "strCode":""
  },
  {
     "displayName":"CCPI DO SUDOESTE MT/PA",
     "name":"COOPERATIVA DE CRÉDITO, POUPANÇA E INVESTIMENTO DO SUDOESTE MT/PA - SICREDI SUDOESTE MT/PA",
     "spiCode":"32995755",
     "strCode":""
  },
  {
     "displayName":"CCPI DO ARAGUAIA E XINGU",
     "name":"COOPERATIVA DE CRÉDITO, POUPANÇA E INVESTIMENTO DO ARAGUAIA E XINGU - SICREDI ARAXINGU",
     "spiCode":"33021064",
     "strCode":""
  },
  {
     "displayName":"CC POUP INV DO NOROESTE DE MT, AC E AM-SICREDI BIOMAS",
     "name":"COOPERATIVA DE CRÉDITO, POUPANÇA E INVESTIMENTO DO NOROESTE DE MATO GROSSO, ACRE E AMAZONAS - SICREDI BIOMAS",
     "spiCode":"33022690",
     "strCode":""
  },
  {
     "displayName":"FURNAS",
     "name":"COOPERATIVA DE ECONOMIA E CRÉDITO MÚTUO DOS EMPREGADOS DE FURNAS E DAS DEMAIS EMPRESAS DO SISTEMA ELETROBRÁS LTDA. – SICOOB CECREMEF",
     "spiCode":"33370115",
     "strCode":""
  },
  {
     "displayName":"CCC DE GOIAS LTDA",
     "name":"COOPERATIVA CENTRAL DE CRÉDITO DE GOIÁS LTDA",
     "spiCode":"33416108",
     "strCode":""
  },
  {
     "displayName":"CCLA CENTRO-SUL GOIANO",
     "name":"COOPERATIVA DE CRÉDITO DE LIVRE ADMISSÃO DO CENTRO-SUL GOIANO LTDA",
     "spiCode":"33579731",
     "strCode":""
  },
  {
     "displayName":"CCLA ANÁPOLIS E REGIÃO",
     "name":"COOPERATIVA DE CRÉDITO DE LIVRE ADMISSÃO DE ANÁPOLIS E REGIÃO LTDA",
     "spiCode":"33615055",
     "strCode":""
  },
  {
     "displayName":"SICOOB COOPVALE",
     "name":"COOPERATIVA DE CRÉDITO CLÁSSICA DOS EMPREGADOS DA VALE S/A E ENTIDADES VINCULADAS LTDA. – SICOOB COOPVALE",
     "spiCode":"33924028",
     "strCode":""
  },
  {
     "displayName":"CC SICOOB CENTRAL BA",
     "name":"COOPERATIVA CENTRAL DE CRÉDITO DA BAHIA LTDA – SICOOB CENTRAL BA",
     "spiCode":"34148882",
     "strCode":""
  },
  {
     "displayName":"CLARO PAY",
     "name":"CLARO PAY S/A",
     "spiCode":"34878543",
     "strCode":""
  },
  {
     "displayName":"CCPI SICREDI EVOLUÇÃO",
     "name":"COOPERATIVA DE CRÉDITO, POUPANÇA E INVESTIMENTO SICREDI EVOLUÇÃO - SICREDI EVOLUÇÃO",
     "spiCode":"35571249",
     "strCode":""
  },
  {
     "displayName":"CCM MED EMP MT",
     "name":"COOPERATIVA DE CRÉDITO DOS MÉDICOS, PROFISSIONAIS DA SAÚDE E EMPRESÁRIOS DE MATO GROSSO",
     "spiCode":"36900256",
     "strCode":""
  },
  {
     "displayName":"CECMS PJ ENSINO SUPERIOR ADVOGADOS DEFENSORES PUBLICOS",
     "name":"COOPERATIVA DE ECONOMIA E CRÉDITO MÚTUO SERVIDORES PJ, MP, ENSINO SUPERIOR ADVOGADOS PÚBLICOS, DEFENSORES PÚBLICOS E DELEGADOS POLÍCIA FEDERAL NO DISTRITO FEDERAL - SICOOB JUDICIÁRIO",
     "spiCode":"37076205",
     "strCode":""
  },
  {
     "displayName":"CCLA SICOOB CREDIJUSTRA",
     "name":"COOPERATIVA DE CRÉDITO DE LIVRE ADMISSÃO LTDA. – SICOOB CREDIJUSTRA",
     "spiCode":"37079720",
     "strCode":""
  },
  {
     "displayName":"SICOOB CREDIGOIÁS CC",
     "name":"SICOOB CREDIGOIÁS COOPERATIVA DE CRÉDITO LTDA.",
     "spiCode":"37255049",
     "strCode":""
  },
  {
     "displayName":"CCLA CENTRO BRASILEIRA",
     "name":"COOPERATIVA DE CRÉDITO DE LIVRE ADMISSÃO CENTRO BRASILEIRA LTDA.",
     "spiCode":"37395399",
     "strCode":""
  },
  {
     "displayName":"CC POUP E INV NORTE MATO-GROSSENSE E OESTE PARAENSE",
     "name":"COOPERATIVA DE CRÉDITO, POUPANÇA E INVESTIMENTO NORTE MATO-GROSSENSE E OESTE PARAENSE – SICREDI GRANDES RIOS MT/PA",
     "spiCode":"37442605",
     "strCode":""
  },
  {
     "displayName":"CC UNIPRIME SUL MS",
     "name":"UNIPRIME SUL MS - COOPERATIVA DE CRÉDITO",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"CCLA DE PALMEIRAS E REGIÃO",
     "name":"COOPERATIVA DE CRÉDITO DE LIVRE ADMISSÃO DE PALMEIRAS E REGIÃO LTDA.",
     "spiCode":"37631058",
     "strCode":""
  },
  {
     "displayName":"CCLA CONSELHEIRO PENA",
     "name":"COOPERATIVA DE CRÉDITO DE LIVRE ADMISSÃO DE CONSELHEIRO PENA E REGIÃO LTDA. - SICOOB CREDICOPE",
     "spiCode":"38588174",
     "strCode":""
  },
  {
     "displayName":"UNICRED COSTA DO SOL RJ",
     "name":"UNICRED COSTA DO SOL RJ – COOPERATIVA DE ECONOMIA E CRÉDITO MÚTUO LTDA.",
     "spiCode":"39231527",
     "strCode":""
  },
  {
     "displayName":"SICREDI EXPANSÃO – CC",
     "name":"SICREDI EXPANSÃO – COOPERATIVA DE CRÉDITO",
     "spiCode":"41180092",
     "strCode":""
  },
  {
     "displayName":"CC SICREDI CENTRO PERNAMBUCANA",
     "name":"COOPERATIVA DE CRÉDITO SICREDI CENTRO PERNAMBUCANA",
     "spiCode":"41255225",
     "strCode":""
  },
  {
     "displayName":"CCLA PROD RURAIS TRIÂNGULO",
     "name":"COOPERATIVA DE CRÉDITO DE PRODUTORES RURAIS E DE LIVRE ADMISSÃO DO TRIÂNGULO LTDA SICOOB CREDITRIL",
     "spiCode":"41669227",
     "strCode":""
  },
  {
     "displayName":"CC CREDILIVRE LTDA. - SICOOB CREDILIVRE",
     "name":"COOPERATIVA DE CRÉDITO CREDILIVRE LTDA. - SICOOB CREDILIVRE",
     "spiCode":"41697103",
     "strCode":""
  },
  {
     "displayName":"CCLA SANTO ANTONIO DO MONTE",
     "name":"COOPERATIVA DE CRÉDITO DE LIVRE ADMISSÃO DE SANTO ANTÔNIO DO MONTE LTDA. SICOOB CREDIMONTE",
     "spiCode":"41707258",
     "strCode":""
  },
  {
     "displayName":"CC CARLOS CHAGAS",
     "name":"COOPERATIVA DE CRÉDITO CARLOS CHAGAS LTDA. - SICOOB CARLOS CHAGAS",
     "spiCode":"41753500",
     "strCode":""
  },
  {
     "displayName":"CECM SERV MUN POÇOS DE CALDAS",
     "name":"COOPERATIVA DE ECONOMIA E CRÉDITO MÚTUO DOS SERVIDORES MUNICIPAIS DE POÇOS DE CALDAS LTDA. - COOPOÇOS",
     "spiCode":"41791005",
     "strCode":""
  },
  {
     "displayName":"CCLA ALTO SÃO FRANCISCO",
     "name":"COOPERATIVA DE CRÉDITO DE LIVRE ADMISSÃO DO ALTO SÃO FRANCISCO LTDA.",
     "spiCode":"41805003",
     "strCode":""
  },
  {
     "displayName":"SICOOB CREDICOOP",
     "name":"COOPERATIVA DE CRÉDITO DE LIVRE ADMISSÃO DE PITANGUI, PAPAGAIOS, MARAVILHAS E REGIÃO LTDA. - SICOOB CREDICOOP",
     "spiCode":"41931221",
     "strCode":""
  },
  {
     "displayName":"CC CREDIFOR LTDA",
     "name":"COOPERATIVA DE CRÉDITO CREDIFOR LTDA – SICOOB CREDIFOR",
     "spiCode":"41931445",
     "strCode":""
  },
  {
     "displayName":"CC AGROCREDI LTDA",
     "name":"COOPERATIVA DE CRÉDITO AGROCREDI LTDA. - SICOOB AGROCREDI",
     "spiCode":"42873828",
     "strCode":""
  },
  {
     "displayName":"CCLA REG TIROS E MATUTINA",
     "name":"COOPERATIVA DE CRÉDITO DE LIVRE ADMISSÃO DA REGIÃO DE TIROS E MATUTINA LTDA - SICOOB CREDITIROS",
     "spiCode":"42880617",
     "strCode":""
  },
  {
     "displayName":"CCLA REG SUDESTE MG",
     "name":"COOPERATIVA DE CREDITO DE LIVRE ADMISSÃO DA REGIÃO SUDESTE DE MINAS GERAIS LTDA. - SICOOB CREDISUCESSO",
     "spiCode":"42887133",
     "strCode":""
  },
  {
     "displayName":"CECM MEDICOS E PROFISSIONAIS AREA SAUDE DO BRASIL",
     "name":"SICOOB CREDICOM - COOPERATIVA DE ECONOMIA E CREDITO MUTUO DOS MEDICOS E PROFISSIONAIS DA AREA DE SAUDE DO BRASIL LTDA.",
     "spiCode":"42898825",
     "strCode":""
  },
  {
     "displayName":"CC DE PIMENTA LTDA.",
     "name":"COOPERATIVA DE CRÉDITO DE PIMENTA LTDA. - SICOOB CREDIPIMENTA",
     "spiCode":"42934794",
     "strCode":""
  },
  {
     "displayName":"CCLA REGIÃO DE GUARIBA",
     "name":"COOPERATIVA DE CRÉDITO DE LIVRE ADMISSÃO DA REGIÃO DE GUARIBA",
     "spiCode":"44469161",
     "strCode":""
  },
  {
     "displayName":"CCME EMBRAER E BOEING BRASIL COMMERCIAL",
     "name":"COOPERATIVA DE CRÉDITO MÚTUO DOS EMPREGADOS DA EMBRAER E DA BOEING BRASIL COMMERCIAL - COOPEREMB",
     "spiCode":"46642294",
     "strCode":""
  },
  {
     "displayName":"CECM EMPR COOP E SUPER Z SUL",
     "name":"COOPERATIVA DE ECONOMIA E CRÉDITO MÚTUO DOS EMPREGADOS DA COOP - COOPERATIVA DE CONSUMO E DOS EMPREGADOS DO SUPER MERCADO ZONA SUL S/A",
     "spiCode":"47074323",
     "strCode":""
  },
  {
     "displayName":"CC COOPLIVRE",
     "name":"COOPERATIVA DE CREDITO COOPLIVRE",
     "spiCode":"49389307",
     "strCode":""
  },
  {
     "displayName":"CC PLANT CANA REG LENCOIS PAUL",
     "name":"COOPERATIVA DE CRÉDITO DOS PLANTADORES DE CANA DA REGIÃO DE LENÇÓIS PAULISTA",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"CECM MAGISTRADOS DE SÃO PAULO",
     "name":"COOPERATIVA DE ECONOMIA E CRÉDITO MÚTUO DOS MAGISTRADOS DE SÃO PAULO - MAGISCRED",
     "spiCode":"51489318",
     "strCode":""
  },
  {
     "displayName":"CCLA DE ARARAQUARA E REGIÃO",
     "name":"COOPERATIVA DE ECONOMIA E CRÉDITO MÚTUO DE LIVRE ADMISSÃO DE ARARAQUARA E REGIÃO - SICOOB",
     "spiCode":"53236808",
     "strCode":""
  },
  {
     "displayName":"CC COOPCRED",
     "name":"COOPERATIVA DE CRÉDITO COOPCRED",
     "spiCode":"53623781",
     "strCode":""
  },
  {
     "displayName":"CCLA SICOOB COOCRELIVRE",
     "name":"COOPERATIVA DE CRÉDITO COOCRELIVRE",
     "spiCode":"53935029",
     "strCode":""
  },
  {
     "displayName":"CC CREDICITRUS",
     "name":"COOPERATIVA DE CREDITO CREDICITRUS",
     "spiCode":"54037916",
     "strCode":""
  },
  {
     "displayName":"CECM SERV MUN REG METR VALE PARAIBA E LITORAL NORTE",
     "name":"COOPERATIVA DE ECONOMIA E CRÉDITO MÚTUO DOS SERVIDORES MUNICIPAIS DA REGIÃO METROPOLITANA DO VALE DO PARAÍBA E LITORAL NORTE - SICOOB-CRESSEM",
     "spiCode":"54190525",
     "strCode":""
  },
  {
     "displayName":"CC COCRE",
     "name":"COOPERATIVA DE CRÉDITO COCRE",
     "spiCode":"54401286",
     "strCode":""
  },
  {
     "displayName":"CC CECRES",
     "name":"COOPERATIVA DE CREDITO CECRES - SICOOB CECRES",
     "spiCode":"54603022",
     "strCode":""
  },
  {
     "displayName":"CECME EMPR EMP GR ECON RHODIA",
     "name":"COOPERATIVA DE ECONOMIA E CRÉDITO MÚTUO DOS EMPREGADOS DAS EMPRESAS DO GRUPO ECONÔMICO RHODIA - CREDIRHODIA",
     "spiCode":"57563728",
     "strCode":""
  },
  {
     "displayName":"CC POUP INV DA REG DAS FLORES, DAS ÁGUAS E DOS VENTOS SP",
     "name":"COOPERATIVA DE CRÉDITO, POUPANÇA E INVESTIMENTO DA REGIÃO DAS FLORES, DAS ÁGUAS E DOS VENTOS SP - SICREDI FORÇA DOS VENTOS SP",
     "spiCode":"57647653",
     "strCode":""
  },
  {
     "displayName":"CECM EMPR EMP ZILLO LORENZETTI",
     "name":"COPERCRED - COOPERATIVA DE ECONOMIA E CRÉDITO MÚTUO DOS EMPREGADOS DAS EMPRESAS ZILLO LORENZETTI",
     "spiCode":"58022195",
     "strCode":""
  },
  {
     "displayName":"CC NOSSO - SICOOB NOSSO",
     "name":"COOPERATIVA DE CREDITO NOSSO - SICOOB NOSSO",
     "spiCode":"59869560",
     "strCode":""
  },
  {
     "displayName":"CREFISA S.A. CFI",
     "name":"CREFISA S.A.-CREDITO, FINANCIAMENTO E INVESTIMENTOS",
     "spiCode":"60779196",
     "strCode":""
  },
  {
     "displayName":"CC SICOOB COOPMIL",
     "name":"COOPERATIVA DE CRÉDITO SICOOB COOPMIL",
     "spiCode":"62673470",
     "strCode":""
  },
  {
     "displayName":"CCC ESTADO SP - CECRESP",
     "name":"CENTRAL DAS COOPERATIVAS DE CRÉDITO DO ESTADO DE SÃO PAULO - SICOOB CENTRAL CECRESP",
     "spiCode":"62931522",
     "strCode":""
  },
  {
     "displayName":"CCR ASCOOB SISAL",
     "name":"COOPERATIVA DE CREDITO RURAL ASCOOB SISAL",
     "spiCode":"63229629",
     "strCode":""
  },
  {
     "displayName":"CCC DO ESTADO DE SÃO PAULO",
     "name":"COOPERATIVA CENTRAL DE CRÉDITO DO ESTADO DE SÃO PAULO - SICOOB SÃO PAULO",
     "spiCode":"63917579",
     "strCode":""
  },
  {
     "displayName":"CC CREDIUNA LTDA",
     "name":"COOPERATIVA DE CRÉDITO CREDIUNA LTDA – SICOOB CREDIUNA",
     "spiCode":"64237530",
     "strCode":""
  },
  {
     "displayName":"CCLA NORDESTE DE MG E SUL DA B",
     "name":"COOPERATIVA DE CRÉDITO DE LIVRE ADMISSÃO DO NORDESTE DE MINAS GERAIS E SUL DA BAHIA LTDA - SICOOB CREDINORTE",
     "spiCode":"64276058",
     "strCode":""
  },
  {
     "displayName":"CCLA MARTINHO CAMPOS",
     "name":"COOPERATIVA DE CREDITO DE LIVRE ADMISSÃO DE MARTINHO CAMPOS LTDA - SICOOB CREDIMAC",
     "spiCode":"64480833",
     "strCode":""
  },
  {
     "displayName":"SICOOB CRED COPERCANA CC",
     "name":"SICOOB CRED COPERCANA COOPERATIVA DE CRÉDITO",
     "spiCode":"64739121",
     "strCode":""
  },
  {
     "displayName":"CC COOPACREDI",
     "name":"COOPERATIVA DE CRÉDITO COOPACREDI LTDA. – SICOOB COOPACREDI",
     "spiCode":"65229254",
     "strCode":""
  },
  {
     "displayName":"CC UNIÃO DO CENTRO OESTE DE MINAS",
     "name":"COOPERATIVA DE CRÉDITO UNIÃO DO CENTRO OESTE DE MINAS LTDA. - SICOOB CENTRO UNIÃO",
     "spiCode":"65239402",
     "strCode":""
  },
  {
     "displayName":"CECM SICOOB SANTA",
     "name":"COOPERATIVA DE ECONOMIA E CRÉDITO MÚTUO SICOOB SANTA",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"CCLA BAMBUÍ",
     "name":"COOPERATIVA DE CRÉDITO DE LIVRE ADMISSÃO DE BAMBUÍ LTDA. - SICOOB CREDIBAM",
     "spiCode":"66259110",
     "strCode":""
  },
  {
     "displayName":"CCLA DE POMPEU LTDA.",
     "name":"COOPERATIVA DE CRÉDITO DE LIVRE ADMISSÃO DE POMPÉU LTDA. - SICOOB CREDIPÉU",
     "spiCode":"66262643",
     "strCode":""
  },
  {
     "displayName":"CC DA REGIÃO DE ARAXÁ LTDA.",
     "name":"COOPERATIVA DE CRÉDITO DA REGIÃO DE ARAXÁ LTDA. - SICOOB CREDIARA",
     "spiCode":"66398496",
     "strCode":""
  },
  {
     "displayName":"CCLA S. ROQUE DE MINAS",
     "name":"COOPERATIVA DE CRÉDITO DE LIVRE ADMISSÃO DE SÃO ROQUE DE MINAS LTDA. SICOOB SAROMCREDI",
     "spiCode":"66402207",
     "strCode":""
  },
  {
     "displayName":"CCLA DE ITAÚNA E REGIÃO LTDA",
     "name":"COOPERATIVA DE CRÉDITO DE LIVRE ADMISSÃO DE ITAÚNA E REGIÃO LTDA - SICOOB CENTRO-OESTE",
     "spiCode":"66463407",
     "strCode":""
  },
  {
     "displayName":"CCINV EMPR. SERV. SECRET. ESTAD. LOGISTICA E TRANSPORTES SP",
     "name":"COOPERATIVA DE CRÉDITO E INVESTIMENTO DOS EMPREGADOS E SERVIDORES DA SECRETARIA ESTADUAL DE LOGÍSTICA E TRANSPORTES E ÓRGÃOS SUBORDINADOS DO ESTADO DE SÃO PAULO - SICOOB COOPTRANSP",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"CC CREDIMOTA",
     "name":"COOPERATIVA DE CREDITO CREDIMOTA SICOOB CREDIMOTA",
     "spiCode":"66788142",
     "strCode":""
  },
  {
     "displayName":"CC CREDICOCAPEC",
     "name":"COOPERATIVA DE CRÉDITO CREDICOCAPEC",
     "spiCode":"67096909",
     "strCode":""
  },
  {
     "displayName":"CECM TRAB CIA PROC DADOS SP",
     "name":"COOPERATIVA DE ECONOMIA E CRÉDITO MÚTUO DOS TRABALHADORES DA CIA. DE PROCESSAMENTO DE DADOS DO ESTADO DE SÃO PAULO - CREDIPRODESP",
     "spiCode":"67915868",
     "strCode":""
  },
  {
     "displayName":"CC CREDIGUAÇU",
     "name":"COOPERATIVA DE CRÉDITO CREDIGUAÇU - SICOOB CREDIGUAÇU",
     "spiCode":"67960229",
     "strCode":""
  },
  {
     "displayName":"CC CREDIESMERALDAS LTDA.",
     "name":"COOPERATIVA DE CRÉDITO CREDIESMERALDAS LTDA. – SICOOB CREDIESMERALDAS",
     "spiCode":"68512748",
     "strCode":""
  },
  {
     "displayName":"CECM EMP USAGRO",
     "name":"COOPERATIVA DE ECONOMIA E CRÉDITO MÚTUO DOS EMPREGADOS DAS EMPRESAS CONTROLADAS E COLIGADAS DA USINA SANTA ADELIA - USAGRO",
     "spiCode":"68969625",
     "strCode":""
  },
  {
     "displayName":"CECM DOS PROFISSIONAIS DA SAUDE DA REG ALTA MOGIANA",
     "name":"COOPERATIVA DE ECONOMIA E CRÉDITO MÚTUO DOS PROFISSIONAIS DA SAÚDE DA REGIÃO DA ALTA MOGIANA - SICOOB CREDIMOGIANA",
     "spiCode":"69346856",
     "strCode":""
  },
  {
     "displayName":"SICREDI RIO GRANDE DO NORTE",
     "name":"COOPERATIVA DE CRÉDITO - SICREDI RIO GRANDE DO NORTE",
     "spiCode":"70038237",
     "strCode":""
  },
  {
     "displayName":"SICOOB CENTRAL NE",
     "name":"CENTRAL DAS COOPERATIVAS DE CRÉDITO DO NORDESTE - SICOOB CENTRAL NE",
     "spiCode":"70116611",
     "strCode":""
  },
  {
     "displayName":"CC POUP INV SICREDI RECIFE - SICREDI RECIFE",
     "name":"COOPERATIVA DE CRÉDITO, POUPANÇA E INVESTIMENTO SICREDI RECIFE - SICREDI RECIFE",
     "spiCode":"70241658",
     "strCode":""
  },
  {
     "displayName":"CC POUP E INV UNIVALES – SICREDI UNIVALES MT/RO",
     "name":"COOPERATIVA DE CRÉDITO, POUPANÇA E INVESTIMENTO UNIVALES – SICREDI UNIVALES MT/RO",
     "spiCode":"70431630",
     "strCode":""
  },
  {
     "displayName":"CCLA PROF SAÚDE UNICRED ALIANÇA",
     "name":"COOPERATIVA DE CRÉDITO DOS PROFISSIONAIS DA ÁREA DA SAÚDE E DE LIVRE ADMISSÃO LTDA - UNICRED ALIANÇA",
     "spiCode":"70937271",
     "strCode":""
  },
  {
     "displayName":"CCLA GUAPÉ - SICOOB CREDIGUAPÉ",
     "name":"COOPERATIVA DE CRÉDITO DE GUAPÉ LTDA - SICOOB CREDIGUAPÉ",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"CCLA DORES DO INDAIÁ LTDA",
     "name":"COOPERATIVA DE CRÉDITO DE LIVRE ADMISSÃO DE DORES DO INDAIÁ LTDA. - SICOOB CREDINDAIÁ",
     "spiCode":"71069587",
     "strCode":""
  },
  {
     "displayName":"CCLA CAMPOS GERAIS E CAMPO DO",
     "name":"COOPERATIVA DE CRÉDITO DE LIVRE ADMISSÃO DE CAMPOS GERAIS E CAMPO DO MEIO LTDA - SICOOB CREDCAM",
     "spiCode":"71146450",
     "strCode":""
  },
  {
     "displayName":"CC SACRAMENTO",
     "name":"COOPERATIVA DE CRÉDITO SACRAMENTO LTDA - SICOOB SACRAMENTO",
     "spiCode":"71154256",
     "strCode":""
  },
  {
     "displayName":"CCLA NOROESTE DE MG CREDIPINHO",
     "name":"COOPERATIVA DE CRÉDITO DE LIVRE ADMISSÃO DO NOROESTE DE MINAS GERAIS LTDA. SICOOB CREDIPINHO",
     "spiCode":"71154876",
     "strCode":""
  },
  {
     "displayName":"CCM PROF SAÚDE ARQ SETE LAGOAS",
     "name":"COOPERATIVA DE CRÉDITO MÚTUO DOS PROFISSIONAIS DE NÍVEL SUPERIOR DA SAÚDE, ARQUITETOS E URBANISTAS DE SETE LAGOAS E REGIÃO LTDA - UNICRED SETE LAGOAS",
     "spiCode":"71163315",
     "strCode":""
  },
  {
     "displayName":"CCR IRAI - SICOOB CREDIMIL",
     "name":"COOPERATIVA DE CRÉDITO RURAL DE IRAÍ DE MINAS LTDA -SICOOB CREDIMIL",
     "spiCode":"71207740",
     "strCode":""
  },
  {
     "displayName":"CCLA ARCOS",
     "name":"COOPERATIVA DE CRÉDITO DE LIVRE ADMISSÃO DE ARCOS LTDA. - SICOOB ARCOMCREDI",
     "spiCode":"71230338",
     "strCode":""
  },
  {
     "displayName":"CC CREDIBELO LTDA. - SICOOB CREDIBELO",
     "name":"COOPERATIVA DE CRÉDITO CREDIBELO LTDA. - SICOOB CREDIBELO",
     "spiCode":"71237184",
     "strCode":""
  },
  {
     "displayName":"CC CENTRO SUL MINEIRO",
     "name":"COOPERATIVA DE CRÉDITO DO CENTRO SUL MINEIRO LTDA. - SICOOB CENTRO SUL MINEIRO",
     "spiCode":"71238232",
     "strCode":""
  },
  {
     "displayName":"COOPERATIVA DE CRÉDITO CREDIJEQUITINHONHA LTDA.",
     "name":"COOPERATIVA DE CRÉDITO CREDIJEQUITINHONHA LTDA. – SICOOB CREDIJEQUITINHONHA",
     "spiCode":"71243034",
     "strCode":""
  },
  {
     "displayName":"CCLA REGIÃO DO CAPARAÓ",
     "name":"COOPERATIVA DE CRÉDITO DE LIVRE ADMISSÃO DA REGIÃO DO CAPARAÓ LTDA. - SICOOB SAÚDE",
     "spiCode":"71261184",
     "strCode":""
  },
  {
     "displayName":"CCLA OESTE MINEIRO LTDA-SICOOB",
     "name":"COOPERATIVA DE CRÉDITO DE LIVRE ADMISSÃO DO OESTE MINEIRO LTDA. - SICOOB CREDICOPA",
     "spiCode":"71297899",
     "strCode":""
  },
  {
     "displayName":"SICOOB COCRED CC",
     "name":"SICOOB COCRED COOPERATIVA DE CRÉDITO",
     "spiCode":"71328769",
     "strCode":""
  },
  {
     "displayName":"CECM SERV PUBL MUN UBERABA",
     "name":"COOPERATIVA DE ECONOMIA E CRÉDITO MÚTUO DOS SERVIDORES PÚBLICOS FEDERAIS DO MUNICÍPIO DE UBERABA E SUAS FUNDAÇÕES - SICOOB - CREDIMED LTDA.",
     "spiCode":"71336432",
     "strCode":""
  },
  {
     "displayName":"CCLA ARAXÁ LTDA - SICOOB COOCREZ",
     "name":"COOPERATIVA DE CRÉDITO DE LIVRE ADMISSÃO DE ARAXÁ LTDA - SICOOB COOCREZ",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"CC MONTECREDI LTDA.",
     "name":"COOPERATIVA DE CRÉDITO MONTECREDI LTDA. – SICOOB MONTECREDI",
     "spiCode":"71392047",
     "strCode":""
  },
  {
     "displayName":"CC MED, TEC E DEMAIS PROF SAUDE DO SUL DE MINAS",
     "name":"COOPERATIVA DE CRÉDITO DOS MÉDICOS, TÉCNICOS E DEMAIS PROFISSIONAIS DA SAÚDE DO SUL DE MINAS LTDA., UNICRED SUL DE MINAS",
     "spiCode":"71418784",
     "strCode":""
  },
  {
     "displayName":"CCLA DA REGIÃO DE FRUTAL",
     "name":"COOPERATIVA DE CRÉDITO DE LIVRE ADMISSÃO DA REGIÃO DE FRUTAL LTDA. SICOOB FRUTAL",
     "spiCode":"71419600",
     "strCode":""
  },
  {
     "displayName":"CC PROF SAUDE TRIANGULO SUL MG",
     "name":"COOPERATIVA DE CRÉDITO DOS PROFISSIONAIS DE SAÚDE DO TRIÂNGULO MINEIRO E SUL DE MINAS LTDA.",
     "spiCode":"71432926",
     "strCode":""
  },
  {
     "displayName":"CCR ALTEROSA",
     "name":"COOPERATIVA DE CRÉDITO RURAL DE ALTEROSA LTDA - SICOOB COOPEROSA",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"CCLA PEDRO LEOPOLDO",
     "name":"COOPERATIVA DE CRÉDITO DE LIVRE ADMISSÃO DE PEDRO LEOPOLDO LTDA - SICOOB CREDIPEL",
     "spiCode":"71441406",
     "strCode":""
  },
  {
     "displayName":"CECM MÉD JUIZ DE FORA",
     "name":"COOPERATIVA DE ECONOMIA E CRÉDITO MÚTUO DOS MÉDICOS E DEMAIS PROFISSIONAIS DA ÁREA DE SAÚDE DE NÍVEL SUPERIOR DE JUIZ DE FORA LTDA. - UNICRED JUIZ DE FORA",
     "spiCode":"71479653",
     "strCode":""
  },
  {
     "displayName":"CC ITAPAGIPE",
     "name":"COOPERATIVA DE CREDITO DE ITAPAGIPE LTDA - SICOOB ITAPAGIPE",
     "spiCode":"71506513",
     "strCode":""
  },
  {
     "displayName":"SICOOB UNIMAIS MANTIQUEIRA CCLA",
     "name":"SICOOB UNIMAIS MANTIQUEIRA - COOPERATIVA DE CRÉDITO DE LIVRE ADMISSÃO",
     "spiCode":"71698674",
     "strCode":""
  },
  {
     "displayName":"CECM MÉD UNICRED SP",
     "name":"COOPERATIVA DE ECONOMIA E CREDITO MUTUO DOS MEDICOS E DEMAIS PROFISSIONAIS DA AREA DE SAUDE DO ESTADO DE SAO PAULO LTDA UNICRED DO ESTADO DE SAO PAULO",
     "spiCode":"71884498",
     "strCode":""
  },
  {
     "displayName":"CC POUP E INV DO RIO DE JANEIRO - SICREDI RIO RJ",
     "name":"COOPERATIVA DE CREDITO, POUPANCA E INVESTIMENTO DO RIO DE JANEIRO - SICREDI RIO RJ",
     "spiCode":"72128440",
     "strCode":""
  },
  {
     "displayName":"SICREDI CEARÁ CENTRO NORTE",
     "name":"SICREDI CEARÁ CENTRO NORTE - COOPERATIVA DE CRÉDITO DA REGIÃO CENTRO NORTE DO CEARÁ",
     "spiCode":"72257793",
     "strCode":""
  },
  {
     "displayName":"CCCM SICOOB UNIMAIS",
     "name":"COOPERATIVA CENTRAL DE ECONOMIA E CRÉDITO MÚTUO - SICOOB UNIMAIS",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"CCM SERV MIN EDUC SÃO PAULO",
     "name":"COOPERATIVA DE CRÉDITO MÚTUO DOS SERVIDORES DO MINISTERIO DA EDUCAÇÃO EM SÃO PAULO - COOPEMESP",
     "spiCode":"73092827",
     "strCode":""
  },
  {
     "displayName":"CC POUP INV DE ARARAQUARA E REGIAO - SICREDI CENTRO NORTE SP",
     "name":"COOPERATIVA DE CRÉDITO, POUPANÇA E INVESTIMENTO DE ARARAQUARA E REGIÃO - SICREDI CENTRO NORTE SP",
     "spiCode":"73113243",
     "strCode":""
  },
  {
     "displayName":"CCE INT SOL DE QUILOMBO",
     "name":"COOPERATIVA DE CREDITO E ECONOMIA COM INTERAÇÃO SOLIDARIA DE QUILOMBO - CRESOL QUILOMBO",
     "spiCode":"73254757",
     "strCode":""
  },
  {
     "displayName":"CECM MÉDICOS PROF SAÚDE EMPRESÁRIOS CRUZ ALTA",
     "name":"COOPERATIVA DE ECONOMIA E CRÉDITO MÚTUO DOS MÉDICOS, DEMAIS PROFISSIONAIS DA SAÚDE E EMPRESÁRIOS DE CRUZ ALTA LTDA - SICOOB CRUZ ALTA",
     "spiCode":"73326449",
     "strCode":""
  },
  {
     "displayName":"SOC CC SICOOB COOPERE",
     "name":"SOCIEDADE COOPERATIVA DE CRÉDITO COOPERE LTDA. - SICOOB COOPERE",
     "spiCode":"73398646",
     "strCode":""
  },
  {
     "displayName":"CCLA RUBIATABA REGIAO",
     "name":"COOPERATIVA DE CREDITO DE LIVRE ADMISSAO DE RUBIATABA E REGIAO LTDA",
     "spiCode":"73422792",
     "strCode":""
  },
  {
     "displayName":"CC UNICRED VALE EUROPEU SANTA CATARINA LTDA",
     "name":"COOPERATIVA DE CRÉDITO UNICRED VALE EUROPEU SANTA CATARINA LTDA - UNICRED VALE EUROPEU",
     "spiCode":"73443863",
     "strCode":""
  },
  {
     "displayName":"UNIPRIME CENTRO-OESTE DO BRASIL CC",
     "name":"UNIPRIME CENTRO-OESTE DO BRASIL - COOPERATIVA DE CRÉDITO",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"CECM UNICRED INTEGRAÇÃO",
     "name":"COOPERATIVA DE ECONOMIA E CRÉDITO MÚTUO UNICRED INTEGRAÇÃO LTDA.",
     "spiCode":"73750424",
     "strCode":""
  },
  {
     "displayName":"CC SICOOB COOPERBOM",
     "name":"COOPERATIVA DE CRÉDITO SICOOB COOPERBOM LTDA",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"CC UNICRED VALOR CAPITAL LTDA – UNICRED VALOR CAPITAL",
     "name":"COOPERATIVA DE CRÉDITO UNICRED VALOR CAPITAL LTDA – UNICRED VALOR CAPITAL",
     "spiCode":"74064502",
     "strCode":""
  },
  {
     "displayName":"CC UNICRED UNIÃO LTDA - UNICRED UNIÃO",
     "name":"COOPERATIVA DE CRÉDITO UNICRED UNIÃO LTDA - UNICRED UNIÃO",
     "spiCode":"74114042",
     "strCode":""
  },
  {
     "displayName":"CC SICOOB UNISP",
     "name":"SICOOB UNISP - COOPERATIVA DE CRÉDITO",
     "spiCode":"74220948",
     "strCode":""
  },
  {
     "displayName":"CC POUP INV PROGRESSO PR/SP",
     "name":"COOPERATIVA DE CRÉDITO, POUPANÇA E INVESTIMENTO PROGRESSO - SICREDI PROGRESSO PR/SP",
     "spiCode":"76059997",
     "strCode":""
  },
  {
     "displayName":"CC POUP E INV TERRA DOS PINHEIRAIS DO PR E NOROESTE PAULISTA",
     "name":"COOPERATIVA DE CRÉDITO, POUPANÇA E INVESTIMENTO TERRA DOS PINHEIRAIS DO PARANÁ E NOROESTE PAULISTA - SICREDI PLANALTO DAS ÁGUAS PR/SP",
     "spiCode":"77984870",
     "strCode":""
  },
  {
     "displayName":"CC POUP INV VANGUARDA CATARATAS DO IGUAÇU E VALE DO PARAÍBA",
     "name":"COOPERATIVA DE CRÉDITO, POUPANÇA E INVESTIMENTO VANGUARDA DA REGIÃO DAS CATARATAS DO IGUAÇU E VALE DO PARAÍBA - SICREDI VANGUARDA PR/SP/RJ",
     "spiCode":"78414067",
     "strCode":""
  },
  {
     "displayName":"CCLA ITAPIRANGA",
     "name":"COOPERATIVA DE CRÉDITO DE LIVRE ADMISSÃO DE ASSOCIADOS ITAPIRANGA - SICOOB CREDITAPIRANGA SC/RS",
     "spiCode":"78483310",
     "strCode":""
  },
  {
     "displayName":"CCLAA OESTE CATARINENSE",
     "name":"COOPERATIVA DE CRÉDITO DE LIVRE ADMISSÃO DE ASSOCIADOS OESTE CATARINENSE",
     "spiCode":"78825023",
     "strCode":""
  },
  {
     "displayName":"CCLA MAXI ALFA",
     "name":"COOPERATIVA DE CRÉDITO MAXI ALFA DE LIVRE ADMISSÃO DE ASSOCIADOS - SICOOB MAXICRÉDITO",
     "spiCode":"78825270",
     "strCode":""
  },
  {
     "displayName":"CCLA DO VALE DO CANOINHAS",
     "name":"COOPERATIVA DE CRÉDITO DE LIVRE ADMISSÃO DE ASSOCIADOS DO VALE DO CANOINHAS - SICOOB CREDICANOINHAS/SC",
     "spiCode":"78834975",
     "strCode":""
  },
  {
     "displayName":"CCLAA ALTO URUGUAI CATARINENSE",
     "name":"COOPERATIVA DE CRÉDITO DE LIVRE ADMISSÃO DE ASSOCIADOS DO ALTO URUGUAI CATARINENSE – SICOOB CREDIAUC",
     "spiCode":"78840071",
     "strCode":""
  },
  {
     "displayName":"CCLAA AURIVERDE–SICOOB CREDIAL",
     "name":"COOPERATIVA DE CRÉDITO DE LIVRE ADMISSÃO DE ASSOCIADOS AURIVERDE SICOOB CREDIAL/SC",
     "spiCode":"78858107",
     "strCode":""
  },
  {
     "displayName":"CCLA CAMPOS NOVOS",
     "name":"COOPERATIVA DE CRÉDITO DE LIVRE ADMISSÃO DE ASSOCIADOS CAMPOS NOVOS - SICOOB CAMPOS NOVOS",
     "spiCode":"78862083",
     "strCode":""
  },
  {
     "displayName":"CCLA VALE DO RIO DO PEIXE",
     "name":"COOPERATIVA DE CRÉDITO DE LIVRE ADMISSÃO DE ASSOCIADOS VALE DO RIO DO PEIXE - SICOOB CREDIRIO SC",
     "spiCode":"78865995",
     "strCode":""
  },
  {
     "displayName":"CC POUP INV CENTRO SUL",
     "name":"COOPERATIVA DE CRÉDITO, POUPANÇA E INVESTIMENTO CENTRO SUL - SICREDI CENTRO SUL PR/SC/RJ",
     "spiCode":"78907607",
     "strCode":""
  },
  {
     "displayName":"CCPI ALIANÇA",
     "name":"COOPERATIVA DE CREDITO, POUPANÇA E INVESTIMENTO ALIANÇA - SICREDI ALIANÇA PR/SP",
     "spiCode":"79052122",
     "strCode":""
  },
  {
     "displayName":"CC POUP INV DO NORTE DO PARANÁ E SUL DE SÃO PAULO",
     "name":"COOPERATIVA DE CRÉDITO, POUPANÇA E INVESTIMENTO DO NORTE DO PARANÁ E SUL DE SÃO PAULO - SICREDI NORTE SUL PR/SP",
     "spiCode":"79063574",
     "strCode":""
  },
  {
     "displayName":"CC POUP INV PARANAPANEMA PR/SP",
     "name":"COOPERATIVA DE CRÉDITO, POUPANÇA E INVESTIMENTO PARANAPANEMA PR/SP - SICREDI PARANAPANEMA PR/SP",
     "spiCode":"79086997",
     "strCode":""
  },
  {
     "displayName":"CC POUP E INV UNIÃO PARANÁ/SÃO PAULO",
     "name":"COOPERATIVA DE CRÉDITO, POUPANÇA E INVESTIMENTO UNIÃO PARANÁ/SÃO PAULO - SICREDI UNIÃO PR/SP",
     "spiCode":"79342069",
     "strCode":""
  },
  {
     "displayName":"CCLA AGROEMPRESARIAL - SICREDI AGROEMPRESARIAL PR/SP",
     "name":"COOPERATIVA DE CRÉDITO E INVESTIMENTO DE LIVRE ADMISSÃO AGROEMPRESARIAL - SICREDI AGROEMPRESARIAL PR/SP",
     "spiCode":"79457883",
     "strCode":""
  },
  {
     "displayName":"CCC SICOOB CENTRAL SC/RS",
     "name":"COOPERATIVA CENTRAL DE CRÉDITO DE SANTA CATARINA E RIO GRANDE DO SUL - SICOOB CENTRAL SC/RS",
     "spiCode":"80160260",
     "strCode":""
  },
  {
     "displayName":"CC ALTO VALE DO ITAJAÍ",
     "name":"COOPERATIVA DE CRÉDITO ALTO VALE DO ITAJAÍ - SICOOB ALTO VALE",
     "spiCode":"80959612",
     "strCode":""
  },
  {
     "displayName":"CCLA MEIO OESTE CATARINENSE",
     "name":"COOPERATIVA DE CRÉDITO DE LIVRE ADMISSÃO DE ASSOCIADOS DO MEIO OESTE CATARINENSE - SICOOB CREDIMOC SC",
     "spiCode":"80959638",
     "strCode":""
  },
  {
     "displayName":"CC DO NORTE CATARINENSE E SUL PARANAENSE",
     "name":"COOPERATIVA DE CRÉDITO DO NORTE CATARINENSE E SUL PARANAENSE - SICOOB CREDINORTE",
     "spiCode":"81011686",
     "strCode":""
  },
  {
     "displayName":"CCLAA ITAIPU SICOOB CREDITAIPU",
     "name":"COOPERATIVA DE CREDITO DE LIVRE ADMISSÃO DE ASSOCIADOS ITAIPU SICOOB CREDITAIPU",
     "spiCode":"81014060",
     "strCode":""
  },
  {
     "displayName":"CCLA VALE DO VINHO",
     "name":"COOPERATIVA DE CRÉDITO DE LIVRE ADMISSÃO DE ASSOCIADOS DO VALE DO VINHO - SICOOB VALE DO VINHO",
     "spiCode":"81016131",
     "strCode":""
  },
  {
     "displayName":"CC POUP INV INTEGRAÇÃO",
     "name":"COOPERATIVA DE CRÉDITO, POUPANÇA E INVESTIMENTO INTEGRAÇÃO - SICREDI INTEGRAÇÃO PR/SC",
     "spiCode":"81054686",
     "strCode":""
  },
  {
     "displayName":"CC POUP E INV VALE DO PIQUIRI ABCD",
     "name":"COOPERATIVA DE CRÉDITO, POUPANÇA E INVESTIMENTO VALE DO PIQUIRI ABCD - SICREDI VALE DO PIQUIRI ABCD PR/SP",
     "spiCode":"81099491",
     "strCode":""
  },
  {
     "displayName":"CC POUP E INV GRANDES LAGOS DO PARANÁ E LITORAL PAULISTA",
     "name":"COOPERATIVA DE CRÉDITO, POUPANÇA E INVESTIMENTO GRANDES LAGOS DO PARANÁ E LITORAL PAULISTA – SICREDI GRANDES LAGOS PR/SP",
     "spiCode":"81115149",
     "strCode":""
  },
  {
     "displayName":"CC POUP INV NOSSA TERRA - SICREDI NOSSA TERRA PR/SP",
     "name":"COOPERATIVA DE CRÉDITO, POUPANÇA E INVESTIMENTO NOSSA TERRA - SICREDI NOSSA TERRA PR/SP",
     "spiCode":"81192106",
     "strCode":""
  },
  {
     "displayName":"CCPI RIO PARANA",
     "name":"COOPERATIVA DE CREDITO, POUPANÇA E INVESTIMENTO RIO PARANA - SICREDI RIO PARANA PR/SP",
     "spiCode":"81206039",
     "strCode":""
  },
  {
     "displayName":"CC ORIGINAL - SICOOB ORIGINAL",
     "name":"COOPERATIVA DE CRÉDITO ORIGINAL - SICOOB ORIGINAL",
     "spiCode":"81292278",
     "strCode":""
  },
  {
     "displayName":"CCLA SUL CATARINENSE",
     "name":"COOPERATIVA DE CRÉDITO DE LIVRE ADMISSÃO DE ASSOCIADOS SUL CATARINENSE - SICOOB CREDISULCA SC",
     "spiCode":"81367880",
     "strCode":""
  },
  {
     "displayName":"CC POUP INV CAMPOS GERAIS",
     "name":"COOPERATIVA DE CRÉDITO, POUPANÇA E INVESTIMENTO CAMPOS GERAIS - SICREDI CAMPOS GERAIS PR/SP",
     "spiCode":"81466286",
     "strCode":""
  },
  {
     "displayName":"CCLAA SAO MIGUEL DO OESTE",
     "name":"COOPERATIVA DE CRÉDITO DE LIVRE ADMISSÃO DE ASSOCIADOS SÃO MIGUEL DO OESTE - SICOOB SÃO MIGUEL SC",
     "spiCode":"81607046",
     "strCode":""
  },
  {
     "displayName":"CC POUP E INV VALOR SUST - SICREDI VALOR SUSTENTÁVEL PR/SP",
     "name":"COOPERATIVA DE CRÉDITO, POUPANÇA E INVESTIMENTO VALOR SUSTENTÁVEL - SICREDI VALOR SUSTENTÁVEL PR/SP",
     "spiCode":"81706616",
     "strCode":""
  },
  {
     "displayName":"CCPI PARQUE DAS ARAUCARIAS PR/SC/SP",
     "name":"COOPERATIVA DE CREDITO, POUPANÇA E INVESTIMENTO PARQUE DAS ARAUCARIAS - SICREDI PARQUE DAS ARAUCARIAS PR/SC/SP",
     "spiCode":"82065285",
     "strCode":""
  },
  {
     "displayName":"CC DO VALE EUROPEU - SICOOB EURO VALE",
     "name":"COOPERATIVA DE CRÉDITO DO VALE EUROPEU - SICOOB EURO VALE",
     "spiCode":"82133182",
     "strCode":""
  },
  {
     "displayName":"CC POUP INV FRON PR, SC E SP - SICREDI FRONTEIRAS PR/SC/SP",
     "name":"COOPERATIVA DE CRÉDITO, POUPANÇA E INVESTIMENTO FRONTEIRAS DO PARANÁ, SANTA CATARINA E SÃO PAULO - SICREDI FRONTEIRAS PR/SC/SP",
     "spiCode":"82527557",
     "strCode":""
  },
  {
     "displayName":"CC VALE DO ITAJAÍ",
     "name":"COOPERATIVA DE CREDITO VALE DO ITAJAI - VIACREDI",
     "spiCode":"82639451",
     "strCode":""
  },
  {
     "displayName":"SICREDI NORTE - COOPERATIVA DE CRÉDITO",
     "name":"SICREDI NORTE - COOPERATIVA DE CRÉDITO",
     "spiCode":"83315408",
     "strCode":""
  },
  {
     "displayName":"CCLA DO ESTADO DO PARÁ - SICOOB COOESA",
     "name":"COOPERATIVA DE CRÉDITO DE LIVRE ADMISSÃO DO ESTADO DO PARÁ - SICOOB COOESA",
     "spiCode":"83836114",
     "strCode":""
  },
  {
     "displayName":"CC INTEGRANTES MIN PUBL E PODER JUDIC AP E CE E CCLA PA",
     "name":"COOPERATIVA DE CRÉDITO DOS INTEGRANTES DO MINISTÉRIO PÚBLICO E DO PODER JUDICIÁRIO NOS ESTADOS DO AMAPÁ E CEARÁ E LIVRE ADMISSÃO NO ESTADO DO PARÁ - SICOOB COIMPPA",
     "spiCode":"84156470",
     "strCode":""
  },
  {
     "displayName":"CC POUP INV IGUAÇU - SICREDI IGUAÇU PR/SC/SP",
     "name":"COOPERATIVA DE CREDITO, POUPANCA E INVESTIMENTO IGUACU - SICREDI IGUACU PR/SC/SP",
     "spiCode":"84974278",
     "strCode":""
  },
  {
     "displayName":"CC LITORÂNEA",
     "name":"COOPERATIVA DE CRÉDITO LITORÂNEA",
     "spiCode":"85291086",
     "strCode":""
  },
  {
     "displayName":"CC INV SERVI FIN SICOOB UNI SUDESTE",
     "name":"COOPERATIVA DE CRÉDITO, INVESTIMENTO E SERVIÇOS FINANCEIROS SICOOB UNI SUDESTE",
     "spiCode":"86389236",
     "strCode":""
  },
  {
     "displayName":"CC NOROESTE DE MINAS",
     "name":"COOPERATIVA DE CRÉDITO DO NOROESTE DE MINAS LTDA. - SICOOB NOROESTE DE MINAS",
     "spiCode":"86564051",
     "strCode":""
  },
  {
     "displayName":"SICOOB CREDILEITE",
     "name":"COOPERATIVA DE CRÉDITO DA REGIÃO DO VALE DO RIO GRANDE LTDA. - SICOOB CREDILEITE",
     "spiCode":"86585049",
     "strCode":""
  },
  {
     "displayName":"CCLA DE CAÇADOR",
     "name":"COOPERATIVA DE CRÉDITO DE LIVRE ADMISSÃO DE ASSOCIADOS DE CAÇADOR SICOOB CAÇADOR SC",
     "spiCode":"86791837",
     "strCode":""
  },
  {
     "displayName":"UNICRED SERRA MAR LTDA.",
     "name":"COOPERATIVA DE CRÉDITO MÚTUO SERRA MAR LTDA. - UNICRED SERRA MAR",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"CCLA DO VALE",
     "name":"COOPERATIVA DE CRÉDITO DE LIVRE ADMISSÃO DO VALE - SICOOB CREDIVALE/SC",
     "spiCode":"86829827",
     "strCode":""
  },
  {
     "displayName":"SICREDI CARIRI",
     "name":"SICREDI CARIRI - COOPERATIVA DE CRÉDITO DA REGIÃO DO CARIRI",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"CCPI CENTRO SERRA",
     "name":"COOPERATIVA DE CRÉDITO, POUPANÇA E INVESTIMENTO CENTRO SERRA - SICREDI CENTRO SERRA RS",
     "spiCode":"87067757",
     "strCode":""
  },
  {
     "displayName":"CC INT ROTA DAS TERRAS- SICREDI INT ROTA DAS TERRAS RS MG",
     "name":"COOPERATIVA DE CRÉDITO INTEGRAÇÃO ROTA DAS TERRAS – SICREDI INTEGRAÇÃO ROTA DAS TERRAS RS/MG",
     "spiCode":"87510475",
     "strCode":""
  },
  {
     "displayName":"CC POUP E INV PAMPA GAÚCHO - SICREDI PAMPA GAÚCHO",
     "name":"COOPERATIVA DE CRÉDITO, POUPANÇA E INVESTIMENTO PAMPA GAÚCHO - SICREDI PAMPA GAÚCHO",
     "spiCode":"87733077",
     "strCode":""
  },
  {
     "displayName":"CC POUP E INV ALTO URUGUAI - SICREDI ALTO URUGUAI RS/SC/MG",
     "name":"COOPERATIVA DE CRÉDITO, POUPANÇA E INVESTIMENTO ALTO URUGUAI - SICREDI ALTO URUGUAI RS/SC/MG",
     "spiCode":"87733770",
     "strCode":""
  },
  {
     "displayName":"COOPERATIVA DE CRÉDITO COOPERAÇÃO RS/SC – SICREDI COOPERAÇÃO",
     "name":"COOPERATIVA DE CRÉDITO COOPERAÇÃO RS/SC – SICREDI COOPERAÇÃO RS/SC",
     "spiCode":"87779625",
     "strCode":""
  },
  {
     "displayName":"CC POUP E INV UNIAO DE EST DO RS SC E MG SICREDI UNIESTADOS",
     "name":"COOPERATIVA DE CRÉDITO, POUPANÇA E INVESTIMENTO UNIÃO DE ESTADOS RIO GRANDE DO SUL, SANTA CATARINA E MINAS GERAIS - SICREDI UNIESTADOS",
     "spiCode":"87780268",
     "strCode":""
  },
  {
     "displayName":"CC POUP E INV SICREDI VALE DO JAGUARI E ZONA DA MATA RS/MG",
     "name":"COOPERATIVA DE CRÉDITO, POUPANÇA E INVESTIMENTO VALE DO JAGUARI E ZONA DA MATA – SICREDI VALE DO JAGUARI E ZONA DA MATA RS/MG",
     "spiCode":"87780284",
     "strCode":""
  },
  {
     "displayName":"CCPI INT DE ESTADOS DO RIO GRANDE DO SUL E SANTA CATARINA",
     "name":"COOPERATIVA DE CRÉDITO, POUPANÇA E INVESTIMENTO INTEGRAÇÃO DE ESTADOS DO RIO GRANDE DO SUL E SANTA CATARINA - SICREDI INTEGRAÇÃO DE ESTADOS RS/SC",
     "spiCode":"87781530",
     "strCode":""
  },
  {
     "displayName":"CC POUP E INV SUL MINAS DO RS E MG - SICREDI SUL MINAS RS/MG",
     "name":"COOPERATIVA DE CRÉDITO, POUPANÇA E INVESTIMENTO SUL MINAS DO RIO GRANDE DO SUL E MINAS GERAIS – SICREDI SUL MINAS RS/MG.",
     "spiCode":"87784088",
     "strCode":""
  },
  {
     "displayName":"C.C ALIANÇA RS/SC - SICREDI ALIANÇA RS/SC",
     "name":"COOPERATIVA DE CRÉDITO ALIANÇA RS/SC - SICREDI ALIANÇA RS/SC",
     "spiCode":"87795639",
     "strCode":""
  },
  {
     "displayName":"CC POUP E INV OURO BRANCO – SICREDI OURO BRANCO RS",
     "name":"COOPERATIVA DE CRÉDITO, POUPANÇA E INVESTIMENTO OURO BRANCO – SICREDI OURO BRANCO RS",
     "spiCode":"87853206",
     "strCode":""
  },
  {
     "displayName":"CC POUP E INV DE ESPUMOSO – SICREDI ESPUMOSO RS/MG",
     "name":"COOPERATIVA DE CRÉDITO, POUPANÇA E INVESTIMENTO DE ESPUMOSO – SICREDI ESPUMOSO RS/MG",
     "spiCode":"87900411",
     "strCode":""
  },
  {
     "displayName":"CC POUP E INV BOTUCARAÍ - SICREDI BOTUCARAÍ RS/MG",
     "name":"COOPERATIVA DE CRÉDITO, POUPANÇA E INVESTIMENTO BOTUCARAÍ – SICREDI BOTUCARAÍ RS/MG",
     "spiCode":"87900601",
     "strCode":""
  },
  {
     "displayName":"CC POUP E INV PLANALTO - SICREDI PLANALTO RS/MG",
     "name":"COOPERATIVA DE CRÉDITO, POUPANÇA E INVESTIMENTO PLANALTO – SICREDI PLANALTO RS/MG",
     "spiCode":"88038260",
     "strCode":""
  },
  {
     "displayName":"CC POUP E INV RAIZES - SICREDI RAIZES RS/SC/MG",
     "name":"COOPERATIVA DE CRÉDITO, POUPANÇA E INVESTIMENTO RAÍZES – SICREDI RAÍZES RS/SC/MG",
     "spiCode":"88099247",
     "strCode":""
  },
  {
     "displayName":"CC CENTRO LESTE - SICREDI CENTRO LESTE RS",
     "name":"COOPERATIVA DE CRÉDITO CENTRO LESTE - SICREDI CENTRO LESTE RS",
     "spiCode":"88471024",
     "strCode":""
  },
  {
     "displayName":"CC DA FRONTEIRA SUL – SICREDI FRONTEIRA SUL RS",
     "name":"COOPERATIVA DE CRÉDITO DA FRONTEIRA SUL – SICREDI FRONTEIRA SUL RS",
     "spiCode":"88530142",
     "strCode":""
  },
  {
     "displayName":"SICREDI UNIAO RS/ES",
     "name":"COOPERATIVA DE CRéDITO, POUPANçA E INVESTIMENTO UNIãO - SICREDI UNIAO RS/ES",
     "spiCode":"88894548",
     "strCode":""
  },
  {
     "displayName":"CC POUP E INV NOROESTE RS – SICREDI NOROESTE RS",
     "name":"COOPERATIVA DE CRÉDITO, POUPANÇA E INVESTIMENTO NOROESTE RS – SICREDI NOROESTE RS",
     "spiCode":"89049738",
     "strCode":""
  },
  {
     "displayName":"CCPI DA REGIÃO DOS VALES",
     "name":"COOPERATIVA DE CRÉDITO, POUPANÇA E INVESTIMENTO DA REGIÃO DOS VALES - SICREDI REGIÃO DOS VALES RS",
     "spiCode":"89126130",
     "strCode":""
  },
  {
     "displayName":"CECM DOS EMPR DAS EMP RANDON",
     "name":"COOPERATIVA DE ECONOMIA E CREDITO MUTUO DOS EMPREGADOS DAS EMPRESAS RANDON",
     "spiCode":"89280960",
     "strCode":""
  },
  {
     "displayName":"CCPI DA REG DA PRODUCAO SICREDI REGIAO DA PRODUCAO RS/SC/MG",
     "name":"COOPERATIVA DE CRÉDITO, POUPANÇA E INVESTIMENTO DA REGIÃO DA PRODUÇÃO - SICREDI REGIÃO DA PRODUÇÃO RS/SC/MG",
     "spiCode":"89468565",
     "strCode":""
  },
  {
     "displayName":"CC POUP E INV DE IBIRAIARAS - SICREDI IBIRAIARAS RS/MG",
     "name":"COOPERATIVA DE CRÉDITO, POUPANÇA E INVESTIMENTO DE IBIRAIARAS – SICREDI IBIRAIARAS RS/MG",
     "spiCode":"89990501",
     "strCode":""
  },
  {
     "displayName":"SICREDI INTERESTADOS RS/ES",
     "name":"COOPERATIVA DE CRéDITO, POUPANçA E INVESTIMENTO INTERESTADOS - SICREDI INTERESTADOS RS/ES",
     "spiCode":"90497256",
     "strCode":""
  },
  {
     "displayName":"SICREDI SERRANA RS",
     "name":"COOPERATIVA DE CRÉDITO SICREDI SERRANA RS/ES",
     "spiCode":"90608712",
     "strCode":""
  },
  {
     "displayName":"CC POUP E INV REG DAS CULTURAS - SICREDI DAS CULTURAS RS/MG",
     "name":"COOPERATIVA DE CRÉDITO, POUPANÇA E INVESTIMENTO REGIÕES DAS CULTURAS – SICREDI DAS CULTURAS RS/MG",
     "spiCode":"90729369",
     "strCode":""
  },
  {
     "displayName":"CC POUP E INV DE LAJEADO - SICREDI INTEGRAÇÃO RS/MG",
     "name":"COOPERATIVA DE CRÉDITO, POUPANÇA E INVESTIMENTO DE LAJEADO - SICREDI INTEGRAÇÃO RS/MG",
     "spiCode":"91159764",
     "strCode":""
  },
  {
     "displayName":"CC POUPANÇA E INVESTIMENTO SICREDI PIONEIRA RS",
     "name":"COOPERATIVA DE CRÉDITO, POUPANÇA E INVESTIMENTO SICREDI PIONEIRA RS - SICREDI PIONEIRA RS",
     "spiCode":"91586982",
     "strCode":""
  },
  {
     "displayName":"CCPI DA REGIAO ALTOS DA SERRA - SICREDI ALTOS DA SERRA RS/SC",
     "name":"COOPERATIVA DE CRÉDITO, POUPANÇA E INVESTIMENTO DA REGIÃO ALTOS DA SERRA - SICREDI ALTOS DA SERRA RS/SC",
     "spiCode":"92555150",
     "strCode":""
  },
  {
     "displayName":"CCPI SUL RIOGRANDENSE",
     "name":"COOPERATIVA DE CRÉDITO, POUPANÇA E INVESTIMENTO SUL RIOGRANDENSE - SICREDI UNIÃO METROPOLITANA RS",
     "spiCode":"92796564",
     "strCode":""
  },
  {
     "displayName":"BANRISUL CARTOES S A",
     "name":"BANRISUL CARTOES S.A.",
     "spiCode":"92934215",
     "strCode":""
  },
  {
     "displayName":"CECM DO VALE DAS ANTAS",
     "name":"COOPERATIVA DE ECONOMIA E CRÉDITO MÚTUO DO VALE DAS ANTAS LTDA. - UNICRED VALE DAS ANTAS",
     "spiCode":"94243839",
     "strCode":""
  },
  {
     "displayName":"CECM MÉD DE PORTO ALEGRE",
     "name":"COOPERATIVA DE ECONOMIA E CREDITO MUTUO DOS MEDICOS DE PORTO ALEGRE LTDA-UNICRED PORTO ALEGRE",
     "spiCode":"94433109",
     "strCode":""
  },
  {
     "displayName":"UNICRED ELEVA",
     "name":"COOPERATIVA DE ECONOMIA E CRÉDITO MÚTUO UNICRED ELEVA LTDA.",
     "spiCode":"95163002",
     "strCode":""
  },
  {
     "displayName":"CC POUP E INV ENCOSTA SUPERIOR DO NORDESTE RS",
     "name":"COOPERATIVA DE CRÉDITO, POUPANÇA E INVESTIMENTO ENCOSTA SUPERIOR DO NORDESTE RS – SICREDI NORDESTE RS",
     "spiCode":"95213211",
     "strCode":""
  },
  {
     "displayName":"CCPI DO VALE DO RIO PARDO",
     "name":"COOPERATIVA DE CRÉDITO, POUPANÇA E INVESTIMENTO DO VALE DO RIO PARDO - SICREDI VALE DO RIO PARDO RS",
     "spiCode":"95424891",
     "strCode":""
  },
  {
     "displayName":"CCPI DAS REG CENTRO DO RS E MG - SICREDI REGIAO CENTRO RS/MG",
     "name":"COOPERATIVA DE CRÉDITO, POUPANÇA E INVESTIMENTO DAS REGIÕES CENTRO DO RS E MG – SICREDI REGIÃO CENTRO RS/MG",
     "spiCode":"95594941",
     "strCode":""
  },
  {
     "displayName":"CC SICREDI REGIAO SUL BAHIA",
     "name":"COOPERATIVA DE CRÉDITO SICREDI REGIÃO SUL DA BAHIA -SICREDI REGIÃO SUL DA BAHIA",
     "spiCode":"97489280",
     "strCode":""
  },
  {
     "displayName":"BRB - BCO DE BRASILIA S.A.",
     "name":"BRB - BANCO DE BRASILIA S.A.",
     "spiCode":"00000208",
     "strCode":"70"
  },
  {
     "displayName":"Selic",
     "name":"Banco Central do Brasil - Selic",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"Bacen",
     "name":"Banco Central do Brasil",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"Selic",
     "name":"Banco Central do Brasil - Selic",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"Bacen",
     "name":"Banco Central do Brasil",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"CIP Siloc",
     "name":"Câmara Interbancária de Pagamentos - CIP - LDL",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"CIP Sitraf",
     "name":"Câmara Interbancária de Pagamentos",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"Balcão B3",
     "name":"Sistema do Balcão B3",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"CIP C3",
     "name":"Câmara Interbancária de Pagamentos - CIP C3",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"Câmara B3",
     "name":"Câmara B3",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"Câmbio B3",
     "name":"Câmara de Câmbio B3",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"Selic",
     "name":"Banco Central do Brasil - Selic",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"Bacen",
     "name":"Banco Central do Brasil",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"CIP Siloc",
     "name":"Câmara Interbancária de Pagamentos - CIP - LDL",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"CIP Sitraf",
     "name":"Câmara Interbancária de Pagamentos",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"Balcão B3",
     "name":"Sistema do Balcão B3",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"CIP C3",
     "name":"Câmara Interbancária de Pagamentos - CIP C3",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"Câmara B3",
     "name":"Câmara B3",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"Câmbio B3",
     "name":"Câmara de Câmbio B3",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"Selic",
     "name":"Banco Central do Brasil - Selic",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"Bacen",
     "name":"Banco Central do Brasil",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"CIP Siloc",
     "name":"Câmara Interbancária de Pagamentos - CIP - LDL",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"CIP Sitraf",
     "name":"Câmara Interbancária de Pagamentos",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"MIDWAY S.A. - SCFI",
     "name":"MIDWAY S.A. - CRÉDITO",
     "spiCode":"09464032",
     "strCode":"358"
  },
  {
     "displayName":"Balcão B3",
     "name":"Sistema do Balcão B3",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"CIP C3",
     "name":"Câmara Interbancária de Pagamentos - CIP C3",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"Câmara B3",
     "name":"Câmara B3",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"Câmbio B3",
     "name":"Câmara de Câmbio B3",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"DOURADA CORRETORA",
     "name":"DOURADA CORRETORA DE CÂMBIO LTDA.",
     "spiCode":"",
     "strCode":"311"
  },
  {
     "displayName":"Selic",
     "name":"Banco Central do Brasil - Selic",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"Bacen",
     "name":"Banco Central do Brasil",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"CIP Siloc",
     "name":"Câmara Interbancária de Pagamentos - CIP - LDL",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"CIP Sitraf",
     "name":"Câmara Interbancária de Pagamentos",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"HSCM SCMEPP LTDA.",
     "name":"HSCM - SOCIEDADE DE CRÉDITO AO MICROEMPREENDEDOR E À EMPRESA DE PEQUENO PORTE LT",
     "spiCode":"07693858",
     "strCode":"312"
  },
  {
     "displayName":"Balcão B3",
     "name":"Sistema do Balcão B3",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"CIP C3",
     "name":"Câmara Interbancária de Pagamentos - CIP C3",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"Câmara B3",
     "name":"Câmara B3",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"Câmbio B3",
     "name":"Câmara de Câmbio B3",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"Selic",
     "name":"Banco Central do Brasil - Selic",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"Bacen",
     "name":"Banco Central do Brasil",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"ÍNDIGO INVESTIMENTOS DTVM LTDA.",
     "name":"ÍNDIGO INVESTIMENTOS DISTRIBUIDORA DE TÍTULOS E VALORES MOBILIÁRIOS LTDA.",
     "spiCode":"00329598",
     "strCode":"407"
  },
  {
     "displayName":"CIP Siloc",
     "name":"Câmara Interbancária de Pagamentos - CIP - LDL",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"CIP Sitraf",
     "name":"Câmara Interbancária de Pagamentos",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"VIA CERTA FINANCIADORA S.A. - CFI",
     "name":"Via Certa Financiadora S.A. - Crédito",
     "spiCode":"05192316",
     "strCode":"411"
  },
  {
     "displayName":"PLANNER SCM S.A.",
     "name":"PLANNER SOCIEDADE DE CRÉDITO AO MICROEMPREENDEDOR S.A.",
     "spiCode":"",
     "strCode":"410"
  },
  {
     "displayName":"Balcão B3",
     "name":"Sistema do Balcão B3",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"CIP C3",
     "name":"Câmara Interbancária de Pagamentos - CIP C3",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"Câmara B3",
     "name":"Câmara B3",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"Câmbio B3",
     "name":"Câmara de Câmbio B3",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"MAG PAGAMENTOS",
     "name":"MAG PAGAMENTOS LTDA",
     "spiCode":"21995256",
     "strCode":""
  },
  {
     "displayName":"PHI PAGAMENTOS",
     "name":"PHI SERVICOS DE PAGAMENTO S.A.",
     "spiCode":"31752308",
     "strCode":""
  },
  {
     "displayName":"CECM EMPR DO BANRISUL",
     "name":"COOPERATIVA DE ECONOMIA E CRÉDITO MÚTUO DOS EMPREGADOS DO BANRISUL",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"Selic",
     "name":"Banco Central do Brasil - Selic",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"Bacen",
     "name":"Banco Central do Brasil",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"CIP Siloc",
     "name":"Câmara Interbancária de Pagamentos - CIP - LDL",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"CIP Sitraf",
     "name":"Câmara Interbancária de Pagamentos",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"CC POUP SER FIN CO",
     "name":"COOPERATIVA DE CRÉDITO",
     "spiCode":"",
     "strCode":"400"
  },
  {
     "displayName":"Balcão B3",
     "name":"Sistema do Balcão B3",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"CIP C3",
     "name":"Câmara Interbancária de Pagamentos - CIP C3",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"Câmara B3",
     "name":"Câmara B3",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"Câmbio B3",
     "name":"Câmara de Câmbio B3",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"BCO RNX S.A.",
     "name":"BANCO RNX S.A.",
     "spiCode":"",
     "strCode":"720"
  },
  {
     "displayName":"MAGALU PAGAMENTOS",
     "name":"MAGALU PAGAMENTOS LTDA",
     "spiCode":"17948578",
     "strCode":""
  },
  {
     "displayName":"S3 TECNOLOGIA E SOLUCOES DE PA",
     "name":"S3 TECNOLOGIA E SOLUCOES DE PAGAMENTOS S.A.",
     "spiCode":"31711972",
     "strCode":""
  },
  {
     "displayName":"UZZIPAY",
     "name":"MADEIRA ADMINISTRADORA DE CARTOES S/A",
     "spiCode":"32192325",
     "strCode":""
  },
  {
     "displayName":"CACTVS",
     "name":"CACTVS INSTITUICAO DE PAGAMENTO S.A",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"Selic",
     "name":"Banco Central do Brasil - Selic",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"Bacen",
     "name":"Banco Central do Brasil",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"CIP Siloc",
     "name":"Câmara Interbancária de Pagamentos - CIP - LDL",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"CAMARA INTERBANCARIA DE PAGAMENTOS - CIP",
     "name":"Câmara Interbancária de Pagamentos",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"CECM FABRIC CALÇADOS SAPIRANGA",
     "name":"COOPERATIVA DE ECONOMIA E CRÉDITO MÚTUO DOS FABRICANTES DE CALÇADOS DE SAPIRANGA",
     "spiCode":"",
     "strCode":"328"
  },
  {
     "displayName":"LAMARA SCD S.A.",
     "name":"LAMARA SOCIEDADE DE CRÉDITO DIRETO S.A.",
     "spiCode":"",
     "strCode":"416"
  },
  {
     "displayName":"CRED-UFES",
     "name":"COOPERATIVA DE CREDITO DOS SERVIDORES DA UNIVERSIDADE FEDERAL DO ESPIRITO SANTO",
     "spiCode":"27302181",
     "strCode":"427"
  },
  {
     "displayName":"Balcão B3",
     "name":"Sistema do Balcão B3",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"CIP C3",
     "name":"Câmara Interbancária de Pagamentos - CIP C3",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"WORK SCD S.A.",
     "name":"WORK SOCIEDADE DE CRÉDITO DIRETO S.A.",
     "spiCode":"",
     "strCode":"414"
  },
  {
     "displayName":"NUMBRS SCD S.A.",
     "name":"NUMBRS SOCIEDADE DE CRÉDITO DIRETO S.A.",
     "spiCode":"38129006",
     "strCode":"419"
  },
  {
     "displayName":"BCV - BCO",
     "name":"RSFN",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"Câmara B3",
     "name":"Câmara B3",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"Câmbio B3",
     "name":"Câmara de Câmbio B3",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"Selic",
     "name":"Banco Central do Brasil - Selic",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"Bacen",
     "name":"Banco Central do Brasil",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"CIP Siloc",
     "name":"Câmara Interbancária de Pagamentos - CIP - LDL",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"CAMARA INTERBANCARIA DE PAGAMENTOS - CIP",
     "name":"Câmara Interbancária de Pagamentos",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"Balcão B3",
     "name":"Sistema do Balcão B3",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"CIP C3",
     "name":"Câmara Interbancária de Pagamentos - CIP C3",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"CRED-SYSTEM SCD S.A.",
     "name":"CRED-SYSTEM SOCIEDADE DE CRÉDITO DIRETO S.A.",
     "spiCode":"39664698",
     "strCode":"428"
  },
  {
     "displayName":"BCV - BCO",
     "name":"RSFN",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"Câmara B3",
     "name":"Câmara B3",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"Câmbio B3",
     "name":"Câmara de Câmbio B3",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"CREDZ ADMINISTRADORA DE CARTOE",
     "name":"CREDZ ADMINISTRADORA DE CARTOES S/A",
     "spiCode":"12109247",
     "strCode":""
  },
  {
     "displayName":"CONTA ZAP",
     "name":"CONTA ZAP SOLUCOES EM PAGAMENTOS S.A.",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"MK DIGITAL ",
     "name":"MK DIGITAL BANK S.A.",
     "spiCode":"35624319",
     "strCode":""
  },
  {
     "displayName":"Selic",
     "name":"Banco Central do Brasil - Selic",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"Bacen",
     "name":"Banco Central do Brasil",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"CIP Siloc",
     "name":"Câmara Interbancária de Pagamentos - CIP - LDL",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"CAMARA INTERBANCARIA DE PAGAMENTOS - CIP",
     "name":"Câmara Interbancária de Pagamentos",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"Balcão B3",
     "name":"Sistema do Balcão B3",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"CIP C3",
     "name":"Câmara Interbancária de Pagamentos - CIP C3",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"BCV - BCO",
     "name":"RSFN",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"Câmara B3",
     "name":"Câmara B3",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"Câmbio B3",
     "name":"Câmara de Câmbio B3",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"PLANNER TRUSTEE DTVM LTDA",
     "name":"PLANNER TRUSTEE DISTRIBUIDORA DE TÍTULOS E VALORES MOBILIÁRIOS LTDA.",
     "spiCode":"",
     "strCode":"438"
  },
  {
     "displayName":"Selic",
     "name":"Banco Central do Brasil - Selic",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"Bacen",
     "name":"Banco Central do Brasil",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"CIP Siloc",
     "name":"Câmara Interbancária de Pagamentos - CIP - LDL",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"CAMARA INTERBANCARIA DE PAGAMENTOS - CIP",
     "name":"Câmara Interbancária de Pagamentos",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"Balcão B3",
     "name":"Sistema do Balcão B3",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"CIP C3",
     "name":"Câmara Interbancária de Pagamentos - CIP C3",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"BCV - BCO",
     "name":"RSFN",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"Câmara B3",
     "name":"Câmara B3",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"Câmbio B3",
     "name":"Câmara de Câmbio B3",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"TRANSFEERA PAGAMENTOS S.A.",
     "name":"TRANSFEERA PAGAMENTOS S.A",
     "spiCode":"27084098",
     "strCode":""
  },
  {
     "displayName":"Galax Pay",
     "name":"GALAX PAY PAGAMENTOS ELETRONICOS LTDA",
     "spiCode":"30765018",
     "strCode":""
  },
  {
     "displayName":"Selic",
     "name":"Banco Central do Brasil - Selic",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"Bacen",
     "name":"Banco Central do Brasil",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"BCO BV S.A.",
     "name":"BANCO BV S.A.",
     "spiCode":"01858774",
     "strCode":"413"
  },
  {
     "displayName":"CIP Siloc",
     "name":"Câmara Interbancária de Pagamentos - CIP - LDL",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"CAMARA INTERBANCARIA DE PAGAMENTOS - CIP",
     "name":"Câmara Interbancária de Pagamentos",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"BIORC FINANCEIRA - CFI S.A.",
     "name":"Biorc Financeira - Crédito",
     "spiCode":"",
     "strCode":"426"
  },
  {
     "displayName":"Balcão B3",
     "name":"Sistema do Balcão B3",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"CIP C3",
     "name":"Câmara Interbancária de Pagamentos - CIP C3",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"BCV - BCO",
     "name":"RSFN",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"Câmara B3",
     "name":"Câmara B3",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"Câmbio B3",
     "name":"Câmara de Câmbio B3",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"PAYPAL DO BRASIL SERVIÇOS DE PAGAMENTOS LTDA.",
     "name":"PAYPAL DO BRASIL SERVICOS DE PAGAMENTOS LTDA.",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"Selic",
     "name":"Banco Central do Brasil - Selic",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"Bacen",
     "name":"Banco Central do Brasil",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"COLUNA S.A. DTVM",
     "name":"COLUNA S/A DISTRIBUIDORA DE TITULOS E VALORES MOBILIÁRIOS",
     "spiCode":"",
     "strCode":"423"
  },
  {
     "displayName":"CIP Siloc",
     "name":"Câmara Interbancária de Pagamentos - CIP - LDL",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"CAMARA INTERBANCARIA DE PAGAMENTOS - CIP",
     "name":"Câmara Interbancária de Pagamentos",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"Balcão B3",
     "name":"Sistema do Balcão B3",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"CIP C3",
     "name":"Câmara Interbancária de Pagamentos - CIP C3",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"BCV - BCO",
     "name":"RSFN",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"Câmara B3",
     "name":"Câmara B3",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"Câmbio B3",
     "name":"Câmara de Câmbio B3",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"Selic",
     "name":"Banco Central do Brasil - Selic",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"Bacen",
     "name":"Banco Central do Brasil",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"CIP Siloc",
     "name":"Câmara Interbancária de Pagamentos - CIP - LDL",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"CAMARA INTERBANCARIA DE PAGAMENTOS - CIP",
     "name":"Câmara Interbancária de Pagamentos",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"Balcão B3",
     "name":"Sistema do Balcão B3",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"CIP C3",
     "name":"Câmara Interbancária de Pagamentos - CIP C3",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"PLANTAE CFI",
     "name":"PLANTAE S.A. - CRÉDITO",
     "spiCode":"",
     "strCode":"445"
  },
  {
     "displayName":"BR-CAPITAL DTVM S.A.",
     "name":"BR-CAPITAL DISTRIBUIDORA DE TÍTULOS E VALORES MOBILIÁRIOS S.A.",
     "spiCode":"",
     "strCode":"433"
  },
  {
     "displayName":"BCV - BCO",
     "name":"RSFN",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"Câmara B3",
     "name":"Câmara B3",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"Câmbio B3",
     "name":"Câmara de Câmbio B3",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"Selic",
     "name":"Banco Central do Brasil - Selic",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"Bacen",
     "name":"Banco Central do Brasil",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"CIP Siloc",
     "name":"Câmara Interbancária de Pagamentos - CIP - LDL",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"CAMARA INTERBANCARIA DE PAGAMENTOS - CIP",
     "name":"Câmara Interbancária de Pagamentos",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"FITBANK PAGAMENTOS ELETRONICOS S.A.",
     "name":"FITBANK PAGAMENTOS ELETRONICOS S.A.",
     "spiCode":"13203354",
     "strCode":"450"
  },
  {
     "displayName":"Balcão B3",
     "name":"Sistema do Balcão B3",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"CIP C3",
     "name":"Câmara Interbancária de Pagamentos - CIP C3",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"BCV - BCO",
     "name":"RSFN",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"Câmara B3",
     "name":"Câmara B3",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"Câmbio B3",
     "name":"Câmara de Câmbio B3",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"Selic",
     "name":"Banco Central do Brasil - Selic",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"Bacen",
     "name":"Banco Central do Brasil",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"CIP Siloc",
     "name":"Câmara Interbancária de Pagamentos - CIP - LDL",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"CAMARA INTERBANCARIA DE PAGAMENTOS - CIP",
     "name":"Câmara Interbancária de Pagamentos",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"Balcão B3",
     "name":"Sistema do Balcão B3",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"CIP C3",
     "name":"Câmara Interbancária de Pagamentos - CIP C3",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"CC LAR CREDI",
     "name":"LAR COOPERATIVA DE CRÉDITO - LAR CREDI",
     "spiCode":"39343350",
     "strCode":"421"
  },
  {
     "displayName":"BCV - BCO",
     "name":"RSFN",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"Câmara B3",
     "name":"Câmara B3",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"Câmbio B3",
     "name":"Câmara de Câmbio B3",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"WILL",
     "name":"WILL S.A. MEIOS DE PAGAMENTO",
     "spiCode":"36272465",
     "strCode":""
  },
  {
     "displayName":"Selic",
     "name":"Banco Central do Brasil - Selic",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"Bacen",
     "name":"Banco Central do Brasil",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"CIP Siloc",
     "name":"Câmara Interbancária de Pagamentos - CIP - LDL",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"SOCINAL S.A. CFI",
     "name":"SOCINAL S.A. - CRÉDITO",
     "spiCode":"03881423",
     "strCode":"425"
  },
  {
     "displayName":"CAMARA INTERBANCARIA DE PAGAMENTOS - CIP",
     "name":"Câmara Interbancária de Pagamentos",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"Balcão B3",
     "name":"Sistema do Balcão B3",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"CIP C3",
     "name":"Câmara Interbancária de Pagamentos - CIP C3",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"BCV - BCO",
     "name":"RSFN",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"Câmara B3",
     "name":"Câmara B3",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"Câmbio B3",
     "name":"Câmara de Câmbio B3",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"Selic",
     "name":"Banco Central do Brasil - Selic",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"Bacen",
     "name":"Banco Central do Brasil",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"CIP Siloc",
     "name":"Câmara Interbancária de Pagamentos - CIP - LDL",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"CAMARA INTERBANCARIA DE PAGAMENTOS - CIP",
     "name":"Câmara Interbancária de Pagamentos",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"VALOR SCD S.A.",
     "name":"VALOR SOCIEDADE DE CRÉDITO DIRETO S.A.",
     "spiCode":"",
     "strCode":"195"
  },
  {
     "displayName":"Balcão B3",
     "name":"Sistema do Balcão B3",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"CIP C3",
     "name":"Câmara Interbancária de Pagamentos - CIP C3",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"BCV - BCO",
     "name":"RSFN",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"Câmara B3",
     "name":"Câmara B3",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"Câmbio B3",
     "name":"Câmara de Câmbio B3",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"LOGBANK",
     "name":"LOGBANK SOLUCOES EM PAGAMENTOS S/A.",
     "spiCode":"28505126",
     "strCode":""
  },
  {
     "displayName":"Selic",
     "name":"Banco Central do Brasil - Selic",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"Bacen",
     "name":"Banco Central do Brasil",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"CIP Siloc",
     "name":"Câmara Interbancária de Pagamentos - CIP - LDL",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"CAMARA INTERBANCARIA DE PAGAMENTOS - CIP",
     "name":"Câmara Interbancária de Pagamentos",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"Balcão B3",
     "name":"Sistema do Balcão B3",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"CIP C3",
     "name":"Câmara Interbancária de Pagamentos - CIP C3",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"ZIPDIN SCD S.A.",
     "name":"ZIPDIN SOLUÇÕES DIGITAIS SOCIEDADE DE CRÉDITO DIRETO S/A",
     "spiCode":"",
     "strCode":"418"
  },
  {
     "displayName":"HEMERA DTVM LTDA.",
     "name":"HEMERA DISTRIBUIDORA DE TÍTULOS E VALORES MOBILIÁRIOS LTDA.",
     "spiCode":"",
     "strCode":"448"
  },
  {
     "displayName":"BCV - BCO",
     "name":"RSFN",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"Câmara B3",
     "name":"Câmara B3",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"Câmbio B3",
     "name":"Câmara de Câmbio B3",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"DONUS SOLUCOES DE PAGAMENTOS L",
     "name":"DONUS SOLUCOES DE PAGAMENTOS LTDA.",
     "spiCode":"35523352",
     "strCode":""
  },
  {
     "displayName":"Selic",
     "name":"Banco Central do Brasil - Selic",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"Bacen",
     "name":"Banco Central do Brasil",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"CCR SEARA",
     "name":"COOPERATIVA DE CREDITO RURAL SEARA - CREDISEARA",
     "spiCode":"00204963",
     "strCode":"430"
  },
  {
     "displayName":"CIP Siloc",
     "name":"Câmara Interbancária de Pagamentos - CIP - LDL",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"CAMARA INTERBANCARIA DE PAGAMENTOS - CIP",
     "name":"Câmara Interbancária de Pagamentos",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"HEDGE INVESTMENTS DTVM LTDA.",
     "name":"HEDGE INVESTMENTS DISTRIBUIDORA DE TÍTULOS E VALORES MOBILIÁRIOS LTDA.",
     "spiCode":"",
     "strCode":"458"
  },
  {
     "displayName":"Balcão B3",
     "name":"Sistema do Balcão B3",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"CIP C3",
     "name":"Câmara Interbancária de Pagamentos - CIP C3",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"DELCRED SCD S.A.",
     "name":"DELCRED SOCIEDADE DE CRÉDITO DIRETO S.A.",
     "spiCode":"38224857",
     "strCode":"435"
  },
  {
     "displayName":"TRINUS SCD S.A.",
     "name":"TRINUS SOCIEDADE DE CRÉDITO DIRETO S.A.",
     "spiCode":"40654622",
     "strCode":"444"
  },
  {
     "displayName":"BCV - BCO",
     "name":"RSFN",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"Câmara B3",
     "name":"Câmara B3",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"Câmbio B3",
     "name":"Câmara de Câmbio B3",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"CECM BRF",
     "name":"COOPERATIVA DE ECONOMIA E CRÉDITO MÚTUO BRF - CREDIBRF",
     "spiCode":"",
     "strCode":"440"
  },
  {
     "displayName":"Selic",
     "name":"Banco Central do Brasil - Selic",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"Bacen",
     "name":"Banco Central do Brasil",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"CIP Siloc",
     "name":"Câmara Interbancária de Pagamentos - CIP - LDL",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"CAMARA INTERBANCARIA DE PAGAMENTOS - CIP",
     "name":"Câmara Interbancária de Pagamentos",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"CREDIARE CFI S.A.",
     "name":"Crediare S.A. - Crédito",
     "spiCode":"",
     "strCode":"429"
  },
  {
     "displayName":"Balcão B3",
     "name":"Sistema do Balcão B3",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"CIP C3",
     "name":"Câmara Interbancária de Pagamentos - CIP C3",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"CREDIFIT SCD S.A.",
     "name":"CREDIFIT SOCIEDADE DE CRÉDITO DIRETO S.A.",
     "spiCode":"39676772",
     "strCode":"452"
  },
  {
     "displayName":"BCV - BCO",
     "name":"RSFN",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"Câmara B3",
     "name":"Câmara B3",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"Câmbio B3",
     "name":"Câmara de Câmbio B3",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"MAGNETIS - DTVM",
     "name":"MAGNETIS - DISTRIBUIDORA DE TÍTULOS E VALORES MOBILIÁRIOS LTDA",
     "spiCode":"",
     "strCode":"442"
  },
  {
     "displayName":"Selic",
     "name":"Banco Central do Brasil - Selic",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"Bacen",
     "name":"Banco Central do Brasil",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"CIP Siloc",
     "name":"Câmara Interbancária de Pagamentos - CIP - LDL",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"CAMARA INTERBANCARIA DE PAGAMENTOS - CIP",
     "name":"Câmara Interbancária de Pagamentos",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"MIRAE ASSET CCTVM LTDA",
     "name":"MIRAE ASSET WEALTH MANAGEMENT (BRAZIL) CORRETORA DE CÂMBIO",
     "spiCode":"",
     "strCode":"447"
  },
  {
     "displayName":"ID CTVM",
     "name":"ID CORRETORA DE TÍTULOS E VALORES MOBILIÁRIOS S.A.",
     "spiCode":"16695922",
     "strCode":"439"
  },
  {
     "displayName":"Balcão B3",
     "name":"Sistema do Balcão B3",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"CIP C3",
     "name":"Câmara Interbancária de Pagamentos - CIP C3",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"STARK SCD S.A.",
     "name":"STARK SOCIEDADE DE CRÉDITO DIRETO S.A.",
     "spiCode":"39908427",
     "strCode":"462"
  },
  {
     "displayName":"BCV - BCO",
     "name":"RSFN",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"Câmara B3",
     "name":"Câmara B3",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"Câmbio B3",
     "name":"Câmara de Câmbio B3",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"DMCARD",
     "name":"DMCARD MEIOS DE PAGAMENTO LTDA",
     "spiCode":"22371651",
     "strCode":""
  },
  {
     "displayName":"Selic",
     "name":"Banco Central do Brasil - Selic",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"Bacen",
     "name":"Banco Central do Brasil",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"CIP Siloc",
     "name":"Câmara Interbancária de Pagamentos - CIP - LDL",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"CAMARA INTERBANCARIA DE PAGAMENTOS - CIP",
     "name":"Câmara Interbancária de Pagamentos",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"Balcão B3",
     "name":"Sistema do Balcão B3",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"CIP C3",
     "name":"Câmara Interbancária de Pagamentos - CIP C3",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"BCV - BCO",
     "name":"RSFN",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"Câmara B3",
     "name":"Câmara B3",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"Câmbio B3",
     "name":"Câmara de Câmbio B3",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"CCPEMM DA RM DE PORTO ALEGRE",
     "name":"COOPERATIVA DE ECONOMIA E CRÉDITO MÚTUO DOS PEQUENOS EMPRESÁRIOS, MICROEMPRESÁRIOS E MICROEMPREENDEDORES DA REGIÃO METROPOLITANA DE PORTO ALEGRE - COOPESA",
     "spiCode":"06975532",
     "strCode":""
  },
  {
     "displayName":"CECM MÉDICOS PLANALTO MÉDIO RS",
     "name":"COOPERATIVA DE ECONOMIA E CREDITO MÚTUO DOS MÉDICOS DO PLANALTO MÉDIO DO RIO GRANDE DO SUL LTDA - CREDIPLAN",
     "spiCode":"97259253",
     "strCode":""
  },
  {
     "displayName":"Selic",
     "name":"Banco Central do Brasil - Selic",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"Bacen",
     "name":"Banco Central do Brasil",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"CIP Siloc",
     "name":"Câmara Interbancária de Pagamentos - CIP - LDL",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"CAMARA INTERBANCARIA DE PAGAMENTOS - CIP",
     "name":"Câmara Interbancária de Pagamentos",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"CCM SERV. PÚBLICOS SP",
     "name":"COOPERATIVA DE CRÉDITO MÚTUO DE SERVIDORES PÚBLICOS DO ESTADO DE SÃO PAULO - CRE",
     "spiCode":"",
     "strCode":"459"
  },
  {
     "displayName":"ASAAS IP S.A.",
     "name":"ASAAS GESTÃO FINANCEIRA INSTITUIÇÃO DE PAGAMENTO S.A.",
     "spiCode":"19540550",
     "strCode":"461"
  },
  {
     "displayName":"Balcão B3",
     "name":"Sistema do Balcão B3",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"CIP C3",
     "name":"Câmara Interbancária de Pagamentos - CIP C3",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"DMCARD SCD S.A.",
     "name":"DMCARD SOCIEDADE DE CRÉDITO DIRETO S.A.",
     "spiCode":"",
     "strCode":"449"
  },
  {
     "displayName":"UY3 SCD S/A",
     "name":"UY3 SOCIEDADE DE CRÉDITO DIRETO S/A",
     "spiCode":"39587424",
     "strCode":"457"
  },
  {
     "displayName":"BCV - BCO",
     "name":"RSFN",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"Câmara B3",
     "name":"Câmara B3",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"Câmbio B3",
     "name":"Câmara de Câmbio B3",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"Selic",
     "name":"Banco Central do Brasil - Selic",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"Bacen",
     "name":"Banco Central do Brasil",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"CIP Siloc",
     "name":"Câmara Interbancária de Pagamentos - CIP - LDL",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"CAMARA INTERBANCARIA DE PAGAMENTOS - CIP",
     "name":"Câmara Interbancária de Pagamentos",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"Balcão B3",
     "name":"Sistema do Balcão B3",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"CIP C3",
     "name":"Câmara Interbancária de Pagamentos - CIP C3",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"BCV - BCO",
     "name":"RSFN",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"Câmara B3",
     "name":"Câmara B3",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"Câmbio B3",
     "name":"Câmara de Câmbio B3",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"SLED",
     "name":"SLED S/A",
     "spiCode":"13207930",
     "strCode":""
  },
  {
     "displayName":"Selic",
     "name":"Banco Central do Brasil - Selic",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"Bacen",
     "name":"Banco Central do Brasil",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"CIP Siloc",
     "name":"Câmara Interbancária de Pagamentos - CIP - LDL",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"CAMARA INTERBANCARIA DE PAGAMENTOS - CIP",
     "name":"Câmara Interbancária de Pagamentos",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"Balcão B3",
     "name":"Sistema do Balcão B3",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"CIP C3",
     "name":"Câmara Interbancária de Pagamentos - CIP C3",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"CREDIHOME SCD",
     "name":"CREDIHOME SOCIEDADE DE CRÉDITO DIRETO S.A.",
     "spiCode":"",
     "strCode":"443"
  },
  {
     "displayName":"MÉRITO DTVM LTDA.",
     "name":"MÉRITO DISTRIBUIDORA DE TÍTULOS E VALORES MOBILIÁRIOS LTDA.",
     "spiCode":"",
     "strCode":"454"
  },
  {
     "displayName":"BCV - BCO",
     "name":"RSFN",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"Câmara B3",
     "name":"Câmara B3",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"Câmbio B3",
     "name":"Câmara de Câmbio B3",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"Selic",
     "name":"Banco Central do Brasil - Selic",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"Bacen",
     "name":"Banco Central do Brasil",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"CIP Siloc",
     "name":"Câmara Interbancária de Pagamentos - CIP - LDL",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"CAMARA INTERBANCARIA DE PAGAMENTOS - CIP",
     "name":"Câmara Interbancária de Pagamentos",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"Balcão B3",
     "name":"Sistema do Balcão B3",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"CIP C3",
     "name":"Câmara Interbancária de Pagamentos - CIP C3",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"CAPITAL CONSIG SCD S.A.",
     "name":"CAPITAL CONSIG SOCIEDADE DE CRÉDITO DIRETO S.A.",
     "spiCode":"40083667",
     "strCode":"465"
  },
  {
     "displayName":"BCV - BCO",
     "name":"RSFN",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"Câmara B3",
     "name":"Câmara B3",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"Câmbio B3",
     "name":"Câmara de Câmbio B3",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"Selic",
     "name":"Banco Central do Brasil - Selic",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"Bacen",
     "name":"Banco Central do Brasil",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"CIP Siloc",
     "name":"Câmara Interbancária de Pagamentos - CIP - LDL",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"CAMARA INTERBANCARIA DE PAGAMENTOS - CIP",
     "name":"Câmara Interbancária de Pagamentos",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"Balcão B3",
     "name":"Sistema do Balcão B3",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"CIP C3",
     "name":"Câmara Interbancária de Pagamentos - CIP C3",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"MASTER S/A CCTVM",
     "name":"MASTER S/A CORRETORA DE CâMBIO",
     "spiCode":"",
     "strCode":"467"
  },
  {
     "displayName":"AZUMI DTVM",
     "name":"AZUMI DISTRIBUIDORA DE TíTULOS E VALORES MOBILIáRIOS LTDA.",
     "spiCode":"",
     "strCode":"463"
  },
  {
     "displayName":"BCV - BCO",
     "name":"RSFN",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"Câmara B3",
     "name":"Câmara B3",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"Câmbio B3",
     "name":"Câmara de Câmbio B3",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"BRASIL CARD PAGAMENTOS",
     "name":"BRASIL CARD MEIOS DE PAGAMENTOS LTDA",
     "spiCode":"03130170",
     "strCode":""
  },
  {
     "displayName":"SOL E MAR",
     "name":"SOLARPAY INSTITUICAO DE PAGAMENTOS S.A.",
     "spiCode":"35210425",
     "strCode":""
  },
  {
     "displayName":"Selic",
     "name":"Banco Central do Brasil - Selic",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"Bacen",
     "name":"Banco Central do Brasil",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"CIP Siloc",
     "name":"Câmara Interbancária de Pagamentos - CIP - LDL",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"CAMARA INTERBANCARIA DE PAGAMENTOS - CIP",
     "name":"Câmara Interbancária de Pagamentos",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"Balcão B3",
     "name":"Sistema do Balcão B3",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"CIP C3",
     "name":"Câmara Interbancária de Pagamentos - CIP C3",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"BCV - BCO",
     "name":"RSFN",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"Câmara B3",
     "name":"Câmara B3",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"Câmbio B3",
     "name":"Câmara de Câmbio B3",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"PINBANK BRASIL",
     "name":"PINBANK BRASIL - PAGAMENTOS INTELIGENTES S.A.",
     "spiCode":"17079937",
     "strCode":"529"
  },
  {
     "displayName":"Selic",
     "name":"Banco Central do Brasil - Selic",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"Bacen",
     "name":"Banco Central do Brasil",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"CIP Siloc",
     "name":"Câmara Interbancária de Pagamentos - CIP - LDL",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"CAMARA INTERBANCARIA DE PAGAMENTOS - CIP",
     "name":"Câmara Interbancária de Pagamentos",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"Balcão B3",
     "name":"Sistema do Balcão B3",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"CIP C3",
     "name":"Câmara Interbancária de Pagamentos - CIP C3",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"J17 - SCD S/A",
     "name":"J17 - SOCIEDADE DE CRÉDITO DIRETO S/A",
     "spiCode":"",
     "strCode":"451"
  },
  {
     "displayName":"UNAVANTI SCD S/A",
     "name":"UNAVANTI SOCIEDADE DE CRÉDITO DIRETO S/A",
     "spiCode":"",
     "strCode":"460"
  },
  {
     "displayName":"BCV - BCO",
     "name":"RSFN",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"Câmara B3",
     "name":"Câmara B3",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"Câmbio B3",
     "name":"Câmara de Câmbio B3",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"BEETELLERPAY",
     "name":"BEETELLERPAY INSTITUICAO DE PAGAMENTO LTDA",
     "spiCode":"32074986",
     "strCode":""
  },
  {
     "displayName":"Selic",
     "name":"Banco Central do Brasil - Selic",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"Bacen",
     "name":"Banco Central do Brasil",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"CIP Siloc",
     "name":"Câmara Interbancária de Pagamentos - CIP - LDL",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"CAMARA INTERBANCARIA DE PAGAMENTOS - CIP",
     "name":"Câmara Interbancária de Pagamentos",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"Balcão B3",
     "name":"Sistema do Balcão B3",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"CIP C3",
     "name":"Câmara Interbancária de Pagamentos - CIP C3",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"BCV - BCO",
     "name":"RSFN",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"Câmara B3",
     "name":"Câmara B3",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"Câmbio B3",
     "name":"Câmara de Câmbio B3",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"MONEY CLOUD",
     "name":"MONEY CLOUD TECNOLOGIA LTDA",
     "spiCode":"30153943",
     "strCode":""
  },
  {
     "displayName":"CARDPAY",
     "name":"UNLIMINT BRASIL SERVICOS DE INTERNET LTDA.",
     "spiCode":"35542555",
     "strCode":""
  },
  {
     "displayName":"CF BANK",
     "name":"CHRISTIAN FAMILY BANK S/A",
     "spiCode":"38406148",
     "strCode":""
  },
  {
     "displayName":"Selic",
     "name":"Banco Central do Brasil - Selic",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"Bacen",
     "name":"Banco Central do Brasil",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"CIP Siloc",
     "name":"Câmara Interbancária de Pagamentos - CIP - LDL",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"CAMARA INTERBANCARIA DE PAGAMENTOS - CIP",
     "name":"Câmara Interbancária de Pagamentos",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"Balcão B3",
     "name":"Sistema do Balcão B3",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"CIP C3",
     "name":"Câmara Interbancária de Pagamentos - CIP C3",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"BCV - BCO",
     "name":"RSFN",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"Câmara B3",
     "name":"Câmara B3",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"Câmbio B3",
     "name":"Câmara de Câmbio B3",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"SKILOPAY INTERMEDIACOES E SOLU",
     "name":"SKILOPAY INTERMEDIACOES E SOLUCOES FINANCEIRAS LTDA",
     "spiCode":"35480428",
     "strCode":""
  },
  {
     "displayName":"MACREBANK",
     "name":"MACRE EMPREENDIMENTOS FINANCEIROS LTDA",
     "spiCode":"38460917",
     "strCode":""
  },
  {
     "displayName":"Selic",
     "name":"Banco Central do Brasil - Selic",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"Bacen",
     "name":"Banco Central do Brasil",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"CIP Siloc",
     "name":"Câmara Interbancária de Pagamentos - CIP - LDL",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"CAMARA INTERBANCARIA DE PAGAMENTOS - CIP",
     "name":"Câmara Interbancária de Pagamentos",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"PORTOSEG S.A. CFI",
     "name":"PORTOSEG S.A. - CREDITO",
     "spiCode":"04862600",
     "strCode":"468"
  },
  {
     "displayName":"Balcão B3",
     "name":"Sistema do Balcão B3",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"CIP C3",
     "name":"Câmara Interbancária de Pagamentos - CIP C3",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"BCV - BCO",
     "name":"RSFN",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"Câmara B3",
     "name":"Câmara B3",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"Câmbio B3",
     "name":"Câmara de Câmbio B3",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"CECM SAÚDE EMP ACCI ERECHIM",
     "name":"COOPERATIVA DE ECONOMIA E CRÉDITO MÚTUO DOS TRABALHADORES NA ÁREA DE SAÚDE, AFINS E EMPRESÁRIOS VINCULADOS À ACCI DE ERECHIM-RS-CREDISUL",
     "spiCode":"07494300",
     "strCode":""
  },
  {
     "displayName":"Selic",
     "name":"Banco Central do Brasil - Selic",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"Bacen",
     "name":"Banco Central do Brasil",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"CIP Siloc",
     "name":"Câmara Interbancária de Pagamentos - CIP - LDL",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"CECM DOS TRAB.PORT. DA G.VITOR",
     "name":"COOPERATIVA DE ECONOMIA E CREDITO MUTUO DOS TRABALHADORES PORTUARIOS DA GRANDE V",
     "spiCode":"",
     "strCode":"385"
  },
  {
     "displayName":"CAMARA INTERBANCARIA DE PAGAMENTOS - CIP",
     "name":"Câmara Interbancária de Pagamentos",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"Balcão B3",
     "name":"Sistema do Balcão B3",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"CIP C3",
     "name":"Câmara Interbancária de Pagamentos - CIP C3",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"BCV - BCO",
     "name":"RSFN",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"Câmara B3",
     "name":"Câmara B3",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"Câmbio B3",
     "name":"Câmara de Câmbio B3",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"BERLIN FINANCE",
     "name":"BERLIN FINANCE MEIOS DE PAGAMENTOS LTDA",
     "spiCode":"16814330",
     "strCode":""
  },
  {
     "displayName":"ONBANK",
     "name":"ONBANK SOLUCOES FINANCEIRAS SA",
     "spiCode":"32914717",
     "strCode":""
  },
  {
     "displayName":"Selic",
     "name":"Banco Central do Brasil - Selic",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"Bacen",
     "name":"Banco Central do Brasil",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"CIP Siloc",
     "name":"Câmara Interbancária de Pagamentos - CIP - LDL",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"CAMARA INTERBANCARIA DE PAGAMENTOS - CIP",
     "name":"Câmara Interbancária de Pagamentos",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"LECCA DTVM LTDA",
     "name":"LECCA DISTRIBUIDORA DE TITULOS E VALORES MOBILIARIOS LTDA",
     "spiCode":"",
     "strCode":"469"
  },
  {
     "displayName":"GAZINCRED S.A. SCFI",
     "name":"GAZINCRED S.A. SOCIEDADE DE CRÉDITO",
     "spiCode":"11760553",
     "strCode":"478"
  },
  {
     "displayName":"CDC SOCIEDADE DE CRÉDITO",
     "name":"CDC SOCIEDADE DE CRÉDITO AO MICROEMPREENDEDOR E À EMPRESADE PEQUENO PORTE LTDA.",
     "spiCode":"",
     "strCode":"470"
  },
  {
     "displayName":"Balcão B3",
     "name":"Sistema do Balcão B3",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"CIP C3",
     "name":"Câmara Interbancária de Pagamentos - CIP C3",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"BCV - BCO",
     "name":"RSFN",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"Câmara B3",
     "name":"Câmara B3",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"Câmbio B3",
     "name":"Câmara de Câmbio B3",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"Selic",
     "name":"Banco Central do Brasil - Selic",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"Bacen",
     "name":"Banco Central do Brasil",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"CIP Siloc",
     "name":"Câmara Interbancária de Pagamentos - CIP - LDL",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"CAMARA INTERBANCARIA DE PAGAMENTOS - CIP",
     "name":"Câmara Interbancária de Pagamentos",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"Balcão B3",
     "name":"Sistema do Balcão B3",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"CIP C3",
     "name":"Câmara Interbancária de Pagamentos - CIP C3",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"BCV - BCO",
     "name":"RSFN",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"Câmara B3",
     "name":"Câmara B3",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"Câmbio B3",
     "name":"Câmara de Câmbio B3",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"Selic",
     "name":"Banco Central do Brasil - Selic",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"Bacen",
     "name":"Banco Central do Brasil",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"CIP Siloc",
     "name":"Câmara Interbancária de Pagamentos - CIP - LDL",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"CAMARA INTERBANCARIA DE PAGAMENTOS - CIP",
     "name":"Câmara Interbancária de Pagamentos",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"Balcão B3",
     "name":"Sistema do Balcão B3",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"CIP C3",
     "name":"Câmara Interbancária de Pagamentos - CIP C3",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"BCV - BCO",
     "name":"RSFN",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"Câmara B3",
     "name":"Câmara B3",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"Câmbio B3",
     "name":"Câmara de Câmbio B3",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"Selic",
     "name":"Banco Central do Brasil - Selic",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"Bacen",
     "name":"Banco Central do Brasil",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"CIP Siloc",
     "name":"Câmara Interbancária de Pagamentos - CIP - LDL",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"CAMARA INTERBANCARIA DE PAGAMENTOS - CIP",
     "name":"Câmara Interbancária de Pagamentos",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"Balcão B3",
     "name":"Sistema do Balcão B3",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"CIP C3",
     "name":"Câmara Interbancária de Pagamentos - CIP C3",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"BCV - BCO",
     "name":"RSFN",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"Câmara B3",
     "name":"Câmara B3",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"Câmbio B3",
     "name":"Câmara de Câmbio B3",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"ZRO PAGAMENTOS S/A",
     "name":"ZRO PAGAMENTOS S/A",
     "spiCode":"26264220",
     "strCode":""
  },
  {
     "displayName":"Selic",
     "name":"Banco Central do Brasil - Selic",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"Bacen",
     "name":"Banco Central do Brasil",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"CIP Siloc",
     "name":"Câmara Interbancária de Pagamentos - CIP - LDL",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"CAMARA INTERBANCARIA DE PAGAMENTOS - CIP",
     "name":"Câmara Interbancária de Pagamentos",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"Balcão B3",
     "name":"Sistema do Balcão B3",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"CIP C3",
     "name":"Câmara Interbancária de Pagamentos - CIP C3",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"BCV - BCO",
     "name":"RSFN",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"Câmara B3",
     "name":"Câmara B3",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"Câmbio B3",
     "name":"Câmara de Câmbio B3",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"Selic",
     "name":"Banco Central do Brasil - Selic",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"Bacen",
     "name":"Banco Central do Brasil",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"CIP Siloc",
     "name":"Câmara Interbancária de Pagamentos - CIP - LDL",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"CAMARA INTERBANCARIA DE PAGAMENTOS - CIP",
     "name":"Câmara Interbancária de Pagamentos",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"Balcão B3",
     "name":"Sistema do Balcão B3",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"CIP C3",
     "name":"Câmara Interbancária de Pagamentos - CIP C3",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"BCV - BCO",
     "name":"RSFN",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"Câmara B3",
     "name":"Câmara B3",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"Câmbio B3",
     "name":"Câmara de Câmbio B3",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"Selic",
     "name":"Banco Central do Brasil - Selic",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"Bacen",
     "name":"Banco Central do Brasil",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"CIP Siloc",
     "name":"Câmara Interbancária de Pagamentos - CIP - LDL",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"CAMARA INTERBANCARIA DE PAGAMENTOS - CIP",
     "name":"Câmara Interbancária de Pagamentos",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"Balcão B3",
     "name":"Sistema do Balcão B3",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"CIP C3",
     "name":"Câmara Interbancária de Pagamentos - CIP C3",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"BCV - BCO",
     "name":"RSFN",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"Câmara B3",
     "name":"Câmara B3",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"Câmbio B3",
     "name":"Câmara de Câmbio B3",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"Selic",
     "name":"Banco Central do Brasil - Selic",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"Bacen",
     "name":"Banco Central do Brasil",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"CIP Siloc",
     "name":"Câmara Interbancária de Pagamentos - CIP - LDL",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"CAMARA INTERBANCARIA DE PAGAMENTOS - CIP",
     "name":"Câmara Interbancária de Pagamentos",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"Balcão B3",
     "name":"Sistema do Balcão B3",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"CIP C3",
     "name":"Câmara Interbancária de Pagamentos - CIP C3",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"BCV - BCO",
     "name":"RSFN",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"Câmara B3",
     "name":"Câmara B3",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"Câmbio B3",
     "name":"Câmara de Câmbio B3",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"COOP SICOOB UNI SUL MS",
     "name":"COOPERATIVA DE CRÉDITO SICOOB UNI SUL MS",
     "spiCode":"37554441",
     "strCode":""
  },
  {
     "displayName":"COOP UNIQUE BR LTDA.",
     "name":"COOPERATIVA DE CRÉDITO UNIQUE BR",
     "spiCode":"73647935",
     "strCode":""
  },
  {
     "displayName":"Selic",
     "name":"Banco Central do Brasil - Selic",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"Bacen",
     "name":"Banco Central do Brasil",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"CIP Siloc",
     "name":"Câmara Interbancária de Pagamentos - CIP - LDL",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"CAMARA INTERBANCARIA DE PAGAMENTOS - CIP",
     "name":"Câmara Interbancária de Pagamentos",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"Balcão B3",
     "name":"Sistema do Balcão B3",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"CIP C3",
     "name":"Câmara Interbancária de Pagamentos - CIP C3",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"BCV - BCO",
     "name":"RSFN",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"Câmara B3",
     "name":"Câmara B3",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"Câmbio B3",
     "name":"Câmara de Câmbio B3",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"Selic",
     "name":"Banco Central do Brasil - Selic",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"Bacen",
     "name":"Banco Central do Brasil",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"CIP Siloc",
     "name":"Câmara Interbancária de Pagamentos - CIP - LDL",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"CAMARA INTERBANCARIA DE PAGAMENTOS - CIP",
     "name":"Câmara Interbancária de Pagamentos",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"CECM SERV PUBL PINHÃO",
     "name":"COOPERATIVA DE ECONOMIA E CREDITO MUTUO DOS SERVIDORES PUBLICOS DE PINHÃO - CRES",
     "spiCode":"",
     "strCode":"471"
  },
  {
     "displayName":"Balcão B3",
     "name":"Sistema do Balcão B3",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"CIP C3",
     "name":"Câmara Interbancária de Pagamentos - CIP C3",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"MAF DTVM SA",
     "name":"MAF DISTRIBUIDORA DE TÍTULOS E VALORES MOBILIÁRIOS S.A.",
     "spiCode":"",
     "strCode":"484"
  },
  {
     "displayName":"BCV - BCO",
     "name":"RSFN",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"Câmara B3",
     "name":"Câmara B3",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"Câmbio B3",
     "name":"Câmara de Câmbio B3",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"Selic",
     "name":"Banco Central do Brasil - Selic",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"Bacen",
     "name":"Banco Central do Brasil",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"CIP Siloc",
     "name":"Câmara Interbancária de Pagamentos - CIP - LDL",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"CAMARA INTERBANCARIA DE PAGAMENTOS - CIP",
     "name":"Câmara Interbancária de Pagamentos",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"Balcão B3",
     "name":"Sistema do Balcão B3",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"CIP C3",
     "name":"Câmara Interbancária de Pagamentos - CIP C3",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"CAPTALYS DTVM LTDA.",
     "name":"CAPTALYS DISTRIBUIDORA DE TÍTULOS E VALORES MOBILIÁRIOS LTDA.",
     "spiCode":"",
     "strCode":"512"
  },
  {
     "displayName":"RJI",
     "name":"RJI CORRETORA DE TITULOS E VALORES MOBILIARIOS LTDA",
     "spiCode":"",
     "strCode":"506"
  },
  {
     "displayName":"BCV - BCO",
     "name":"RSFN",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"Câmara B3",
     "name":"Câmara B3",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"Câmbio B3",
     "name":"Câmara de Câmbio B3",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"Selic",
     "name":"Banco Central do Brasil - Selic",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"Bacen",
     "name":"Banco Central do Brasil",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"CIP Siloc",
     "name":"Câmara Interbancária de Pagamentos - CIP - LDL",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"CAMARA INTERBANCARIA DE PAGAMENTOS - CIP",
     "name":"Câmara Interbancária de Pagamentos",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"Balcão B3",
     "name":"Sistema do Balcão B3",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"CIP C3",
     "name":"Câmara Interbancária de Pagamentos - CIP C3",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"BCV - BCO",
     "name":"RSFN",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"Câmara B3",
     "name":"Câmara B3",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"Câmbio B3",
     "name":"Câmara de Câmbio B3",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"Selic",
     "name":"Banco Central do Brasil - Selic",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"Bacen",
     "name":"Banco Central do Brasil",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"CIP Siloc",
     "name":"Câmara Interbancária de Pagamentos - CIP - LDL",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"CAMARA INTERBANCARIA DE PAGAMENTOS - CIP",
     "name":"Câmara Interbancária de Pagamentos",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"Balcão B3",
     "name":"Sistema do Balcão B3",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"CIP C3",
     "name":"Câmara Interbancária de Pagamentos - CIP C3",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"MAGNUM SCD",
     "name":"MAGNUM SOCIEDADE DE CRÉDITO DIRETO S.A.",
     "spiCode":"44683140",
     "strCode":"511"
  },
  {
     "displayName":"BCV - BCO",
     "name":"RSFN",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"Câmara B3",
     "name":"Câmara B3",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"Câmbio B3",
     "name":"Câmara de Câmbio B3",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"AVENUE SECURITIES DTVM LTDA.",
     "name":"AVENUE SECURITIES DISTRIBUIDORA DE TÍTULOS E VALORES MOBILIÁRIOS LTDA.",
     "spiCode":"",
     "strCode":"508"
  },
  {
     "displayName":"ADYEN LATIN AMERICA",
     "name":"ADYEN DO BRASIL INSTITUICAO DE PAGAMENTO LTDA.",
     "spiCode":"14796606",
     "strCode":""
  },
  {
     "displayName":"SWAP",
     "name":"SWAP MEIOS DE PAGAMENTOS INSTITUICAO DE PAGAMENTO S.A.",
     "spiCode":"31680151",
     "strCode":""
  },
  {
     "displayName":"01 PAGAMENTOS",
     "name":"01 PAGAMENTOS E NEGOCIOS LTDA",
     "spiCode":"35810871",
     "strCode":""
  },
  {
     "displayName":"Selic",
     "name":"Banco Central do Brasil - Selic",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"Bacen",
     "name":"Banco Central do Brasil",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"CIP Siloc",
     "name":"Câmara Interbancária de Pagamentos - CIP - LDL",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"CAMARA INTERBANCARIA DE PAGAMENTOS - CIP",
     "name":"Câmara Interbancária de Pagamentos",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"Balcão B3",
     "name":"Sistema do Balcão B3",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"CIP C3",
     "name":"Câmara Interbancária de Pagamentos - CIP C3",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"BCV - BCO",
     "name":"RSFN",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"Câmara B3",
     "name":"Câmara B3",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"Câmbio B3",
     "name":"Câmara de Câmbio B3",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"Selic",
     "name":"Banco Central do Brasil - Selic",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"Bacen",
     "name":"Banco Central do Brasil",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"CIP Siloc",
     "name":"Câmara Interbancária de Pagamentos - CIP - LDL",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"CAMARA INTERBANCARIA DE PAGAMENTOS - CIP",
     "name":"Câmara Interbancária de Pagamentos",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"BCO YAMAHA MOTOR S.A.",
     "name":"Banco Yamaha Motor do Brasil S.A.",
     "spiCode":"",
     "strCode":"475"
  },
  {
     "displayName":"Balcão B3",
     "name":"Sistema do Balcão B3",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"CIP C3",
     "name":"Câmara Interbancária de Pagamentos - CIP C3",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"SUPERLÓGICA SCD S.A.",
     "name":"SUPERLÓGICA SOCIEDADE DE CRÉDITO DIRETO S.A.",
     "spiCode":"",
     "strCode":"481"
  },
  {
     "displayName":"BCV - BCO",
     "name":"RSFN",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"Câmara B3",
     "name":"Câmara B3",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"Câmbio B3",
     "name":"Câmara de Câmbio B3",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"SOCIAL BANK IP SA",
     "name":"SOCIAL BANK INSTITUIÇÃO DE PAGAMENTO S.A",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"KREDIT BANK",
     "name":"KREDIT BANK INSTITUICAO DE PAGAMENTOS S/A",
     "spiCode":"34678263",
     "strCode":""
  },
  {
     "displayName":"ISSUER",
     "name":"ISSUER ADMINISTRADORA DE CARTOES LTDA.",
     "spiCode":"34747388",
     "strCode":""
  },
  {
     "displayName":"Selic",
     "name":"Banco Central do Brasil - Selic",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"Bacen",
     "name":"Banco Central do Brasil",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"CIP SA Siloc",
     "name":"CIP S.A",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"CAMARA INTERBANCARIA DE PAGAMENTOS - CIP",
     "name":"CIP S.A",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"Balcão B3",
     "name":"Sistema do Balcão B3",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"CIP SA C3",
     "name":"CIP S.A",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"BCV - BCO",
     "name":"RSFN",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"Câmara B3",
     "name":"Câmara B3",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"Câmbio B3",
     "name":"Câmara de Câmbio B3",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"BANK10 INVESTIMENTOS",
     "name":"BANK10 PAGAMENTOS S.A.",
     "spiCode":"23273917",
     "strCode":""
  },
  {
     "displayName":"SERVNET IP LTDA.",
     "name":"SERVNET ADMINISTRACAO DE CARTOES LTDA.",
     "spiCode":"29759316",
     "strCode":""
  },
  {
     "displayName":"900PAY",
     "name":"MOOVE MONEY INSTITUICAO DE PAGAMENTO S.A.",
     "spiCode":"35210410",
     "strCode":""
  },
  {
     "displayName":"Selic",
     "name":"Banco Central do Brasil - Selic",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"Bacen",
     "name":"Banco Central do Brasil",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"CIP SA Siloc",
     "name":"CIP S.A",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"CAMARA INTERBANCARIA DE PAGAMENTOS - CIP",
     "name":"CIP S.A",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"Balcão B3",
     "name":"Sistema do Balcão B3",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"CIP SA C3",
     "name":"CIP S.A",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"BCV - BCO",
     "name":"RSFN",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"Câmara B3",
     "name":"Câmara B3",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"Câmbio B3",
     "name":"Câmara de Câmbio B3",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"Selic",
     "name":"Banco Central do Brasil - Selic",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"Bacen",
     "name":"Banco Central do Brasil",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"CIP SA Siloc",
     "name":"CIP S.A",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"CAMARA INTERBANCARIA DE PAGAMENTOS - CIP",
     "name":"CIP S.A",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"Balcão B3",
     "name":"Sistema do Balcão B3",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"CIP SA C3",
     "name":"CIP S.A",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"SBCASH SCD",
     "name":"SBCASH SOCIEDADE DE CRÉDITO DIRETO S.A.",
     "spiCode":"",
     "strCode":"482"
  },
  {
     "displayName":"BCV - BCO",
     "name":"RSFN",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"Câmara B3",
     "name":"Câmara B3",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"Câmbio B3",
     "name":"Câmara de Câmbio B3",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"CONPAY",
     "name":"CONPAY TECNOLOGIA E PAGAMENTOS S.A",
     "spiCode":"31531997",
     "strCode":""
  },
  {
     "displayName":"Selic",
     "name":"Banco Central do Brasil - Selic",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"Bacen",
     "name":"Banco Central do Brasil",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"CIP SA Siloc",
     "name":"CIP S.A",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"CAMARA INTERBANCARIA DE PAGAMENTOS - CIP",
     "name":"CIP S.A",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"Balcão B3",
     "name":"Sistema do Balcão B3",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"CIP SA C3",
     "name":"CIP S.A",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"FC FINANCEIRA S.A. - CFI",
     "name":"FC FINANCEIRA S.A. - CRÉDITO",
     "spiCode":"",
     "strCode":"516"
  },
  {
     "displayName":"BCV - BCO",
     "name":"RSFN",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"Câmara B3",
     "name":"Câmara B3",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"Câmbio B3",
     "name":"Câmara de Câmbio B3",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"N26 SCD",
     "name":"N26 SOCIEDADE DE CRÉDITO DIRETO S.A.",
     "spiCode":"40867163",
     "strCode":""
  },
  {
     "displayName":"CCR CREDICANA",
     "name":"COOPERATIVA DE CRÉDITO RURAL - CREDICANA",
     "spiCode":"44373041",
     "strCode":""
  },
  {
     "displayName":"Selic",
     "name":"Banco Central do Brasil - Selic",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"Bacen",
     "name":"Banco Central do Brasil",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"CIP SA Siloc",
     "name":"CIP S.A",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"CAMARA INTERBANCARIA DE PAGAMENTOS - CIP",
     "name":"CIP S.A",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"Balcão B3",
     "name":"Sistema do Balcão B3",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"CIP SA C3",
     "name":"CIP S.A",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"BCV - BCO",
     "name":"RSFN",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"Câmara B3",
     "name":"Câmara B3",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"Câmbio B3",
     "name":"Câmara de Câmbio B3",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"Selic",
     "name":"Banco Central do Brasil - Selic",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"Bacen",
     "name":"Banco Central do Brasil",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"CIP SA Siloc",
     "name":"CIP S.A",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"CAMARA INTERBANCARIA DE PAGAMENTOS - CIP",
     "name":"CIP S.A",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"Balcão B3",
     "name":"Sistema do Balcão B3",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"CIP SA C3",
     "name":"CIP S.A",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"FFCRED SCD S.A.",
     "name":"FFCRED SOCIEDADE DE CRÉDITO DIRETO S.A..",
     "spiCode":"",
     "strCode":"510"
  },
  {
     "displayName":"BCV - BCO",
     "name":"RSFN",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"Câmara B3",
     "name":"Câmara B3",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"Câmbio B3",
     "name":"Câmara de Câmbio B3",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"Selic",
     "name":"Banco Central do Brasil - Selic",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"Bacen",
     "name":"Banco Central do Brasil",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"CIP SA Siloc",
     "name":"CIP S.A",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"CAMARA INTERBANCARIA DE PAGAMENTOS - CIP",
     "name":"CIP S.A",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"Balcão B3",
     "name":"Sistema do Balcão B3",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"CIP SA C3",
     "name":"CIP S.A",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"INTERCAM CC LTDA",
     "name":"INTERCAM CORRETORA DE CÂMBIO LTDA.",
     "spiCode":"",
     "strCode":"525"
  },
  {
     "displayName":"FÊNIX DTVM LTDA.",
     "name":"FÊNIX DISTRIBUIDORA DE TÍTULOS E VALORES MOBILIÁRIOS LTDA.",
     "spiCode":"",
     "strCode":"455"
  },
  {
     "displayName":"ATF CREDIT SCD S.A.",
     "name":"ATF CREDIT SOCIEDADE DE CRÉDITO DIRETO S.A.",
     "spiCode":"",
     "strCode":"513"
  },
  {
     "displayName":"BCV - BCO",
     "name":"RSFN",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"Câmara B3",
     "name":"Câmara B3",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"Câmbio B3",
     "name":"Câmara de Câmbio B3",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"ACG IP S.A.",
     "name":"ACG INSTITUIÇÃO DE PAGAMENTO S.A.",
     "spiCode":"10868663",
     "strCode":""
  },
  {
     "displayName":"Selic",
     "name":"Banco Central do Brasil - Selic",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"Bacen",
     "name":"Banco Central do Brasil",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"CIP SA Siloc",
     "name":"CIP S.A",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"CAMARA INTERBANCARIA DE PAGAMENTOS - CIP",
     "name":"CIP S.A",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"Balcão B3",
     "name":"Sistema do Balcão B3",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"CIP SA C3",
     "name":"CIP S.A",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"GERENCIANET CFI",
     "name":"GERENCIANET S.A. - CRÉDITO",
     "spiCode":"",
     "strCode":"507"
  },
  {
     "displayName":"LIONS TRUST DTVM",
     "name":"LIONS TRUST DISTRIBUIDORA DE TÍTULOS E VALORES MOBILIÁRIOS LTDA.",
     "spiCode":"",
     "strCode":"519"
  },
  {
     "displayName":"BCV - BCO",
     "name":"RSFN",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"Câmara B3",
     "name":"Câmara B3",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"Câmbio B3",
     "name":"Câmara de Câmbio B3",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"VALEPAY",
     "name":"VALEPAY BRASIL LTDA",
     "spiCode":"28220872",
     "strCode":""
  },
  {
     "displayName":"ALIPAY",
     "name":"ALIPAY BRASIL MEIOS DE PAGAMENTO LTDA.",
     "spiCode":"32246162",
     "strCode":""
  },
  {
     "displayName":"REPASSES FINANCEIROS",
     "name":"REPASSES FINANCEIROS E SOLUCOES TECNOLOGICAS INSTITUICAO DE PAGAMENTO S.A.",
     "spiCode":"40473435",
     "strCode":""
  },
  {
     "displayName":"Selic",
     "name":"Banco Central do Brasil - Selic",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"Bacen",
     "name":"Banco Central do Brasil",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"CIP SA Siloc",
     "name":"CIP S.A",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"CAMARA INTERBANCARIA DE PAGAMENTOS - CIP",
     "name":"CIP S.A",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"Balcão B3",
     "name":"Sistema do Balcão B3",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"CIP SA C3",
     "name":"CIP S.A",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"BCV - BCO",
     "name":"RSFN",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"Câmara B3",
     "name":"Câmara B3",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"Câmbio B3",
     "name":"Câmara de Câmbio B3",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"INOVANTI",
     "name":"INOVANTI INSTITUICAO DE PAGAMENTOS S.A.",
     "spiCode":"37511258",
     "strCode":""
  },
  {
     "displayName":"Selic",
     "name":"Banco Central do Brasil - Selic",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"Bacen",
     "name":"Banco Central do Brasil",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"CIP SA Siloc",
     "name":"CIP S.A",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"CAMARA INTERBANCARIA DE PAGAMENTOS - CIP",
     "name":"CIP S.A",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"Balcão B3",
     "name":"Sistema do Balcão B3",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"CIP SA C3",
     "name":"CIP S.A",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"BCV - BCO",
     "name":"RSFN",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"Câmara B3",
     "name":"Câmara B3",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"Câmbio B3",
     "name":"Câmara de Câmbio B3",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"Selic",
     "name":"Banco Central do Brasil - Selic",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"Bacen",
     "name":"Banco Central do Brasil",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"CIP SA Siloc",
     "name":"CIP S.A",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"CAMARA INTERBANCARIA DE PAGAMENTOS - CIP",
     "name":"CIP S.A",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"Balcão B3",
     "name":"Sistema do Balcão B3",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"CIP SA C3",
     "name":"CIP S.A",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"MONETARIE SCD",
     "name":"MONETARIE SOCIEDADE DE CRÉDITO DIRETO S.A.",
     "spiCode":"",
     "strCode":"526"
  },
  {
     "displayName":"BCV - BCO",
     "name":"RSFN",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"Câmara B3",
     "name":"Câmara B3",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"Câmbio B3",
     "name":"Câmara de Câmbio B3",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"BRINKS PAY IP LTDA.",
     "name":"BRINKS PAY INSTITUICAO DE PAGAMENTO LTDA",
     "spiCode":"09437293",
     "strCode":""
  },
  {
     "displayName":"QESH IP LTDA.",
     "name":"QESH INSTITUICAO DE PAGAMENTO LTDA",
     "spiCode":"31818873",
     "strCode":""
  },
  {
     "displayName":"Selic",
     "name":"Banco Central do Brasil - Selic",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"Bacen",
     "name":"Banco Central do Brasil",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"CIP SA Siloc",
     "name":"CIP S.A",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"CAMARA INTERBANCARIA DE PAGAMENTOS - CIP",
     "name":"CIP S.A",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"Balcão B3",
     "name":"Sistema do Balcão B3",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"CIP SA C3",
     "name":"CIP S.A",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"MERCADO CRÉDITO SCFI S.A.",
     "name":"MERCADO CRÉDITO SOCIEDADE DE CRÉDITO",
     "spiCode":"",
     "strCode":"518"
  },
  {
     "displayName":"PEAK SEP S.A.",
     "name":"PEAK SOCIEDADE DE EMPRÉSTIMO ENTRE PESSOAS S.A.",
     "spiCode":"",
     "strCode":"521"
  },
  {
     "displayName":"ATICCA SCD S.A.",
     "name":"ATICCA - SOCIEDADE DE CRÉDITO DIRETO S.A.",
     "spiCode":"",
     "strCode":"527"
  },
  {
     "displayName":"BCV - BCO",
     "name":"RSFN",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"Câmara B3",
     "name":"Câmara B3",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"Câmbio B3",
     "name":"Câmara de Câmbio B3",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"CSU",
     "name":"CSU CARDSYSTEM S/A",
     "spiCode":"01896779",
     "strCode":""
  },
  {
     "displayName":"Selic",
     "name":"Banco Central do Brasil - Selic",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"Bacen",
     "name":"Banco Central do Brasil",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"CIP SA Siloc",
     "name":"CIP S.A",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"CAMARA INTERBANCARIA DE PAGAMENTOS - CIP",
     "name":"CIP S.A",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"BANCO BESA S.A.",
     "name":"BANCO BESA S.A.",
     "spiCode":"",
     "strCode":"334"
  },
  {
     "displayName":"Balcão B3",
     "name":"Sistema do Balcão B3",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"CIP SA C3",
     "name":"CIP S.A",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"MARU SCD S.A.",
     "name":"MARÚ SOCIEDADE DE CRÉDITO DIRETO S.A.",
     "spiCode":"",
     "strCode":"535"
  },
  {
     "displayName":"HR DIGITAL SCD",
     "name":"HR DIGITAL - SOCIEDADE DE CRÉDITO DIRETO S/A",
     "spiCode":"",
     "strCode":"523"
  },
  {
     "displayName":"BCV - BCO",
     "name":"RSFN",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"Câmara B3",
     "name":"Câmara B3",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"Câmbio B3",
     "name":"Câmara de Câmbio B3",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"ECOMOVI",
     "name":"ECOMOVI SOLUCOES E SERVICOS EM PAGAMENTOS LTDA",
     "spiCode":"33053580",
     "strCode":""
  },
  {
     "displayName":"Selic",
     "name":"Banco Central do Brasil - Selic",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"Bacen",
     "name":"Banco Central do Brasil",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"FDO GARANTIDOR CRÉDITOS",
     "name":"FUNDO GARANTIDOR DE CREDITOS - FGC",
     "spiCode":"",
     "strCode":"541"
  },
  {
     "displayName":"CIP SA Siloc",
     "name":"CIP S.A",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"CAMARA INTERBANCARIA DE PAGAMENTOS - CIP",
     "name":"CIP S.A",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"Balcão B3",
     "name":"Sistema do Balcão B3",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"CIP SA C3",
     "name":"CIP S.A",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"REAG DTVM S.A.",
     "name":"REAG DISTRIBUIDORA DE TÍTULOS E VALORES MOBILIÁRIOS S.A.",
     "spiCode":"",
     "strCode":"528"
  },
  {
     "displayName":"MICROCASH SCMEPP LTDA.",
     "name":"MICROCASH SOCIEDADE DE CRÉDITO AO MICROEMPREENDEDOR E À EMPRESA DE PEQUENO PORTE",
     "spiCode":"45756448",
     "strCode":"537"
  },
  {
     "displayName":"WNT CAPITAL DTVM",
     "name":"WNT CAPITAL DISTRIBUIDORA DE TÍTULOS E VALORES MOBILIÁRIOS S.A.",
     "spiCode":"",
     "strCode":"524"
  },
  {
     "displayName":"BCV - BCO",
     "name":"RSFN",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"Câmara B3",
     "name":"Câmara B3",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"Câmbio B3",
     "name":"Câmara de Câmbio B3",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"Selic",
     "name":"Banco Central do Brasil - Selic",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"Bacen",
     "name":"Banco Central do Brasil",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"CIP SA Siloc",
     "name":"CIP S.A",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"CAMARA INTERBANCARIA DE PAGAMENTOS - CIP",
     "name":"CIP S.A",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"MASTER BI S.A.",
     "name":"BANCO MASTER DE INVESTIMENTO S.A.",
     "spiCode":"",
     "strCode":"141"
  },
  {
     "displayName":"Balcão B3",
     "name":"Sistema do Balcão B3",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"CIP SA C3",
     "name":"CIP S.A",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"SER FINANCE SCD S.A.",
     "name":"SER FINANCE SOCIEDADE DE CRÉDITO DIRETO S.A.",
     "spiCode":"",
     "strCode":"530"
  },
  {
     "displayName":"BCV - BCO",
     "name":"RSFN",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"Câmara B3",
     "name":"Câmara B3",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"Câmbio B3",
     "name":"Câmara de Câmbio B3",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"UNIPRIME OURO - COOP DE OURO",
     "name":"UNIPRIME OURO - COOPERATIVA DE CRÉDITO DE OURO",
     "spiCode":"07853842",
     "strCode":""
  },
  {
     "displayName":"Selic",
     "name":"Banco Central do Brasil - Selic",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"Bacen",
     "name":"Banco Central do Brasil",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"CIP SA Siloc",
     "name":"CIP S.A",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"CAMARA INTERBANCARIA DE PAGAMENTOS - CIP",
     "name":"CIP S.A",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"Balcão B3",
     "name":"Sistema do Balcão B3",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"CIP SA C3",
     "name":"CIP S.A",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"EAGLE SCD S.A.",
     "name":"EAGLE SOCIEDADE DE CRÉDITO DIRETO S.A.",
     "spiCode":"",
     "strCode":"532"
  },
  {
     "displayName":"RED SCD S.A.",
     "name":"RED SOCIEDADE DE CRÉDITO DIRETO S.A.",
     "spiCode":"",
     "strCode":"522"
  },
  {
     "displayName":"BCV - BCO",
     "name":"RSFN",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"Câmara B3",
     "name":"Câmara B3",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"Câmbio B3",
     "name":"Câmara de Câmbio B3",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"PAYBROKERS IP",
     "name":"DONA FAVORITA SERVICOS FINANCEIROS LTDA.",
     "spiCode":"40820176",
     "strCode":""
  },
  {
     "displayName":"Selic",
     "name":"Banco Central do Brasil - Selic",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"Bacen",
     "name":"Banco Central do Brasil",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"SANTINVEST S.A. - CFI",
     "name":"SANTINVEST S.A. - CREDITO",
     "spiCode":"",
     "strCode":"539"
  },
  {
     "displayName":"CIP SA Siloc",
     "name":"CIP S.A",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"CAMARA INTERBANCARIA DE PAGAMENTOS - CIP",
     "name":"CIP S.A",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"Balcão B3",
     "name":"Sistema do Balcão B3",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"CIP SA C3",
     "name":"CIP S.A",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"BCV - BCO",
     "name":"RSFN",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"Câmara B3",
     "name":"Câmara B3",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"Câmbio B3",
     "name":"Câmara de Câmbio B3",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"Selic",
     "name":"Banco Central do Brasil - Selic",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"Bacen",
     "name":"Banco Central do Brasil",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"CIP SA Siloc",
     "name":"CIP S.A",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"CAMARA INTERBANCARIA DE PAGAMENTOS - CIP",
     "name":"CIP S.A",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"Balcão B3",
     "name":"Sistema do Balcão B3",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"CIP SA C3",
     "name":"CIP S.A",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"BCV - BCO",
     "name":"RSFN",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"Câmara B3",
     "name":"Câmara B3",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"Câmbio B3",
     "name":"Câmara de Câmbio B3",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"Selic",
     "name":"Banco Central do Brasil - Selic",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"Bacen",
     "name":"Banco Central do Brasil",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"CIP SA Siloc",
     "name":"CIP S.A",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"CAMARA INTERBANCARIA DE PAGAMENTOS - CIP",
     "name":"CIP S.A",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"Balcão B3",
     "name":"Sistema do Balcão B3",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"CIP SA C3",
     "name":"CIP S.A",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"BCV - BCO",
     "name":"RSFN",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"Câmara B3",
     "name":"Câmara B3",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"Câmbio B3",
     "name":"Câmara de Câmbio B3",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"Selic",
     "name":"Banco Central do Brasil - Selic",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"Bacen",
     "name":"Banco Central do Brasil",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"CIP SA Siloc",
     "name":"CIP S.A",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"CAMARA INTERBANCARIA DE PAGAMENTOS - CIP",
     "name":"CIP S.A",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"Balcão B3",
     "name":"Sistema do Balcão B3",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"CIP SA C3",
     "name":"CIP S.A",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"BCV - BCO",
     "name":"RSFN",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"Câmara B3",
     "name":"Câmara B3",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"Câmbio B3",
     "name":"Câmara de Câmbio B3",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"Selic",
     "name":"Banco Central do Brasil - Selic",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"Bacen",
     "name":"Banco Central do Brasil",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"CIP SA Siloc",
     "name":"CIP S.A",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"CAMARA INTERBANCARIA DE PAGAMENTOS - CIP",
     "name":"CIP S.A",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"Balcão B3",
     "name":"Sistema do Balcão B3",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"CIP SA C3",
     "name":"CIP S.A",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"BCV - BCO",
     "name":"RSFN",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"Câmara B3",
     "name":"Câmara B3",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"Câmbio B3",
     "name":"Câmara de Câmbio B3",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"Selic",
     "name":"Banco Central do Brasil - Selic",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"Bacen",
     "name":"Banco Central do Brasil",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"CIP SA Siloc",
     "name":"CIP S.A",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"CAMARA INTERBANCARIA DE PAGAMENTOS - CIP",
     "name":"CIP S.A",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"Balcão B3",
     "name":"Sistema do Balcão B3",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"CIP SA C3",
     "name":"CIP S.A",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"BCV - BCO",
     "name":"RSFN",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"Câmara B3",
     "name":"Câmara B3",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"Câmbio B3",
     "name":"Câmara de Câmbio B3",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"AQBANK",
     "name":"AQBANK INSTITUICAO DE PAGAMENTO LTDA",
     "spiCode":"00377239",
     "strCode":""
  },
  {
     "displayName":"Selic",
     "name":"Banco Central do Brasil - Selic",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"Bacen",
     "name":"Banco Central do Brasil",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"CIP SA Siloc",
     "name":"CIP S.A",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"CAMARA INTERBANCARIA DE PAGAMENTOS - CIP",
     "name":"CIP S.A",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"Balcão B3",
     "name":"Sistema do Balcão B3",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"CIP SA C3",
     "name":"CIP S.A",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"BCV - BCO",
     "name":"RSFN",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"Câmara B3",
     "name":"Câmara B3",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"Câmbio B3",
     "name":"Câmara de Câmbio B3",
     "spiCode":"",
     "strCode":""
  },
  {
     "displayName":"ZENEX PAGAMENTOS LTDA",
     "name":"ZENEX PAGAMENTOS LTDA",
     "spiCode":"34006497",
     "strCode":""
  }
];
// source: github [?]
export const PHONE_CODES = [
      {
      "name": "Afeganistão",
      "code": "+93",
      "iso": "AF",
      "flag": "https://cdn.kcak11.com/CountryFlags/countries/af.svg",
      "mask": "99-999-9999"
      },
      {
      "name": "Ilhas Aland",
      "code": "+358",
      "iso": "AX",
      "flag": "https://cdn.kcak11.com/CountryFlags/countries/ax.svg",
      "mask": "(999)999-99-99"
      },
      {
      "name": "Albânia",
      "code": "+355",
      "iso": "AL",
      "flag": "https://cdn.kcak11.com/CountryFlags/countries/al.svg",
      "mask": "(999)999-999"
      },
      {
      "name": "Argélia",
      "code": "+213",
      "iso": "DZ",
      "flag": "https://cdn.kcak11.com/CountryFlags/countries/dz.svg",
      "mask": "99-999-9999"
      },
      {
      "name": "Samoa Americana",
      "code": "+1",
      "iso": "AS",
      "flag": "https://cdn.kcak11.com/CountryFlags/countries/as.svg",
      "mask": "(684)999-9999"
      },
      {
      "name": "Andorra",
      "code": "+376",
      "iso": "AD",
      "flag": "https://cdn.kcak11.com/CountryFlags/countries/ad.svg",
      "mask": "999-999"
      },
      {
      "name": "Angola",
      "code": "+244",
      "iso": "AO",
      "flag": "https://cdn.kcak11.com/CountryFlags/countries/ao.svg",
      "mask": "(999)999-999"
      },
      {
      "name": "Anguilla",
      "code": "+1",
      "iso": "AI",
      "flag": "https://cdn.kcak11.com/CountryFlags/countries/ai.svg",
      "mask": "(264)999-9999"
      },
      {
      "name": "Antártica",
      "code": "+672",
      "iso": "AQ",
      "flag": "https://cdn.kcak11.com/CountryFlags/countries/aq.svg",
      "mask": "199-999"
      },
      {
      "name": "Antígua e Barbuda",
      "code": "+1",
      "iso": "AG",
      "flag": "https://cdn.kcak11.com/CountryFlags/countries/ag.svg",
      "mask": "(268)999-9999"
      },
      {
      "name": "Argentina",
      "code": "+54",
      "iso": "AR",
      "flag": "https://cdn.kcak11.com/CountryFlags/countries/ar.svg",
      "mask": "(999)999-9999"
      },
      {
      "name": "Armênia",
      "code": "+374",
      "iso": "AM",
      "flag": "https://cdn.kcak11.com/CountryFlags/countries/am.svg",
      "mask": "99-999-999"
      },
      {
      "name": "Aruba",
      "code": "+297",
      "iso": "AW",
      "flag": "https://cdn.kcak11.com/CountryFlags/countries/aw.svg",
      "mask": "999-9999"
      },
      {
      "name": "Ilha de Ascensão",
      "code": "+247",
      "iso": "AC",
      "flag": "https://cdn.kcak11.com/CountryFlags/countries/sh.svg",
      "mask": "9999"
      },
      {
      "name": "Austrália",
      "code": "+61",
      "iso": "AU",
      "flag": "https://cdn.kcak11.com/CountryFlags/countries/au.svg",
      "mask": "9-9999-9999"
      },
      {
      "name": "Áustria",
      "code": "+43",
      "iso": "AT",
      "flag": "https://cdn.kcak11.com/CountryFlags/countries/at.svg",
      "mask": "(999)999-9999"
      },
      {
      "name": "Azerbaijão",
      "code": "+994",
      "iso": "AZ",
      "flag": "https://cdn.kcak11.com/CountryFlags/countries/az.svg",
      "mask": "99-999-99-99"
      },
      {
      "name": "Bahamas",
      "code": "+1",
      "iso": "BS",
      "flag": "https://cdn.kcak11.com/CountryFlags/countries/bs.svg",
      "mask": "(242)999-9999"
      },
      {
      "name": "Bahrain",
      "code": "+973",
      "iso": "BH",
      "flag": "https://cdn.kcak11.com/CountryFlags/countries/bh.svg",
      "mask": "9999-9999"
      },
      {
      "name": "Bangladesh",
      "code": "+880",
      "iso": "BD",
      "flag": "https://cdn.kcak11.com/CountryFlags/countries/bd.svg",
      "mask": "1999-999999"
      },
      {
      "name": "Barbados",
      "code": "+1",
      "iso": "BB",
      "flag": "https://cdn.kcak11.com/CountryFlags/countries/bb.svg",
      "mask": "(246)999-9999"
      },
      {
      "name": "Belarus",
      "code": "+375",
      "iso": "BY",
      "flag": "https://cdn.kcak11.com/CountryFlags/countries/by.svg",
      "mask": "(99)999-99-99"
      },
      {
      "name": "Bélgica",
      "code": "+32",
      "iso": "BE",
      "flag": "https://cdn.kcak11.com/CountryFlags/countries/be.svg",
      "mask": "(999)999-999"
      },
      {
      "name": "Belize",
      "code": "+501",
      "iso": "BZ",
      "flag": "https://cdn.kcak11.com/CountryFlags/countries/bz.svg",
      "mask": "999-9999"
      },
      {
      "name": "Benin",
      "code": "+229",
      "iso": "BJ",
      "flag": "https://cdn.kcak11.com/CountryFlags/countries/bj.svg",
      "mask": "99-99-9999"
      },
      {
      "name": "Bermudas",
      "code": "+1",
      "iso": "BM",
      "flag": "https://cdn.kcak11.com/CountryFlags/countries/bm.svg",
      "mask": "(441)999-9999"
      },
      {
      "name": "Butão",
      "code": "+975",
      "iso": "BT",
      "flag": "https://cdn.kcak11.com/CountryFlags/countries/bt.svg",
      "mask": [
         "17-999-999",
         "77-999-999",
         "9-999-999"
      ]
      },
      {
      "name": "Bolívia",
      "code": "+591",
      "iso": "BO",
      "flag": "https://cdn.kcak11.com/CountryFlags/countries/bo.svg",
      "mask": "9-999-9999"
      },
      {
      "name": "Bósnia e Herzegovina",
      "code": "+387",
      "iso": "BA",
      "flag": "https://cdn.kcak11.com/CountryFlags/countries/ba.svg",
      "mask": [
         "99-9999",
         "99-99999"
      ]
      },
      {
      "name": "Botsuana",
      "code": "+267",
      "iso": "BW",
      "flag": "https://cdn.kcak11.com/CountryFlags/countries/bw.svg",
      "mask": "99-999-999"
      },
      {
      "name": "Brasil",
      "code": "+55",
      "iso": "BR",
      "flag": "https://cdn.kcak11.com/CountryFlags/countries/br.svg",
      "mask": [
         "(99) 9999-9999",
         "(99) 99999-9999"
      ]
      },
      {
      "name": "Território Britânico do Oceano Índico",
      "code": "+246",
      "iso": "IO",
      "flag": "https://cdn.kcak11.com/CountryFlags/countries/io.svg",
      "mask": "999-9999"
      },
      {
      "name": "Brunei Darussalam",
      "code": "+673",
      "iso": "BN",
      "flag": "https://cdn.kcak11.com/CountryFlags/countries/bn.svg",
      "mask": "999-9999"
      },
      {
      "name": "Bulgária",
      "code": "+359",
      "iso": "BG",
      "flag": "https://cdn.kcak11.com/CountryFlags/countries/bg.svg",
      "mask": "(999)999-999"
      },
      {
      "name": "Burkina Faso",
      "code": "+226",
      "iso": "BF",
      "flag": "https://cdn.kcak11.com/CountryFlags/countries/bf.svg",
      "mask": "99-99-9999"
      },
      {
      "name": "Burundi",
      "code": "+257",
      "iso": "BI",
      "flag": "https://cdn.kcak11.com/CountryFlags/countries/bi.svg",
      "mask": "99-99-9999"
      },
      {
      "name": "Camboja",
      "code": "+855",
      "iso": "KH",
      "flag": "https://cdn.kcak11.com/CountryFlags/countries/kh.svg",
      "mask": "99-999-999"
      },
      {
      "name": "Camarões",
      "code": "+237",
      "iso": "CM",
      "flag": "https://cdn.kcak11.com/CountryFlags/countries/cm.svg",
      "mask": "9999-9999"
      },
      {
      "name": "Canadá",
      "code": "+1",
      "iso": "CA",
      "flag": "https://cdn.kcak11.com/CountryFlags/countries/ca.svg",
      "mask": "(999)999-9999"
      },
      {
      "name": "Cabo Verde",
      "code": "+238",
      "iso": "CV",
      "flag": "https://cdn.kcak11.com/CountryFlags/countries/cv.svg",
      "mask": "(999)99-99"
      },
      {
      "name": "Ilhas Cayman",
      "code": "+1",
      "iso": "KY",
      "flag": "https://cdn.kcak11.com/CountryFlags/countries/ky.svg",
      "mask": "(345)999-9999"
      },
      {
      "name": "República Centro-Africana",
      "code": "+236",
      "iso": "CF",
      "flag": "https://cdn.kcak11.com/CountryFlags/countries/cf.svg",
      "mask": "99-99-9999"
      },
      {
      "name": "Chade",
      "code": "+235",
      "iso": "TD",
      "flag": "https://cdn.kcak11.com/CountryFlags/countries/td.svg",
      "mask": "99-99-99-99"
      },
      {
      "name": "Chile",
      "code": "+56",
      "iso": "CL",
      "flag": "https://cdn.kcak11.com/CountryFlags/countries/cl.svg",
      "mask": "9-9999-9999"
      },
      {
      "name": "China",
      "code": "+86",
      "iso": "CN",
      "flag": "https://cdn.kcak11.com/CountryFlags/countries/cn.svg",
      "mask": [
         "(999)9999-999",
         "(999)9999-9999",
         "99-99999-99999"
      ]
      },
      {
        "name": "Ilha do Natal",
        "code": "+61",
        "iso": "CX",
        "flag": "https://cdn.kcak11.com/CountryFlags/countries/cx.svg",
        "mask": "9-9999-9999"
      },
      {
        "name": "Ilhas Cocos (Keeling)",
        "code": "+61",
        "iso": "CC",
        "flag": "https://cdn.kcak11.com/CountryFlags/countries/cc.svg",
        "mask": "9-9999-9999"
      },
      {
        "name": "Colômbia",
        "code": "+57",
        "iso": "CO",
        "flag": "https://cdn.kcak11.com/CountryFlags/countries/co.svg",
        "mask": "(999)999-9999"
      },
      {
        "name": "Comores",
        "code": "+269",
        "iso": "KM",
        "flag": "https://cdn.kcak11.com/CountryFlags/countries/km.svg",
        "mask": "99-99999"
      },
      {
        "name": "Congo",
        "code": "+242",
        "iso": "CG",
        "flag": "https://cdn.kcak11.com/CountryFlags/countries/cg.svg",
        "mask": "99-99999"
      },
      {
        "name": "Ilhas Cook",
        "code": "+682",
        "iso": "CK",
        "flag": "https://cdn.kcak11.com/CountryFlags/countries/ck.svg",
        "mask": "99-999"
      },
      {
        "name": "Costa Rica",
        "code": "+506",
        "iso": "CR",
        "flag": "https://cdn.kcak11.com/CountryFlags/countries/cr.svg",
        "mask": "9999-9999"
      },
      {
        "name": "Croácia",
        "code": "+385",
        "iso": "HR",
        "flag": "https://cdn.kcak11.com/CountryFlags/countries/hr.svg",
        "mask": "99-999-999"
      },
      {
        "name": "Cuba",
        "code": "+53",
        "iso": "CU",
        "flag": "https://cdn.kcak11.com/CountryFlags/countries/cu.svg",
        "mask": "9-999-9999"
      },
      {
        "name": "Chipre",
        "code": "+357",
        "iso": "CY",
        "flag": "https://cdn.kcak11.com/CountryFlags/countries/cy.svg",
        "mask": "99-999-999"
      },
      {
        "name": "República Tcheca",
        "code": "+420",
        "iso": "CZ",
        "flag": "https://cdn.kcak11.com/CountryFlags/countries/cz.svg",
        "mask": "(999)999-999"
      },
      {
        "name": "República Democrática do Congo",
        "code": "+243",
        "iso": "CD",
        "flag": "https://cdn.kcak11.com/CountryFlags/countries/cd.svg",
        "mask": "(999)999-999"
      },
      {
        "name": "Dinamarca",
        "code": "+45",
        "iso": "DK",
        "flag": "https://cdn.kcak11.com/CountryFlags/countries/dk.svg",
        "mask": "99-99-99-99"
      },
      {
        "name": "Djibuti",
        "code": "+253",
        "iso": "DJ",
        "flag": "https://cdn.kcak11.com/CountryFlags/countries/dj.svg",
        "mask": "99-99-99-99"
      },
      {
        "name": "Dominica",
        "code": "+1",
        "iso": "DM",
        "flag": "https://cdn.kcak11.com/CountryFlags/countries/dm.svg",
        "mask": "(767)999-9999"
      },
      {
        "name": "República Dominicana",
        "code": "+1",
        "iso": "DO",
        "flag": "https://cdn.kcak11.com/CountryFlags/countries/do.svg",
        "mask": [
          "(809)999-9999",
          "(829)999-9999",
          "(849)999-9999"
        ]
      },
      {
        "name": "Equador",
        "code": "+593",
        "iso": "EC",
        "flag": "https://cdn.kcak11.com/CountryFlags/countries/ec.svg",
        "mask": [
          "9-999-9999",
          "99-999-9999"
        ]
      },
      {
        "name": "Egito",
        "code": "+20",
        "iso": "EG",
        "flag": "https://cdn.kcak11.com/CountryFlags/countries/eg.svg",
        "mask": "(999)999-9999"
      },
      {
        "name": "El Salvador",
        "code": "+503",
        "iso": "SV",
        "flag": "https://cdn.kcak11.com/CountryFlags/countries/sv.svg",
        "mask": "99-99-9999"
      },
      {
        "name": "Guiné Equatorial",
        "code": "+240",
        "iso": "GQ",
        "flag": "https://cdn.kcak11.com/CountryFlags/countries/gq.svg",
        "mask": "99-999-9999"
      },
      {
        "name": "Eritreia",
        "code": "+291",
        "iso": "ER",
        "flag": "https://cdn.kcak11.com/CountryFlags/countries/er.svg",
        "mask": "9-999-999"
      },
      {
        "name": "Estônia",
        "code": "+372",
        "iso": "EE",
        "flag": "https://cdn.kcak11.com/CountryFlags/countries/ee.svg",
        "mask": [
          "999-9999",
          "9999-9999"
        ]
      },
      {
        "name": "Essuatíni",
        "code": "+268",
        "iso": "SZ",
        "flag": "https://cdn.kcak11.com/CountryFlags/countries/sz.svg",
        "mask": "99-99-9999"
      },
      {
        "name": "Etiópia",
        "code": "+251",
        "iso": "ET",
        "flag": "https://cdn.kcak11.com/CountryFlags/countries/et.svg",
        "mask": "99-999-9999"
      },
      {
        "name": "Ilhas Malvinas",
        "code": "+500",
        "iso": "FK",
        "flag": "https://cdn.kcak11.com/CountryFlags/countries/fk.svg",
        "mask": "99999"
      },
      {
        "name": "Ilhas Faroé",
        "code": "+298",
        "iso": "FO",
        "flag": "https://cdn.kcak11.com/CountryFlags/countries/fo.svg",
        "mask": "999-999"
      },
      {
        "name": "Fiji",
        "code": "+679",
        "iso": "FJ",
        "flag": "https://cdn.kcak11.com/CountryFlags/countries/fj.svg",
        "mask": "99-99999"
      },
      {
        "name": "Finlândia",
        "code": "+358",
        "iso": "FI",
        "flag": "https://cdn.kcak11.com/CountryFlags/countries/fi.svg",
        "mask": "(999)999-99-99"
      },
      {
        "name": "França",
        "code": "+33",
        "iso": "FR",
        "flag": "https://cdn.kcak11.com/CountryFlags/countries/fr.svg",
        "mask": "(999)999-999"
      },
      {
        "name": "Guiana Francesa",
        "code": "+594",
        "iso": "GF",
        "flag": "https://cdn.kcak11.com/CountryFlags/countries/gf.svg",
        "mask": "99999-9999"
      },
      {
        "name": "Polinésia Francesa",
        "code": "+689",
        "iso": "PF",
        "flag": "https://cdn.kcak11.com/CountryFlags/countries/pf.svg",
        "mask": "99-99-99"
      },
      {
        "name": "Gabão",
        "code": "+241",
        "iso": "GA",
        "flag": "https://cdn.kcak11.com/CountryFlags/countries/ga.svg",
        "mask": "9-99-99-99"
      },
      {
        "name": "Gâmbia",
        "code": "+220",
        "iso": "GM",
        "flag": "https://cdn.kcak11.com/CountryFlags/countries/gm.svg",
        "mask": "(999)99-99"
      },
      {
        "name": "Geórgia",
        "code": "+995",
        "iso": "GE",
        "flag": "https://cdn.kcak11.com/CountryFlags/countries/ge.svg",
        "mask": "(999)999-999"
      },
      {
        "name": "Alemanha",
        "code": "+49",
        "iso": "DE",
        "flag": "https://cdn.kcak11.com/CountryFlags/countries/de.svg",
        "mask": [
          "999-999",
          "(999)99-99",
          "(999)99-999",
          "(999)99-9999",
          "(999)999-9999",
          "(9999)999-9999"
        ]
      },
      {
         "name": "Gana",
         "code": "+233",
         "iso": "GH",
         "flag": "https://cdn.kcak11.com/CountryFlags/countries/gh.svg",
         "mask": "(999)999-999"
       },
       {
         "name": "Gibraltar",
         "code": "+350",
         "iso": "GI",
         "flag": "https://cdn.kcak11.com/CountryFlags/countries/gi.svg",
         "mask": "999-99999"
       },
       {
         "name": "Grécia",
         "code": "+30",
         "iso": "GR",
         "flag": "https://cdn.kcak11.com/CountryFlags/countries/gr.svg",
         "mask": "(999)999-9999"
       },
       {
         "name": "Groenlândia",
         "code": "+299",
         "iso": "GL",
         "flag": "https://cdn.kcak11.com/CountryFlags/countries/gl.svg",
         "mask": "99-99-99"
       },
       {
         "name": "Granada",
         "code": "+1",
         "iso": "GD",
         "flag": "https://cdn.kcak11.com/CountryFlags/countries/gd.svg",
         "mask": "(473)999-9999"
       },
       {
         "name": "Guadalupe",
         "code": "+590",
         "iso": "GP",
         "flag": "https://cdn.kcak11.com/CountryFlags/countries/gp.svg",
         "mask": "(999)999-999"
       },
       {
         "name": "Guam",
         "code": "+1",
         "iso": "GU",
         "flag": "https://cdn.kcak11.com/CountryFlags/countries/gu.svg",
         "mask": "(671)999-9999"
       },
       {
         "name": "Guatemala",
         "code": "+502",
         "iso": "GT",
         "flag": "https://cdn.kcak11.com/CountryFlags/countries/gt.svg",
         "mask": "9-999-9999"
       },
       {
         "name": "Guernsey",
         "code": "+44",
         "iso": "GG",
         "flag": "https://cdn.kcak11.com/CountryFlags/countries/gg.svg",
         "mask": "(9999)999999"
       },
       {
         "name": "Guiné",
         "code": "+224",
         "iso": "GN",
         "flag": "https://cdn.kcak11.com/CountryFlags/countries/gn.svg",
         "mask": "99-999-999"
       },
       {
         "name": "Guiné-Bissau",
         "code": "+245",
         "iso": "GW",
         "flag": "https://cdn.kcak11.com/CountryFlags/countries/gw.svg",
         "mask": "9-999999"
       },
       {
         "name": "Guiana",
         "code": "+592",
         "iso": "GY",
         "flag": "https://cdn.kcak11.com/CountryFlags/countries/gy.svg",
         "mask": "999-9999"
       },
       {
         "name": "Haiti",
         "code": "+509",
         "iso": "HT",
         "flag": "https://cdn.kcak11.com/CountryFlags/countries/ht.svg",
         "mask": "99-99-9999"
       },
       {
         "name": "Santa Sé (Cidade do Vaticano)",
         "code": "+39",
         "iso": "VA",
         "flag": "https://cdn.kcak11.com/CountryFlags/countries/va.svg",
         "mask": "06 69899999"
       },
       {
         "name": "Honduras",
         "code": "+504",
         "iso": "HN",
         "flag": "https://cdn.kcak11.com/CountryFlags/countries/hn.svg",
         "mask": "9999-9999"
       },
       {
         "name": "Hong Kong",
         "code": "+852",
         "iso": "HK",
         "flag": "https://cdn.kcak11.com/CountryFlags/countries/hk.svg",
         "mask": "9999-9999"
       },
       {
         "name": "Hungria",
         "code": "+36",
         "iso": "HU",
         "flag": "https://cdn.kcak11.com/CountryFlags/countries/hu.svg",
         "mask": "(999)999-999"
       },
       {
         "name": "Islândia",
         "code": "+354",
         "iso": "IS",
         "flag": "https://cdn.kcak11.com/CountryFlags/countries/is.svg",
         "mask": "999-9999"
       },
       {
         "name": "Índia",
         "code": "+91",
         "iso": "IN",
         "flag": "https://cdn.kcak11.com/CountryFlags/countries/in.svg",
         "mask": "(9999)999-999"
       },
       {
         "name": "Indonésia",
         "code": "+62",
         "iso": "ID",
         "flag": "https://cdn.kcak11.com/CountryFlags/countries/id.svg",
         "mask": [
           "99-999-99",
           "99-999-999",
           "99-999-9999",
           "(899)999-999",
           "(899)999-99-999"
         ]
       },
       {
         "name": "Irã",
         "code": "+98",
         "iso": "IR",
         "flag": "https://cdn.kcak11.com/CountryFlags/countries/ir.svg",
         "mask": "(999)999-9999"
       },
       {
         "name": "Iraque",
         "code": "+924",
         "iso": "IQ",
         "flag": "https://cdn.kcak11.com/CountryFlags/countries/iq.svg",
         "mask": "(999)999-9999"
       },
       {
         "name": "Irlanda",
         "code": "+353",
         "iso": "IE",
         "flag": "https://cdn.kcak11.com/CountryFlags/countries/ie.svg",
         "mask": "(999)999-999"
       },
       {
         "name": "Ilha de Man",
         "code": "+44",
         "iso": "IM",
         "flag": "https://cdn.kcak11.com/CountryFlags/countries/im.svg",
         "mask": "(9999)999999"
       },
       {
         "name": "Israel",
         "code": "+972",
         "iso": "IL",
         "flag": "https://cdn.kcak11.com/CountryFlags/countries/il.svg",
         "mask": [
           "9-999-9999",
           "59-999-9999"
         ]
       },
       {
         "name": "Itália",
         "code": "+39",
         "iso": "IT",
         "flag": "https://cdn.kcak11.com/CountryFlags/countries/it.svg",
         "mask": "(999)9999-999"
       },
       {
         "name": "Costa do Marfim / Cote d'Ivoire",
         "code": "+225",
         "iso": "CI",
         "flag": "https://cdn.kcak11.com/CountryFlags/countries/ci.svg",
         "mask": "99-999-999"
       },
       {
         "name": "Jamaica",
         "code": "+1",
         "iso": "JM",
         "flag": "https://cdn.kcak11.com/CountryFlags/countries/jm.svg",
         "mask": "(876)999-9999"
       },
       {
         "name": "Japão",
         "code": "+81",
         "iso": "JP",
         "flag": "https://cdn.kcak11.com/CountryFlags/countries/jp.svg",
         "mask": [
           "(999)999-999",
           "99-9999-9999"
         ]
       },
       {
         "name": "Jersey",
         "code": "+44",
         "iso": "JE",
         "flag": "https://cdn.kcak11.com/CountryFlags/countries/je.svg",
         "mask": "(9999)9999-999999"
       },
       {
         "name": "Jordânia",
         "code": "+962",
         "iso": "JO",
         "flag": "https://cdn.kcak11.com/CountryFlags/countries/jo.svg",
         "mask": "9-9999-9999"
       },
       {
         "name": "Cazaquistão",
         "code": "+77",
         "iso": "KZ",
         "flag": "https://cdn.kcak11.com/CountryFlags/countries/kz.svg",
         "mask": [
           "(699)999-99-99",
           "(799)999-99-99"
         ]
       },
       {
         "name": "Quênia",
         "code": "+254",
         "iso": "KE",
         "flag": "https://cdn.kcak11.com/CountryFlags/countries/ke.svg",
         "mask": "999-999999"
       },
       {
         "name": "Kiribati",
         "code": "+686",
         "iso": "KI",
         "flag": "https://cdn.kcak11.com/CountryFlags/countries/ki.svg",
         "mask": "99-999"
       },
       {
         "name": "Coreia, República Popular Democrática da Coreia",
         "code": "+850",
         "iso": "KP",
         "flag": "https://cdn.kcak11.com/CountryFlags/countries/kp.svg",
         "mask": [
           "999-999",
           "9999-9999",
           "99-999-999",
           "999-9999-999",
           "191-999-9999",
           "9999-9999999999999"
         ]
       },
       {
         "name": "Coreia, República da Coreia",
         "code": "+82",
         "iso": "KR",
         "flag": "https://cdn.kcak11.com/CountryFlags/countries/kr.svg",
         "mask": "99-999-9999"
       },
       {
         "name": "Kosovo",
         "code": "+383",
         "iso": "XK",
         "flag": "https://cdn.kcak11.com/CountryFlags/countries/xk.svg",
         "mask": [
           "99-999-999",
           "999-999-999"
         ]
       },
       {
         "name": "Kuwait",
         "code": "+965",
         "iso": "KW",
         "flag": "https://cdn.kcak11.com/CountryFlags/countries/kw.svg",
         "mask": "9999-9999"
       },
       {
         "name": "Quirguistão",
         "code": "+996",
         "iso": "KG",
         "flag": "https://cdn.kcak11.com/CountryFlags/countries/kg.svg",
         "mask": "(999)999-999"
       },
       {
         "name": "Laos",
         "code": "+856",
         "iso": "LA",
         "flag": "https://cdn.kcak11.com/CountryFlags/countries/la.svg",
         "mask": [
           "99-999-999",
           "(2099)999-999"
         ]
       },
       {
         "name": "Letônia",
         "code": "+371",
         "iso": "LV",
         "flag": "https://cdn.kcak11.com/CountryFlags/countries/lv.svg",
         "mask": "99-999-999"
       },
       {
         "name": "Líbano",
         "code": "+961",
         "iso": "LB",
         "flag": "https://cdn.kcak11.com/CountryFlags/countries/lb.svg",
         "mask": [
           "9-999-999",
           "99-999-999"
         ]
       },
       {
         "name": "Lesoto",
         "code": "+266",
         "iso": "LS",
         "flag": "https://cdn.kcak11.com/CountryFlags/countries/ls.svg",
         "mask": "9-999-9999"
       },
       {
         "name": "Libéria",
         "code": "+231",
         "iso": "LR",
         "flag": "https://cdn.kcak11.com/CountryFlags/countries/lr.svg",
         "mask": "99-999-999"
       },
       {
         "name": "Líbia",
         "code": "+218",
         "iso": "LY",
         "flag": "https://cdn.kcak11.com/CountryFlags/countries/ly.svg",
         "mask": [
           "99-999-999",
           "21-999-9999"
         ]
       },
       {
         "name": "Liechtenstein",
         "code": "+423",
         "iso": "LI",
         "flag": "https://cdn.kcak11.com/CountryFlags/countries/li.svg",
         "mask": "(999)999-9999"
       },
       {
         "name": "Lituânia",
         "code": "+370",
         "iso": "LT",
         "flag": "https://cdn.kcak11.com/CountryFlags/countries/lt.svg",
         "mask": "(999)99-999"
       },
       {
         "name": "Luxemburgo",
         "code": "+352",
         "iso": "LU",
         "flag": "https://cdn.kcak11.com/CountryFlags/countries/lu.svg",
         "mask": "(999)999-999"
       },
       {
         "name": "Macau",
         "code": "+853",
         "iso": "MO",
         "flag": "https://cdn.kcak11.com/CountryFlags/countries/mo.svg",
         "mask": "9999-9999"
       },
       {
         "name": "Madagascar",
         "code": "+261",
         "iso": "MG",
         "flag": "https://cdn.kcak11.com/CountryFlags/countries/mg.svg",
         "mask": "99-99-99999"
       },
       {
         "name": "Malawi",
         "code": "+265",
         "iso": "MW",
         "flag": "https://cdn.kcak11.com/CountryFlags/countries/mw.svg",
         "mask": [
           "1-999-999",
           "9-9999-9999"
         ]
       },
       {
         "name": "Malásia",
         "code": "+60",
         "iso": "MY",
         "flag": "https://cdn.kcak11.com/CountryFlags/countries/my.svg",
         "mask": [
           "9-999-999",
           "99-999-999",
           "(999)999-999",
           "99-999-9999"
         ]
       },
       {
         "name": "Maldivas",
         "code": "+960",
         "iso": "MV",
         "flag": "https://cdn.kcak11.com/CountryFlags/countries/mv.svg",
         "mask": "999-9999"
       },
       {
         "name": "Mali",
         "code": "+223",
         "iso": "ML",
         "flag": "https://cdn.kcak11.com/CountryFlags/countries/ml.svg",
         "mask": "99-99-9999"
       },
       {
         "name": "Malta",
         "code": "+356",
         "iso": "MT",
         "flag": "https://cdn.kcak11.com/CountryFlags/countries/mt.svg",
         "mask": "9999-9999"
       },
       {
         "name": "Ilhas Marshall",
         "code": "+692",
         "iso": "MH",
         "flag": "https://cdn.kcak11.com/CountryFlags/countries/mh.svg",
         "mask": "999-9999"
       },
       {
         "name": "Martinica",
         "code": "+596",
         "iso": "MQ",
         "flag": "https://cdn.kcak11.com/CountryFlags/countries/mq.svg",
         "mask": "(999)99-99-99"
       },
       {
         "name": "Mauritânia",
         "code": "+222",
         "iso": "MR",
         "flag": "https://cdn.kcak11.com/CountryFlags/countries/mr.svg",
         "mask": "99-99-9999"
       },
       {
         "name": "Maurício",
         "code": "+230",
         "iso": "MU",
         "flag": "https://cdn.kcak11.com/CountryFlags/countries/mu.svg",
         "mask": "999-9999"
       },
       {
         "name": "Mayotte",
         "code": "+262",
         "iso": "YT",
         "flag": "https://cdn.kcak11.com/CountryFlags/countries/yt.svg",
         "mask": "99999-9999"
       },
       {
         "name": "México",
         "code": "+52",
         "iso": "MX",
         "flag": "https://cdn.kcak11.com/CountryFlags/countries/mx.svg",
         "mask": [
           "99-99-9999",
           "(999)999-9999"
         ]
       },
       {
         "name": "Micronésia, Estados Federados da Micronésia",
         "code": "+691",
         "iso": "FM",
         "flag": "https://cdn.kcak11.com/CountryFlags/countries/fm.svg",
         "mask": "999-9999"
       },
       {
         "name": "Moldávia",
         "code": "+373",
         "iso": "MD",
         "flag": "https://cdn.kcak11.com/CountryFlags/countries/md.svg",
         "mask": "9999-9999"
       },
       {
         "name": "Mônaco",
         "code": "+377",
         "iso": "MC",
         "flag": "https://cdn.kcak11.com/CountryFlags/countries/mc.svg",
         "mask": [
           "99-999-999",
           "(999)999-999"
         ]
       },
       {
         "name": "Mongólia",
         "code": "+976",
         "iso": "MN",
         "flag": "https://cdn.kcak11.com/CountryFlags/countries/mn.svg",
         "mask": "99-99-9999"
       },
       {
         "name": "Montenegro",
         "code": "+382",
         "iso": "ME",
         "flag": "https://cdn.kcak11.com/CountryFlags/countries/me.svg",
         "mask": "99-999-999"
       },
       {
         "name": "Montserrat",
         "code": "+1",
         "iso": "MS",
         "flag": "https://cdn.kcak11.com/CountryFlags/countries/ms.svg",
         "mask": "(664)999-9999"
       },
       {
         "name": "Marrocos",
         "code": "+212",
         "iso": "MA",
         "flag": "https://cdn.kcak11.com/CountryFlags/countries/ma.svg",
         "mask": "99-9999-999"
       },
       {
         "name": "Moçambique",
         "code": "+258",
         "iso": "MZ",
         "flag": "https://cdn.kcak11.com/CountryFlags/countries/mz.svg",
         "mask": "99-999-999"
       },
       {
         "name": "Myanmar",
         "code": "+95",
         "iso": "MM",
         "flag": "https://cdn.kcak11.com/CountryFlags/countries/mm.svg",
         "mask": [
           "999-999",
           "9-999-999",
           "99-999-999"
         ]
       },
       {
         "name": "Namíbia",
         "code": "+224",
         "iso": "NA",
         "flag": "https://cdn.kcak11.com/CountryFlags/countries/na.svg",
         "mask": "99-999-9999"
       },
       {
         "name": "Nauru",
         "code": "+674",
         "iso": "NR",
         "flag": "https://cdn.kcak11.com/CountryFlags/countries/nr.svg",
         "mask": "999-9999"
       },
       {
         "name": "Nepal",
         "code": "+977",
         "iso": "NP",
         "flag": "https://cdn.kcak11.com/CountryFlags/countries/np.svg",
         "mask": "99-999999"
       },
       {
         "name": "Países Baixos",
         "code": "+31",
         "iso": "NL",
         "flag": "https://cdn.kcak11.com/CountryFlags/countries/nl.svg",
         "mask": "(999)999-9999"
       },
       {
         "name": "Nova Caledônia",
         "code": "+687",
         "iso": "NC",
         "flag": "https://cdn.kcak11.com/CountryFlags/countries/nc.svg",
         "mask": "99.99.99"
       },
       {
         "name": "Nova Zelândia",
         "code": "+64",
         "iso": "NZ",
         "flag": "https://cdn.kcak11.com/CountryFlags/countries/nz.svg",
         "mask": [
           "999-999-9999",
           "99-999-999",
           "9999-999-9999"
         ]
       },
       {
         "name": "Nicarágua",
         "code": "+505",
         "iso": "NI",
         "flag": "https://cdn.kcak11.com/CountryFlags/countries/ni.svg",
         "mask": "9999-9999"
       },
       {
         "name": "Níger",
         "code": "+227",
         "iso": "NE",
         "flag": "https://cdn.kcak11.com/CountryFlags/countries/ne.svg",
         "mask": "99-99-99-99"
       },
       {
         "name": "Nigéria",
         "code": "+234",
         "iso": "NG",
         "flag": "https://cdn.kcak11.com/CountryFlags/countries/ng.svg",
         "mask": "9999-999-9999"
       },
       {
         "name": "Niue",
         "code": "+683",
         "iso": "NU",
         "flag": "https://cdn.kcak11.com/CountryFlags/countries/nu.svg",
         "mask": "999"
       },
       {
         "name": "Ilha Norfolk",
         "code": "+672",
         "iso": "NF",
         "flag": "https://cdn.kcak11.com/CountryFlags/countries/nf.svg",
         "mask": "99-999-999"
       },
       {
         "name": "Ilhas Marianas do Norte",
         "code": "+1",
         "iso": "MP",
         "flag": "https://cdn.kcak11.com/CountryFlags/countries/mp.svg",
         "mask": "(670)999-9999"
       },
       {
         "name": "Noruega",
         "code": "+47",
         "iso": "NO",
         "flag": "https://cdn.kcak11.com/CountryFlags/countries/no.svg",
         "mask": "999 99 999"
       },
       {
         "name": "Omã",
         "code": "+968",
         "iso": "OM",
         "flag": "https://cdn.kcak11.com/CountryFlags/countries/om.svg",
         "mask": "9999-9999"
       },
       {
         "name": "Paquistão",
         "code": "+92",
         "iso": "PK",
         "flag": "https://cdn.kcak11.com/CountryFlags/countries/pk.svg",
         "mask": [
           "99-999-999",
           "999-999-9999"
         ]
       },
       {
         "name": "Palau",
         "code": "+680",
         "iso": "PW",
         "flag": "https://cdn.kcak11.com/CountryFlags/countries/pw.svg",
         "mask": "999-9999"
       },
       {
         "name": "Palestina",
         "code": "+970",
         "iso": "PS",
         "flag": "https://cdn.kcak11.com/CountryFlags/countries/ps.svg",
         "mask": [
           "9999-9999",
           "9-999-9999"
         ]
       },
       {
         "name": "Panamá",
         "code": "+507",
         "iso": "PA",
         "flag": "https://cdn.kcak11.com/CountryFlags/countries/pa.svg",
         "mask": "9999-9999"
       },
       {
         "name": "Papua Nova Guiné",
         "code": "+675",
         "iso": "PG",
         "flag": "https://cdn.kcak11.com/CountryFlags/countries/pg.svg",
         "mask": "9999-9999"
       },
       {
         "name": "Paraguai",
         "code": "+595",
         "iso": "PY",
         "flag": "https://cdn.kcak11.com/CountryFlags/countries/py.svg",
         "mask": "9999-999999"
       },
       {
         "name": "Peru",
         "code": "+51",
         "iso": "PE",
         "flag": "https://cdn.kcak11.com/CountryFlags/countries/pe.svg",
         "mask": [
           "999-999-999",
           "999-999-9999"
         ]
       },
       {
         "name": "Filipinas",
         "code": "+63",
         "iso": "PH",
         "flag": "https://cdn.kcak11.com/CountryFlags/countries/ph.svg",
         "mask": [
           "99-999-9999",
           "999-9999"
         ]
       },
       {
         "name": "Ilhas Pitcairn",
         "code": "+64",
         "iso": "PN",
         "flag": "https://cdn.kcak11.com/CountryFlags/countries/pn.svg",
         "mask": "9999"
       },
       {
         "name": "Polônia",
         "code": "+48",
         "iso": "PL",
         "flag": "https://cdn.kcak11.com/CountryFlags/countries/pl.svg",
         "mask": "999-999-999"
       },
       {
         "name": "Portugal",
         "code": "+351",
         "iso": "PT",
         "flag": "https://cdn.kcak11.com/CountryFlags/countries/pt.svg",
         "mask": [
           "999 999 999",
           "99999 9999"
         ]
       },
       {
         "name": "Porto Rico",
         "code": "+1",
         "iso": "PR",
         "flag": "https://cdn.kcak11.com/CountryFlags/countries/pr.svg",
         "mask": "(787)999-9999"
       },
       {
         "name": "Catar",
         "code": "+974",
         "iso": "QA",
         "flag": "https://cdn.kcak11.com/CountryFlags/countries/qa.svg",
         "mask": "9999-9999"
       },
       {
         "name": "Ilha Reunião",
         "code": "+262",
         "iso": "RE",
         "flag": "https://cdn.kcak11.com/CountryFlags/countries/re.svg",
         "mask": "99999-9999"
       },
       {
         "name": "Romênia",
         "code": "+40",
         "iso": "RO",
         "flag": "https://cdn.kcak11.com/CountryFlags/countries/ro.svg",
         "mask": "9999-999-999"
       },
       {
         "name": "Rússia",
         "code": "+7",
         "iso": "RU",
         "flag": "https://cdn.kcak11.com/CountryFlags/countries/ru.svg",
         "mask": [
           "(999)999-99-99",
           "(9999)999-999",
           "(99999)999-999",
           "999-999",
           "9999-999"
         ]
       },
       {
         "name": "Ruanda",
         "code": "+250",
         "iso": "RW",
         "flag": "https://cdn.kcak11.com/CountryFlags/countries/rw.svg",
         "mask": "9999"
       },
       {
         "name": "Santa Helena, Ascensão e Tristão da Cunha",
         "code": "+290",
         "iso": "SH",
         "flag": "https://cdn.kcak11.com/CountryFlags/countries/sh.svg",
         "mask": "99999"
       },
       {
         "name": "Santa Lúcia",
         "code": "+1",
         "iso": "LC",
         "flag": "https://cdn.kcak11.com/CountryFlags/countries/lc.svg",
         "mask": "(758)999-9999"
       },
       {
         "name": "São Martinho",
         "code": "+590",
         "iso": "MF",
         "flag": "https://cdn.kcak11.com/CountryFlags/countries/mf.svg",
         "mask": "9999 99 99 99"
       },
       {
         "name": "São Martinho",
         "code": "+590",
         "iso": "SX",
         "flag": "https://cdn.kcak11.com/CountryFlags/countries/sx.svg",
         "mask": "9999 99 99 99"
       },
       {
         "name": "São Pedro e Miquelão",
         "code": "+508",
         "iso": "PM",
         "flag": "https://cdn.kcak11.com/CountryFlags/countries/pm.svg",
         "mask": "99999"
       },
       {
         "name": "São Vicente e Granadinas",
         "code": "+1",
         "iso": "VC",
         "flag": "https://cdn.kcak11.com/CountryFlags/countries/vc.svg",
         "mask": "(784)999-9999"
       },
       {
         "name": "Samoa",
         "code": "+685",
         "iso": "WS",
         "flag": "https://cdn.kcak11.com/CountryFlags/countries/ws.svg",
         "mask": "99-999"
       },
       {
         "name": "San Marino",
         "code": "+378",
         "iso": "SM",
         "flag": "https://cdn.kcak11.com/CountryFlags/countries/sm.svg",
         "mask": "99 99 99 99"
       },
       {
         "name": "São Tomé e Príncipe",
         "code": "+239",
         "iso": "ST",
         "flag": "https://cdn.kcak11.com/CountryFlags/countries/st.svg",
         "mask": "9999-999"
       },
       {
         "name": "Arábia Saudita",
         "code": "+966",
         "iso": "SA",
         "flag": "https://cdn.kcak11.com/CountryFlags/countries/sa.svg",
         "mask": "9999-999-999"
       },
       {
         "name": "Senegal",
         "code": "+221",
         "iso": "SN",
         "flag": "https://cdn.kcak11.com/CountryFlags/countries/sn.svg",
         "mask": "99-999-99-99"
       },
       {
         "name": "Sérvia",
         "code": "+381",
         "iso": "RS",
         "flag": "https://cdn.kcak11.com/CountryFlags/countries/rs.svg",
         "mask": "999-9999999"
       },
       {
         "name": "Seicheles",
         "code": "+248",
         "iso": "SC",
         "flag": "https://cdn.kcak11.com/CountryFlags/countries/sc.svg",
         "mask": "9-999-999"
       },
       {
         "name": "Serra Leoa",
         "code": "+232",
         "iso": "SL",
         "flag": "https://cdn.kcak11.com/CountryFlags/countries/sl.svg",
         "mask": "(999)999-99"
       },
       {
         "name": "Singapura",
         "code": "+65",
         "iso": "SG",
         "flag": "https://cdn.kcak11.com/CountryFlags/countries/sg.svg",
         "mask": "9999-9999"
       },
       {
         "name": "Sint Maarten",
         "code": "+1",
         "iso": "SX",
         "flag": "https://cdn.kcak11.com/CountryFlags/countries/sx.svg",
         "mask": "(721)999-9999"
       },
       {
         "name": "Eslováquia",
         "code": "+421",
         "iso": "SK",
         "flag": "https://cdn.kcak11.com/CountryFlags/countries/sk.svg",
         "mask": "9999 999 999"
       },
       {
         "name": "Eslovênia",
         "code": "+386",
         "iso": "SI",
         "flag": "https://cdn.kcak11.com/CountryFlags/countries/si.svg",
         "mask": "999 99 99 99"
       },
       {
         "name": "Ilhas Salomão",
         "code": "+677",
         "iso": "SB",
         "flag": "https://cdn.kcak11.com/CountryFlags/countries/sb.svg",
         "mask": "9999"
       },
       {
         "name": "Somália",
         "code": "+252",
         "iso": "SO",
         "flag": "https://cdn.kcak11.com/CountryFlags/countries/so.svg",
         "mask": "9-999-9999"
       },
       {
         "name": "África do Sul",
         "code": "+27",
         "iso": "ZA",
         "flag": "https://cdn.kcak11.com/CountryFlags/countries/za.svg",
         "mask": "999 999 9999"
       },
       {
         "name": "Coreia do Sul",
         "code": "+82",
         "iso": "KR",
         "flag": "https://cdn.kcak11.com/CountryFlags/countries/kr.svg",
         "mask": "999-9999-9999"
       },
       {
         "name": "South Sudan",
         "code": "+211",
         "iso": "SS",
         "flag": "https://cdn.kcak11.com/CountryFlags/countries/ss.svg",
         "mask": "9999"
       },
       {
         "name": "Espanha",
         "code": "+34",
         "iso": "ES",
         "flag": "https://cdn.kcak11.com/CountryFlags/countries/es.svg",
         "mask": [
           "999 999 999",
           "999-999-999",
           "999 999 9999999"
         ]
       },
       {
         "name": "Sri Lanka",
         "code": "+94",
         "iso": "LK",
         "flag": "https://cdn.kcak11.com/CountryFlags/countries/lk.svg",
         "mask": "999 999 9999"
       },
       {
         "name": "São Cristóvão e Nevis",
         "code": "+1",
         "iso": "KN",
         "flag": "https://cdn.kcak11.com/CountryFlags/countries/kn.svg",
         "mask": "(869)999-9999"
       },
       {
         "name": "Santa Lúcia",
         "code": "+1",
         "iso": "LC",
         "flag": "https://cdn.kcak11.com/CountryFlags/countries/lc.svg",
         "mask": "(758)999-9999"
       },
       {
         "name": "São Vicente e Granadinas",
         "code": "+1",
         "iso": "VC",
         "flag": "https://cdn.kcak11.com/CountryFlags/countries/vc.svg",
         "mask": "(784)999-9999"
       },
       {
         "name": "Sudão",
         "code": "+249",
         "iso": "SD",
         "flag": "https://cdn.kcak11.com/CountryFlags/countries/sd.svg",
         "mask": "9999-999-999"
       },
       {
         "name": "Suriname",
         "code": "+597",
         "iso": "SR",
         "flag": "https://cdn.kcak11.com/CountryFlags/countries/sr.svg",
         "mask": "999-9999"
       },
       {
         "name": "Suécia",
         "code": "+46",
         "iso": "SE",
         "flag": "https://cdn.kcak11.com/CountryFlags/countries/se.svg",
         "mask": "999-999 99 99"
       },
       {
         "name": "Suíça",
         "code": "+41",
         "iso": "CH",
         "flag": "https://cdn.kcak11.com/CountryFlags/countries/ch.svg",
         "mask": "999 999 99 99"
       },
       {
         "name": "Síria",
         "code": "+963",
         "iso": "SY",
         "flag": "https://cdn.kcak11.com/CountryFlags/countries/sy.svg",
         "mask": "99-9999-999"
       },
       {
         "name": "Taiwan",
         "code": "+886",
         "iso": "TW",
         "flag": "https://cdn.kcak11.com/CountryFlags/countries/tw.svg",
         "mask": "9999-999-999"
       },
       {
         "name": "Tajiquistão",
         "code": "+992",
         "iso": "TJ",
         "flag": "https://cdn.kcak11.com/CountryFlags/countries/tj.svg",
         "mask": "9999-999-999"
       },
       {
         "name": "Tanzânia",
         "code": "+255",
         "iso": "TZ",
         "flag": "https://cdn.kcak11.com/CountryFlags/countries/tz.svg",
         "mask": "9999-999-999"
       },
       {
         "name": "Tailândia",
         "code": "+66",
         "iso": "TH",
         "flag": "https://cdn.kcak11.com/CountryFlags/countries/th.svg",
         "mask": "999-999-9999"
       },
       {
         "name": "Timor-Leste",
         "code": "+670",
         "iso": "TL",
         "flag": "https://cdn.kcak11.com/CountryFlags/countries/tl.svg",
         "mask": "9999-9999"
       },
       {
         "name": "Togo",
         "code": "+228",
         "iso": "TG",
         "flag": "https://cdn.kcak11.com/CountryFlags/countries/tg.svg",
         "mask": "99-99-9999"
       },
       {
         "name": "Tokelau",
         "code": "+690",
         "iso": "TK",
         "flag": "https://cdn.kcak11.com/CountryFlags/countries/tk.svg",
         "mask": "9999"
       },
       {
         "name": "Tonga",
         "code": "+676",
         "iso": "TO",
         "flag": "https://cdn.kcak11.com/CountryFlags/countries/to.svg",
         "mask": "999-9999"
       },
       {
         "name": "Trinidad e Tobago",
         "code": "+1",
         "iso": "TT",
         "flag": "https://cdn.kcak11.com/CountryFlags/countries/tt.svg",
         "mask": "(868)999-9999"
       },
       {
         "name": "Tunísia",
         "code": "+216",
         "iso": "TN",
         "flag": "https://cdn.kcak11.com/CountryFlags/countries/tn.svg",
         "mask": "99-999-999"
       },
       {
         "name": "Turquia",
         "code": "+90",
         "iso": "TR",
         "flag": "https://cdn.kcak11.com/CountryFlags/countries/tr.svg",
         "mask": "9999-999-9999"
       },
       {
         "name": "Turcomenistão",
         "code": "+993",
         "iso": "TM",
         "flag": "https://cdn.kcak11.com/CountryFlags/countries/tm.svg",
         "mask": "99-999999"
       },
       {
         "name": "Ilhas Turcas e Caicos",
         "code": "+1",
         "iso": "TC",
         "flag": "https://cdn.kcak11.com/CountryFlags/countries/tc.svg",
         "mask": "(649)999-9999"
       },
       {
         "name": "Tuvalu",
         "code": "+688",
         "iso": "TV",
         "flag": "https://cdn.kcak11.com/CountryFlags/countries/tv.svg",
         "mask": "999999"
       },
       {
         "name": "Uganda",
         "code": "+256",
         "iso": "UG",
         "flag": "https://cdn.kcak11.com/CountryFlags/countries/ug.svg",
         "mask": "9999"
       },
       {
         "name": "Ucrânia",
         "code": "+380",
         "iso": "UA",
         "flag": "https://cdn.kcak11.com/CountryFlags/countries/ua.svg",
         "mask": "999 999 9999"
       },
       {
         "name": "Emirados Árabes Unidos",
         "code": "+971",
         "iso": "AE",
         "flag": "https://cdn.kcak11.com/CountryFlags/countries/ae.svg",
         "mask": "999-999-9999"
       },
       {
         "name": "Reino Unido",
         "code": "+44",
         "iso": "GB",
         "flag": "https://cdn.kcak11.com/CountryFlags/countries/gb.svg",
         "mask": "99999 999999"
       },
       {
         "name": "Estados Unidos da América",
         "code": "+1",
         "iso": "US",
         "flag": "https://cdn.kcak11.com/CountryFlags/countries/us.svg",
         "mask": "(999)999-9999"
       },
       {
         "name": "Uruguai",
         "code": "+598",
         "iso": "UY",
         "flag": "https://cdn.kcak11.com/CountryFlags/countries/uy.svg",
         "mask": "999 999 999"
       },
       {
         "name": "Uzbequistão",
         "code": "+998",
         "iso": "UZ",
         "flag": "https://cdn.kcak11.com/CountryFlags/countries/uz.svg",
         "mask": "99 999 99 99"
       },
       {
         "name": "Vanuatu",
         "code": "+678",
         "iso": "VU",
         "flag": "https://cdn.kcak11.com/CountryFlags/countries/vu.svg",
         "mask": "999 9999"
       },
       {
         "name": "Venezuela",
         "code": "+58",
         "iso": "VE",
         "flag": "https://cdn.kcak11.com/CountryFlags/countries/ve.svg",
         "mask": "9999-999999"
       },
       {
         "name": "Vietnã",
         "code": "+84",
         "iso": "VN",
         "flag": "https://cdn.kcak11.com/CountryFlags/countries/vn.svg",
         "mask": "999 999 99 99"
       },
       {
         "name": "Wallis e Futuna",
         "code": "+681",
         "iso": "WF",
         "flag": "https://cdn.kcak11.com/CountryFlags/countries/wf.svg",
         "mask": "99 99 99"
       },
       {
         "name": "Iêmen",
         "code": "+967",
         "iso": "YE",
         "flag": "https://cdn.kcak11.com/CountryFlags/countries/ye.svg",
         "mask": "999-999-999"
       },
       {
         "name": "Zâmbia",
         "code": "+260",
         "iso": "ZM",
         "flag": "https://cdn.kcak11.com/CountryFlags/countries/zm.svg",
         "mask": "9999-999999"
       },
       {
         "name": "Zimbábue",
         "code": "+263",
         "iso": "ZW",
         "flag": "https://cdn.kcak11.com/CountryFlags/countries/zw.svg",
         "mask": "999 999 999"
       }
];