import { useMemo, useState, useEffect } from "react";
import { TOKEN_KEY, API_URL, BANK_CODES } from "./../../constants";
import { replaceAccents } from "./../../functions";
import { useParams } from "react-router-dom";
import {
    useShow,
    HttpError,
    IResourceComponentsProps,
    useTranslate,
    useNavigation,
    useCustom
} from "@refinedev/core";

import {
    useTable,
    List,
    TextField,
    getDefaultSortOrder,
    NumberField,
    DateField,
    BooleanField,
    Show,
    Breadcrumb,
    CreateButton,
    useModal,    
    Create,
    useForm,
    useModalForm,
    useEditableTable,
    SaveButton,
    EditButton    
} from "@refinedev/antd";

import {
    UserOutlined,
    PhoneOutlined,
    CalendarOutlined,
    CheckOutlined,
    PicLeftOutlined,
    BankOutlined,
    PlusSquareOutlined,
    TagOutlined,
    FormOutlined,
    MoreOutlined,
    EditOutlined
} from "@ant-design/icons";
import {
    Typography,
    Avatar,
    Row,
    Col,
    Card,
    Space,
    Table,
    Grid,
    Popover,
    Form,
    FormProps,
    Modal,
    Input,
    Select,
    Button,
    Alert,
    Menu,
    Dropdown,
    Checkbox,
    Tag
} from "antd";
import type { SelectProps } from 'antd';

const { Text } = Typography;

import { InvoiceStatus, AccountStatus } from "components";

import { ICustomer, IInvoice, IInvoiceFilterVariables, IAccount } from "interfaces";

const { useBreakpoint } = Grid;

import BankAccountValidator from "br-bank-account-validator";

export const CustomerShow: React.FC<IResourceComponentsProps> = () => {
    const t = useTranslate();
    const { show, edit } = useNavigation();
    const {id} = useParams();
    const { xl } = useBreakpoint();
    const { queryResult } = useShow<ICustomer>();

    const { data } = queryResult;
    const customer = data?.data;

    const [open, setOpen] = useState(false);
    const [bankCodes, setBankCodes] = useState([] as any[]);

    // useEffect(() => {
    //     let bankCodesFormatted = BANK_CODES.filter(item => item.strCode).map((item : any) => { return { label: `${item.name} (${item.strCode})`, value: item.strCode, mask: item.mask}})
    //     setBankCodes(bankCodesFormatted);
    // },[bankCodes]);

    const TrueIcon = () => <span>✅</span>;

    const FalseIcon = () => <span>❌</span>;

    const renderInvoices = () => {
        const { tableProps, sorter } = useTable<
            IInvoice,
            HttpError,
            IInvoiceFilterVariables
        >({
            resource: "invoices",
            initialSorter: [
                {
                    field: "createdAt",
                    order: "desc",
                },
            ],
            permanentFilter: [
                {
                    field: "customerId",
                    operator: "eq",
                    value: customer?.id,
                },
            ],
            initialPageSize: 4,
            queryOptions: {
                enabled: customer !== undefined,
            },
            syncWithLocation: false,
        });

        if(["payer","all"].includes(customer?.payerOrReceiver ?? "") ?? false){
            return (
                <List
                            title={t("invoices.invoices")}
                            headerProps={{
                                extra: <></>,
                            }}
                            breadcrumb={<></>}
                        >
                            <Table {...tableProps}
                                rowKey="id"
                                onRow={(record) => {
                                    return {
                                        onClick: () => {
                                            show("invoices", record.id);
                                        },
                                    };
                                }}>
                                <Table.Column
                                    key="id"
                                    dataIndex="id"
                                    title={t("invoices.fields.id")}
                                    render={(value) => <TextField value={value} />}
                                />
                                <Table.Column
                                    key="statusText"
                                    dataIndex="statusText"
                                    title={t("invoices.fields.statusText")}
                                    render={(value) => {
                                        return <InvoiceStatus status={value} />;
                                    }}
                                />
                                {/* <Table.Column
                                    align="right"
                                    key="amount"
                                    dataIndex="amount"
                                    title={t("invoices.fields.amount")}
                                    defaultSortOrder={getDefaultSortOrder(
                                        "amount",
                                        sorter,
                                    )}
                                    sorter
                                    render={(value) => {
                                        return (
                                            <NumberField
                                                options={{
                                                    currency: "USD",
                                                    style: "currency",
                                                    notation: "compact",
                                                }}
                                                value={value}
                                            />
                                        );
                                    }}
                                />
                                <Table.Column
                                    key="user.fullName"
                                    dataIndex={["user", "fullName"]}
                                    title={t("invoices.fields.user")}
                                />
                                <Table.Column<IInvoice>
                                    key="products"
                                    dataIndex="products"
                                    title={t("invoices.fields.products")}
                                    render={(_, record) => (
                                        <Popover
                                            content={
                                                <ul>
                                                    {record.products.map(
                                                        (product) => (
                                                            <li key={product.id}>
                                                                {product.name}
                                                            </li>
                                                        ),
                                                    )}
                                                </ul>
                                            }
                                            title="Products"
                                            trigger="hover"
                                        >
                                            {t("invoices.fields.itemsAmount", {
                                                amount: record.products.length,
                                            })}
                                        </Popover>
                                    )}
                                />  */}
                                <Table.Column
                                align="left"
                                key="gross"
                                dataIndex="gross"
                                title={t("invoices.fields.gross")}
                                defaultSortOrder={getDefaultSortOrder(
                                    "total",
                                    sorter,
                                )}
                                sorter
                                render={(value) => {
                                    return (
                                        <NumberField
                                            options={{
                                                currency: "BRL",
                                                style: "currency",
                                            }}
                                            value={value / 100}
                                        />
                                    );
                                }}
                            />
                                <Table.Column
                                    key="createdAt"
                                    dataIndex="createdAt"
                                    title={t("invoices.fields.createdAt")}
                                    render={(value) => (
                                        <DateField value={value} format="LLL" />
                                    )}
                                    sorter
                                    defaultSortOrder={getDefaultSortOrder(
                                        "createdAt",
                                        sorter,
                                    )}
                                />
                            </Table>
                        </List>
            );
        } else {
            return;
        }
    }

    const ModalAccountCreate: React.FC<IResourceComponentsProps> = () => {
        const t = useTranslate();
        const [bank, setBank] = useState([] as any[]);
        const [errorMsg, setErrorMsg] = useState(null);        
        const {id} = useParams();
        // const { formProps, saveButtonProps, queryResult } = useModalForm<IAccount>({
        //     resource: "accounts",
        //     redirect: false,
        // });

        const {
            // saveButtonProps,
            modalProps,
            formProps,
            // show: createModalShow,
            // formLoading: createFormLoading,
        } = useModalForm<IAccount>({            
            action: "create",
            resource: "accounts",
            redirect: false,
            autoSubmitClose: true,
            // autoResetForm: true,
            warnWhenUnsavedChanges: true,
            errorNotification: (data, values, resource) => {
                console.log({data, values, resource});
                let errorMsg = data?.response?.data?.errors.map((item : any) => item.message)?.join(" / ");
                setErrorMsg(errorMsg);
                return {
                    message: errorMsg,
                    description: "Erro",
                    type: "error",
                };
            },
            successNotification: (data, values) => {
                setOpen(false);
                return {
                    message: `Conta Criada com sucesso!`,
                    description: "Uma nova conta de cliente foi adicionada com sucesso",
                    type: "success",
                };
            },
            // syncWithLocation: true,
        });        

        // redirect("show",id);
        console.log(modalProps);
        console.log(formProps);

        // useEffect(() => {
            let bankCodes = BANK_CODES.filter(item => item.strCode).map((item : any) => { return { label: `${item.name} (${item.strCode})`, name: item.displayName, code: ("00" + item.strCode).slice(-3), value: ("00" + item.strCode).slice(-3), mask: item.mask}})
            // setBankCodes(bankCodesFormatted);
        // });

        const handleBankChange = (value: any, option: any) => {
            console.log("zzz",value,option);
            formProps.form?.setFields([
                {
                name: "bank", // required
                value: option?.label
                },
            ]);
        };

        const validateBankAccount = async () => {
            // const form = Form.useFormInstance();
            console.log(formProps.form?.getFieldsValue());
            let values : any = formProps.form?.getFieldsValue();
            if(values.bankCode && values.agency && values.account){
                try {
                    let bank = BankAccountValidator.validate({
                        bankNumber: "00" + values.bankCode.slice(-3),
                        agencyNumber: values.agency,
                        agencyCheckNumber: values.agencyDigit,
                        accountNumber: values.account,
                        accountCheckNumber: values.accountDigit,
                    });
                    // Se chegou até aqui, a conta bancária é válida
                    console.log("aaaaaa",bank);
                    setErrorMsg(null);
                    let fields = ["agency","agencyDigit","account","accountDigit"];
                    fields.map(item => {
                        formProps.form?.setFields([
                            {
                            name: item, // required
                            errors: [],
                            },
                        ]);
                    });
                } catch (e : any) {
                    // se não for válida, lança uma exceção
                    console.log("eeeeee",e?.message);
                    let fields = [];
                    if(e?.message.includes("Agência inválida")) fields.push({ name: "agency", error: "Agência Inválida" })
                    if(e?.message.includes("Dígito da agência inválido")) fields.push({ name: "agencyDigit", error: "Dígito da agência inválido" })
                    if(e?.message.includes("Conta corrente inválida")) fields.push({ name: "account", error: "Conta corrente inválida" })
                    if(e?.message.includes("Dígito da conta corrente inválido")) fields.push({ name: "accountDigit", error: "Dígito da conta corrente inválido" })

                    fields.map(item => {
                        formProps.form?.setFields([
                            {
                            name: item.name, // required
                            errors: [item.error],
                            },
                        ]);
                    });

                    let msg = fields.map(item => item.error)?.join("<br>") || null;
                    console.log(msg);
                    setErrorMsg(e?.message);
                }
            }
        }   

        return (
            <Modal
                {...modalProps}
                forceRender
                getContainer={false} 
                open={open}
                title={t("accounts.titles.create")}
                okText={t("buttons.create")}
                // okButtonProps={{ disabled: true }}
                cancelText={t("buttons.cancel")}
                onCancel={() => {
                    setOpen(false);
                }}
                // footer={<></>}                
            >
                {/* <Create
                    resource="accounts"
                    isLoading={queryResult?.isFetching}
                    // saveButtonProps={saveButtonProps}
                    headerProps={{
                        extra: <></>,
                    }}
                    breadcrumb={<></>}
                    // footerButtons={({ defaultButtons }) => (
                    //     <>                            
                    //         <Button type="primary"
                    //         danger
                    //         onClick={() => {
                    //             setOpen(true);
                    //         }}>
                    //             {t("buttons.cancel")}
                    //         </Button>
                    //         {defaultButtons}
                    //     </>
                    // )}
                    title={
                        <Text style={{ fontSize: 22, fontWeight: 800 }}>
                            {customer?.name}
                        </Text>
                    }> */}
                    <Form
                        {...formProps}
                        layout="vertical"
                        onValuesChange={validateBankAccount}                                             
                        // onFinish={() => {
                        //     // setOpen(false);
                        //     // location.reload();
                        //     setErrorMsg(null)
                        // }}
                        // onFinishFailed={onFinishFailed}
                        // onSuccess={() => {
                        //     setOpen(false);
                        // }}
                        onError={() => {
                            setOpen(false);
                        }}
                        initialValues={{
                            active: true,
                            customerId: id                 
                        }}
                        style={{ marginTop: 30 }}
                    >
                        <Row gutter={[64, 0]} wrap>
                            <Col xs={24} lg={24}>
                                <Form.Item
                                        label={t("accounts.fields.customerId")}
                                        name="customerId"
                                        rules={[
                                            {
                                                required: false,
                                            },
                                        ]}
                                        hidden={true}
                                    >
                                        <Input />                        
                                </Form.Item>
                                <Form.Item
                                    label={t("accounts.fields.name")}
                                    name="name"
                                    rules={[
                                        {
                                            required: true,
                                        },
                                    ]}
                                >
                                    <Input />
                                </Form.Item>                        
                            {/* </Col>
                            <Col xs={24} lg={12}> */}
                                <Form.Item
                                    label={t("accounts.fields.bank")}
                                    name="bankCode"
                                    rules={[
                                        {
                                            required: true,
                                        },
                                    ]}
                                >
                                    <Select
                                        showSearch
                                        // defaultValue=""
                                        onChange={handleBankChange}
                                        // onSelect={(value) => {
                                        //     console.log("onSelect",value);
                                        //     setBank(value?.label);
                                        // }}
                                        options={bankCodes}
                                        optionFilterProp="children"
                                        filterOption={(input, option) =>
                                            // (option?.label ?? '').includes(input) || (option?.name ?? '').includes(input)
                                            (replaceAccents(option?.label ?? '').toLowerCase().indexOf(replaceAccents(input).toLowerCase()) >= 0) || (replaceAccents(option?.name ?? '').toLowerCase().indexOf(replaceAccents(input).toLowerCase()) >= 0)
                                        }
                                        filterSort={(optionA, optionB) =>
                                        (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                                        }
                                    />
                                </Form.Item>
                                <Form.Item
                                        label={t("accounts.fields.bank")}
                                        name="bank"
                                        rules={[
                                            {
                                                required: false,
                                            },
                                        ]}
                                        hidden={true}
                                    >
                                        <Input />                        
                                </Form.Item>
                                <Space.Compact style={{ width: '100%' }}>
                                    <Form.Item
                                        label={t("accounts.fields.agency")}
                                        name="agency"                                
                                        style={{ width: '70%' }}
                                        rules={[
                                            {
                                                required: true,
                                            },
                                        ]}
                                    >
                                        <Input />                        
                                    </Form.Item>
                                    <Form.Item
                                        label={t("accounts.fields.agencyDigit")}
                                        name="agencyDigit"
                                        style={{ width: '30%' }}
                                        rules={[
                                            {
                                                required: false,
                                            },
                                        ]}
                                    >
                                        <Input />                        
                                    </Form.Item>
                                </Space.Compact>
                                <Space.Compact style={{ width: '100%' }}>
                                    <Form.Item
                                        label={t("accounts.fields.account")}
                                        name="account"
                                        style={{ width: '70%' }}
                                        rules={[
                                            {
                                                required: true,
                                            },
                                            {
                                            // ({ getFieldValue }) => ({
                                                validator(_, value) {
                                                  console.log(value);
                                                  if (!value) {
                                                    return Promise.reject('Oops! Insira o número da conta corrente');
                                                  } else if (value && value?.includes("-")) {
                                                    return Promise.reject(`Oops! O dígito da agência deve ser informado no campo ao lado`);
                                                  }
                                                  return Promise.resolve();
                                                },
                                            // }),
                                            }
                                        ]}
                                    >
                                        <Input />
                                    </Form.Item>
                                    <Form.Item
                                        label={t("accounts.fields.accountDigit")}
                                        name="accountDigit"
                                        style={{ width: '30%' }}
                                        rules={[
                                            {
                                                required: false,
                                            },
                                        ]}
                                    >
                                        <Input />
                                    </Form.Item>
                                </Space.Compact>
                                <Form.Item
                                        label={t("accounts.fields.type")}
                                        name="type"
                                        rules={[
                                            {
                                                required: true,
                                            },
                                        ]}
                                    >
                                    <Select
                                        showSearch
                                        defaultActiveFirstOption={true}
                                        placeholder=""
                                        optionFilterProp="children"
                                        // onChange={onChange}
                                        // onSearch={onSearch}
                                        filterOption={(input, option) =>
                                        (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                        }
                                        options={[
                                            {
                                                value: 'conta_corrente',
                                                label: 'Conta Corrente',
                                            },
                                            {
                                                value: 'conta_poupanca',
                                                label: 'Conta Poupança',
                                            },
                                            // {
                                            //     value: 'conta_corrente_conjunta',
                                            //     label: 'Conta Corrente Conjunta',
                                            // },
                                            // {
                                            //     value: 'conta_poupanca_conjunta',
                                            //     label: 'Conta Poupança Conjunta',
                                            // },
                                        ]}
                                    />
                                </Form.Item>
                                <Text style={{ color: 'green' }}>* Esta conta deve ser da mesma titularidade do CNPJ/CPF do cliente</Text>
                                {errorMsg && (
                                <Space direction="vertical" style={{ width: '100%' }}>
                                    <Alert
                                    message="ATENÇÃO"
                                    description={errorMsg}
                                    type="error"
                                    showIcon
                                    />
                                </Space>
                                )}
                            </Col>
                        </Row>
                    </Form>
                {/* </Create> */}
            </Modal>
        );
    };

    const renderAccounts = () => {

        const {id} = useParams();
        const {
            tableProps,
            formProps,
            isEditing,
            saveButtonProps,
            cancelButtonProps,
            setId: setEditId
        } = useEditableTable<
            IAccount,
            HttpError
        >({
            resource: "accounts",
            redirect: false,
            initialSorter: [
                {
                    field: "createdAt",
                    order: "desc",
                },
            ],
            permanentFilter: [
                {
                    field: "customerId",
                    operator: "eq",
                    value: id ?? null,
                },
            ],
            initialPageSize: 4,
            queryOptions: {
                enabled: customer !== undefined,
            },
            syncWithLocation: false,
        });        

        console.log("tableProps",tableProps); 

        const Actions: React.FC = () => (
            <CreateButton onClick={() => {
                setOpen(true);
            }}>Incluir Conta </CreateButton>
        );

        const moreMenu = (record: IAccount) => (
            <Menu
                mode="vertical"
                onClick={({ domEvent }) => domEvent.stopPropagation()}
            >
                <Menu.Item
                    key="accept"
                    style={{
                        fontSize: 15,
                        display: "flex",
                        alignItems: "center",
                        fontWeight: 500,
                    }}
                    icon={
                        <FormOutlined
                            style={{
                                color: "#52c41a",
                                fontSize: 17,
                                fontWeight: 500,
                            }}
                        />
                    }
                    onClick={() => {
                        setEditId?.(record.id);
                    }}
                >
                    {t("buttons.edit")}
                </Menu.Item>
            </Menu>
        );

        return (
            <List
                title={"Contas Bancárias do Cliente"}
                headerProps={{
                    extra: <Actions />,
                    }}                    
                breadcrumb={<></>}
        >
                        {(!tableProps?.dataSource?.length || tableProps?.dataSource?.filter((item : any) => item.status===1)?.length===0) &&
                            (<Space direction="vertical" style={{ width: '100%', marginBottom: 20 }}>
                                <Alert
                                message="ATENÇÃO"
                                description={t("accounts.titles.empty")}
                                type="error"
                                showIcon
                                />
                            </Space>)
                            
                        }
                        {tableProps?.dataSource?.find((item : any) => item.status===0) &&
                            (<Space direction="vertical" style={{ width: '100%', marginBottom: 20 }}>
                                <Alert
                                    message="Conta Em Análise: após inserir uma nova conta, ela passará por uma análise de aprovação de cadastro. (Média de 5 minutos. Máxima em até 1 dia útil)"
                                    // description="Additional description and information about copywriting."
                                    type="info"
                                    showIcon/>
                            </Space>)
                        }
                        <Form {...formProps}>
                            <Table {...tableProps}
                                rowKey="id"
                                onRow={(record) => ({
                                    // eslint-disable-next-line
                                    onClick: (event: any) => {
                                        if (event.target.nodeName === "TD") {
                                            setEditId && setEditId(record.id);
                                        }
                                    },
                                })}
                                >
                            <Table.Column
                                key="name"
                                dataIndex="name"
                                title={t("accounts.fields.name")}
                                render={(value) => <TextField value={value} />}
                            />
                            <Table.Column
                                    key="bank"
                                    dataIndex="bank"
                                    title={t("accounts.fields.bank")}
                                    render={(value) => <TextField value={value} />}
                                />
                            <Table.Column
                                    key="agency"
                                    dataIndex="agency"
                                    title={t("accounts.fields.agency")}
                                    render={(value) => <TextField value={value} />}
                                />
                            <Table.Column
                                    key="account"
                                    dataIndex="account"
                                    title={t("accounts.fields.account")}
                                    render={(value) => <TextField value={value} />}
                                />        
                            <Table.Column
                                key="typeText"
                                dataIndex="typeText"
                                title={t("accounts.fields.type")}
                                render={(value) => <TextField value={value} />}
                                />
                            <Table.Column
                                key="statusText"
                                dataIndex="statusText"
                                title={t("accounts.fields.statusText")}
                                render={(value) => {
                                    return <AccountStatus status={value} />;
                                }}
                                />                                                                                                                                                 
                            <Table.Column
                                key="active"
                                dataIndex="active"
                                title={t("accounts.fields.active.title")}
                                /*  render={(value) => <BooleanField value={value} />} */
                                render={(value, data: IAccount) => {
                                    if (isEditing(data.id)) {
                                        return (
                                            <Form.Item
                                                name="active"
                                                style={{ margin: 0 }}
                                                valuePropName="checked"
                                            >
                                                <Checkbox />
                                            </Form.Item>
                                        );
                                    }
                                    return <BooleanField
                                    value={value}
                                    trueIcon={<TrueIcon />}
                                    falseIcon={<FalseIcon />}/>
                                }}
                            />
                            {/* <Table.Column<IAccount>
                                title={t("table.actions")}
                                dataIndex="actions"
                                key="actions"
                                align="center"
                                render={(_text, record) => {
                                    if (isEditing(record.id)) {
                                        return (
                                            <Space>
                                                <SaveButton
                                                    {...saveButtonProps}
                                                    size="small"
                                                />
                                                <Button
                                                    {...cancelButtonProps}
                                                    size="small"
                                                >
                                                    {t("buttons.cancel")}
                                                </Button>
                                            </Space>
                                        );
                                    }
                                    return (
                                        <Dropdown
                                            overlay={moreMenu(record)}
                                            trigger={["click"]}
                                        >
                                            <MoreOutlined
                                                onClick={(e) => e.stopPropagation()}
                                                style={{
                                                    fontSize: 24,
                                                }}
                                            />
                                        </Dropdown>
                                    );
                                }}
                            /> */}
                            <Table.Column<IAccount>
                            title="Opções"
                            dataIndex="actions"
                            render={(_, record) => {
                                if (isEditing(record.id)) {
                                    return (
                                        <Space>
                                            <SaveButton
                                                {...saveButtonProps}
                                                size="small"
                                            />
                                            <Button
                                                {...cancelButtonProps}
                                                size="small"
                                            >
                                                {t("buttons.cancel")}
                                            </Button>
                                        </Space>
                                    );
                                }
                                return (                        
                                <Space>
                                    <EditButton
                                        hideText
                                        size="large"
                                        recordItemId={record.id}
                                        onClick={() => {
                                            setEditId?.(record.id);
                                        }}
                                    />
                                    {/* <ShowButton
                                        hideText
                                        size="large"
                                        recordItemId={record.id}
                                    /> */}
                                </Space>
                            )}}
                            />
                            </Table>
                        </Form>
                                    
                        {(canAccess) && (
                        <Button block 
                            type="primary"
                            icon={<PlusSquareOutlined />} 
                            style={{ marginTop: 10, marginBottom: 30 }}
                            onClick={() => setOpen(true)}
                        >
                            Incluir Conta
                        </Button>
                        )}
                    </List>                    

        );
    }

    let canAccess = ["manager","admin","owner"].includes(localStorage.getItem("role") ?? "");

    return (
        <>
            <Show breadcrumb={<Breadcrumb />}>
                <Row gutter={[16, 16]} style={{}}>                
                    <Col xl={6} lg={24} xs={24}
                        style={{ backgroundColor: "#fafafa",}}>
                        <Card
                            bordered={false}
                            style={{
                                height: "100%",
                                backgroundColor: "#fafafa"
                            }}                        
                        >
                            <Space
                                direction="vertical"
                                style={{
                                    width: "100%",
                                    height: "100%",
                                }}
                            >
                                <Space
                                    direction="vertical"
                                    style={{
                                        textAlign: "center",
                                        width: "100%",
                                    }}
                                >
                                    {/* <Avatar
                                        size={120}
                                        src={customer?.avatar?.[0].url}
                                    ></Avatar> */}
                                    <Typography.Title level={3}>
                                        {customer?.name}
                                    </Typography.Title>
                                    <Typography.Title level={4}>
                                        ({customer?.razaoSocial})
                                    </Typography.Title>
                                </Space>
                                <Space
                                    direction="vertical"
                                    style={{
                                        width: "100%",
                                        textAlign: xl ? "unset" : "center",
                                    }}
                                >   
                                    <Typography.Text>
                                        <PicLeftOutlined /> {t("customers.fields.cpfCnpj")}: {customer?.cpfCnpj}
                                    </Typography.Text>                             
                                    <Typography.Text>
                                        <PhoneOutlined /> {t("customers.fields.phone")}: {customer?.phone}
                                    </Typography.Text>
                                    {/* <Typography.Text>
                                        <CalendarOutlined /> {t("customers.fields.createdAt")}: {customer?.createdAt}
                                    </Typography.Text> */}
                                    <Typography.Text>
                                        <BankOutlined /> {t("customers.fields.payerOrReceiver.title")}: <Tag color="purple">{customer?.payerOrReceiverText}</Tag>
                                    </Typography.Text>
                                    <Typography.Text>
                                        {/* <CheckOutlined /> */}
                                        {`${'Status:    '}`}
                                        {customer?.active
                                            ? (<>{  }<TrueIcon /> <Text>Cliente Ativado</Text></>)
                                            : (<>{  }<FalseIcon /> <Text>Cliente Inativado</Text></>)}
                                    </Typography.Text>
                                    {/* {renderTags()} */}
                                    <Button
                                            onClick={() => edit("customers", id ?? "")}
                                            icon={<EditOutlined />}
                                            block
                                    >
                                            Editar Dados Cliente
                                    </Button>
                                </Space>
                            </Space>
                        </Card>                    
                    </Col>
                    <Col xl={18} xs={24}>
                        {renderAccounts()}
                        {renderInvoices()}
                    </Col>
                </Row>
            </Show>
            <ModalAccountCreate/>
        </>
    );
};



    // const useTag = () => {
    //     const addTag = () => {
    //         // console.log(`selected ${value}`);
    //         const {id} = useParams();
    //         const url = `${API_URL}/customerTags`;
    //         useCustom<{data: any;}>({ url, method: "post", config: {
    //             payload: {
    //                 // customerId: id,
    //                 // tagId: Number(value)
    //             },
    //         }});
    //     }
    //     const removeTag = () => {
    //         // console.log(`selected ${value}`);
    //         const {id} = useParams();
    //         const url = `${API_URL}/customerTags`;
    //         useCustom<{data: any;}>({ url, method: "delete", config: {
    //             payload: {
    //                 // customerId: id,
    //                 // tagId: Number(value)
    //             },
    //         }});
    //     };
    //     return { addTag, removeTag }
    // };

    // const renderTags = () => {
        
    //     const t = useTranslate();
    //     const { addTag, removeTag } = useTag();
    //     // const [defaulTags, setTags] = useState([]);    
    
    //     // Pega Tags Empresa
    //     const companyId = localStorage.getItem("companyId");
    //     const url = `${API_URL}/tags`;
    //     const { data } = useCustom<{data: any;}>({ url, method: "get"});
    //     const result : any = data?.data;                
    //     console.log(result);
    //     var tags: SelectProps['options'] = [];
    //     tags = result?.filter((item : any) => item.active)?.map(
    //                     (item : any) => {
    //                         return {
    //                             value: String(item.id),
    //                             label: `${item.name}`,
    //                             disabled: !item.active ? true : false
    //                         }
    //                     }
    //     );
    //     console.log("tags",tags);
    //     var defaulTags = customer?.customerTags?.map((item: any) => { return String(item.tagId) }) ?? [];        
    //     console.log("defaulTags",defaulTags);
    //     // setTags(defaulTagsData)

    //     return (
    //                 <Space style={{ width: '100%' }} direction="vertical">
    //                             <Typography.Text>
    //                                 <TagOutlined style={{ marginRight: 10}}/>
    //                                 Categorias:
    //                             </Typography.Text>
    //                             <Select
    //                                 // showSearch
    //                                 mode="multiple"
    //                                 allowClear
    //                                 placeholder=""
    //                                 // optionFilterProp="children"
    //                                 defaultValue={defaulTags}
    //                                 onSelect={addTag}
    //                                 onDeselect={removeTag}
    //                                 // defaultActiveFirstOption={true}
    //                                 // onChange={onChange}
    //                                 // onSearch={onSearch}
    //                                 // filterOption={(input, option) =>
    //                                 // (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
    //                                 // }
    //                                 style={{ width: '300px', marginLeft: 10 }}
    //                                 options={tags}
    //                             />                                                   
    //                 </Space> 
    //     );
    // }