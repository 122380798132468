export const replaceAccents = (str : any) => {

    if(!str) return null;
    // Normalize the string and remove combining diacritical marks
    const normalizedStr = str?.normalize('NFD')?.replace(/[\u0300-\u036f]/g, '');

    // Replace accented characters with non-accented equivalents
    const replacedStr = normalizedStr?.replace(/[áàäâ]/gi, 'a')
                                    .replace(/[éèëê]/gi, 'e')
                                    .replace(/[íìïî]/gi, 'i')
                                    .replace(/[óòöô]/gi, 'o')
                                    .replace(/[úùüû]/gi, 'u')
                                    .replace(/[ç]/gi, 'c');

    return replacedStr;
    
}

export function currencyFormatter(value : any){
    
    if(!value) return;

    return new Intl.NumberFormat("pt-br", {
      style: "currency",
      currency: "BRL"
    }).format(value);
}