import React from "react";
import { useState, useEffect } from "react";
import { TOKEN_KEY, API_URL, PHONE_CODES } from "./../../../constants";
import {
  RegisterPageProps,
  RegisterFormTypes,
  useRouterType,
  useLink,
  useActiveAuthProvider,
  useTranslate,
  useRouterContext,
  useRegister,
  useCustomMutation
} from "@refinedev/core";
import { ThemedTitle, useForm } from "@refinedev/antd";
import {
  layoutStyles,
  containerStyles,
  titleStyles,
  headStyles,
  bodyStyles,
} from "./styles";
import {
  Row,
  Col,
  Layout,
  Card,
  Typography,
  Form,
  Input,
  Button,
  LayoutProps,
  CardProps,
  FormProps,
  Divider,
  theme,
  Space,
  Select
} from "antd";
import MaskedInput from "antd-mask-input";
import { BikeWhiteIcon, FineFoodsIcon } from "components";
const { Text, Title } = Typography;
const { useToken } = theme;

type RegisterProps = RegisterPageProps<LayoutProps, CardProps, FormProps>;
/**
 * **refine** has register page form which is served on `/register` route when the `authProvider` configuration is provided.
 *
 * @see {@link https://refine.dev/docs/ui-frameworks/antd/components/antd-auth-page/#register} for more details.
 */
export const RegisterPage: React.FC<RegisterProps> = ({
  providers,
  loginLink,
  wrapperProps,
  contentProps,
  renderContent,
  // formProps,
  title,
}) => {
  const [errorMsg,setErrorMsg] = useState("");
  const [isLoading,setIsLoading] = useState(false); 
  const [phoneMask, setPhoneMask] = useState(null as any);
  const { token } = useToken();
  // const [form] = Form.useForm<RegisterFormTypes>();
  const { formProps, onFinish } = useForm();    
  const translate = useTranslate();
  const routerType = useRouterType();
  const Link = useLink();
  const { Link: LegacyLink } = useRouterContext();  
  const { mutate } = useCustomMutation();

  const ActiveLink = routerType === "legacy" ? LegacyLink : Link;

  const authProvider = useActiveAuthProvider();

  // const { mutate: register, isLoading } = useRegister<RegisterFormTypes>({
  //   v3LegacyAuthProviderCompatible: Boolean(authProvider?.isLegacy),
  // });
  
  const register = async() => {

    let values : any = formProps.form?.getFieldsValue();
    console.log(formProps.form?.getFieldsValue());

    let name = values.name;
    let email = values.email;
    let cpfCnpj = values.cpfCnpj;
    let razaoSocial = values.razaoSocial;
    let password = values.password;
    let phone = values.phone;
    let countryCode = values.countryCode; 
    const strongPassword = new RegExp("(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})");

    setErrorMsg("");

    if(!name){
      setErrorMsg("Insira o nome da conta")
      formProps.form?.setFields([ { name: "name", errors: ["Insira o nome da conta"] }, ]);
      return false;    
    } else if(!razaoSocial){
      setErrorMsg("Insira a Razão Social")
      formProps.form?.setFields([ { name: "razaoSocial", errors: ["Insira a Razão Social"] }, ]);
      return false;      
    } else if(!cpfCnpj){
      setErrorMsg("Insira o CNPJ da empresa")
      formProps.form?.setFields([ { name: "cpfCnpj", errors: ["Insira o CNPJ da empresa"] }, ]);
      return false;
    } else if(!email){
      setErrorMsg("Insira o email de acesso da conta")
      formProps.form?.setFields([ { name: "email", errors: ["Insira o email de acesso da conta"] }, ]);
      return false;    
    } else if(!password){
      setErrorMsg("Insira uma senha de acesso")
      formProps.form?.setFields([ { name: "password", errors: ["Insira uma senha de acesso"] }, ]);
      return false;
    } else if(!strongPassword.test(password)){
      setErrorMsg("Sua nova senha deve conter no mínimo 8 caracteres e conter ao menos uma letra minúscula, uma letra maiucúscula e um número")
      formProps.form?.setFields([ { name: "password", errors: ["Sua nova senha deve conter no mínimo 8 caracteres e conter ao menos uma letra minúscula, uma letra maiucúscula e um número"] }, ]);
      return false;
    }
    setIsLoading(true);

    mutate({
        url: `${API_URL}/companies`,
        method: "post",
        values: {
            name,
            razaoSocial,            
            cpfCnpj,
            phone,
            countryCode,
            email,
            password,            
        },        
        successNotification: (data, values) => {
            setIsLoading(false);
            formProps.form?.setFields([ { name: "name", value: null }, { name: "razaoSocial", value: null }, { name: "cpfCnpj", value: null }, { name: "phone", value: null }, { name: "email", value: null }, { name: "password", value: null } ]);
            alert(`Empresa criada com sucesso \n Faça o login com o email e senha cadastrados`);
            return {
                message: `Empresa criada com sucesso`,
                description: "Faça o login com o email e senha cadastrados",
                type: "success",
            };            
        },
        errorNotification: (data, values) => {
            setIsLoading(false);
            return {
                message: `Oops! Houve um erro`,
                description: "Não conseguimos criar um nova conta de Empresa, tente novamente ou entre em contato com o nosso suporte. Verifique também se esta empresa já não está cadastrada.",
                type: "error",
            };
        }
    });    

  }

  const phoneCodes = PHONE_CODES?.map(item => { return { label: `${item.name} (${item.code})`, value: item.iso, mask: item.mask}}) ?? [];

  const changePhoneCode = (value: any) => {
        console.log(`selected ${value}`);
        let findMask = phoneCodes?.find(item => item.value===value)?.mask;
        if(Array.isArray(findMask)){
            let mask =  findMask[findMask.length - 1]; // findMask.map((item: any) => { return { mask: item.replaceAll("9","0"), lazy: true } });
            console.log(mask);
            setPhoneMask(mask?.replaceAll("9","0"));
        } else {            
            let mask = findMask; //Array.isArray(findMask) && findMask?.length ? findMask[findMask.length - 1] : findMask; // pega último mask por padrão
            console.log(mask);
            setPhoneMask(mask?.replaceAll("9","0") || "(00) 00000-0000");
        }        
  };

  const PageTitle =
    title === false ? null : (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          marginBottom: "32px",
          fontSize: "20px",
        }}
      >
        <FineFoodsIcon style={{ color: token.colorTextHeading }} />
      </div>
    );

  const CardTitle = (
    <Title
      level={3}
      style={{
        color: token.colorPrimaryTextHover,
        ...titleStyles,
      }}
    >
      Cadastre uma nova Empresa
    </Title>
  );

  const CardContent = (
    <Card
      title={CardTitle}
      headStyle={headStyles}
      bodyStyle={bodyStyles}
      style={{
        ...containerStyles,
        backgroundColor: token.colorBgElevated,
      }}
      {...(contentProps ?? {})}
    >
      <Form
        {...formProps}
        layout="vertical"
        // form={form}
        // onFinish={(values) => register(values)}
        requiredMark={false}
        initialValues={{
          name: "",          
          razaoSocial: "",
          cpfCnpj: "",
          email: "",
          countryCode: "BR",
          phone: "",
          password: ""
        }}        
        onFinish={register}
      >
        <Form.Item
          name="name"
          label={translate("pages.register.fields.name", "Name")}
          rules={[
            { required: true },
          ]}
        >
          <Input
            size="large"
            placeholder={"Ex: Split Fácil"}
          />
        </Form.Item>
        <Form.Item
          name="razaoSocial"
          label={translate("pages.register.fields.razaoSocial", "razaoSocial")}
          rules={[
            { required: true },
          ]}
        >
          <Input
            size="large"
            placeholder={"ex: Split Fácil Ltda ME"}
          />
        </Form.Item>
        <Form.Item
          name="cpfCnpj"
          label={translate("pages.register.fields.cpfCnpj", "cpfCnpj")}
          rules={[
            { required: true },
          ]}
        >
          {/* <Input
            size="large"
            placeholder={translate("pages.register.fields.cnpj", "cnpj")}
          /> */}
            <MaskedInput
                disabled={false}
                mask={'00.000.000/0000-00'}
            />                                    
        </Form.Item>
        <Space.Compact style={{ width: '100%' }}>
            <Form.Item
              label={"País"}
              name="countryCode"
              style={{ width: '40%' }}
            >
              <Select
                  showSearch
                  // defaultValue="BR"
                  options={phoneCodes}
                  onChange={changePhoneCode}
                  // onSearch={onSearch}
                  filterOption={(input, option) =>
                    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                  }
                />
            </Form.Item>
            <Form.Item
                label={translate("customers.fields.phone")}
                name="phone"
                style={{ width: '60%' }}
                tooltip={"Opcional: caso não tenha, use 11 99999-9999"}
                rules={[
                        {
                          required: false,
                        },
                        ]}
                        >
                      <MaskedInput
                          disabled={false}
                          mask={phoneMask || "(00) 00000-0000"}
                      />                         
            </Form.Item>
        </Space.Compact>                                
        <Form.Item
          name="email"
          label={translate("pages.register.fields.email", "Email")}
          rules={[
            { required: true },
            {
              type: "email",
              message: translate(
                "pages.register.errors.validEmail",
                "E-mail inválido"
              ),
            },
          ]}
          tooltip={"Atenção: será o acesso Master da conta da empresa"}
        >
          <Input
            size="large"
            placeholder={translate("pages.register.fields.email", "Email")}
          />
        </Form.Item>
        <Form.Item
          name="password"
          label={translate("pages.register.fields.password", "Password")}
          rules={[{ required: true }]}
          tooltip={"Sua nova senha deve conter no mínimo 8 caracteres e conter ao menos uma letra minúscula, uma letra maiucúscula e um número"}
        >
          <Input type="password" placeholder="●●●●●●●●" minLength={6} size="large" />
        </Form.Item>
        <p>* Taxa de serviço: 2% sobre valor das cobranças</p>
        {(errorMsg) && (<p style={{color: "red"}}>{errorMsg}</p>)}
        <Form.Item
          style={{
            marginBottom: 0,
          }}
        >
          <Button
            type="primary"
            size="large"
            htmlType="submit"
            loading={isLoading}
            disabled={isLoading}
            block
          >
            {translate("pages.register.buttons.submit", "Sign up")}
          </Button>
        </Form.Item>
      </Form>
      <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginBottom: "24px",
          }}
        >
          {loginLink ?? (
            <Text
              style={{
                fontSize: 12,
                marginTop: 20,
                marginLeft: "auto",
              }}
            >
              Possui uma conta? Faça seu {" "}
              <ActiveLink
                style={{
                  fontWeight: "bold",
                  color: token.colorPrimaryTextHover,
                }}
                to="/login"
              >
                {translate("pages.login.signin", "Sign in")}
              </ActiveLink>
            </Text>
          )}
        </div>
    </Card>
  );

  return (
    <Layout style={layoutStyles} {...(wrapperProps ?? {})}>
      <Row
        justify="center"
        align="middle"
        style={{
          height: "100vh",
        }}
      >
        <Col xs={22}>
          {renderContent ? (
            renderContent(CardContent, PageTitle)
          ) : (
            <>
              {PageTitle}
              {CardContent}
            </>
          )}
        </Col>
      </Row>
    </Layout>
  );
};
