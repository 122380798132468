import { TOKEN_KEY, API_URL, PHONE_CODES, BANK_CODES } from "./../../constants";
import { useMemo, useState, useEffect } from "react";
import { Link, useParams } from 'react-router-dom';
import { IResourceComponentsProps, useTranslate, useShow, HttpError, useNavigation, useCustom } from "@refinedev/core";
import { Edit,
    useForm,
    useTable,
    List,
    TextField,
    getDefaultSortOrder,
    NumberField,
    DateField,
    BooleanField,
    Show,
    Breadcrumb,
    CreateButton,
    useModal,    
    Create,
    useModalForm,
    useEditableTable,
    SaveButton,
    EditButton  } from "@refinedev/antd";
import {
    UserOutlined,
    PhoneOutlined,
    CalendarOutlined,
    CheckOutlined,
    PicLeftOutlined,
    BankOutlined,
    PlusSquareOutlined,
    TagOutlined,
    FormOutlined,
    MoreOutlined,
    EditOutlined
} from "@ant-design/icons";    
import {
    Form,
    Input,
    Avatar,
    Row,
    Col,
    Typography,
    Space,
    Radio,
    Select,
    InputProps,
    Alert,
    Switch,
    Table,
    Grid,
    Menu,
    Button,
    Dropdown,
    Checkbox,
    Card,
    Modal,
    Spin,
    Skeleton
} from "antd";

import MaskedInput from "antd-mask-input";

import BankAccountValidator from "br-bank-account-validator";

import { replaceAccents } from "./../../functions";

const { Text } = Typography;

import { InvoiceStatus, AccountStatus } from "components";

import { ICustomer, IInvoice, IInvoiceFilterVariables, IAccount } from "interfaces";

import { truncate } from "fs";

import "./../../css/main.css";

export const CustomerEdit: React.FC<IResourceComponentsProps> = ({}) => {
    
    const t = useTranslate();
    const { formProps, saveButtonProps, queryResult } = useForm<ICustomer>({        
        redirect: false,        
    });
    const {id} = useParams();
    const { show, edit } = useNavigation();
    const urlParams = new URLSearchParams(window.location.search);
    const isModalOpen = urlParams.get('openModal') ? Boolean(urlParams.get('openModal')) : false;
    console.log(isModalOpen);

    // console.log(formProps);

    // const [customerPhone, setCustomerPhone] = useState('');
    const [phoneMask, setPhoneMask] = useState('');
    // const [phoneCodes, setphoneCodes] = useState([] as any[]);
    const [customerType, setCustomerType] = useState(null);
    // const [customer, setCustomer] = useState(null);
    const [customerPhone, setCustomerPhone] = useState('');    
    const [loading, setLoading] = useState(false);

    const [open, setOpen] = useState(isModalOpen ?? false);
    const [bankCodes, setBankCodes] = useState([] as any[]);
    const TrueIcon = () => <span>✅</span>;
    const FalseIcon = () => <span>❌</span>;

    const { data } : any = queryResult;
    const customer = data?.data;
    // const customer = {id: 1, payerOrReceiver: "payer"};

    useEffect(() => {
        setCustomerType(formProps?.initialValues?.type);
        setOpen(["receiver","all"].includes(customer?.payerOrReceiver) && !customer?.account?.some((c : any) => c.active && c.status===1) ? true : false);
    },[]);

    const handleTypeChange = (e: any) => {
        setCustomerType(e.target.value);
    };

    const phoneCodes = PHONE_CODES?.map(item => { return { label: `${item.name} (${item.code})`, value: item.iso, mask: item.mask}}) ?? [];

    const changePhoneCode = (value: any) => {
        console.log(`selected ${value}`);
        let findMask = phoneCodes?.find(item => item.value===value)?.mask;
        if(Array.isArray(findMask)){
            let mask =  findMask[findMask.length - 1]; // findMask.map((item: any) => { return { mask: item.replaceAll("9","0"), lazy: true } });
            console.log(mask);
            setPhoneMask(mask?.replaceAll("9","0"));
        } else {            
            let mask = findMask; //Array.isArray(findMask) && findMask?.length ? findMask[findMask.length - 1] : findMask; // pega último mask por padrão
            console.log(mask);
            setPhoneMask(mask?.replaceAll("9","0") || "(00) 00000-0000");
        }        
    };

    const changeActive = (value: boolean) => {
        formProps.form?.setFields([
            {
                name: "active",
                value: value
            },
        ])        
    }

    const renderInvoices = () => {
        const { tableProps, sorter } = useTable<
            IInvoice,
            HttpError,
            IInvoiceFilterVariables
        >({
            resource: "invoices",
            initialSorter: [
                {
                    field: "createdAt",
                    order: "desc",
                },
            ],
            permanentFilter: [
                {
                    field: "customerId",
                    operator: "eq",
                    value: customer?.id,
                },
            ],
            initialPageSize: 4,
            queryOptions: {
                enabled: customer !== undefined,
            },
            syncWithLocation: false,
        });

        let canAccess = ["manager","admin","owner"].includes(localStorage.getItem("role") ?? "");

        return (
                <List
                            title={t("invoices.invoices")}
                            headerProps={{
                                extra: <></>,
                            }}
                            breadcrumb={<></>}
                        >
                            <Table {...tableProps}
                                rowKey="id"
                                onRow={(record) => {
                                    return {
                                        onClick: () => {
                                            show("invoices", record.id);
                                        },
                                    };
                                }}
                                
                            >
                                <Table.Column
                                    key="id"
                                    dataIndex="id"
                                    title={t("invoices.fields.id")}
                                    render={(value) => <TextField value={value} />}
                                />
                                <Table.Column
                                    key="statusText"
                                    dataIndex="statusText"
                                    title={t("invoices.fields.statusText")}
                                    render={(value) => {
                                        return <InvoiceStatus status={value} />;
                                    }}
                                />
                                {/* <Table.Column
                                align="left"
                                key="gross"
                                dataIndex="gross"
                                title={t("invoices.fields.gross")}
                                defaultSortOrder={getDefaultSortOrder(
                                    "total",
                                    sorter,
                                )}
                                sorter
                                render={(value) => {
                                    return (
                                        <NumberField
                                            options={{
                                                currency: "BRL",
                                                style: "currency",
                                            }}
                                            value={value}
                                        />
                                    );
                                }}
                                /> */}
                                <Table.Column
                                    key="createdAt"
                                    dataIndex="createdAt"
                                    title={t("invoices.fields.createdAt")}
                                    render={(value) => (
                                        <DateField value={value} format="LLL" />
                                    )}
                                    sorter
                                    defaultSortOrder={getDefaultSortOrder(
                                        "createdAt",
                                        sorter,
                                    )}
                                />
                            </Table>
                            {(canAccess) && (
                            <Link to="/invoices/create">
                            <Button block 
                                type="primary"
                                icon={<PlusSquareOutlined />} 
                                style={{ marginTop: 10, marginBottom: 30 }}
                                // onClick={() => setOpen(true)}
                            >
                                Criar Cobrança
                            </Button>
                            </Link>
                            )}
                        </List>
        );

    }

    const renderAccounts = () => {

        const {id} = useParams();
        // const [accounts, setAccounts] = useState(null);
        const {
            tableProps,
            formProps,
            isEditing,
            saveButtonProps,
            cancelButtonProps,
            setId: setEditId
        } = useEditableTable<
            IAccount,
            HttpError
        >({
            resource: "accounts",
            redirect: false,
            initialSorter: [
                {
                    field: "createdAt",
                    order: "desc",
                },
            ],
            permanentFilter: [
                {
                    field: "customerId",
                    operator: "eq",
                    value: id ?? null,
                },
            ],
            initialPageSize: 4,
            queryOptions: {
                enabled: customer !== undefined,
            },
            syncWithLocation: false,
        });        

        const rowClassName = (record : any) => {
            return record.active ? '' : 'row-disabled';
        };

        let canAccess = ["manager","admin","owner"].includes(localStorage.getItem("role") ?? "");

        console.log("tableProps",tableProps); 
        console.log("formProps",formProps);

        const Actions: React.FC = () => (
            <CreateButton onClick={() => {
                setOpen(true);
            }}>Incluir Conta Bancária </CreateButton>
        );

        const moreMenu = (record: IAccount) => (
            <Menu
                mode="vertical"
                onClick={({ domEvent }) => domEvent.stopPropagation()}
            >
                <Menu.Item
                    key="accept"
                    style={{
                        fontSize: 15,
                        display: "flex",
                        alignItems: "center",
                        fontWeight: 500,
                    }}
                    icon={
                        <FormOutlined
                            style={{
                                color: "#52c41a",
                                fontSize: 17,
                                fontWeight: 500,
                            }}
                        />
                    }
                    onClick={() => {
                        setEditId?.(record.id);
                    }}
                >
                    {t("buttons.edit")}
                </Menu.Item>
            </Menu>
        );

        const loader = () => {
            setLoading(true);
            setTimeout(() => {
                setLoading(false);
            }, 3000);            
        };

        return (
            <List
                title={"Contas Bancárias do Cliente"}
                headerProps={{
                    extra: <Actions />,
                    }}                    
                breadcrumb={<></>}
        >
                        {(!tableProps?.dataSource?.length || tableProps?.dataSource?.filter((item : any) => item.status===1)?.length===0) &&
                            (<Space direction="vertical" style={{ width: '100%', marginBottom: 20 }}>
                                <Alert
                                message="ATENÇÃO"
                                description={t("accounts.titles.empty")}
                                type="error"
                                showIcon
                                />
                            </Space>)
                            
                        }
                        {tableProps?.dataSource?.find((item : any) => item.status===0) &&
                            (<Space direction="vertical" style={{ width: '100%', marginBottom: 20 }}>
                                <Alert
                                    message="Conta Em Análise: após inserir uma nova conta, ela passará por uma análise de aprovação de cadastro. (Média de 5 minutos. Máxima em até 1 dia útil)"
                                    // description="Additional description and information about copywriting."
                                    type="info"
                                    showIcon/>
                            </Space>)
                        }
                        <Form {...formProps}>
                            <Table {...tableProps}
                                rowKey="id"
                                onRow={(record) => ({
                                    // eslint-disable-next-line
                                    onClick: (event: any) => {
                                        if (event.target.nodeName === "TD") {
                                            setEditId && setEditId(record.id);
                                        }
                                    },
                                })}
                                rowClassName={rowClassName}
                                >
                            <Table.Column
                                key="name"
                                dataIndex="name"
                                title={t("accounts.fields.name")}
                                render={(value) => <TextField value={value} />}
                            />
                            <Table.Column
                                    key="bank"
                                    dataIndex="bank"
                                    title={t("accounts.fields.bank")}
                                    render={(value) => <TextField value={value} />}
                                />
                            <Table.Column
                                    key="agency"
                                    dataIndex="agency"
                                    title={t("accounts.fields.agency")}
                                    render={(value) => <TextField value={value} />}
                                />
                            <Table.Column
                                    key="account"
                                    dataIndex="account"
                                    title={t("accounts.fields.account")}
                                    render={(value) => <TextField value={value} />}
                                />        
                            <Table.Column
                                key="typeText"
                                dataIndex="typeText"
                                title={t("accounts.fields.type")}
                                render={(value) => <TextField value={value} />}
                                />
                            <Table.Column
                                key="statusText"
                                dataIndex="statusText"
                                title={t("accounts.fields.statusText")}
                                render={(value) => {
                                    return <AccountStatus status={value} />;
                                }}
                                />                                                                                                                                                 
                            <Table.Column
                                key="active"
                                dataIndex="active"
                                title={t("accounts.fields.active.title")}
                                // render={(value) => <BooleanField value={value} />}
                                render={(value, data: IAccount) => {                                    
                                    if (isEditing(data.id)) {
                                        return (
                                            <Button {...saveButtonProps} style={{ padding: 0, border: 'none', background: 'transparent' }}>
                                            <Form.Item
                                                name="active"                                                
                                                valuePropName="checked"
                                            >
                                                {(loading) && (<Spin spinning={loading}/>)}
                                                <Switch checkedChildren="SIM" unCheckedChildren="NÃO" onChange={loader}/>
                                            </Form.Item>
                                            </Button>
                                        );
                                    }
                                    return <Switch onClick={() => { loader(); setEditId(data.id); }} disabled={true} checked={value} style={{ 'pointerEvents': 'none'}} checkedChildren="SIM" unCheckedChildren="NÃO"/>
                                    // <BooleanField
                                    // value={value}
                                    // trueIcon={<TrueIcon />}
                                    // falseIcon={<FalseIcon />}/>
                                }}
                            />
                            {/* <Table.Column<IAccount>
                            title="Opções"
                            dataIndex="actions"
                            render={(_, record) => {
                                if (isEditing(record.id)) {
                                    return (
                                        <Space>
                                            <SaveButton
                                                {...saveButtonProps}
                                                size="small"
                                            />
                                            <Button
                                                {...cancelButtonProps}
                                                size="small"
                                            >
                                                {t("buttons.cancel")}
                                            </Button>
                                        </Space>
                                    );
                                }
                                return (                        
                                <Space>
                                    <EditButton
                                        hideText
                                        size="large"
                                        recordItemId={record.id}
                                        onClick={() => {
                                            setEditId?.(record.id);
                                        }}
                                    />
                                </Space>
                            )}}
                            /> */}
                            </Table>
                        </Form>
                                    
                        {(canAccess) && (
                        <Button block 
                            type="primary"
                            icon={<PlusSquareOutlined />} 
                            style={{ marginTop: 10, marginBottom: 30 }}
                            onClick={() => setOpen(true)}
                        >
                            Incluir Conta Bancária
                        </Button>
                        )}
                    </List>                    

        );
    }

    const ModalAccountCreate: React.FC<IResourceComponentsProps> = () => {
        const t = useTranslate();
        const [bank, setBank] = useState([] as any[]);
        const [errorMsg, setErrorMsg] = useState(null);        
        const {id} = useParams();
        // const { formProps, saveButtonProps, queryResult } = useModalForm<IAccount>({
        //     resource: "accounts",
        //     redirect: false,
        // });

        const {
            // saveButtonProps,
            modalProps,
            formProps,
            // show: createModalShow,
            // formLoading: createFormLoading,
        } = useModalForm<IAccount>({            
            action: "create",
            resource: "accounts",
            redirect: false,
            autoSubmitClose: true,
            // autoResetForm: true,
            warnWhenUnsavedChanges: true,
            // errorNotification: (data, values, resource) => {
            //     console.log({data, values, resource});
            //     let errorMsg = data?.response?.data?.errors.map((item : any) => item.message)?.join(" / ");
            //     setErrorMsg(errorMsg);
            //     return {
            //         message: errorMsg,
            //         description: "Erro",
            //         type: "error",
            //     };
            // },
            successNotification: (data, values) => {
                setOpen(false);
                return {
                    message: `Conta Criada com sucesso!`,
                    description: "Uma nova conta de cliente foi adicionada com sucesso",
                    type: "success",
                };
            },
            // syncWithLocation: true,
        });        

        // redirect("show",id);
        console.log(modalProps);
        console.log(formProps);

        // useEffect(() => {
            let bankCodes = BANK_CODES.filter(item => item.strCode).map((item : any) => { return { label: `${item.name} (${item.strCode})`, name: item.displayName, code: ("00" + item.strCode).slice(-3), value: ("00" + item.strCode).slice(-3), mask: item.mask}})
            // setBankCodes(bankCodesFormatted);
        // });

        const handleBankChange = (value: any, option: any) => {
            console.log("zzz",value,option);
            formProps.form?.setFields([
                {
                name: "bank", // required
                value: option?.label
                },
            ]);
        };

        const validateBankAccount = async () => {
            // const form = Form.useFormInstance();
            console.log(formProps.form?.getFieldsValue());
            let values : any = formProps.form?.getFieldsValue();
            if(values.bankCode && values.agency && values.account){
                try {
                    let bank = BankAccountValidator.validate({
                        bankNumber: "00" + values.bankCode.slice(-3),
                        agencyNumber: values.agency,
                        agencyCheckNumber: values.agencyDigit,
                        accountNumber: values.account,
                        accountCheckNumber: values.accountDigit,
                    });
                    // Se chegou até aqui, a conta bancária é válida
                    console.log("aaaaaa",bank);
                    setErrorMsg(null);
                    let fields = ["agency","agencyDigit","account","accountDigit"];
                    fields.map(item => {
                        formProps.form?.setFields([
                            {
                            name: item, // required
                            errors: [],
                            },
                        ]);
                    });
                } catch (e : any) {
                    // se não for válida, lança uma exceção
                    console.log("eeeeee",e?.message);
                    let fields = [];
                    if(e?.message.includes("Agência inválida")) fields.push({ name: "agency", error: "Agência Inválida" })
                    if(e?.message.includes("Dígito da agência inválido")) fields.push({ name: "agencyDigit", error: "Dígito da agência inválido" })
                    if(e?.message.includes("Conta corrente inválida")) fields.push({ name: "account", error: "Conta corrente inválida" })
                    if(e?.message.includes("Dígito da conta corrente inválido")) fields.push({ name: "accountDigit", error: "Dígito da conta corrente inválido" })

                    fields.map(item => {
                        formProps.form?.setFields([
                            {
                            name: item.name, // required
                            errors: [item.error],
                            },
                        ]);
                    });

                    let msg = fields.map(item => item.error)?.join("<br>") || null;
                    console.log(msg);
                    setErrorMsg(e?.message);
                }
            }
        }   

        return (
            <Modal
                {...modalProps}
                forceRender
                getContainer={false} 
                open={open}
                title={`${t("accounts.titles.create")} - ${customer?.name}`}
                okText={t("buttons.create")}
                // okButtonProps={{ disabled: true }}
                cancelText={t("buttons.cancel")}
                onCancel={() => {
                    setOpen(false);
                }}
                // footer={<></>}                
            >
                {/* <Create
                    resource="accounts"
                    isLoading={queryResult?.isFetching}
                    // saveButtonProps={saveButtonProps}
                    headerProps={{
                        extra: <></>,
                    }}
                    breadcrumb={<></>}
                    // footerButtons={({ defaultButtons }) => (
                    //     <>                            
                    //         <Button type="primary"
                    //         danger
                    //         onClick={() => {
                    //             setOpen(true);
                    //         }}>
                    //             {t("buttons.cancel")}
                    //         </Button>
                    //         {defaultButtons}
                    //     </>
                    // )}
                    title={
                        <Text style={{ fontSize: 22, fontWeight: 800 }}>
                            {customer?.name}
                        </Text>
                    }> */}
                    <Form
                        {...formProps}
                        layout="vertical"
                        onValuesChange={validateBankAccount}                                             
                        // onFinish={() => {
                        //     // setOpen(false);
                        //     // location.reload();
                        //     setErrorMsg(null)
                        // }}
                        // onFinishFailed={onFinishFailed}
                        // onSuccess={() => {
                        //     setOpen(false);
                        // }}
                        onError={() => {
                            setOpen(false);
                        }}
                        initialValues={{
                            active: true,
                            customerId: id                 
                        }}
                        style={{ marginTop: 30 }}
                    >
                        <Row gutter={[64, 0]} wrap>
                            <Col xs={24} lg={24}>
                                <Form.Item
                                        label={t("accounts.fields.customerId")}
                                        name="customerId"
                                        rules={[
                                            {
                                                required: false,
                                            },
                                        ]}
                                        hidden={true}
                                    >
                                        <Input />                        
                                </Form.Item>
                                <Form.Item
                                    label={t("accounts.fields.name")}
                                    name="name"
                                    rules={[
                                        {
                                            required: true,
                                        },
                                    ]}
                                >
                                    <Input />
                                </Form.Item>                        
                            {/* </Col>
                            <Col xs={24} lg={12}> */}
                                <Form.Item
                                    label={t("accounts.fields.bank")}
                                    name="bankCode"
                                    rules={[
                                        {
                                            required: true,
                                        },
                                    ]}
                                >
                                    <Select
                                        showSearch
                                        // defaultValue=""
                                        onChange={handleBankChange}
                                        // onSelect={(value) => {
                                        //     console.log("onSelect",value);
                                        //     setBank(value?.label);
                                        // }}
                                        options={bankCodes}
                                        optionFilterProp="children"
                                        filterOption={(input, option) =>
                                            // (option?.label ?? '').includes(input) || (option?.name ?? '').includes(input)
                                            (replaceAccents(option?.label ?? '').toLowerCase().indexOf(replaceAccents(input).toLowerCase()) >= 0) || (replaceAccents(option?.name ?? '').toLowerCase().indexOf(replaceAccents(input).toLowerCase()) >= 0)
                                        }
                                        filterSort={(optionA, optionB) =>
                                        (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                                        }
                                    />
                                </Form.Item>
                                <Form.Item
                                        label={t("accounts.fields.bank")}
                                        name="bank"
                                        rules={[
                                            {
                                                required: false,
                                            },
                                        ]}
                                        hidden={true}
                                    >
                                        <Input />                        
                                </Form.Item>
                                <Space.Compact style={{ width: '100%' }}>
                                    <Form.Item
                                        label={t("accounts.fields.agency")}
                                        name="agency"                                
                                        style={{ width: '70%' }}
                                        rules={[
                                            {
                                                required: true,
                                            },
                                        ]}
                                    >
                                        <Input />                        
                                    </Form.Item>
                                    <Form.Item
                                        label={t("accounts.fields.agencyDigit")}
                                        name="agencyDigit"
                                        style={{ width: '30%' }}
                                        rules={[
                                            {
                                                required: false,
                                            },
                                        ]}
                                    >
                                        <Input />                        
                                    </Form.Item>
                                </Space.Compact>
                                <Space.Compact style={{ width: '100%' }}>
                                    <Form.Item
                                        label={t("accounts.fields.account")}
                                        name="account"
                                        style={{ width: '70%' }}
                                        rules={[
                                            {
                                                required: true,
                                            },
                                            {
                                            // ({ getFieldValue }) => ({
                                                validator(_, value) {
                                                  console.log(value);
                                                  if (!value) {
                                                    return Promise.reject('Oops! Insira o número da conta corrente');
                                                  } else if (value && value?.includes("-")) {
                                                    return Promise.reject(`Oops! O dígito da agência deve ser informado no campo ao lado`);
                                                  }
                                                  return Promise.resolve();
                                                },
                                            // }),
                                            }
                                        ]}
                                    >
                                        <Input />
                                    </Form.Item>
                                    <Form.Item
                                        label={t("accounts.fields.accountDigit")}
                                        name="accountDigit"
                                        style={{ width: '30%' }}
                                        rules={[
                                            {
                                                required: false,
                                            },
                                        ]}
                                    >
                                        <Input />
                                    </Form.Item>
                                </Space.Compact>
                                <Form.Item
                                        label={t("accounts.fields.type")}
                                        name="type"
                                        rules={[
                                            {
                                                required: true,
                                            },
                                        ]}
                                    >
                                    <Select
                                        showSearch
                                        defaultActiveFirstOption={true}
                                        placeholder=""
                                        optionFilterProp="children"
                                        // onChange={onChange}
                                        // onSearch={onSearch}
                                        filterOption={(input, option) =>
                                        (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                        }
                                        options={[
                                            {
                                                value: 'conta_corrente',
                                                label: 'Conta Corrente',
                                            },
                                            {
                                                value: 'conta_poupanca',
                                                label: 'Conta Poupança',
                                            },
                                            // {
                                            //     value: 'conta_corrente_conjunta',
                                            //     label: 'Conta Corrente Conjunta',
                                            // },
                                            // {
                                            //     value: 'conta_poupanca_conjunta',
                                            //     label: 'Conta Poupança Conjunta',
                                            // },
                                        ]}
                                    />
                                </Form.Item>
                                <Text style={{ color: 'green' }}>* Esta conta deve ser da mesma titularidade do CNPJ/CPF do cliente</Text>
                                {errorMsg && (
                                <Space direction="vertical" style={{ width: '100%' }}>
                                    <Alert
                                    message="ATENÇÃO"
                                    description={errorMsg}
                                    type="error"
                                    showIcon
                                    />
                                </Space>
                                )}
                            </Col>
                        </Row>
                    </Form>
                {/* </Create> */}
            </Modal>
        );
    };

    // if(!customer){
    //     return (<Skeleton />);
    // }
    
    return (
        <>
        <Edit
            isLoading={queryResult?.isFetching}
            saveButtonProps={saveButtonProps}
            title={`Editar ${customer?.name}`}
            wrapperProps={{
                style: {
                  backgroundColor: "#fff",
                  padding: "8px",
                  borderRadius: "8px",
                  boxSizing: "border-box"
                },
            }}
            headerButtons={({ defaultButtons }) => (
                <>
                  {defaultButtons}
                  <Button block 
                            type="primary"
                            icon={<PlusSquareOutlined />} 
                            onClick={() => setOpen(true)}
                        >
                            Incluir Conta Bancária
                  </Button>
                </>
            )}
        >
            <Form
                {...formProps}
                layout="vertical"
                initialValues={{
                    isActive: true,
                    ...formProps.initialValues,
                }}
            >
                <Row gutter={[64, 0]} wrap>
                    <Col xs={24} lg={12}>
                        <Form.Item
                            label={t("customers.fields.type.title")}
                            name="type"
                            rules={[
                                {
                                    required: true,
                                },
                            ]}                            
                        >
                            <Radio.Group onChange={handleTypeChange} disabled={true}>
                                <Radio value="individual">{t("customers.fields.type.individual")}</Radio><br/>
                                <Radio value="corporation">{t("customers.fields.type.corporation")}</Radio>
                            </Radio.Group>
                        </Form.Item>
                        <Form.Item
                            label={t("customers.fields.cpfCnpj")}
                            name="cpfCnpj"
                            rules={[
                                {
                                    required: true,
                                },
                            ]}
                        >
                            <MaskedInput
                                disabled={true}
                                mask={customerType==="individual" ? '000.000.000-00' : '00.000.000/0000-00'}
                            >
                                    {/* 
                                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                        // @ts-ignore */}
                                    {/* {(props: InputProps) => <Input disabled={false} {...props} />} */}
                            </MaskedInput>
                        </Form.Item>        
                        <Form.Item
                            label={t("customers.fields.name")}
                            name="name"
                            rules={[
                                {
                                    required: true,
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                        <Form.Item
                            label={t("customers.fields.razaoSocial")}
                            name="razaoSocial"
                            rules={[
                                {
                                    required: true,
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>                                        
                    </Col>
                    <Col xs={24} lg={12}>
                        {/* <Form.Item
                            label={t("customers.fields.address")}
                            name={["address", "text"]}
                            rules={[
                                {
                                    required: true,
                                },
                            ]}
                        >
                            <Input.TextArea rows={8} />
                        </Form.Item> */}
                        <Form.Item
                            label={t("customers.fields.email")}
                            name="email"
                            rules={[
                                {
                                    required: true,
                                    type: "email",
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                        <Space.Compact style={{ width: '100%' }}>
                        <Form.Item
                            label={"País"}
                            name="countryCode"
                            style={{ width: '40%' }}
                        >
                            <Select
                                showSearch
                                // defaultValue="BR"
                                options={phoneCodes}
                                onChange={changePhoneCode}
                                // onSearch={onSearch}
                                filterOption={(input, option) =>
                                    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                }
                            />
                        </Form.Item>
                        <Form.Item
                            label={t("customers.fields.phone")}
                            name="phone"
                            style={{ width: '60%' }}
                            rules={[
                                {
                                    required: true,
                                },
                            ]}
                        >
                            <MaskedInput
                                    disabled={false}
                                    mask={phoneMask || "(00) 00000-0000"}
                                    >
                                    {/* 
                                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                    // @ts-ignore */}
                                    {/* {(props: InputProps) => <Input {...props} />} */}
                            </MaskedInput>                             
                        </Form.Item>
                        </Space.Compact>
                        <Form.Item
                            label={t("customers.fields.payerOrReceiver.title")}
                            name="payerOrReceiver"
                            rules={[
                                {
                                    required: true,
                                },
                            ]}
                        >
                            <Radio.Group>
                                <Radio value="payer">{t("customers.fields.payerOrReceiver.payer")}</Radio><br/>
                                <Radio value="receiver">{t("customers.fields.payerOrReceiver.receiver")}</Radio><br/>
                                <Radio value="all">{t("customers.fields.payerOrReceiver.all")}</Radio>
                            </Radio.Group>
                        </Form.Item>
                        <Form.Item
                            label={t("customers.fields.active.title")}
                            name="active"
                            valuePropName="active"
                            rules={[
                                {
                                    required: true,
                                },
                            ]}
                        >
                            <Switch checked={formProps.form?.getFieldValue("active")} checkedChildren="SIM" unCheckedChildren="NÃO"/>
                        </Form.Item>
                    </Col>
                    {/* <Col xs={24} lg={8}>
                        <Form.Item
                            label={t("customers.fields.address")}
                            name={["address", "text"]}
                            rules={[
                                {
                                    required: true,
                                },
                            ]}
                        >
                            <Input.TextArea rows={8} />
                        </Form.Item>
                    </Col> */}
                </Row>
            </Form>
        </Edit>
        <Card
            bordered={true}
            style={{
                height: "100%",
                marginTop: 30
            }}                        
        >
            {renderAccounts()}
        </Card>
        <Card
            bordered={true}
            style={{
                height: "100%",
                marginTop: 30
            }}                        
        >
            {renderInvoices()}
        </Card>
        <ModalAccountCreate/>
        </>
    );
};
