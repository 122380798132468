import React from "react";
import { TOKEN_KEY, API_URL } from "./constants";
import { Authenticated, GitHubBanner, Refine } from "@refinedev/core";
import { RefineKbarProvider } from "@refinedev/kbar";
import { notificationProvider, ThemedLayoutV2, ErrorComponent,} from "@refinedev/antd";
import routerProvider, { CatchAllNavigate, NavigateToResource, UnsavedChangesNotifier, DocumentTitleHandler,} from "@refinedev/react-router-v6";
import { BrowserRouter, Routes, Route, Outlet } from "react-router-dom";
import { ShoppingOutlined, UsergroupAddOutlined, ShopOutlined, DollarOutlined, DownloadOutlined, SettingOutlined, StarOutlined, DashboardOutlined, BankOutlined } from "@ant-design/icons";
// import jsonServerDataProvider from "@refinedev/simple-rest";
import { dataProvider } from "./rest-data-provider";
import { authProvider } from "authProvider";
// import {auditLogProvider} from "auditLogProvider";
// import { ErrorPage } from "pages/error";
import "dayjs/locale/de";

import { DashboardPage } from "./pages/dashboard";
import { PreferencePage } from "./pages/preferences";
// import { AuthPage } from "./pages/auth";
// @ts-ignore  
import { AuthPage } from "./pages/auth2";
import { CompanyAccountList, CompanyAccountCreate } from "./pages/companies";
import { InvoiceList, InvoiceShow, InvoiceCreate } from "./pages/invoices";
import { UserCreate, UserShow, UserEdit, UserList } from "./pages/users";
import { CustomerCreate, CustomerShow, CustomerEdit, CustomerList } from "./pages/customers";
import { TagList, TagCreate } from "./pages/tags";
import { useTranslation } from "react-i18next";
import { Header, Title, OffLayoutArea } from "components";
import { BikeWhiteIcon, PizzaIcon } from "components/icons";
import { ConfigProvider } from "context";
import { checkRoles } from "accessControl";
import "@refinedev/antd/dist/reset.css";

const App: React.FC = () => {

    const role = localStorage.getItem("role") ?? "viewer";

    const { t, i18n } = useTranslation();

    const i18nProvider = {
        translate: (key: string, params: object) => t(key, params),
        changeLocale: (lang: string) => i18n.changeLanguage(lang),
        getLocale: () => i18n.language,
    };

    return (
        <BrowserRouter>
            {/* <GitHubBanner /> */}
            <ConfigProvider>
                <RefineKbarProvider>
                    <Refine
                        routerProvider={routerProvider}
                        dataProvider={dataProvider(API_URL)}
                        authProvider={authProvider}
                        i18nProvider={i18nProvider}                        
                        options={{
                            syncWithLocation: true,
                            warnWhenUnsavedChanges: true,
                        }}
                        notificationProvider={notificationProvider}
                        accessControlProvider={{
                            can: async ({ resource, action, params }) => {
                                 let result : unknown = await checkRoles(
                                        role,
                                        `${resource}`,
                                        action,
                                );
                                return Promise.resolve({
                                    can: result as boolean,
                                    // Global settings
                                    options: {
                                        buttons: {
                                            enableAccessControl: true,
                                            // hide action buttons if not authorized.
                                            hideIfUnauthorized: true,
                                        },
                                    }
                                });
                            },
                        }}
                        resources={[
                            {
                                name: "dashboard",
                                list: "/",
                                meta: {
                                    label: "Início",
                                    icon: <DashboardOutlined />,
                                },
                            },
                            {
                                name: "invoices",
                                list: "/invoices",
                                create: "/invoices/create",
                                show: "/invoices/show/:id",
                                meta: {
                                    label: "Cobranças",
                                    icon: <ShoppingOutlined />,
                                },
                            },                            
                            {
                                name: "customers",
                                // identifier: "receivers",
                                list: "/customers",
                                create: "/customers/create",
                                edit: "/customers/edit/:id",
                                show: "/customers/show/:id",
                                meta: {
                                    label: "Recebedores",
                                    icon: <DownloadOutlined />,
                                    // filter: {
                                    //     payerOrReceiver: 'receiver'
                                    // },
                                },
                            },
                            {
                                name: "payers",
                                // identifier: "payers",
                                list: "/payers",
                                create: "/payers/create",
                                edit: "/payers/edit/:id",
                                show: "/payers/show/:id",
                                meta: {
                                    label: "Pagadores",
                                    icon: <DollarOutlined />,
                                    // filter: {
                                    //     payerOrReceiver: 'payers'
                                    // },
                                },
                            },
                            {
                                name: "tags",
                                list: "/tags",
                                create: "/tags/create",
                                meta: {
                                    label: "Grupos",
                                    // icon: <ShopOutlined />,
                                },
                            },
                            {
                                name: "companyAccounts",
                                list: "/companyAccounts",
                                show: "/companyAccounts/show/:id",
                                create: "/companyAccounts/create",
                                meta: {
                                    label: "Minhas Contas",
                                    icon: <BankOutlined />,
                                },
                            },
                            // {
                            //     name: "accounts",
                            //     list: "/accounts",
                            //     create: "/accounts/create",
                            //     meta: {
                            //         label: "Conta Bancária Clientes",
                            //         icon: <BankOutlined />,
                            //         hideOnMissingParameter: true
                            //     },                                
                            // },
                            {
                                name: "users",
                                list: "/users",
                                show: "/users/show/:id",
                                create: "/users/create",
                                edit: "/users/edit/:id",
                                meta: {
                                    label: "Funcionários",
                                    icon: <UsergroupAddOutlined />,
                                },
                            },
                            {
                                name: "preferences",
                                list: "/preferences",
                                meta: {
                                    label: "Preferências",
                                    icon: <SettingOutlined />,
                                },
                            }                        
                        ]}
                        auditLogProvider={{
                            get: async ({ resource, meta }) => {
                                const { data } = await dataProvider(API_URL).getList({
                                    resource: "logs",
                                    filters: [
                                        {
                                            field: "resource",
                                            operator: "eq",
                                            value: resource,
                                        },
                                        {
                                            field: "meta.id",
                                            operator: "eq",
                                            value: meta?.id,
                                        },
                                    ],
                                });
        
                                return data;
                            },
                            create: (params) => {
                                return dataProvider(API_URL).create({
                                    resource: "logs",
                                    variables: params,
                                });
                            },
                            update: async ({ id, name }) => {
                                const { data } = await dataProvider(API_URL).update({
                                    resource: "logs",
                                    id,
                                    variables: { name },
                                });
                                return data;
                            },
                        }}
                    >
                        <Routes>
                            <Route
                                element={
                                    <Authenticated
                                        // redirectOnFail="/login
                                        fallback={
                                            <CatchAllNavigate to="/login" />
                                        }
                                    >
                                        <ThemedLayoutV2
                                            Header={Header}
                                            Title={Title}
                                            OffLayoutArea={OffLayoutArea}
                                        >
                                            <Outlet />
                                        </ThemedLayoutV2>
                                    </Authenticated>
                                }
                            >
                                <Route index element={<DashboardPage />} />

                                <Route path="/invoices">
                                    <Route index element={<InvoiceList />} />
                                    <Route
                                        path="show/:id"
                                        element={<InvoiceShow />}
                                    />
                                    <Route
                                        path="create"
                                        element={<InvoiceCreate />}
                                    />
                                </Route>

                                <Route path="/users">
                                    <Route index element={<UserList />} />
                                    <Route
                                        path="show/:id"
                                        element={<UserShow />}
                                    />
                                    <Route
                                        path="edit/:id"
                                        element={<UserEdit />}
                                    />
                                    <Route
                                        path="create"
                                        element={<UserCreate />}
                                    />
                                </Route>

                                <Route path="/customers">
                                    <Route index element={<CustomerList />} />
                                    <Route
                                        path="create"
                                        element={<CustomerCreate />}
                                    />
                                    <Route
                                        path="show/:id"
                                        element={<CustomerShow />}
                                    />
                                    <Route
                                        path="edit/:id"
                                        element={<CustomerEdit />}
                                    />
                                </Route>

                                <Route path="/payers">
                                    <Route index element={<CustomerList />} />
                                    <Route
                                        path="create"
                                        element={<CustomerCreate />}
                                    />
                                    <Route
                                        path="show/:id"
                                        element={<CustomerShow />}
                                    />
                                    <Route
                                        path="edit/:id"
                                        element={<CustomerEdit />}
                                    />
                                </Route>

                                <Route path="/tags">
                                    <Route index element={<TagList />} />
                                    <Route
                                        path="create"
                                        element={<TagCreate />}
                                    />
                                </Route>

                                <Route path="/companyAccounts">
                                    <Route index element={<CompanyAccountList />} />
                                    <Route
                                        path="create"
                                        element={<CompanyAccountCreate />}
                                    />
                                </Route>

                                <Route path="/preferences" element={<PreferencePage />}/>


                                {/* <Route path="/accounts">
                                    <Route
                                        path="create"
                                        element={<ModalAccountCreate />}
                                    />
                                </Route> */}
                                <Route path="*" element={<ErrorComponent />} />
                            </Route>

                            <Route
                                element={
                                    <Authenticated fallback={<Outlet />}>
                                        <NavigateToResource resource="dashboard" />
                                    </Authenticated>
                                }
                            >
                                <Route
                                    path="/login"
                                    element={
                                        <AuthPage
                                            type="login"
                                            registerLink={false}
                                            rememberMe={false}                                            
                                            title={"Split Fácil"}
                                            formProps={{
                                                initialValues: {
                                                    email: "",
                                                    password: "",
                                                },
                                            }}
                                        />
                                    }
                                />
                                <Route
                                    path="/register"
                                    element={
                                        <AuthPage
                                            type="register"
                                            formProps={{
                                                initialValues: {
                                                    email: "",
                                                    password: "",
                                                },
                                            }}
                                        />
                                    }
                                />
                                <Route
                                    path="/forgot-password"
                                    element={<AuthPage type="forgotPassword" />}
                                />
                                {/* <Route
                                    path="/update-password"
                                    element={<AuthPage type="updatePassword" />}
                                /> */}
                                <Route path="*" element={<ErrorComponent />} />
                            </Route>

                            <Route
                                element={
                                    <Authenticated>
                                        <ThemedLayoutV2
                                            Header={Header}
                                            Title={Title}
                                            OffLayoutArea={OffLayoutArea}
                                        >
                                            <Outlet />
                                        </ThemedLayoutV2>
                                    </Authenticated>
                                }
                            >
                                <Route path="*" element={<ErrorComponent />} />
                            </Route>
                        </Routes>
                        <UnsavedChangesNotifier />
                        <DocumentTitleHandler />
                    </Refine>
                </RefineKbarProvider>
            </ConfigProvider>
        </BrowserRouter>
    );
};

export default App;
