import { TOKEN_KEY, API_URL } from "./constants";
import { AuthBindings } from "@refinedev/core";
import { notification } from "antd";
import axios from "axios";
const axiosInstance = axios.create();

export const authProvider: AuthBindings = {
    login: async ({ email, password }) => {
        
        let response = await axios.post(`${API_URL}/auth`, {
            email,
            password,
        });

        console.log(response);
        if(response?.data?.token){
                
            localStorage.setItem(TOKEN_KEY, response.data.token);
            localStorage.setItem("companyId", response.data.user?.roles[0]?.company?.id);
            localStorage.setItem("companyFee", response.data.user?.roles[0]?.company?.defaultFee);
            localStorage.setItem("role", response.data.user?.roles[0]?.role);
            
            location.reload();
            // window.location.href = '/'; // full reload para home
            return {
                success: true,
                    redirectTo: "/",
            };
        }

        return {
            success: false,
            error: {
                name: "Login Error",
                message: "Username or password is incorrect",
            },
        };

    },
    register: async ({ email, password }) => {
        try {
            await authProvider.login({ email, password });
            return {
                success: true,
            };
        } catch (error) {
            return {
                success: false,
                error: {
                    message: "Register failed",
                    name: "Invalid email or password",
                },
            };
        }
    },
    updatePassword: async () => {
        notification.success({
            message: "Updated Password",
            description: "Password updated successfully",
        });
        return {
            success: true,
        };
    },
    forgotPassword: async ({ email }) => {

        let response = await axios.post(`${API_URL}/auth/forgotPassword`, {
            email
        });

        if(response?.data?.success){
            notification.success({
                message: "Nova Senha enviada por e-mail",
                description: `Uma senha temporária foi enviada para "${email}". Verifique sua caixa de entrada e spam.`,
            });
            return {
                success: true,
            };
        } else {
            return {
                success: false,
                error: {
                    name: "Error",
                    message: "Resgate de senha ",
                },
            };
        }
    },
    logout: async () => {
        localStorage.removeItem(TOKEN_KEY);
        localStorage.removeItem("companyId");
        localStorage.removeItem("role");
        localStorage.removeItem("companyFee");

        return {
            success: true,
            redirectTo: "/login",
        };
    },
    onError: async (error) => {
        const status = error?.status ?? error?.statusCode ?? error?.response?.status;
        console.log(status);
        console.log(error);

        if ([401,408,418].includes(Number(status))) {
            localStorage.removeItem(TOKEN_KEY);
            localStorage.removeItem("companyId");
            localStorage.removeItem("companyFee");
            localStorage.removeItem("role");
            return {
                logout: true,
                redirectTo: "/login",
                error: new Error(error),
            };
        }        
        return { error };
    },
    check: async () => {
        const token = localStorage.getItem(TOKEN_KEY);
        if (token) {
            return {
                authenticated: true,
            };
        }

        return {
            authenticated: false,
            error: {
                message: "Check failed",
                name: "Token not found",
            },
            logout: true,
            redirectTo: "/login",
        };
    },
    getPermissions: async () => null,
    getIdentity: async () => {
        const token = localStorage.getItem(TOKEN_KEY);

        if (!token) {
            return null;
        }
        
        let response = await axios.get(`${API_URL}/auth/me`,{
            headers: { Authorization: `Bearer ${token}` }
        });

        if(response?.data?.user){
            if(!localStorage.getItem("companyId")){
                localStorage.setItem("companyId", response.data.user?.roles[0]?.company?.id);
                localStorage.setItem("companyFee", response.data.user?.roles[0]?.company?.defaultFee);
                localStorage.setItem("role", response.data.user?.roles[0]?.role);
            }            
            return response.data.user
        }  

        return null;
    },
};
